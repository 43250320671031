import {
  Box,
  Divider,
  Grid,
  LinearProgress,
  linearProgressClasses,
  Link,
  List,
  ListItem,
  ListItemText,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Header from "../../common/Header";
import Card from "../../common/Card";
import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getInvoiceById,
  InvoiceDetailDTO,
  invoiceInfo,
  SaveInvoiceInfo,
} from "../../../redux/slice/invoice/InvoiceSlice";
import { tokens } from "../../../theme";
import styled from "styled-components";
import { useAppDispatch } from "../../../redux/slice/redux-hooks";
import { SUCCESS_CODE } from "../../../service/ServiceConstants";
import { CancelOutlined } from "@mui/icons-material";
import Button from "../../common/Button";
import { priceFormatter } from "../../../utils/Utility";

const BillingDetail = () => {
  const location = useLocation();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const colors = tokens(theme.palette.mode);
  const [selectedInvoice, setSelectedInvoice] = useState<InvoiceDetailDTO>();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const hasV3CommissionRate = selectedInvoice?.v3Commissions && selectedInvoice.v3Commissions.commissionRate !== null;
  const hasAgencyCommissionRate = selectedInvoice?.agencyCommissions && selectedInvoice.agencyCommissions.commissionRate !== null;
  const hasOvrAgencyCommissionRate = selectedInvoice?.ovrAgencyCommissions && selectedInvoice.ovrAgencyCommissions.commissionRate !== null;
  const expandGridItem = !hasV3CommissionRate || !hasAgencyCommissionRate || !hasOvrAgencyCommissionRate;

  useEffect(() => {
    const invoiceId = location.state?.invoiceId;
    if(invoiceId) {
      setInvoiceDetails(invoiceId);
    };
  }, []);

  const setInvoiceDetails = async (invoiceId: number) => {
    const response = await dispatch(getInvoiceById(invoiceId));
    if(response?.payload?.code === SUCCESS_CODE && response?.payload?.data) {
      setSelectedInvoice(response.payload.data);
    }
  };

  const paymentListData = [
    { date: "04/07/2024", amount: "$600.00" },
    { date: "21/07/2024", amount: "$400.00" },
  ];

  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    backgroundColor: colors.grey[900],
  }));

  const remainingPercentage = (totalAmount: number | null, remainingAmount: number | null) => {
    if(totalAmount && remainingAmount) {
      const remaining = remainingAmount / (totalAmount + remainingAmount) * 100;
      const percentageValue = 100 - remaining
      return percentageValue;
    } else if(remainingAmount && !totalAmount) {
      return 0;
    } else if (!remainingAmount && !totalAmount) {
      return 0;
    } else {
      return 100;
    }
  };

  const remainingInvoicePaymentAmount = useCallback((invoiceAmount: number, remainingAmount: number) => {
    if (remainingAmount) {
      return priceFormatter(remainingAmount);
    }
    else if (remainingAmount === null) {
      return priceFormatter(invoiceAmount);
    }
    else {
      return "$0.00";
    }
  }, []);

  const handleOrderListNavigate = (orderNumber: String) => {
    navigate("/order_list", { state: { orderNumber: orderNumber } });
  };

  return (
    <>
      <Box m="20px">
        <Header title="Invoice Detail" subtitle="Invoice Management" />
        <Box>
          <Card>
            <Grid container spacing={2}>
              <Grid item md={6} xs={12}>
                <Box
                  sx={{
                    border: 1,
                    borderRadius: 4,
                    p: 2,
                    borderColor: colors.grey[700],
                  }}
                >
                  <Typography variant="h4" fontWeight="bold" mb={2}>
                    Invoice Details
                  </Typography>

                  <Grid container spacing={2}>
                    <Grid item md={6} xs={12}>
                      <Typography variant="body2" color="text.secondary">
                        Invoice
                      </Typography>
                      <Typography variant="body1">
                        {selectedInvoice?.mfgInvoiceNumber}
                      </Typography>
                    </Grid>

                    {isMobile ? <Divider sx={{ width: "100%", marginY: 2 }} /> : ""}
                    <Grid item md={6} xs={12}>
                      <Typography variant="body2" color="text.secondary">
                        Invoice Amount
                      </Typography>
                      <Typography variant="body1">
                        {selectedInvoice?.invoiceAmount &&
                          priceFormatter(selectedInvoice.invoiceAmount)}
                      </Typography>
                    </Grid>
                  </Grid>

                  <Divider sx={{ width: "100%", marginY: 2 }} />
                  <Grid container spacing={2}>
                    {hasV3CommissionRate && (
                      <Grid item md={4} xs={12}>
                        <Typography variant="body2" color="text.secondary">
                          V3 Commission
                        </Typography>
                        <Typography variant="body1">
                          {selectedInvoice?.v3Commissions &&
                            priceFormatter((selectedInvoice.v3Commissions.remainingAmount + selectedInvoice.v3Commissions.totalAmount))}
                        </Typography>
                      </Grid>
                    )}

                    {isMobile ? <Divider sx={{ width: "100%", marginY: 2 }} /> : ""}
                    {hasAgencyCommissionRate && (
                      <Grid item md={4} xs={12}>
                        <Typography variant="body2" color="text.secondary">
                          Agency Commission
                        </Typography>
                        <Typography variant="body1">
                          {selectedInvoice?.agencyCommissions &&
                            priceFormatter((selectedInvoice.agencyCommissions.remainingAmount + selectedInvoice.agencyCommissions.totalAmount))}
                        </Typography>
                      </Grid>
                    )}

                    {isMobile ? <Divider sx={{ width: "100%", marginY: 2 }} /> : ""}
                    {hasOvrAgencyCommissionRate && (
                      <Grid item md={4} xs={12}>
                        <Typography variant="body2" color="text.secondary">
                          OVR Agency Commission
                        </Typography>
                        <Typography variant="body1">
                          {selectedInvoice?.ovrAgencyCommissions &&
                            priceFormatter((selectedInvoice.ovrAgencyCommissions.remainingAmount + selectedInvoice.ovrAgencyCommissions.totalAmount))}
                        </Typography>
                      </Grid>
                    )}
                  </Grid>

                  <Divider sx={{ width: "100%", marginY: 2 }} />
                  <Grid container spacing={2}>
                    <Grid item md={12} xs={12}>
                      <Typography variant="body2" color="text.secondary">
                        MFG
                      </Typography>
                      <Typography variant="body1">
                        {selectedInvoice?.mfgName}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>

              <Grid item md={6} xs={12}>
                <Box
                  sx={{
                    border: 1,
                    borderRadius: 4,
                    p: 2,
                    borderColor: colors.grey[700],
                  }}
                >
                  <Typography variant="h4" fontWeight="bold" mb={2}>
                    Other Details
                  </Typography>


                  <Grid container spacing={2}>
                    <Grid item md={6} xs={12}>
                      <Typography variant="body2" color="text.secondary">
                        Practice
                      </Typography>
                      <Typography variant="body1">
                        {selectedInvoice?.practiceName}
                      </Typography>
                      {isMobile ? <Divider sx={{ width: "100%", marginY: 2 }} /> : ""}
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <Typography variant="body2" color="text.secondary">
                        Product
                      </Typography>
                      <Typography variant="body1">
                        {selectedInvoice?.productName}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Divider sx={{ width: "100%", marginY: 2 }} />

                  <Grid container spacing={2}>
                    <Grid item md={6} xs={12}>
                      <Typography variant="body2" color="text.secondary">
                        Agency
                      </Typography>
                      <Typography variant="body1">
                        {selectedInvoice?.agencyName}
                      </Typography>
                      {isMobile ? <Divider sx={{ width: "100%", marginY: 2 }} /> : ""}
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <Typography variant="body2" color="text.secondary">
                        Override Agency
                      </Typography>
                      <Typography variant="body1">
                        {selectedInvoice?.overrideAgencyName}
                      </Typography>
                      {isMobile ? <Divider sx={{ width: "100%", marginY: 2 }} /> : ""}
                    </Grid>
                  </Grid>
                  <Divider sx={{ width: "100%", marginY: 2 }} />

                  <Grid container spacing={2}>
                    <Grid item md={6} xs={12}>
                      <Typography variant="body2" color="text.secondary">
                        Order
                      </Typography>
                      <Typography variant="body1">
                        {selectedInvoice?.orderNumber !== null && selectedInvoice?.orderNumber !== undefined ? (
                          <Link
                            component="button"
                            underline="hover"
                            variant="body2"
                            onClick={() => handleOrderListNavigate(selectedInvoice.orderNumber)}
                            color={colors.info[300]}
                          >
                            {selectedInvoice?.orderNumber}
                          </Link>
                        ) : (
                          selectedInvoice?.orderNumber || ''
                        )}
                      </Typography>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <Typography variant="body2" color="text.secondary">
                        Agent
                      </Typography>
                      <Typography variant="body1">
                        {selectedInvoice?.agentNames?.join(", ")} 
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Card>

          <Box sx={{ mt: 2 }}>
            <Grid container spacing={2} className="grid-container">
              {selectedInvoice?.payment && (
                <Grid item xs={12} md={expandGridItem? 4 : 3} className="grid-item">
                  <Card sx={{ height: "100%", display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                    <Box>
                      <Typography variant="h5" fontWeight="bold">
                        Payments
                      </Typography>
                      {selectedInvoice?.payment?.paymentInfo?.map((paymentInfo) => (
                        <Box display="flex" justifyContent="space-between" mt={2}>
                          <Typography variant="body1">
                            {paymentInfo.paymentDate}
                          </Typography>
                          <Typography variant="body1">
                            {priceFormatter(paymentInfo.paymentAmount)}
                          </Typography>
                        </Box>
                      ))}
                    </Box>
                    <Box>
                      <Box display="flex" justifyContent="space-between" mt={2}>
                        <Typography variant="h5" fontWeight="bold">
                          Total Amount
                        </Typography>
                        <Typography variant="h5" fontWeight="bold">
                        {selectedInvoice?.payment?.totalAmount ?
                          priceFormatter(
                            Number(selectedInvoice?.payment.totalAmount)
                          ) : "$0.00" }
                        </Typography>
                      </Box>
                      <Box display="flex" justifyContent="space-between" mt={2}>
                        <Typography variant="body1">Remaining Amount</Typography>
                        <Typography variant="body1">
                          {/* {selectedInvoice?.payment?.remainingAmount ?
                            priceFormatter(
                              Number(selectedInvoice.payment.remainingAmount)
                            ) : "$0.00"} */}
                          {remainingInvoicePaymentAmount(selectedInvoice.invoiceAmount, selectedInvoice.payment.remainingAmount)}
                        </Typography>
                      </Box>
                      <Box display="flex" alignItems="center" mt={1}>
                        <BorderLinearProgress
                          variant="determinate"
                          value={remainingPercentage(
                            selectedInvoice?.payment?.totalAmount
                              ? parseInt(selectedInvoice.payment.totalAmount)
                              : null,
                            selectedInvoice?.payment?.remainingAmount
                              ? selectedInvoice.payment.remainingAmount
                              : null
                          )}
                          color="warning"
                          sx={{ flexGrow: 1, marginRight: '10px' }}
                        />
                        <Typography variant="body2">
                          {`${Math.round(remainingPercentage(
                            selectedInvoice?.payment?.totalAmount
                              ? parseInt(selectedInvoice.payment.totalAmount)
                              : null,
                            selectedInvoice?.payment?.remainingAmount
                              ? selectedInvoice.payment.remainingAmount
                              : null
                          ))}%`}
                        </Typography>
                      </Box>
                    </Box>
                  </Card>
                </Grid>
              )}

              {hasV3CommissionRate && (
                <Grid item xs={12} md={expandGridItem? 4 : 3} className="grid-item">
                  <Card sx={{ height: "100%", display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                    <Box>
                      <Typography variant="h5" fontWeight="bold">
                        V3 Commission {selectedInvoice?.v3Commissions?.commissionRate ? ` (${selectedInvoice.v3Commissions.commissionRate}%)` : ""}
                      </Typography>
                      {selectedInvoice?.v3Commissions?.commissionInfo.map((commissionInfo) => (
                        <Box display="flex" justifyContent="space-between" mt={2}>
                          <Typography variant="body1">
                            {commissionInfo.commissionDate}
                          </Typography>
                          <Typography variant="body1">
                            {priceFormatter(commissionInfo.commissionAmount)}
                          </Typography>
                        </Box>
                      ))}
                    </Box>
                    <Box>
                      <Box display="flex" justifyContent="space-between" mt={2}>
                        <Typography variant="h5" fontWeight="bold">
                          Total Amount
                        </Typography>
                        <Typography variant="h5" fontWeight="bold">
                          {selectedInvoice?.v3Commissions?.totalAmount ?
                            priceFormatter(selectedInvoice.v3Commissions.totalAmount) : "$0.00"}
                        </Typography>
                      </Box>
                      <Box display="flex" justifyContent="space-between" mt={2}>
                        <Typography variant="body1">Remaining Amount</Typography>
                        <Typography variant="body1">
                          {selectedInvoice?.v3Commissions?.remainingAmount ? 
                            priceFormatter(selectedInvoice.v3Commissions.remainingAmount) : "$0.00"}
                        </Typography>
                      </Box>
                      <Box display="flex" alignItems="center" mt={1}>
                        <BorderLinearProgress
                          variant="determinate"
                          value={remainingPercentage(
                            selectedInvoice?.v3Commissions?.totalAmount
                              ? selectedInvoice.v3Commissions.totalAmount
                              : null,
                            selectedInvoice?.v3Commissions?.remainingAmount
                              ? selectedInvoice.v3Commissions.remainingAmount
                              : null
                          )}
                          color="info"
                          sx={{ flexGrow: 1, marginRight: '10px' }}
                        />
                        <Typography variant="body2">
                          {`${Math.round(remainingPercentage(
                            selectedInvoice?.v3Commissions?.totalAmount
                              ? selectedInvoice.v3Commissions.totalAmount
                              : null,
                            selectedInvoice?.v3Commissions?.remainingAmount
                              ? selectedInvoice.v3Commissions.remainingAmount
                              : null
                          ))}%`}
                        </Typography>
                      </Box>
                    </Box>
                  </Card>
                </Grid>
              )}

              {hasAgencyCommissionRate && (
                <Grid item xs={12} md={expandGridItem? 4 : 3} className="grid-item">
                  <Card sx={{ height: "100%", display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                    <Box>
                    <Typography variant="h5" fontWeight="bold">
                      {selectedInvoice?.agencyName
                        ? `${selectedInvoice.agencyName} - Agency Commission (${selectedInvoice.agencyCommissions.commissionRate}%)`
                        : selectedInvoice?.agencyCommissions
                        ? `Agency Commission (${selectedInvoice.agencyCommissions.commissionRate}%)`
                        : ""}
                    </Typography>
                      {/* <Typography variant="h5" fontWeight="bold">
                        Agency Commission Payouts {selectedInvoice?.agencyCommissions?.commissionRate ? ` (${selectedInvoice.agencyCommissions.commissionRate}%)` : ""}
                      </Typography> */}
                      {selectedInvoice?.agencyCommissions?.commissionInfo.map((commissionInfo) => (
                        <Box display="flex" justifyContent="space-between" mt={2}>
                          <Typography variant="body1">
                            {commissionInfo.commissionDate}
                          </Typography>
                          <Typography variant="body1">
                            {priceFormatter(commissionInfo.commissionAmount)}
                          </Typography>
                        </Box>
                      ))}
                    </Box>
                    <Box>
                      <Box display="flex" justifyContent="space-between" mt={2}>
                        <Typography variant="h5" fontWeight="bold">
                          Total Amount
                        </Typography>
                        <Typography variant="h5" fontWeight="bold">
                          {selectedInvoice?.agencyCommissions?.totalAmount ?
                            priceFormatter(selectedInvoice.agencyCommissions.totalAmount) : "$0.00"}
                        </Typography>
                      </Box>
                      <Box display="flex" justifyContent="space-between" mt={2}>
                        <Typography variant="body1">Remaining Amount</Typography>
                        <Typography variant="body1">
                          {selectedInvoice?.agencyCommissions?.remainingAmount ? 
                            priceFormatter(selectedInvoice.agencyCommissions.remainingAmount) : "$0.00"}
                        </Typography>
                      </Box>
                      <Box display="flex" alignItems="center" mt={1}>
                        <BorderLinearProgress
                          variant="determinate"
                          value={remainingPercentage(
                            selectedInvoice?.agencyCommissions?.totalAmount
                              ? selectedInvoice.agencyCommissions.totalAmount
                              : null,
                            selectedInvoice?.agencyCommissions?.remainingAmount
                              ? selectedInvoice.agencyCommissions.remainingAmount
                              : null
                          )}
                          color="error"
                          sx={{ flexGrow: 1, marginRight: '10px' }}
                        />
                        <Typography variant="body2">
                          {`${Math.round(remainingPercentage(
                            selectedInvoice?.agencyCommissions?.totalAmount
                              ? selectedInvoice.agencyCommissions.totalAmount
                              : null,
                            selectedInvoice?.agencyCommissions?.remainingAmount
                              ? selectedInvoice.agencyCommissions.remainingAmount
                              : null
                          ))}%`}
                        </Typography>
                      </Box>
                    </Box>
                  </Card>
                </Grid>
              )}

              {hasOvrAgencyCommissionRate && (
                <Grid item xs={12} md={expandGridItem? 4 : 3} className="grid-item">
                  <Card sx={{ height: "100%", display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                    <Box>
                    <Typography variant="h5" fontWeight="bold">
                      {selectedInvoice?.overrideAgencyName
                        ? `${selectedInvoice.overrideAgencyName} - OVR Agency Commission (${selectedInvoice.ovrAgencyCommissions.commissionRate}%)`
                        : selectedInvoice?.ovrAgencyCommissions
                        ? `OVR Agency Commission (${selectedInvoice.ovrAgencyCommissions.commissionRate}%)`
                        : ""}
                    </Typography>
                      {/* <Typography variant="h5" fontWeight="bold">
                        OVR Agency Commission Payouts {selectedInvoice?.ovrAgencyCommissions?.commissionRate ? ` (${selectedInvoice.ovrAgencyCommissions.commissionRate}%)` : ""}
                      </Typography> */}
                      {selectedInvoice?.ovrAgencyCommissions?.commissionInfo.map((commissionInfo) => (
                        <Box display="flex" justifyContent="space-between" mt={2}>
                          <Typography variant="body1">
                            {commissionInfo.commissionDate}
                          </Typography>
                          <Typography variant="body1">
                            {priceFormatter(commissionInfo.commissionAmount)}
                          </Typography>
                        </Box>
                      ))}
                    </Box>
                    <Box>
                      <Box display="flex" justifyContent="space-between" mt={2}>
                        <Typography variant="h5" fontWeight="bold">
                          Total Amount
                        </Typography>
                        <Typography variant="h5" fontWeight="bold">
                          {selectedInvoice?.ovrAgencyCommissions?.totalAmount ?
                            priceFormatter(selectedInvoice.ovrAgencyCommissions.totalAmount) : "$0.00"}
                        </Typography>
                      </Box>
                      <Box display="flex" justifyContent="space-between" mt={2}>
                        <Typography variant="body1">Remaining Amount</Typography>
                        <Typography variant="body1">
                          {selectedInvoice?.ovrAgencyCommissions?.remainingAmount ?
                            priceFormatter(selectedInvoice.ovrAgencyCommissions.remainingAmount) : "$0.00"}
                        </Typography>
                      </Box>
                      <Box display="flex" alignItems="center" mt={1}>
                        <BorderLinearProgress
                          variant="determinate"
                          value={remainingPercentage(
                            selectedInvoice?.ovrAgencyCommissions?.totalAmount
                              ? selectedInvoice.ovrAgencyCommissions.totalAmount
                              : null,
                            selectedInvoice?.ovrAgencyCommissions?.remainingAmount
                              ? selectedInvoice.ovrAgencyCommissions.remainingAmount
                              : null
                          )}
                          color="success"
                          sx={{ flexGrow: 1, marginRight: '10px' }}
                        />
                        <Typography variant="body2">
                          {`${Math.round(remainingPercentage(
                            selectedInvoice?.ovrAgencyCommissions?.totalAmount
                              ? selectedInvoice.ovrAgencyCommissions.totalAmount
                              : null,
                            selectedInvoice?.ovrAgencyCommissions?.remainingAmount
                              ? selectedInvoice.ovrAgencyCommissions.remainingAmount
                              : null
                          ))}%`}
                        </Typography>
                      </Box>
                    </Box>
                  </Card>
                </Grid>
              )}
            </Grid>
            <Box display="flex" justifyContent="flex-end" mt="40px">
              <Grid>
                <Button
                  color="error"
                  startIcon={<CancelOutlined />}
                  onClick={() => navigate(-1)}
                >
                  Cancel
                </Button>
              </Grid>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default BillingDetail;
