import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";
import Button from "../../common/Button";
import CustomTextField from "../../common/CustomTextField";
import { useForm } from "react-hook-form";
import { ProductCommissionAgency, Option, AgencyOption } from "../../../redux/slice/agency/AgencySlice";
import CustomDatePicker from "../../common/CustomDatePicker";
import CustomDropdownField from "../../common/CustomDropdownFiled";
import { Cancel } from "@mui/icons-material";

interface Row {
  productName: string | undefined;
  effectiveDate: string | undefined;
  inactiveDate: string | undefined;
  commissionRate: number | string | undefined,
}

interface AddProductCommissionModalProps {
  open: boolean;
  productOptions: Option[];
  selectedAssociateTypeOption: any;
  setSelectedAssociateType: any;
  associateAgencyOptions: AgencyOption[];
  associateOptions: any
  practiceListOptions: any;
  agencyExecutivesOptions: any;
  isCommTypeOverride: boolean;
  editCommission: boolean;
  defaultValue: Row,
  handleClose: () => void;
  submitCommission: (commission: ProductCommissionAgency) => void;
}

const AddProductCommissionModal: React.FC<AddProductCommissionModalProps> = ({
  open,
  productOptions,
  associateAgencyOptions,
  agencyExecutivesOptions,
  associateOptions,
  practiceListOptions,
  isCommTypeOverride,
  selectedAssociateTypeOption,
  setSelectedAssociateType,
  editCommission,
  defaultValue,
  handleClose,
  submitCommission,

}) => {
  const { control, handleSubmit, reset, setValue } = useForm<ProductCommissionAgency>();

  const onCloseAction = () => {
    handleClose();
    reset();
    setSelectedAssociateType('')
  };
  const defaultValues = useCallback(() => {
    if (defaultValue) {
      console.log(defaultValue);
      reset(defaultValue);
    } else {
      // reset();
    }
  }, [defaultValue, reset]);

  useEffect(() => {
    defaultValues();
  }, [defaultValues]);

  return (
    // <Dialog open={open} onClose={onCloseAction}>
    <Dialog open={open} >
      <DialogTitle>Add Agency Product Commission</DialogTitle>
      <form onSubmit={handleSubmit(submitCommission)}>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} style={{ marginTop: '5px' }}>
              <CustomDropdownField
                name="productId"
                control={control}
                options={productOptions}
                label="Product"
                rules={{
                  required: "Product is required.",
                  validate: {
                    notZero: (value: number) =>
                      value !== 0 || "Product is required.",
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <CustomTextField
                control={control}
                controllerName="commissionRate"
                label="Commission %"
                rules={{
                  required: "Commission Rate is required.",
                  pattern: {
                    value: /^[0-9]+(\.[0-9]+)?$/,
                    message: "Invalid Commission Rate.",
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <CustomTextField
                control={control}
                controllerName="commissionPayTerms"
                label="Commission Pay Terms "
                rules={{
                  required: "Commission Pay Terms is required.",
                }}
              />
            </Grid>
            {isCommTypeOverride && <Grid item xs={12} style={{ marginTop: '5px' }}>
              <CustomDropdownField name="associateType"
                control={control}
                label="Associate Type"
                options={associateOptions}
                rules={{ required: "Associate Type is required." }}
                onChangeSelect={(data) => {
                  if (data) {
                    if (data.value === "PRACTICE") {
                      setSelectedAssociateType(data.value)
                      setValue("associateAgencyId", undefined)
                      setValue("associateAccountRepId", undefined)
                    } else if (data.value === "ACCOUNTREP") {
                      setSelectedAssociateType(data.value)
                      setValue("associateAgencyId", undefined)
                      setValue("associatePracticeId", undefined)
                    } else if (data.value === "AGENCY") {
                      setSelectedAssociateType(data.value)
                      setValue("associatePracticeId", undefined)
                      setValue("associateAccountRepId", undefined)
                    }else {
                      setSelectedAssociateType('')
                      setValue("associateAgencyId", undefined)
                      setValue("associateAccountRepId", undefined)
                      setValue("associatePracticeId", undefined)
                    }
                  } else {
                    setSelectedAssociateType('')
                    setValue("associateAgencyId", undefined)
                    setValue("associateAccountRepId", undefined)
                    setValue("associatePracticeId", undefined)
                  }
                }}
              />
            </Grid>}
            {isCommTypeOverride && (selectedAssociateTypeOption === "PRACTICE" ) && <Grid item xs={12} style={{ marginTop: '5px' }}>
              <CustomDropdownField
                name="associatePracticeId"
                control={control}
                options={practiceListOptions}
                label="Associate Practice"
                rules={{ required: "Associate Practice is required." }}
              />
            </Grid>}
            {isCommTypeOverride && (selectedAssociateTypeOption === "AGENCY") && <Grid item xs={12} style={{ marginTop: '5px' }}>
              <CustomDropdownField
                name="associateAgencyId"
                control={control}
                options={associateAgencyOptions}
                label="Associate Agency"
                rules={{ required: "Associate Agency is required." }}
              />
            </Grid>}
            {isCommTypeOverride && (selectedAssociateTypeOption === "ACCOUNTREP") && <Grid item xs={12} style={{ marginTop: '5px' }}>
              <CustomDropdownField
                name="associateAccountRepId"
                control={control}
                options={agencyExecutivesOptions}
                label="Associate Account Representative"
                rules={{ required: "Associate Account Representative is required." }}
              />
            </Grid>}
            <Grid item xs={12}>
              <CustomDatePicker
                control={control}
                controllerName="effectiveDate"
                label="Effective Date"
                rules={{ required: "Effective Date is required." }}
              />
            </Grid>
            <Grid item xs={12}>
              <CustomDatePicker
                control={control}
                controllerName="inactiveDate"
                label="Inactive Date"
              // rules={{ required: "Inactive Date is required." }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button type="submit">{editCommission ? "Update Commission" : "Save Commission"} </Button>
          <Button color="error" startIcon={<Cancel />} onClick={() => onCloseAction()}>
            Cancel
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AddProductCommissionModal;
