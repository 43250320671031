import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GeneralV3Response, InterceptorOption, LoadingType } from "../../../types/CommonTypes";
import { fetchPracticeExecutiveById, updatePracticeExecutive } from "../../../service/PracticeService";
import { useAppSelector } from "./../redux-hooks";
import { SUCCESS_CODE } from "../../../service/ServiceConstants";

export type PracticeExecutiveInfo = {
  practiceExecutiveId: number;
  agency: {
    agencyId: number;
    agencyName: string;
  };
  user: {
    userId: number | null;
    firstName: string;
    lastName: string;
    middleName: string;
  };
  agencyId?: number;
};

export type UpdatePracticeExInfo = {
  practiceExecutive : {
    practiceExecutiveId?: number,
    agencyId?: number,
    userId?: number
  }[],
  agencyId?: number,
  practiceId: number
}

type PracticeExecutiveState = {
  practiceExecutiveInfo?: PracticeExecutiveInfo[] | null;
  status?: LoadingType;
  error?: string;
};

const initialState: PracticeExecutiveState = {
  practiceExecutiveInfo: undefined,
  status: "idle",
};

interface PracticeData {
  practiceId: number;
  isInactive?: boolean;
}


export const fetchPracticeExecutiveData = createAsyncThunk(
  "fetchPracticeExecutiveData",
  async ( data: number | PracticeData, {rejectWithValue}) => {
    try{
      let practiceId: number;
      let isInactive: boolean | undefined;
      if(typeof data == 'number') {
        practiceId = data;
        isInactive = false;
      } else {
        practiceId = data.practiceId;
        isInactive = data?.isInactive;
      }

      const response = await fetchPracticeExecutiveById(practiceId, isInactive);
    return response.data;
    } catch (error){
      return rejectWithValue(error);
    }
  }
);

export const updatePracticeExecutiveById = createAsyncThunk(
  "updatePracticeExecutive",
  async (data: UpdatePracticeExInfo, { rejectWithValue }) => {
    try {
      const response = await updatePracticeExecutive(data);

      if (response.data.code !== SUCCESS_CODE) {
        return rejectWithValue(response.data);
      }
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.data);
    }
  }
);

const practiceExecutiveSlice = createSlice({
  name: "practiceExecutive",
  initialState,
  reducers: {
    updatePracticeExecutiveInfoObject: (
      state: PracticeExecutiveState,
      action
    ) => {
      state.practiceExecutiveInfo = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPracticeExecutiveData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(
        fetchPracticeExecutiveData.fulfilled,
        (state, action: PayloadAction<GeneralV3Response>) => {
          state.status = "success";
          state.practiceExecutiveInfo = action.payload.data;
        }
      )
      .addCase(fetchPracticeExecutiveData.rejected, (state, action) => {
        state.status = "error";
      })
      .addCase(updatePracticeExecutiveById.pending, (state) => {
        state.status = "loading";
      })
      .addCase(
        updatePracticeExecutiveById.fulfilled,
        (state, action: PayloadAction<GeneralV3Response>) => {
          if (action.payload.code === SUCCESS_CODE) {
            state.status = "success";
            state.practiceExecutiveInfo = action.payload.data;
          } else {
            state.status = "error";
            state.error = action.payload.message;
          }
        }
      )
      .addCase(updatePracticeExecutiveById.rejected, (state, action) => {
        state.status = "error";
        if (action.error.message) {
          state.error = action.error.message;
        } else {
          state.error = "An unknown error occurred.";
        }
      });
  },
});

export const { updatePracticeExecutiveInfoObject } =
  practiceExecutiveSlice.actions;
export const usePracticeExecutiveSlice = () =>
  useAppSelector((state) => state.practiceExecutiveSlice);
export default practiceExecutiveSlice.reducer;
