import React, { useEffect, useMemo } from 'react'
import { CancelOutlined, CheckCircleOutlineOutlined,NoteAddOutlined } from '@mui/icons-material';
import { Box, DialogActions, FormControl, FormGroup, Grid, useTheme } from '@mui/material';
import Card from "../../common/Card";
import Header from '../../common/Header';
import { PracticeInfo } from '../../../redux/slice/practiceSlice';
import { useAppDispatch } from '../../../redux/slice/redux-hooks';
import Button from '../../common/Button';
import { useLocation, useNavigate } from 'react-router-dom';
import CustomRadioButton from '../../common/CustomRadioButton';
import { useForm } from 'react-hook-form';
import CustomTextField from '../../common/CustomTextField';
import Table from '../../common/CustomTable';
import CustomDropdownField from '../../common/CustomDropdownFiled';
import {
  AgencyExecutives, PracticeExecutive, PracticeLocationInfo, PracticeProviderInfo, RejectPractice, approvePracticeRequest, fetchActiveAgencyExecutives,
  fetchActiveBillers, rejectPractice, usePracticeApproveSlice
} from '../../../redux/slice/practice/practiceApproveSlice';
import PracticeApprovePopup from './PracticeApprovePopup';
import { formatAddress, fullName } from '../../../utils/Utility';
import { tokens } from '../../../theme';
import CustomEmailChipField from '../../common/CustomEmailChipField';
import OptionsPopup, { OptionPopupObjectProps } from '../../common/OptionsPopup';
import { SingleAlertInfo, fetchAgenciesList, setSingleAlertObj, useCommonReducer } from '../../../redux/slice/commonSlice';
import CustomMultipleDropDown from '../../common/CustomMultipleDropDown';


interface Column {
  id: string;
  label: string;
  minWidth?: number;
  align?: "left" | "center" | "right";
  format?: (value: number) => string;
}

type Option = {
  value: string | number;
  label: string;
};

interface ApprovePracticeUIState {
  practiceId: string | undefined,
  practiceExecutive: PracticeExecutive[] | null,
  agencyId: number | null;
  notificationEmails: string,
  approvalComments: string,
  billerId: number | null,
  billingType: string | null,
  practiceName: string | null,
  discount: string[] | null,
  practiceExDropDown: any
};

const locationColumns: Column[] = [
  { id: "id", label: "Practice Location Address", minWidth: 100 },
];

const providerColumns: Column[] = [
  { id: "name", label: "Provider Name", minWidth: 100 },
  { id: "npi", label: "Provider NPI", minWidth: 100 },
  { id: "ptan", label: "Provider PTAN", minWidth: 100 },
];

function PracticeRequest() {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const {  agencyExecutives, billerList, errorList } = usePracticeApproveSlice();
  const { agencyInfoList } = useCommonReducer();
  const [locationRows, setLocationRows] = React.useState<{ id: string }[]>([]);
  const [providerRows, setProviderRows] = React.useState<{ name: string, npi: string, ptan: string }[]>([]);
  let [practice, setPractice] = React.useState<PracticeInfo>();
  const { control, handleSubmit, setValue, getValues, reset, watch } = useForm<ApprovePracticeUIState>();
  const [showPracticesPopup, setShowPracticesPopup] = React.useState(false);
  const [isRejectValidations, setIsRejectValidations] = React.useState(false);
  const [showOptionPopup, setShowOptionsPopup] = React.useState<OptionPopupObjectProps>({message:'', show:false});
  let initialValues: ApprovePracticeUIState = {
    practiceId: '',
    practiceExecutive: null,
    agencyId: null,
    notificationEmails: '',
    approvalComments: '',
    billerId: null,
    billingType: null,
    practiceName: null,
    discount: null,
    practiceExDropDown: null
  };

  useEffect(() => {
    const practiceRow = location.state.rowValue;
    setDefaultValues();
    if (practiceRow) {
      setPractice(practiceRow);
      setValue("practiceId", practiceRow.practiceId);
      setValue("billingType", practiceRow.billingType);
      setValue("practiceName", practiceRow.practiceName);
    }
    dispatch(fetchActiveAgencyExecutives({getAgencyAdmins: true, isInactive: false}));
    dispatch(fetchActiveBillers());
    dispatch(fetchAgenciesList());
  
    setLocations(practiceRow);
    setProviders(practiceRow);
  }, []);

  const setDefaultValues = () => {
    reset(initialValues);
  };

  const setLocations = (practiceRow: any) => {
    const locationsArr: { id: string; }[] = [];
    practiceRow && practiceRow.practiceLocations?.forEach((location: PracticeLocationInfo) => {
      locationsArr.push({
        id: formatAddress(location.streetAddress, location.unitNumber, location.city, location.state, location.zipCode)
      });
    });
    setLocationRows(locationsArr);
  };

  const setProviders = (practiceRow: any) => {
    const providersArr: { name: string, npi: string, ptan: string }[] = [];
    practiceRow && practiceRow.practiceProviders?.forEach((provider: PracticeProviderInfo) => {
      providersArr.push({
        name: provider.title + " " + provider.firstName + " " + provider.middleName + " " + provider.lastName,
        npi: provider.npi,
        ptan: provider.ptan
      });
    });
    setProviderRows(providersArr);
  }

  const onSubmit = async () => {
    setAgencyDropdowns();
    const { billingType, practiceName, discount, practiceExDropDown, ...approvePracticeObj } = getValues();
    setShowOptionsPopup({
      message: "Do you want to Approve the practice? ",
      show: true,
      variant: "confirm",
      buttons: [{ name: "Confirm", color: 'primary', onClick: () => approvePractice(approvePracticeObj) }, { name: "Cancel", color: 'secondary', onClick: cancelOptionPopup }]
    });
  };

  const approvePractice = async (approvePracticeObj: any) => {
      const response = await dispatch(approvePracticeRequest(approvePracticeObj));
      if(response.meta.requestStatus === "fulfilled") {
        let successObj: SingleAlertInfo = {
          message: `Practice ${practice && practice.practiceName} has been Approved.`,
          alertType: "success"
        };
        dispatch(setSingleAlertObj(successObj));
        navigate("/practice_list");
      };
    cancelOptionPopup(); 
  };

  const cancelOptionPopup = () => {
    setShowOptionsPopup({ message: "", show: false });
  };

  const clickShowPracticesPopup = () => {
    setShowPracticesPopup(true);
  };

  const clickClosePracticesPopup = () => {
    setShowPracticesPopup(false);
  };

  const reject = async () => {
    const formData = getValues();
    let message: string = "";
    let type: "error" | "success" | "warning" | "info" = "info";
      try {
        let rejectDataObj : RejectPractice = {
          practiceId : practice && practice.practiceId,
          approvalComments : formData.approvalComments
        };
        await dispatch(
          rejectPractice(rejectDataObj)
        ).then((res) => {
          if(res.payload?.message === "SUCCESS"){
            message = `Practice ${practice && practice.practiceName} has been Rejected.`;
            type = "warning";
          }else{
            //@ts-ignore
            message = res.payload ? res.payload.message : res.console.error.message;
            type = "error"
          }
          let successObj: SingleAlertInfo = {
            message: message,
            alertType: type
          }
          dispatch(setSingleAlertObj(successObj));
          // navigate("/practice_list", {state: {status: message, alertType: type}});
          navigate(-1);
        });
      } catch (e) {
        console.error(e);
    };
    cancelOptionPopup(); 
  };

  const handleReject = async () => {

    // const formData = getValues();
    // let errorObjs = [];
    // if(formData.approvalComments === null || formData.approvalComments === ""){
    //   let errorObj = {};
    //   errorObj = {
    //     errIdentifier : "approvalComments",
    //     errMessage : "Approval Comments are required."
    //   };
    //   errorObjs.push(errorObj)
    //   dispatch(updatePracticeApproveErrorList(errorObjs));
    //   let newFormData = {...formData, notificationEmails: "", practiceExDropDown: null}
    //   reset(newFormData);

    //   let errObj: SingleAlertInfo = {
    //     message: "Additional notes / comments required.",
    //     alertType: "error"
    //   };
    //   dispatch(setSingleAlertObj(errObj));
    // } else {
      setShowOptionsPopup({
        message: "Do you want to Reject the practice? ",
        show: true,
        variant: "confirm",
        buttons: [{ name: "Confirm", color: 'primary', onClick: reject }, { name: "Cancel", color: 'secondary', onClick: cancelOptionPopup }]
      });
    // };
  };

  const practiceExOps = useMemo(() => {
    let options: Option[] = [];
    if(getValues('agencyId') && agencyExecutives) {
      agencyExecutives?.map(executive => {
        if(executive.agencyId === getValues('agencyId') )
        options.push({ value: executive.userId, label: executive.agencyName + " - " + fullName(null,executive.firstName, executive.middleName, executive.lastName) });
      });
    }
    return options;
  }, [watch('agencyId')]);

  const billerOptions = useMemo(() => {
    let options: Option[] = [];
    billerList?.map((option) => {
      options.push({ value: option.billerId, label: option.billerName });
    });
    return options;
  }, [billerList]);

  const agencyList = useMemo(() => {
    let options: Option[] = [];
    agencyInfoList?.map((option) => {
      options.push({ label: option.agencyName, value: option.agencyId });
    });
    return options;
  }, [agencyInfoList]);

  const approveValidationRules = {
    practiceAgency : { required: "Agency is required." },
    notificationEmails : {required: "Notification Emails are required." },
  };

  useEffect(() => {
    if(isRejectValidations){
      handleReject();
      setIsRejectValidations(false);
    }
  }, [isRejectValidations]);

  let errorIdentifiers = [];
  let errorMessages = [];

  if(errorList){
    for(let i=0; i< errorList.length; i++) {
      //@ts-ignore
      errorIdentifiers.push(errorList[i].errIdentifier);
  }
}

const setAgencyDropdowns = () => {
  const exIds = getValues('practiceExDropDown');
  const practiceExs: PracticeExecutive[] = [] 
  exIds?.forEach((id: number) => {
    let selectedExecutive: AgencyExecutives | undefined = agencyExecutives?.find(ex => ex.userId === id);
    if(selectedExecutive) {
      practiceExs.push({ userId: selectedExecutive.userId, agencyId: selectedExecutive.agencyId });
    }
  });

  setValue("practiceExecutive", practiceExs);
}

  return (
    <>
      {showOptionPopup.show &&
        <OptionsPopup
          open={showOptionPopup.show}
          onClose={() => setShowOptionsPopup({ message: "", show: false })}
          variant={showOptionPopup.variant}
          message={<>{showOptionPopup.message}</>}
          buttons={showOptionPopup.buttons}
          title={showOptionPopup.title}
          obj={showOptionPopup.obj}
        />
      }
    <PracticeApprovePopup onClose={clickClosePracticesPopup} open={showPracticesPopup} />
    <Box m="20px">
      <Header
        title="Practice Approval"
        subtitle=""
      />
      <Card>
        <form >
          <FormGroup>

            <div>
              <Grid container spacing={2}>
                <Grid item md={6} xs={12}>
                  <CustomTextField control={control} label="Practice Name" controllerName="practiceName" />
                </Grid>
                <Grid item md={6} xs={12}>
                    <DialogActions>
                    <Grid
                        container
                        justifyContent="flex-end"
                        sx={{ mt: 1, flexWrap: "wrap" }}
                        spacing={1}
                      >
                        <Grid item xs={12} sm="auto">
                          <Button fullWidth size="large" startIcon={<NoteAddOutlined />} color="info" onClick={clickShowPracticesPopup}>Practice Request</Button>
                        </Grid>
                      </Grid>
                    </DialogActions>
                </Grid>
                <Grid item md={6} xs={12}>
                  <Box sx={{backgroundColor: theme.palette.mode === "dark" ? colors.primary[500] :colors.grey[700], borderRadius : 2}} p={1}>
                    <Table
                      columns={providerColumns}
                      rows={providerRows} 
                      disablePagination={true}/>
                  </Box>
                </Grid>
                <Grid item md={6} xs={12}>
                  <Box sx={{backgroundColor: theme.palette.mode === "dark" ? colors.primary[500] :colors.grey[700], borderRadius : 2}} p={1}>
                    <Table
                      columns={locationColumns}
                      rows={locationRows}
                      disablePagination={true} />
                  </Box>
                </Grid>
                <Grid item md={12}>
                  <p style={{ fontSize: "medium" }}>
                    How the practice bills?
                  </p>
                  <FormControl>
                    <CustomRadioButton control={control} controllerName="billingType" options={[{ value: "INDIVIDUAL", label: "Individual NPI" }, { value: "GROUP", label: "Group NPI" }]} ></CustomRadioButton>
                  </FormControl>
                </Grid>
                <Grid item md={10}></Grid>
                <Grid item md={3}  xs={12}>
                  <CustomDropdownField control={control} name='billerId' options={billerOptions} label="Practice Biller" disableSearch />
                </Grid>
                <Grid item md={9}></Grid>
                <Grid item md={3} xs={12}>
                  <CustomDropdownField control={control} name='agencyId' options={agencyList} label="Agency" rules={isRejectValidations ? {} : approveValidationRules.practiceAgency} disableSearch />
                </Grid>
                <Grid item md={9}></Grid>
                <Grid item md={3} xs={12}>
                  <CustomMultipleDropDown control={control} name='practiceExDropDown' options={practiceExOps} label="Account Representative" />
                </Grid>
                <Grid item md={9}></Grid>

                <Grid item md={6} xs={12}>
                  <CustomEmailChipField control={control} label="Emails that should receive submission notifications:" controllerName="notificationEmails" rules={isRejectValidations ? {} : approveValidationRules.notificationEmails} />
                </Grid>
                <Grid item md={4}></Grid>
                <Grid item md={6} xs={12}>
                  <CustomTextField control={control} label="Additional notes/comments" controllerName="approvalComments" multiline rows={3} 
                 />
                </Grid>
                <Grid item md={4}></Grid>
              </Grid>
              <div style={{ height: "20px" }}></div>

            </div>

            <Grid
              container
              justifyContent="flex-end"
              sx={{ mt: 1, flexWrap: "wrap" }}
              spacing={1}
            >
              <Grid item xs={12} sm="auto">
                <Button fullWidth type='submit' startIcon={<CheckCircleOutlineOutlined />} onClick={handleSubmit(onSubmit)}>Approve</Button>
              </Grid>
              <Grid item xs={12} sm="auto">
                <Button fullWidth  color={"error"} type="submit" onClick={(e) => {setIsRejectValidations(true); e.preventDefault();}} startIcon={<CancelOutlined />}>Reject</Button>
              </Grid>
            </Grid>
          </FormGroup>
        </form>
      </Card>

      </Box>
    </>

  );
}

export default PracticeRequest