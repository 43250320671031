import { PracticeInfo } from "../redux/slice/practice/practiceRegistrationSlice";
import { EDIT_PRACTICE_URL, FETCH_PRACTICE_URL, SUBMIT_PRACTICE_URL,FETCH_PRACTICE_LOCATIONS_URL, FETCH_PRACTICE_PROVIDERS_URL, FETCH_PRACTICE_PRODUCTS_URL, FETCH_ALL_PRODUCTS_URL, SAVE_PRACTICE_LOCATION_URL, SAVE_PRACTICE_PROVIDER_URL, INACTIVE_PRACTICE_LOCATION_URL, INACTIVE_PRACTICE_PROVIDER_URL, FETCH_PRACTICE_BILLER_URL, FETCH_PRACTICE_EXECUTIVE_URL, UPDATE_PRACTICE_BILLER_URL, UPDATE_PRACTICE_EXECUTIVE_URL, GET_PRACTICE_LOCATION_BY_LOCATIONID_URL, FETCH_PRACTICE_PRODUCTS_WITHOUT_DETAILS_URL, FETCH_PRACTICE_BY_USER_ROLE, FETCH_UPDATED_PRACTICE_REGISTRATION_BY_PRACTICE_ID_URL, DOWNLOAD_UPDATED_REGISTRATION } from "./URL";
import { http } from "./http";
import { PracticeLocationInfo } from "../redux/slice/practice/practiceLocationSlice";
import { PracticeProviderInfo } from "../redux/slice/practice/practiceProvidersSlice";
import { PracticeEditInfo } from "../redux/slice/practice/practiceEditSlice";
import { UpdatePracticeExInfo } from "../redux/slice/practice/practiceExecutiveSlice";

export const submitPractice = async (submitPracticeRequest: PracticeInfo) => {
    try{
        const response = await http.post(SUBMIT_PRACTICE_URL, submitPracticeRequest);
        return response;
    }
    catch(error){
        throw error;
    }
};

export const editPractice = async (editPracticeRequest: PracticeEditInfo) => {
    const response = await http.post(EDIT_PRACTICE_URL, editPracticeRequest);
    return response;
};

export const fetchPracticeById = async (practiceId: string) => {
    const response = await http.get(`${FETCH_PRACTICE_URL}?practiceId=${practiceId}`);
    return response;
};

export const fetchPracticeLocationsById = async (practiceId: string) => {
    const response = await http.get(`${FETCH_PRACTICE_LOCATIONS_URL}?practiceId=${practiceId}`);
    return response;
};

export const fetchActivePracticeLocationsById = async (practiceId: string) => {
    const response = await http.get(`${FETCH_PRACTICE_LOCATIONS_URL}?practiceId=${practiceId}&onlyActiveRecords=${true}`);
    return response;
};

export const fetchPracticeProvidersById = async (practiceId: string) => {
    const response = await http.get(`${FETCH_PRACTICE_PROVIDERS_URL}?practiceId=${practiceId}`);
    return response;
};

export const fetchActivePracticeProvidersById = async (practiceId: string, onlyActiveRecords: boolean) =>{
    const response = await http.get(`${FETCH_PRACTICE_PROVIDERS_URL}?practiceId=${practiceId}&onlyActiveRecords=${onlyActiveRecords}`);
    return response;
};

export const fetchPracticeProductsById = async (practiceId: string) => {
    const response = await http.get(`${FETCH_PRACTICE_PRODUCTS_URL}?practiceId=${practiceId}`);
    return response;
};

export const fetchPracticeProductsByIdUsingDateOfServiceAndProvider = async (practiceId: number, dateOfService?: string, providerId?: number) => {
    const response = await http.get(`${FETCH_PRACTICE_PRODUCTS_URL}?practiceId=${practiceId}&dateOfService=${dateOfService}&providerId=${providerId}`);
    return response;
};

export const fetchAllProducts = async () => {
    const response = await http.get(`${FETCH_ALL_PRODUCTS_URL}`);
    return response;
};

export const savePracticeLocation = async (practiceLocation: PracticeLocationInfo) => {
    try{
        const response = await http.post(SAVE_PRACTICE_LOCATION_URL, practiceLocation);
        return response;
    }
    catch(error){
        throw error;
    }
};

export const savePracticeProvider = async (practiceProvider: PracticeProviderInfo) => {
    try{
        const response = await http.post(SAVE_PRACTICE_PROVIDER_URL, practiceProvider);
        return response;
    }
    catch(error){
        throw error;
    }
};

export const inactivePracticeLocations = async (locationId: number, inactiveDate: any) => {
    console.log("inactiveDate", inactiveDate);
    try{
        const response = await http.post(`${INACTIVE_PRACTICE_LOCATION_URL}/${locationId}?&inactiveDate=${inactiveDate}`);
        return response;
    }
    catch(error){
        throw error;
    }
};

export const inactivePracticeProviders = async (providerId: number, inactiveDate: any) => {
    console.log("providerId", providerId);
    console.log("inactiveDate", inactiveDate);
    try{
        const response = await http.post(`${INACTIVE_PRACTICE_PROVIDER_URL}/${providerId}?&inactiveDate=${inactiveDate}`);
        return response;
    }
    catch(error){
        throw error;
    }
};

export const fetchPracticeBillerById = async (practiceId: string) => {
    const response = await http.get(`${FETCH_PRACTICE_BILLER_URL}?practiceId=${practiceId}`);
    return response;
};

export const fetchPracticeExecutiveById = async (practiceId: number, isInactive?: boolean) => {
    const response = await http.get(`${FETCH_PRACTICE_EXECUTIVE_URL}?practiceId=${practiceId}&isInactive=${isInactive}`);
    return response;
};

export const updatePracticeBiller = async (practiceBillerId: string, billerId: string) => {
    try {
        const response = await http.post(`${UPDATE_PRACTICE_BILLER_URL}?practiceBillerId=${practiceBillerId}&billerId=${billerId}`);
        return response;
    } catch (error) {
        throw error;
    }
};

export const updatePracticeExecutive = async (data : UpdatePracticeExInfo) => {
    try {
        const response = await http.post(`${UPDATE_PRACTICE_EXECUTIVE_URL}`, data);
        return response;
    } catch (error) {
        throw error;
    }
};

export const getLocationsByLocationId = async (locationId: number) => {
    try{
        const response = await http.get(`${GET_PRACTICE_LOCATION_BY_LOCATIONID_URL}?locationId=${locationId}`);
        return response;
    }
    catch(error){
        throw error;
    }
};

export const fetchPracticeProductsWithoutDetailsById = async (practiceId: string) => {
    const response = await http.get(`${FETCH_PRACTICE_PRODUCTS_WITHOUT_DETAILS_URL}?practiceId=${practiceId}`);
    return response;
};

export const fetchPracticesByUserRole = async (isActivePracticeOnly?: boolean) => {
    try {
        const response = await http.get(`${FETCH_PRACTICE_BY_USER_ROLE}?isActivePracticeOnly=${isActivePracticeOnly}`);
        return response;
    } catch (error) {
        throw error;
    }
}

export const fetchUpdatedPracticeRegistrationByPracticeId = async (practiceId: string, updatePracticeAgreement: boolean) => {
    try {
        const response = await http.get(`${DOWNLOAD_UPDATED_REGISTRATION}/${practiceId}?updatePracticeAgreement=${updatePracticeAgreement}`);
        return response;
    } catch (error) {
        throw error;
    }
};

export const fetchAgreementByProductIdAndPracticeId = async (practiceId: string, productId: string) => {
    try {
        const response = await http.get(`${FETCH_UPDATED_PRACTICE_REGISTRATION_BY_PRACTICE_ID_URL}?practiceId=${practiceId}&productId=${productId}`);
        return response;
    } catch (error) {
        throw error;
    };
};