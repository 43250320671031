import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { useAppSelector } from "./../redux-hooks";
import { GeneralV3Response, LoadingType } from "../../../types/CommonTypes";
import { submitPractice, fetchAllProducts } from "../../../service/PracticeService";
import { PracticeLocationInfo } from "./practiceLocationSlice";
import { PracticeProviderInfo } from "./practiceProvidersSlice";
import { ProductListInfo } from "../product/productListSlice";
import { AuthorizedSignerInfo } from "../../../components/operation/practice/AuthorizedSignerPopup";

export type PracticeInfo = {
  practiceId: string;
  practiceName: string;
  contactFirstName: string;
  contactLastName: string;
  contactMiddleName: string;
  contactEMail: string;
  contactPhone: string;
  contactFax: string;
  apFirstName: string;
  apMiddleName: string;
  apLastName: string;
  apPhone: string;
  apEmail: string;
  ptan: string;
  npi: string;
  taxId: string;
  billingType: string;
  notificationEmails: string;
  invitationCode: string;
  practiceRequestInfo : {
    billerFirstName: string;
    billerMiddleName: string;
    billerLastName: string;
    billerContactPhone: string;
    billerContactEmail: string;
    // billerStreetAddress: string;
    // billerUnitNumber: string;
    // billerCity: string;
    // billerState: string;
    // billerZipCode: string;
    // billerNotApplicable: boolean;
    repOrDistributorName: string,
    anyQuestions: string,
    woundCareFocus: '',
    averageWoundsTreatedPerMonth: string;
    placesOfService: string[];
    // insurancePayer1: string;
    // insurancePayer2: string;
    // insurancePayer3: string;
    customerSignature : string;
    // customerFirstName : string;
    // customerMiddleName: string;
    // customerLastName: string;
    customerName: string;
    authorizedSigners: AuthorizedSignerInfo[];
  }
  practiceLocations: PracticeLocationInfo[];
  practiceProviders: PracticeProviderInfo[];
  agencyId?: number;
};

type PracticeRegistrationState = {
  practiceRegistrationInfo?: PracticeInfo | null;
  submitPracticeDataFetchStatus?: LoadingType;
  productList: ProductListInfo[];
  productsListFetchStatus?: LoadingType;
  practiceRegistrationError?: string[];
};

const initialState: PracticeRegistrationState = {
  practiceRegistrationInfo: undefined,
  submitPracticeDataFetchStatus: "idle",
  productList: [],
  productsListFetchStatus: "idle",
  practiceRegistrationError: undefined
};

export const submitPracticeData = createAsyncThunk("submitPracticeData", async (data: PracticeInfo, { rejectWithValue }) => {
  try{
   const response = await submitPractice(data);
   return response.data;
  }catch(error){
    //@ts-ignore
    return rejectWithValue(error);
  }
});

export const fetchProducts = createAsyncThunk("fetchAllProducts", async () => {
  const response = await fetchAllProducts();
  return response.data;
});

const practiceRegistrationSlice = createSlice({
  name: "practiceRegistration",
  initialState,
  reducers: {
    updatePracticeRegistrationInfoObject: (state: PracticeRegistrationState, action) => {
      state.practiceRegistrationInfo = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(submitPracticeData.pending, (state) => {
        state.submitPracticeDataFetchStatus = "loading";
      })
      .addCase(submitPracticeData.fulfilled, (state, action: PayloadAction<GeneralV3Response>) => {
        state.submitPracticeDataFetchStatus = "success";
        state.practiceRegistrationInfo = undefined;
      })
      .addCase(submitPracticeData.rejected, (state, action: any) => {
        state.submitPracticeDataFetchStatus = 'error';
        state.practiceRegistrationError = action.payload;
      })
      .addCase(fetchProducts.pending, (state) => {
        state.productsListFetchStatus = "loading";
      })
      .addCase(fetchProducts.fulfilled, (state, action: PayloadAction<GeneralV3Response>) => {
        state.productsListFetchStatus = "success";
        state.productList = action.payload.data;
      })
      .addCase(fetchProducts.rejected, (state, action) => {
        state.productsListFetchStatus = 'error';
      });
  },
});

export const { updatePracticeRegistrationInfoObject } = practiceRegistrationSlice.actions;
export const usePracticeRegistrationSlice = () => useAppSelector((state) => state.practiceRegistrationSlice);
export default practiceRegistrationSlice.reducer;