import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { useAppSelector } from "../redux-hooks";
import { saveAgencyCommissionPayoutData, uploadCommissionPaymentFileData, uploadPaymentFileData } from "../../../service/PaymentService";
import { GeneralV3Response, InterceptorOption, LoadingType } from "../../../types/CommonTypes";

type PaymentState = {
  paymentRequest: PaymentRequest | null;
  paymentRequestStatus?: LoadingType;
  commissionPaymentRequest: commissionPaymentRequest | null;
  commissionPaymentRequestStatus?: LoadingType;
  recordPayoutInfo: RecordPayoutInfo | null;
  recordPayoutInfoStatus?: LoadingType;
};

const initialState: PaymentState = {
  paymentRequest: null,
  paymentRequestStatus: "idle",
  commissionPaymentRequest: null,
  commissionPaymentRequestStatus: "idle",
  recordPayoutInfo: null,
  recordPayoutInfoStatus: "idle",
};

export type PaymentRequest = {
  content: string;
  documentName: string;
  fileType: string;
  comments: string;
  type: string;
};

export type commissionPaymentRequest = {
  content: string;
  documentName: string;
  fileType: string;
  comments: string;
  type: string;
};

export type RecordPayoutInfo = {
  invoiceIds: number[],
  agencyId?: number,
  commissionAgencyType: 'AGENCY' | 'OVERRIDE',
  payment: number,
  payoutDate: string,
  payoutComment: string,
  v3CommissionReceivedAmount: number
  //cs-346
  fromDate: string | undefined;
  toDate: string | undefined;
  //
};

export const uploadPayment = createAsyncThunk<any, {paymentData: PaymentRequest, interceptorOption: InterceptorOption}>(
  "uploadPayment",
  async (data, {rejectWithValue}) => {
    try{
      const {paymentData, interceptorOption} = data;
      const response = await uploadPaymentFileData(paymentData, interceptorOption);
      return response;
    } catch(error) {
      //@ts-ignore
      return rejectWithValue(error?.data);
    }
  }
);

export const uploadCommissionPayment = createAsyncThunk<any, {commissionData: commissionPaymentRequest, interceptorOption: InterceptorOption}>(
  "uploadCommissionPayment",
  async (data, {rejectWithValue}) => {
    try{
      const {commissionData, interceptorOption} = data;
      const response = await uploadCommissionPaymentFileData(commissionData, interceptorOption);
      return response;
    } catch(error) {
      //@ts-ignore
      return rejectWithValue(error?.data)
    }
  }
);

export const savePayoutInfo = createAsyncThunk(
  "savePayoutInfo",
  async (data: RecordPayoutInfo) => {
    const response = await saveAgencyCommissionPayoutData(data);
    return response.data;
  }
);

const paymentSlice = createSlice({
  name: "payment",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(uploadPayment.pending, (state) => {
        state.paymentRequestStatus = "loading";
      })
      .addCase(
        uploadPayment.fulfilled,
        (state, action: PayloadAction<GeneralV3Response>) => {
          state.paymentRequestStatus = "success";
        }
      )
      .addCase(uploadPayment.rejected, (state, action) => {
        state.paymentRequestStatus = "error";
      })
      .addCase(uploadCommissionPayment.pending, (state) => {
        state.commissionPaymentRequestStatus = "loading";
      })
      .addCase(
        uploadCommissionPayment.fulfilled,
        (state, action: PayloadAction<GeneralV3Response>) => {
          state.commissionPaymentRequestStatus = "success";
        }
      )
      .addCase(uploadCommissionPayment.rejected, (state, action) => {
        state.commissionPaymentRequestStatus = "error";
      })
      .addCase(savePayoutInfo.pending, (state) => {
        state.recordPayoutInfoStatus = "loading";
      })
      .addCase(
        savePayoutInfo.fulfilled,
        (state, action: PayloadAction<GeneralV3Response>) => {
          state.recordPayoutInfoStatus = "success";
        }
      )
      .addCase(savePayoutInfo.rejected, (state, action) => {
        state.recordPayoutInfoStatus = "error";
      });
  },
});

export const usePaymentSlice = () =>
  useAppSelector((state) => state.paymentSlice);
export default paymentSlice.reducer;
