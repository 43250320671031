import { Box, Divider, Grid, Stack, Typography } from "@mui/material";
import Header from "../../common/Header";
import Card from "../../common/Card";
import { useForm } from "react-hook-form";
import Button from "../../common/Button";
import { CancelOutlined, SaveOutlined } from "@mui/icons-material";
import CustomTextField from "../../common/CustomTextField";
import userSlice, {
    fetchAllUserRoles,
    saveUsersProfile,
    updateUserInfoObject,
    useUserReducer,
    clearUserObject,
    UserRole,
    UserNotificationPreference,
    searchUserNotificationPreference,
} from "../../../redux/slice/userSlice";
import CustomDropdownField from "../../common/CustomDropdownFiled";
import CustomPasswordTextField from "../../common/CustomPasswordTextField";
import CustomNametField from "../../common/CustomNametField";
import { useCallback, useEffect, useMemo } from "react";
import { useAppDispatch } from "../../../redux/slice/redux-hooks";
import {
    MainErrorInfo,
    SingleAlertInfo,
    fetchAgenciesList,
    fetchManufacturersList,
    setMainErrorObj,
    setSingleAlertObj,
    showHideLoadingDialog,
    useCommonReducer,
} from "../../../redux/slice/commonSlice";
import { useLocation, useNavigate } from "react-router-dom";
import React from "react";
import { PracticeInfo, getPracticesByUserRole, usePracticeSlice } from "../../../redux/slice/practiceSlice";
import CustomPatternTextField from "../../common/CustomPatternTextField";
import { sortNames } from "../../../utils/Utility";
import { selectTokenValues, useAuthSlice } from "../../../redux/slice/authSlice";
import { USER_ROLES, accountExecutiveRoleId, agencyAdminRoleId, billerRoleId, manufacturerAdminRoleId, manufacturerStaffRoleId, manufacturerIvrRoleId, manufacturerServiceId, practiceAdminRoleId, practiceStaffRoleId, NOTIFICATION_TYPE_LIST, NOTIFICATION_TYPE } from "../../../constants/applicationConstants";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { fetchActiveBillers, usePracticeApproveSlice } from "../../../redux/slice/practice/practiceApproveSlice";
import { CodeTable, getCodeTableValuesByTableName, useCodetablesSlice } from "../../../redux/slice/codetableSlice";
import CustomMultipleDropDown from "../../common/CustomMultipleDropDown";

type Option = {
    value: string | number;
    label: string;
};

export type UserCreateObj = {
    userId: string;
    email: string;
    firstName: string;
    middleName: string;
    lastName: string;
    userRoleId: number | undefined;
    phone: string;
    password: string;
    confirmPassword: string;
    entityId: number;
    entityIds: number[] | undefined;
};

const CreateMaufacturer = () => {
    const location = useLocation();
    const userData = location.state?.user;
    const isEditing = Boolean(userData);
    const {
        control,
        handleSubmit,
        reset,
        watch,
        getValues,
        setError,
        clearErrors,
        setValue,
        formState: { errors },
    } = useForm<UserCreateObj>();
    const { userRoles, userObject, userNotificationPreferenceData } = useUserReducer();
    const { manufacturerInfoList, agencyInfoList } =
        useCommonReducer();
    const { practiceByUserRole } = usePracticeSlice();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const selectedUserType = watch("userRoleId");
    const { userInfo } = useAuthSlice();
    const { allCodeTablesValuesMap } = useCodetablesSlice();

    const { billerList } = usePracticeApproveSlice();
    //cs-442

    const notificationOptions = useMemo(() => {
        let notificationTypes: CodeTable[] = [];
        if (allCodeTablesValuesMap) {
            notificationTypes = getCodeTableValuesByTableName(allCodeTablesValuesMap, NOTIFICATION_TYPE_LIST);
        }
        let options: { label: string, value: any }[] = [];
        notificationTypes?.map((option) => {
            options.push({ label: option.displayValue, value: option.displayValue });
        });
        return options;
    }, [allCodeTablesValuesMap]);

    useEffect(() => {
        const data: UserNotificationPreference = {
            userId: userData?.userId,
            notificationType: NOTIFICATION_TYPE,
            notificationSubType: null
        }
        dispatch(searchUserNotificationPreference(data))
    }, [])
    //

    const canManageUserProfile = useSelector((state: RootState) => selectTokenValues("canManageUsers")(state));

    const defaultValues = useCallback(() => {
        if (userObject) {
            reset(userObject);
        } else {
            reset({
                userRoleId: undefined,
                entityId: 0,
                entityIds: [],
                email: '',
                password: '',
                confirmPassword: '',
                phone: '',
            });
        }
    }, [userObject, reset]);

    const onSubmit = async (data: UserCreateObj) => {
        if (isEditing) {
            dispatch(updateUserInfoObject({ ...data, id: userData.id }));
            await handleSaveUserProfile(data);
        } else {
            await handleSaveUserProfile({ ...data, password: data.password.replace(/\s+/g, ''), confirmPassword: data.confirmPassword.replace(/\s+/g, '') });
        }
    };

    const onClose = () => {
        navigate(-1);
    }

    const userRoleList = useMemo(() => {
        let allUserRoles: Option[] = [];
        let availableUserRoles: UserRole[] | undefined = undefined;

        if (canManageUserProfile && canManageUserProfile.length > 0) {
            availableUserRoles = userRoles?.filter((role: UserRole) => canManageUserProfile.includes(role.roleName));
        }
        availableUserRoles?.forEach((option: UserRole) => {
            allUserRoles.push({ label: option.roleDisplayValue, value: option.userRoleId });
        });
        return allUserRoles;
    }, [canManageUserProfile, userRoles]);

    const manufacturersList = useMemo(() => {
        let options: Option[] = [];
        manufacturerInfoList?.map((option) => {
            options.push({
                label: option.manufacturerName,
                value: option.manufacturerId,
            });
        });
        return options;
    }, [manufacturerInfoList]);

    const agencyList = useMemo(() => {
        let options: Option[] = [];
        agencyInfoList?.map((option) => {
            options.push({ label: option.agencyName, value: option.agencyId });
        });
        return options;
    }, [agencyInfoList]);

    const billerUsersList = useMemo(() => {
        let options: Option[] = [];
        billerList?.map((option) => {
            options.push({ label: option.billerName, value: option.billerId });
        });
        return options;
    }, [billerList]);

    const practiceList = useMemo(() => {
        let options: Option[] = [];
        if (practiceByUserRole) {
            if (practiceByUserRole && practiceByUserRole.length === 1) {
                setValue('entityId', parseInt(practiceByUserRole[0]?.practiceId));
                practiceByUserRole?.map(practice => {
                    options.push({ value: practice.practiceId, label: practice.practiceName });
                });
            } else {
                const sortedPracticeInfo: PracticeInfo[] = sortNames(practiceByUserRole, 'practiceName');
                sortedPracticeInfo?.map(practice => {
                    options.push({ value: practice.practiceId, label: practice.practiceName });
                });
            }
        }
        return options;
    }, [practiceByUserRole]);

    const handleSaveUserProfile = async (data: UserCreateObj) => {
        let errorObj: MainErrorInfo = {
            title: "",
        };

        try {
            const response = await dispatch(saveUsersProfile(data));
            if (response.payload && response.payload.message === 'SUCCESS') {
                navigate(-1);

                let alertMessage = isEditing ?
                    "User Profile Updated Successfully!" :
                    "User Profile Created Successfully!";

                const alertObj: SingleAlertInfo = {
                    message: alertMessage,
                    alertType: "success",
                };

                dispatch(setSingleAlertObj(alertObj));
            } else {
                let errorSentence = '';
                if (response.payload.errors.length <= 0) {
                    errorSentence = response.payload.message || "An unknown error occurred.";
                } else {
                    response.payload.errors.forEach((error: any, index: any) => {
                        if (error.field) {
                            errorSentence += `${error.field}: ${error.errorMessage}\n`;
                            if (error.field === 'email') {
                                setError('email', {
                                    type: 'manual',
                                    message: error.errorMessage,
                                });
                            }
                        } else {
                            errorSentence += `${error.reason}\n`;
                        }
                    });
                }

                errorObj.title = "User Profile Creation Failed!";
                errorObj.errorMessageStream = errorSentence;
                dispatch(setMainErrorObj(errorObj));
            }
        } catch (error) {
            console.log("Error creating user profile:", error);
            errorObj.title = "Error creating user profile";
            errorObj.errorMessageStream = "" + error;
            dispatch(setMainErrorObj(errorObj));
        }
    };

    const getAllList = async () => {
        try {
            dispatch(showHideLoadingDialog(true));
            const [
                resManufacturerCall,
                resAgenciesCall,
                resPracticesCall,
                resUserCall,
                resBillersCall
            ] = await Promise.all([
                dispatch(fetchManufacturersList()),
                dispatch(fetchAgenciesList()),
                dispatch(getPracticesByUserRole(true)),
                dispatch(fetchAllUserRoles()),
                dispatch(fetchActiveBillers())
            ]);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            dispatch(showHideLoadingDialog(false));
        }
    };

    useEffect(() => {
        getAllList();
    }, [dispatch]);

    useEffect(() => {
        defaultValues();
    }, [defaultValues]);

    useEffect(() => {
        return () => {
            dispatch(clearUserObject());
        };
    }, [dispatch]);


    return (
        <Box m="20px">
            <Header
                title="Manufacturer Profile Management"
                subtitle={isEditing ? "Manufacturer Profile Update" : "Manufacturer Profile Create"}
            />
            <Card>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={2}>
                        <Grid item md={4} xs={12}>
                            <CustomTextField
                                control={control}
                                controllerName="manufacturerName"
                                label="Manufacturer Name"
                                rules={{
                                    required: "Manufacturer Name is required.",
                                }}
                            />
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <CustomPatternTextField
                                control={control}
                                name="phone"
                                label="Phone"
                            />
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <CustomTextField
                                control={control}
                                controllerName="email"
                                label="Email"
                            />
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <CustomTextField
                                controllerName="notificationEmails"
                                control={control}
                                label="Notification Emails"
                                multiline
                                rows={6}
                            />
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <CustomTextField
                                controllerName="customConfig"
                                control={control}
                                label="Custom_Config"
                                multiline
                                rows={6}
                            />
                        </Grid>

                        <Grid item md={12} xs={12}>
                            <Divider
                                orientation="horizontal"
                                variant="middle"
                                flexItem
                                sx={{ padding: 0, margin: 0, mt: 1, mb: 1 }}
                            />
                        </Grid>

                        <Grid
                            mt={2}
                            container
                            justifyContent="flex-end"
                            alignItems="center"
                            spacing={1}
                        >
                            <Grid item xs={12} md="auto">
                                <Button
                                    type="submit"
                                    color="info"
                                    startIcon={<SaveOutlined />}
                                    fullWidth
                                >
                                    {isEditing ? "Update Manufacturer" : "Create Manufacturer"}
                                </Button>
                            </Grid>
                            <Grid item xs={12} md="auto">
                                <Button
                                    color="error"
                                    startIcon={<CancelOutlined />}
                                    onClick={() => onClose()}
                                    fullWidth
                                >
                                    Cancel
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            </Card>
        </Box>
    );
};

export default CreateMaufacturer;