import { createSlice } from "@reduxjs/toolkit";
import { useAppSelector } from "../redux-hooks";

export type FetchAuditLogObject = {
    auditedEntity:string|undefined;
    entityId:number|undefined;
    actionType:any;
    dateRange:any;
  }
  export type SearchAuditLogRequest = {
    auditedEntity:any;
    entityNumber:any;
    actionType:any;
    dateRange:any;
  }

  type AuditListState = {
    AuditLogObject?:FetchAuditLogObject;
  };
  const initialState: AuditListState = {
    AuditLogObject: undefined,
  };

  const AuditLogListSlice = createSlice({
    name: "AuditLogList",
    initialState,
    reducers: {
    //   updateElementInCreateIVRSlice : updateElementValue,
      resetIVRData: (state: any) => {
        return {
          ...state,
          ...initialState,
        };
      },
    //   resetIVRList: (state: IVRListState) => {
    //     state.IVRListInfo = undefined;
    //     state.IVRListFetchStatus = "idle";
    //     state.fetchProductsByRoleStatusCreateIVRSlice = "idle";
    //     state.productsByUserRoleCreateIVRSlice = [];
    //     state.getPracticesByRoleStatusCreateIVRSlice = "idle";
    //     state.practiceByUserRoleCreateIVRSlice = [];
    //   },
    },
    // extraReducers: (builder) => {
    //   builder
    //     .addCase(fetchIVRList.pending, (state) => {
    //       state.IVRListFetchStatus = "loading";
    //     })
    //     .addCase(fetchIVRList.fulfilled, (state, action: PayloadAction<GeneralV3Response>) => {
    //       state.IVRListFetchStatus = "success";
    //       state.IVRListInfo = action.payload.data;
    //     })
    //     .addCase(fetchIVRList.rejected, (state, action) => {
    //       state.IVRListFetchStatus = 'error';
    //     })
    //     .addCase(saveIVRData.pending, (state) => {
    //       state.IVRSaveStatus = "loading";
    //     })
    //     .addCase(saveIVRData.fulfilled, (state, action: PayloadAction<GeneralV3Response>) => {
    //       state.IVRSaveStatus = "success";
    //       state.SaveIVRInfo = action.payload.data;
    //     })
    //     .addCase(saveIVRData.rejected, (state, action: any) => {
    //       state.IVRSaveStatus = 'error';
    //       state.IVRSaveError = [action.error.message];
    //     })
    //     .addCase(fetchIVRById.pending, (state) => {
    //       state.IVRFetchStatus = "loading";
    //     })
    //     .addCase(fetchIVRById.fulfilled, (state, action: PayloadAction<GeneralV3Response>) => {
    //       state.IVRFetchStatus = "success";
    //       state.IVRObject = action.payload.data;
    //     })
    //     .addCase(fetchIVRById.rejected, (state, action) => {
    //       state.IVRFetchStatus = 'error';
    //     })
    //     .addCase(updateIVRRespond.pending, (state, action) => {
    //       state.IVRListUpdateStatus = "loading"
    //     })
    //     .addCase(updateIVRRespond.fulfilled, (state, action) => {
    //       state.IVRListUpdateStatus = "success"
    //     })
    //     .addCase(updateIVRRespond.rejected, (state, action) => {
    //       state.IVRListUpdateStatus = "error"
    //     })
    //     .addCase(submitIVR.pending, (state, action) => {
    //       state.IVRSubmitStatus = "loading"
    //     })
    //     .addCase(submitIVR.fulfilled, (state, action) => {
    //       state.IVRSubmitStatus = "success";
    //       state.SubmitIVRInfo = action.payload.data;
    //     })
    //     .addCase(submitIVR.rejected, (state, action) => {
    //       state.IVRSubmitStatus = "error"
    //     })
    //     .addCase(deleteIVRById.pending, (state, action) => {
    //       state.IVRDeleteStatus = "loading"
    //     })
    //     .addCase(deleteIVRById.fulfilled, (state, action) => {
    //       state.IVRDeleteStatus = "success";
    //     })
    //     .addCase(deleteIVRById.rejected, (state, action) => {
    //       state.IVRDeleteStatus = "error"
    //     })
    //     .addCase(cancelIVRById.pending, (state, action) => {
    //       state.IVRDeleteStatus = "loading"
    //     })
    //     .addCase(cancelIVRById.fulfilled, (state, action) => {
    //       state.IVRDeleteStatus = "success";
    //     })
    //     .addCase(cancelIVRById.rejected, (state, action) => {
    //       state.IVRDeleteStatus = "error"
    //     })
    //     .addCase(searchIVRByPatientInfo.pending, (state, action) => {
    //       state.fetchIVRByPatientNameStatus = "loading"
    //     })
    //     .addCase(searchIVRByPatientInfo.fulfilled, (state, action) => {
    //       state.fetchIVRByPatientNameStatus = "success";
    //     })
    //     .addCase(searchIVRByPatientInfo.rejected, (state, action) => {
    //       state.fetchIVRByPatientNameStatus = "error"
    //     })
    //     .addCase(searchIVRByPatientId.pending, (state, action) => {
    //       state.fetchIVRByPatientIdStatus = "loading"
    //     })
    //     .addCase(searchIVRByPatientId.fulfilled, (state, action) => {
    //       state.fetchIVRByPatientIdStatus = "success";
    //     })
    //     .addCase(searchIVRByPatientId.rejected, (state, action) => {
    //       state.fetchIVRByPatientIdStatus = "error"
    //     })
    //     .addCase(fetchProductListByUserRoleCreateIVRSlice.pending, (state) => {
    //       state.fetchProductsByRoleStatusCreateIVRSlice = "loading";
    //     })
    //     .addCase(fetchProductListByUserRoleCreateIVRSlice.fulfilled, (state, action) => {
    //       state.fetchProductsByRoleStatusCreateIVRSlice = "success";
    //       state.productsByUserRoleCreateIVRSlice = action.payload;
    //     })
    //     .addCase(fetchProductListByUserRoleCreateIVRSlice.rejected, (state, action) => {
    //       state.fetchProductsByRoleStatusCreateIVRSlice = 'error';
    //     })
    //     .addCase(fetchProductListIncludingInactiveByUserRoleCreateIVRSlice.pending, (state) => {
    //       state.fetchProductsByRoleStatusCreateIVRSlice = "loading";
    //     })
    //     .addCase(fetchProductListIncludingInactiveByUserRoleCreateIVRSlice.fulfilled, (state, action) => {
    //       state.fetchProductsByRoleStatusCreateIVRSlice = "success";
    //       state.productsByUserRoleCreateIVRSlice = action.payload;
    //     })
    //     .addCase(fetchProductListIncludingInactiveByUserRoleCreateIVRSlice.rejected, (state, action) => {
    //       state.fetchProductsByRoleStatusCreateIVRSlice = 'error';
    //     })
    //     .addCase(getPracticeListByUserRoleCreateIVRSlice.pending, (state, action) => {
    //       state.getPracticesByRoleStatusCreateIVRSlice = 'loading';
    //     })
    //     .addCase(getPracticeListByUserRoleCreateIVRSlice.fulfilled, (state, action) => {
    //       state.getPracticesByRoleStatusCreateIVRSlice = 'success';
    //       state.practiceByUserRoleCreateIVRSlice = action.payload;
    //     })
    //     .addCase(getPracticeListByUserRoleCreateIVRSlice.rejected, (state, action) => {
    //       state.getPracticesByRoleStatusCreateIVRSlice = 'error';
    //     });
    // },
  });

// export const { resetIVRData, resetIVRList, updateElementInCreateIVRSlice } = IVRListSlice.actions;
export const useAuditLogSlice = () => useAppSelector((state) => state.auditLogSlice);
export default AuditLogListSlice.reducer;