import React, { useEffect } from 'react';
import { Box, Grid, Typography, useTheme } from "@mui/material";
import Card from "../../common/Card";
import CustomPasswordTextField from '../../common/CustomPasswordTextField';
import CustomTextField from '../../common/CustomTextField';
import { useForm } from 'react-hook-form';
import { ResetPassword, resetPassword } from '../../../redux/slice/userSlice';
import Button from '../../common/Button';
import { CancelOutlined, SaveOutlined } from '@mui/icons-material';
import { useAppDispatch } from '../../../redux/slice/redux-hooks';
import { useNavigate } from 'react-router-dom';
import { SingleAlertInfo, setSingleAlertObj } from '../../../redux/slice/commonSlice';
import { useAuthSlice } from '../../../redux/slice/authSlice';
import { tokens } from '../../../theme';
import { PayloadAction, SerializedError } from '@reduxjs/toolkit';

const ChangePassword = () => {
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<ResetPassword>();

  const theme = useTheme();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { userInfo } = useAuthSlice();
  const colors = tokens(theme.palette.mode);

  useEffect(() => {
    setValue("userId", userInfo.userId);
    setValue("email", userInfo.userName);
  }, [setValue, userInfo]);

  const onSubmit = async (data: ResetPassword) => {
    if (data.oldPassword === data.password) {
      const alertObj: SingleAlertInfo = {
        message: "Old password cannot be the new password.",
        alertType: "error",
      };
      dispatch(setSingleAlertObj(alertObj));
      return;
    }

    try {
      const response = await dispatch(resetPassword(data));

      if (response.meta.requestStatus === "fulfilled") {
        navigate("/");
        const alertObj: SingleAlertInfo = {
          message: "Password Reset Successfully",
          alertType: "success",
        };
        dispatch(setSingleAlertObj(alertObj));
      } else if (response.meta.requestStatus === "rejected") {
        const error = (response as PayloadAction<unknown, string, any, SerializedError>).error;
      
        if (error?.code) {
          const alertObj: SingleAlertInfo = {
            message: "Password Reset Failed", 
            alertType: "error",
          };
          dispatch(setSingleAlertObj(alertObj));
        }
      }
      return response;
    } catch (error) {
      console.error("Error resetting password:", error);
      throw error;
    }
  };

  const onClose = () => {
    navigate("/");
  };

  return (
    <Grid
      container
      spacing={1}
      justifyContent="center"
      alignItems="center"
    >
      <Grid item md={6} xs={12}>
        <Box
          sx={{
            padding: 3,
            borderRadius: "16px",
            backgroundColor: colors.grey[900],
          }}
          color={colors.info[300]}
        >
          <Typography variant="h6" sx={{ fontWeight: "bold", marginBottom: 2 }}>
            Change Password
          </Typography>
          <Card>
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
              <Grid container spacing={2}>
                <Grid item md={12} xs={12}>
                  <CustomTextField
                    control={control}
                    controllerName="email"
                    label="Email"
                    rules={{ required: "Email is required." }}
                    disabled={true}
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <CustomPasswordTextField
                    control={control}
                    controllerName="oldPassword"
                    label="Current Password"
                    rules={{ required: "Current Password is required." }}
                    inputProps={{ autoComplete: "new-password" }}
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <CustomPasswordTextField
                    control={control}
                    controllerName="password"
                    label="New Password"
                    rules={{
                      required: "New Password is required.",
                      minLength: {
                        value: 12,
                        message: "Password should be at least 12 characters.",
                      },
                      pattern: {
                        value: /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])/,
                        message: "Password must contain at least one uppercase letter, one number, and one special character"
                      },
                    }}
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <CustomPasswordTextField
                    control={control}
                    controllerName="confirmPassword"
                    label="Confirm Password"
                    rules={{
                      required: "Confirmation of password is required.",
                      validate: (value: any) =>
                        value === watch("password") || "Passwords do not match",
                      minLength: {
                        value: 12,
                        message: "Password should be at least 12 characters.",
                      },
                      pattern: {
                        value: /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])/,
                        message: "Password must contain at least one uppercase letter, one number, and one special character"
                      },
                    }}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                justifyContent="flex-end"
                spacing={1}
                sx={{ mt: 2, flexWrap: "wrap" }}
              >
                <Grid item xs={12} sm="auto">
                  <Button
                    type="submit"
                    color="info"
                    startIcon={<SaveOutlined />}
                    fullWidth
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Card>
        </Box>
      </Grid>
    </Grid>
  );
};

export default ChangePassword;
