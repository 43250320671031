import { Typography, Box, useTheme, Grid } from "@mui/material";
import { tokens } from "../../theme";
import CustomHelpMessage from "./CustomHelpMessage";

interface HeaderProps {
  title: string;
  subtitle: string | undefined;
  message?: string;
}

const Header: React.FC<HeaderProps> = ({ title, subtitle, message }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box mb="20px">
      <Grid container spacing={2} alignItems="center">
        <Grid item>
          <Typography
            variant="h3"
            fontWeight="bold"
            sx={{ m: "0 0 5px 0" }}
          >
            {title}
          </Typography>
        </Grid>

        {message && (
          <Grid item>
            <CustomHelpMessage
              title="Help"
              message={message}
            />
          </Grid>
        )}
      </Grid>

      <Typography variant="h5">
        {subtitle}
      </Typography>
    </Box>
  );
};

export default Header;
