import { Box, Divider, Grid, Stack, Typography } from "@mui/material";
import Header from "../../common/Header";
import Card from "../../common/Card";
import { useForm } from "react-hook-form";
import Button from "../../common/Button";
import { CancelOutlined, SaveOutlined } from "@mui/icons-material";
import CustomTextField from "../../common/CustomTextField";
import userSlice, {
  fetchAllUserRoles,
  saveUsersProfile,
  updateUserInfoObject,
  useUserReducer,
  clearUserObject,
  UserRole,
  UserNotificationPreference,
  searchUserNotificationPreference,
} from "../../../redux/slice/userSlice";
import CustomDropdownField from "../../common/CustomDropdownFiled";
import CustomPasswordTextField from "../../common/CustomPasswordTextField";
import CustomNametField from "../../common/CustomNametField";
import { useCallback, useEffect, useMemo } from "react";
import { useAppDispatch } from "../../../redux/slice/redux-hooks";
import {
  MainErrorInfo,
  SingleAlertInfo,
  fetchAgenciesList,
  fetchManufacturersList,
  setMainErrorObj,
  setSingleAlertObj,
  showHideLoadingDialog,
  useCommonReducer,
} from "../../../redux/slice/commonSlice";
import { useLocation, useNavigate } from "react-router-dom";
import React from "react";
import { PracticeInfo, getPracticesByUserRole, usePracticeSlice } from "../../../redux/slice/practiceSlice";
import CustomPatternTextField from "../../common/CustomPatternTextField";
import { sortNames } from "../../../utils/Utility";
import { selectTokenValues, useAuthSlice } from "../../../redux/slice/authSlice";
import { USER_ROLES, accountExecutiveRoleId, agencyAdminRoleId, billerRoleId, manufacturerAdminRoleId, manufacturerStaffRoleId, manufacturerIvrRoleId, manufacturerServiceId, practiceAdminRoleId, practiceStaffRoleId, NOTIFICATION_TYPE_LIST, NOTIFICATION_TYPE } from "../../../constants/applicationConstants";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { fetchActiveBillers, usePracticeApproveSlice } from "../../../redux/slice/practice/practiceApproveSlice";
import { CodeTable, getCodeTableValuesByTableName, useCodetablesSlice } from "../../../redux/slice/codetableSlice";
import CustomMultipleDropDown from "../../common/CustomMultipleDropDown";

type Option = {
  value: string | number;
  label: string;
};

export type UserCreateObj = {
  userId: string;
  email: string;
  firstName: string;
  middleName: string;
  lastName: string;
  userRoleId: number | undefined;
  phone: string;
  password: string;
  confirmPassword: string;
  entityId: number;
  entityIds: number[] | undefined;
};

const CreateUser = () => {
  const location = useLocation();
  const userData = location.state?.user;
  const isEditing = Boolean(userData);
  const {
    control,
    handleSubmit,
    reset,
    watch,
    getValues,
    setError,
    clearErrors,
    setValue,
    formState: { errors },
  } = useForm<UserCreateObj>();
  const { userRoles, userObject, userNotificationPreferenceData } = useUserReducer();
  const { manufacturerInfoList, agencyInfoList } =
    useCommonReducer();
  const { practiceByUserRole } = usePracticeSlice();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const selectedUserType = watch("userRoleId");
  const { userInfo } = useAuthSlice();
  const { allCodeTablesValuesMap } = useCodetablesSlice();

  const { billerList } = usePracticeApproveSlice();
  //cs-442

  const notificationOptions = useMemo(() => {
    let notificationTypes: CodeTable[] = [];
    if (allCodeTablesValuesMap) {
      notificationTypes = getCodeTableValuesByTableName(allCodeTablesValuesMap, NOTIFICATION_TYPE_LIST);
    }
    let options: { label: string, value: any }[] = [];
    notificationTypes?.map((option) => {
      options.push({ label: option.displayValue, value: option.displayValue });
    });
    return options;
  }, [allCodeTablesValuesMap]);

  useEffect(() => {
    const data: UserNotificationPreference = {
      userId: userData?.userId,
      notificationType: NOTIFICATION_TYPE,
      notificationSubType: null
    }
    dispatch(searchUserNotificationPreference(data))
  }, [])
  //

  const canManageUserProfile = useSelector((state: RootState) => selectTokenValues("canManageUsers")(state));

  const defaultValues = useCallback(() => {
    if (userObject) {
      reset(userObject);
    } else {
      reset({
        userRoleId: undefined,
        entityId: 0,
        entityIds: [],
        email: '',
        password: '',
        confirmPassword: '',
        phone: '',
      });
    }
  }, [userObject, reset]);

  const onSubmit = async (data: UserCreateObj) => {
    if (isEditing) {
      dispatch(updateUserInfoObject({ ...data, id: userData.id }));
      await handleSaveUserProfile(data);
    } else {
      await handleSaveUserProfile({ ...data, password: data.password.replace(/\s+/g, ''), confirmPassword: data.confirmPassword.replace(/\s+/g, '') });
    }
  };

  const onClose = () => {
    navigate(-1);
  }

  const userRoleList = useMemo(() => {
    let allUserRoles: Option[] = [];
    let availableUserRoles: UserRole[] | undefined = undefined;

    if (canManageUserProfile && canManageUserProfile.length > 0) {
      availableUserRoles = userRoles?.filter((role: UserRole) => canManageUserProfile.includes(role.roleName));
    }
    availableUserRoles?.forEach((option: UserRole) => {
      allUserRoles.push({ label: option.roleDisplayValue, value: option.userRoleId });
    });
    return allUserRoles;
  }, [canManageUserProfile, userRoles]);

  const manufacturersList = useMemo(() => {
    let options: Option[] = [];
    manufacturerInfoList?.map((option) => {
      options.push({
        label: option.manufacturerName,
        value: option.manufacturerId,
      });
    });
    return options;
  }, [manufacturerInfoList]);

  const agencyList = useMemo(() => {
    let options: Option[] = [];
    agencyInfoList?.map((option) => {
      options.push({ label: option.agencyName, value: option.agencyId });
    });
    return options;
  }, [agencyInfoList]);

  const billerUsersList = useMemo(() => {
    let options: Option[] = [];
    billerList?.map((option) => {
      options.push({ label: option.billerName, value: option.billerId });
    });
    return options;
  }, [billerList]);

  const practiceList = useMemo(() => {
    let options: Option[] = [];
    if (practiceByUserRole) {
      if (practiceByUserRole && practiceByUserRole.length === 1) {
        setValue('entityId', parseInt(practiceByUserRole[0]?.practiceId));
        practiceByUserRole?.map(practice => {
          options.push({ value: practice.practiceId, label: practice.practiceName });
        });
      } else {
        const sortedPracticeInfo: PracticeInfo[] = sortNames(practiceByUserRole, 'practiceName');
        sortedPracticeInfo?.map(practice => {
          options.push({ value: practice.practiceId, label: practice.practiceName });
        });
      }
    }
    return options;
  }, [practiceByUserRole]);

  const handleSaveUserProfile = async (data: UserCreateObj) => {
    let errorObj: MainErrorInfo = {
      title: "",
    };

    try {
      const response = await dispatch(saveUsersProfile(data));
      if (response.payload && response.payload.message === 'SUCCESS') {
        navigate(-1);

        let alertMessage = isEditing ?
          "User Profile Updated Successfully!" :
          "User Profile Created Successfully!";

        const alertObj: SingleAlertInfo = {
          message: alertMessage,
          alertType: "success",
        };

        dispatch(setSingleAlertObj(alertObj));
      } else {
        let errorSentence = '';
        if (response.payload.errors.length <= 0) {
          errorSentence = response.payload.message || "An unknown error occurred.";
        } else {
          response.payload.errors.forEach((error: any, index: any) => {
            if (error.field) {
              errorSentence += `${error.field}: ${error.errorMessage}\n`;
              if (error.field === 'email') {
                setError('email', {
                  type: 'manual',
                  message: error.errorMessage,
                });
              }
            } else {
              errorSentence += `${error.reason}\n`;
            }
          });
        }

        errorObj.title = "User Profile Creation Failed!";
        errorObj.errorMessageStream = errorSentence;
        dispatch(setMainErrorObj(errorObj));
      }
    } catch (error) {
      console.log("Error creating user profile:", error);
      errorObj.title = "Error creating user profile";
      errorObj.errorMessageStream = "" + error;
      dispatch(setMainErrorObj(errorObj));
    }
  };

  const getAllList = async () => {
    try {
      dispatch(showHideLoadingDialog(true));
      const [
        resManufacturerCall,
        resAgenciesCall,
        resPracticesCall,
        resUserCall,
        resBillersCall
      ] = await Promise.all([
        dispatch(fetchManufacturersList()),
        dispatch(fetchAgenciesList()),
        dispatch(getPracticesByUserRole(true)),
        dispatch(fetchAllUserRoles()),
        dispatch(fetchActiveBillers())
      ]);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      dispatch(showHideLoadingDialog(false));
    }
  };

  useEffect(() => {
    getAllList();
  }, [dispatch]);

  useEffect(() => {
    defaultValues();
  }, [defaultValues]);

  useEffect(() => {
    return () => {
      dispatch(clearUserObject());
    };
  }, [dispatch]);


  return (
    <Box m="20px">
      <Header
        title="User Profile Management"
        subtitle={isEditing ? "User Profile Update" : "User Profile Create"}
      />
      <Card>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item md={4} xs={12}>
              <CustomDropdownField
                name="userRoleId"
                control={control}
                options={userRoleList}
                label="User Type"
                rules={{
                  required: "User Type is required.",
                  validate: {
                    notZero: (value: number) =>
                      value !== 0 || "User type is required.",
                  },
                }}
                onChangeSelect={() => { setValue('entityId', 0); setValue('entityIds', []); }}
              />
            </Grid>

            {selectedUserType === agencyAdminRoleId ||
              selectedUserType === accountExecutiveRoleId ? (
              <Grid item md={4} xs={12}>
                <CustomDropdownField
                  name="entityId"
                  control={control}
                  options={agencyList}
                  label="Agency"
                  rules={{
                    validate: {
                      notZero: (value: number) =>
                        value !== 0 || "Agency is required.",
                    },
                  }}
                />
              </Grid>
            ) : selectedUserType === manufacturerAdminRoleId ||
              selectedUserType === manufacturerStaffRoleId ||
              selectedUserType === manufacturerIvrRoleId ||
              selectedUserType == manufacturerServiceId ? (
              <Grid item md={4} xs={12}>
                <CustomDropdownField
                  name="entityId"
                  control={control}
                  options={manufacturersList}
                  label="Manufacturer"
                  rules={{
                    validate: {
                      notZero: (value: number) =>
                        value !== 0 || "Manufacturer is required.",
                    },
                  }}
                />
              </Grid>
            ) : selectedUserType === practiceAdminRoleId ||
              selectedUserType === practiceStaffRoleId ? (
              <Grid item md={4} xs={12}>
                {/* <CustomDropdownField */}
                <CustomMultipleDropDown
                  name="entityIds"
                  control={control}
                  options={practiceList}
                  label="Practice"
                  rules={{
                    validate: {
                      notZero: (value: number) =>
                        value !== 0 || "Practice is required.",
                    },
                  }}
                />
              </Grid>
            ) : selectedUserType === billerRoleId ? (
              <Grid item md={4} xs={12}>
                <CustomDropdownField
                  name="entityId"
                  control={control}
                  options={billerUsersList}
                  label="Biller"
                  rules={{
                    validate: {
                      notZero: (value: number) =>
                        value !== 0 || "Biller is required.",
                    },
                  }}
                />
              </Grid>
            ) : (
              <Grid item md={4} xs={12}></Grid>
            )}

            <Grid item md={12} xs={12}>
              <Divider
                orientation="horizontal"
                variant="middle"
                flexItem
                sx={{ padding: 0, margin: 0, mt: 1, mb: 1 }}
              />
            </Grid>

            {/* <Grid item md={4} xs={12}>
              <CustomTextField
                control={control}
                controllerName="userName"
                label="Username"
                rules={{ required: "Username is required." }}
              />
            </Grid> */}

            <Grid item md={4} xs={12}>
              <CustomTextField
                control={control}
                controllerName="email"
                label="Email"
                rules={{
                  required: "Email is required.",
                  pattern: {
                    value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i,
                    message: "Invalid email.",
                  },
                }}
              />
            </Grid>

            <Grid item md={8} xs={12}></Grid>

            {!isEditing && (
              <>
                <Grid item md={4} xs={12}>
                  <CustomPasswordTextField
                    control={control}
                    controllerName="password"
                    label="Password"
                    autoComplete="new-password"
                    rules={
                      isEditing ? {} : {
                        required: "Password is required.",
                        minLength: {
                          value: 12,
                          message: "Password should be at least 12 characters.",
                        },
                        pattern: {
                          value: /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])(?!.*\s)/,
                          message: "Password must contain at least one uppercase letter, one number, and one special character with no spaces"
                        },
                      }
                    }
                    isDisabled={isEditing}
                  />
                </Grid>

                <Grid item md={4} xs={12}>
                  <CustomPasswordTextField
                    control={control}
                    controllerName="confirmPassword"
                    label="Confirm Password"
                    autoComplete="new-password"
                    rules={
                      isEditing
                        ? {}
                        : {
                          required: "Confirmation of password is required.",
                          minLength: {
                            value: 12,
                            message: "Password must be at least 12 characters.",
                          },
                          pattern: {
                            value: /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])/,
                            message: "Password must contain at least one uppercase letter, one number, and one special character"
                          },
                          validate: (value: string) =>
                            value === watch("password") ||
                            "Passwords do not match",
                        }
                    }
                    isDisabled={isEditing}
                  />
                </Grid>
              </>
            )}

            <Grid item md={12} xs={12}>
              <Divider
                orientation="horizontal"
                variant="middle"
                flexItem
                sx={{ padding: 0, margin: 0, mt: 1, mb: 1 }}
              />
            </Grid>

            <Grid item md={12} xs={12}>
              <CustomNametField
                control={control}
                name=""
                isRequired={true}
                context=""
              />
            </Grid>

            <Grid item md={12} xs={12}>
              <Divider
                orientation="horizontal"
                variant="middle"
                flexItem
                sx={{ padding: 0, margin: 0, mt: 1, mb: 1 }}
              />
            </Grid>

            <Grid item md={4} xs={12}>
              <CustomPatternTextField
                control={control}
                name="phone"
                label="Phone"
                rules={{ required: "Phone is required." }}
              />
            </Grid>
            {/* CS-442 */}
            {userData && userData?.userId && (<>
              <Grid item md={12} xs={12}>
                <Divider
                  orientation="horizontal"
                  variant="middle"
                  flexItem
                  sx={{ padding: 0, margin: 0, mt: 1, mb: 1 }}
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <Header
                  title="Notification Preference(s)"
                  subtitle={""}
                />
                <Grid container spacing={3} ml={0.5}>
                  <Grid container spacing={2} mt={2} wrap="wrap">
                    {notificationOptions.map((data: any, index: number) => {
                      // Find the relevant user preference based on the notification subtype
                      const userPreference = userNotificationPreferenceData?.find(
                        (pref: any) => pref.notificationSubType === data.value
                      );

                      // Determine the isEnabled value
                      const isEnabled = userPreference ? userPreference.isEnabled : false; // Default to false if not found

                      return (
                        <Grid item xs={6} md={2} key={index}>
                          <Typography>
                            <span>{data?.label} :</span>{" "}
                            <b style={{ paddingLeft: "10px", color: isEnabled ? 'green' : 'red' }}>{isEnabled ? "ON" : "OFF"}</b>
                          </Typography>
                        </Grid>
                      );
                    })}
                  </Grid>

                </Grid>
                {/* // */}

              </Grid>
            </>)}
            {/* // */}
            <Grid
              mt={2}
              container
              justifyContent="flex-end"
              alignItems="center"
              spacing={1}
            >
              <Grid item xs={12} md="auto">
                <Button
                  type="submit"
                  color="info"
                  startIcon={<SaveOutlined />}
                  fullWidth
                >
                  {isEditing ? "Update User" : "Create User"}
                </Button>
              </Grid>
              <Grid item xs={12} md="auto">
                <Button
                  color="error"
                  startIcon={<CancelOutlined />}
                  onClick={() => onClose()}
                  fullWidth
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Card>
    </Box>
  );
};

export default CreateUser;