import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Container,
  Typography,
  Grid,
  IconButton,
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Button from "../common/Button";
import Card from "../common/Card";
import { useTheme } from "@mui/material/styles";
import { ColorModeContext, tokens } from "../../theme";
import { useNavigate, useParams } from "react-router-dom";
import { Alert } from "@mui/material";
import { DarkModeOutlined, LightModeOutlined } from "@mui/icons-material";
import CustomTextField from "../common/CustomTextField";
import { useForm } from "react-hook-form";
import {
  ChangeForgotPassword,
  ResetPasswordInfo,
} from "../../redux/slice/authSlice";
import { useAppDispatch } from "../../redux/slice/redux-hooks";
import CustomPasswordTextField from "../common/CustomPasswordTextField";

const ResetPassword: React.FC = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const [errorMessageContent, setErrorMessageContent] = useState("");
  const [open, setOpen] = useState(false);
  const colorMode = React.useContext(ColorModeContext);
  const { code } = useParams();
  const dispatch = useAppDispatch();
  const [successopen, setSuccessOpen] = useState(false);
  const { control, handleSubmit, reset, watch, setValue } =
    useForm<ResetPasswordInfo>();

  const handleClose = () => {
    setOpen(false);
  };

  const handleSuccessClose = () => {
    setSuccessOpen(false);
  };

  useEffect(() => {
    if (!code && code != undefined) {
      setValue("code", code);
    }
  }, [code]);

  const onSubmit = async (data: ResetPasswordInfo) => {
    if (!code && code != undefined) {
      data = { ...data, code: code };
    }
    if (!data.newPassword || data.newPassword.trim() === "") {
      setErrorMessageContent("New Password cannot be empty.");
      setOpen(true);
      return;
    }
    if (!data.confirmPassword || data.confirmPassword.trim() === "") {
      setErrorMessageContent("Confirm Password cannot be empty.");
      setOpen(true);
      return;
    }

    if (data.newPassword !== data.confirmPassword) {
      setErrorMessageContent("Passwords do not match");
      setOpen(true);
      return;
    }
    if (!code && code == undefined) {
      setErrorMessageContent("Error in generating password");
      setOpen(true);
    }

    const restPassword: ResetPasswordInfo = {
      code: code != undefined ? code : "",
      newPassword: data.newPassword,
      confirmPassword: data.confirmPassword,
    };

    const response = await dispatch(ChangeForgotPassword(restPassword));
    // Handle password reset logic here
    if (response.meta.requestStatus === "fulfilled") {
      setErrorMessageContent(
        "Your password has been changed successfully."
      );
      setSuccessOpen(true);
    } else {
      setErrorMessageContent(
        "Password reset failed. Please try again."
      );
      setOpen(true);
    }

    console.log(restPassword);
    // navigate("/signIn");
  };

  return (
    <>
      <Box
        sx={{
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          borderBottom: 1,
          borderColor:
            theme.palette.mode === "dark" ? "primary.dark" : "secondary.main",
        }}
      >
        <Grid
          container
          spacing={2}
          p={2}
          display="flex"
          style={{ backgroundColor: colors.grey[800] }}
        >
          <Grid item sm={4}></Grid>
          <Grid item sm={4} style={{ textAlign: "center" }}>
            <img
              alt="logo"
              src={require("../../assets/V3_logo-gradient.png")}
            />
          </Grid>
          <Grid item sm={4} style={{ textAlign: "end" }}>
            <IconButton onClick={colorMode.toggleColorMode}>
              {theme.palette.mode === "dark" ? (
                <LightModeOutlined />
              ) : (
                <DarkModeOutlined />
              )}
            </IconButton>
          </Grid>
        </Grid>
      </Box>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        sx={{ minHeight: "100vh" }}
      >
        <Container component="main" maxWidth="sm">
          <Card>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Avatar sx={{ m: 1 }}>
                <LockOutlinedIcon />
              </Avatar>
              <Typography variant="h3">
                <b>Reset Password</b>
              </Typography>
              <Box sx={{ textAlign: "center", mt: 2, color: colors.grey[500] }}>
                <Typography variant="h6">
                  Please enter your new password and confirm it.
                </Typography>
              </Box>
              <Box sx={{ mt: 1 }}>
                {errorMessageContent == null || errorMessageContent === "" ? (
                  ""
                ) : (
                  <>
                    <Box mb={2}>
                      {open && (
                        <Alert
                          variant="filled"
                          severity="error"
                          onClose={handleClose}
                        >
                          {errorMessageContent}
                        </Alert>
                      )}
                    </Box>
                    <Box mb={2}>
                      {successopen && (
                        <Alert
                          variant="filled"
                          severity="success"
                          onClose={handleSuccessClose}
                        >
                          {errorMessageContent}
                        </Alert>
                      )}
                    </Box>
                  </>
                )}
                <form onSubmit={handleSubmit(onSubmit)}>
                  <CustomPasswordTextField
                    margin="normal"
                    fullWidth
                    label="New Password"
                    controllerName="newPassword"
                    control={control}
                    rules={
                      {
                        required: "Password is required.",
                        minLength: {
                          value: 12,
                          message: "Password should be at least 12 characters.",
                        },
                        pattern: {
                          value: /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])/,
                          message: "Password must contain at least one uppercase letter, one number, and one special character"
                        },
                      }
                    }
                  />
                  <CustomPasswordTextField
                    margin="normal"
                    fullWidth
                    label="Confirm Password"
                    controllerName="confirmPassword"
                    control={control}
                    rules={
                      {
                        required: "Password is required.",
                        minLength: {
                          value: 12,
                          message: "Password should be at least 12 characters.",
                        },
                        pattern: {
                          value: /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])/,
                          message: "Password must contain at least one uppercase letter, one number, and one special character"
                        },
                      }
                    }
                  />
                  <Button
                    type="submit"
                    variant="contained"
                    color="success"
                    fullWidth
                    size="large"
                    style={{
                      borderRadius: 50,
                      padding: "15px",
                      marginTop: "20px",
                    }}
                  >
                    Reset Password
                  </Button>
                </form>
                <Grid container>
                  <Grid item xs sx={{ textAlign: "center", mt: 2 }}>
                    <Typography
                      variant="h6"
                      style={{ cursor: "pointer" }}
                      onClick={() => navigate("/signIn")}
                    >
                      Back to Sign In
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Card>
        </Container>
      </Grid>
    </>
  );
};

export default ResetPassword;
