import { SearchBaaRequest, UploadBaaInfo } from "../redux/slice/baa/createBusinessAgreementSlice";
import { http } from "./http";
import { PREVIEW_BAA_DETAIL_URL, SEARCH_BAA_LIST_URL, SIGN_BAA_DETAIL_URL, UPLOAD_BAA_DETAIL_URL } from "./URL";

export const getBaaDetailsList = async (data: SearchBaaRequest) => {
    try {
        const response = await http.post(SEARCH_BAA_LIST_URL, data);
        return response
    } catch (error) {
        throw error;
    }
}

export const signBAADetail = async (data: Object) => {
    try {
        const response = await http.post(SIGN_BAA_DETAIL_URL, data);
        return response;
    }catch(error){
        throw error;
    }
};

export const previewBusinessAgreement = async (data?:any) => {
    try{
        const response = await http.post(PREVIEW_BAA_DETAIL_URL, data);
        return response;
    } catch(error){
        throw error;
    }
};

export const uploadBusinessAgreement = async (data?:UploadBaaInfo) => {
    try{
        const response = await http.post(UPLOAD_BAA_DETAIL_URL, data);
        return response;
    } catch(error){
        throw error;
    }
};

