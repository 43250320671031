import React, { useEffect, useRef, useState } from "react";
import SignatureCanvas from "react-signature-canvas";
import {
  Box,
  Grid,
  Tabs,
  Tab,
  useTheme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import {
  BackspaceOutlined,
  DrawOutlined,
  TextFieldsOutlined,
} from "@mui/icons-material";
import Button from "../Button";
import { tokens } from "../../../theme";
import TextField from "../TextField";
import "./SignaturePad.css";
import DropdownField from "../DropdownField";
import { Control, Controller } from "react-hook-form";
import CustomTextField from "../CustomTextField";

const availableFonts = [
  {
    value: "Dancing Script",
    label: "Dancing Script",
    style: { fontFamily: "Dancing Script" },
  },
  {
    value: "Courgette",
    label: "Courgette",
    style: { fontFamily: "Courgette" },
  },
  {
    value: "Indie Flower",
    label: "Indie Flower",
    style: { fontFamily: "Indie Flower" },
  },
  {
    value: "Shadows Into Light",
    label: "Shadows Into Light",
    style: { fontFamily: "Shadows Into Light" },
  },
  {
    value: "Permanent Marker",
    label: "Permanent Marker",
    style: { fontFamily: "Permanent Marker" },
  },
  {
    value: "Homemade Apple",
    label: "Homemade Apple",
    style: { fontFamily: "Homemade Apple" },
  },
];

export interface SignatureData {
  signature: string;
  typedText: string;
}

interface SignatureProps {
  onSignatureChange: (data: SignatureData) => void;
  isError?: boolean;
  name: string;
  textName?: string;
  control: Control<any, any>;
  validationRules?: {
    name?: Object;
    signature?: Object;
  };
  setSignOutside?: string;
  signature?: string;
  signerName?: string;
  disabled?: boolean;
}

const SignaturePad: React.FC<SignatureProps> = ({
  onSignatureChange,
  setSignOutside,
  isError,
  name,
  textName,
  control,
  validationRules = {},
  signature,
  signerName,
  disabled = false,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const signatureRef = useRef<SignatureCanvas>(null);
  const [typedSignature, setTypedSignature] = useState("");
  const [fontSize, setFontSize] = useState(isMobile ? 40 : 60);
  const [fontStyle, setFontStyle] = useState("Dancing Script");
  const [view, setView] = useState<"draw" | "type" | null>("draw");
  const [isSignatureProvided, setIsSignatureProvided] = useState(false);
  const [isChangeDropdown, setIsChangeDropdown] = useState(false);

  const handleTypedSignatureChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newText = e.target.value;
    setIsChangeDropdown(false);
    setTypedSignature(newText);
    setSignature(newText);
  };

  const calculateFontSize = (text: string, canvasWidth: number) => {
    const maxFontSize = isMobile ? 40 : 60;
    const minFontSize = 10;
    const context = document.createElement("canvas").getContext("2d");
    if (context) {
      context.font = `${maxFontSize}px '${fontStyle}'`;
      let fontSize = maxFontSize;
      while (context.measureText(text).width > canvasWidth && fontSize > minFontSize) {
        fontSize -= 1;
        context.font = `${fontSize}px '${fontStyle}'`;
      }
      return fontSize;
    }
    return maxFontSize;
  };

  const setSignature = (setText: string) => {
    if (view === "type" && signatureRef.current) {
      const canvas = signatureRef.current.getCanvas();
      const ctx = canvas.getContext("2d");
      if (ctx) {
        const calculatedFontSize = calculateFontSize(setText, canvas.width);
        console.log(ctx.canvas.height);
        ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
        ctx.font = isChangeDropdown ? `${fontSize}px '${fontStyle}'` : `${calculatedFontSize}px '${fontStyle}'`;
        ctx.fillStyle = "black";

        const text = setText;
        // const textWidth = ctx.measureText(text).width;
        const textHeight = isChangeDropdown ? fontSize : calculatedFontSize;

        // Calculate the center position
        // const x = (canvas.width - textWidth) / 2;
        // const y = (canvas.height + textHeight) / 2;
        //CS-299 : KS : Changes Signature Field in Product Agreements
        const x = 0; // Starting from the left edge of the canvas
        const y = (canvas.height + textHeight) / 2;
        //
        ctx.fillText(text, x, y);

        const base64Data = canvas.toDataURL("image/png");
        const base64Substringed = base64Data.substring(
          "data:image/png;base64,".length
        );
        onSignatureChange({
          signature: base64Substringed,
          typedText: setText,
        });
        setIsSignatureProvided(true);
      }
    }
  };

  useEffect(() => {
    if (signature && signatureRef.current) {
      const image = new Image();
      image.onload = () => {
        const canvas = signatureRef.current?.getCanvas();
        const ctx = canvas?.getContext("2d");
        if (ctx) {
          signatureRef.current?.clear();
          ctx.drawImage(image, 0, 0);

          ctx.globalCompositeOperation = "source-in";
          ctx.fillStyle = theme.palette.mode === "dark" ? "white" : "black";
          ctx.fillRect(0, 0, ctx.canvas.width, ctx.canvas.height);
          ctx.globalCompositeOperation = "source-over";
        }
      };
      image.src = `data:image/png;base64,${signature}`;
    }
  }, [signature]);

  useEffect(() => {
    if (signerName) {
      setTypedSignature(signerName);
    }
  }, [signerName]);

  const drawTypedSignature = () => {
    if (signatureRef.current) {
      const canvas = signatureRef.current.getCanvas();
      const ctx = canvas.getContext("2d");
      if (ctx) {
        const calculatedFontSize = calculateFontSize(typedSignature, canvas.width);
        ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
        ctx.font = isChangeDropdown ? `${fontSize}px '${fontStyle}'` : `${calculatedFontSize}px '${fontStyle}'`;
        ctx.fillStyle = theme.palette.mode === "dark" ? "white" : "black";
        const text = typedSignature;
        const textWidth = ctx.measureText(text).width;
        const textHeight = isChangeDropdown ? fontSize : calculatedFontSize;

        // Calculate the center position
        const x = (canvas.width - textWidth) / 2;
        const y = (canvas.height + textHeight) / 2;
        ctx.fillText(text, x, y);
      }
    }
  };

  const getPenColor = () => {
    if (theme.palette.mode === "dark") {
      return '#FFFFFF'
    } else {
      return 'black'
    }
  }

  useEffect(() => {
    if (setSignOutside) {
      setView("type");
      setTypedSignature(setSignOutside);
      setSignature(setSignOutside);
    } else {
      clearSignature();
    }
  }, [setSignOutside]);

  useEffect(() => {
    if (setSignOutside) {
      if (view === "type") {
        setSignature(setSignOutside);
      }
      setTypedSignature(setSignOutside);
    }
  }, [view]);

  useEffect(() => {
    if (view === "type") {
      drawTypedSignature();
    }
  }, [fontSize, fontStyle, typedSignature, view, theme]);

  const handleFontSizeChange = (e: any) => {
    setIsChangeDropdown(true);
    setFontSize(Number(e.target.value));
    if (view === "type") {
      drawTypedSignature();
    }
  };

  const handleFontStyleChange = (e: any) => {
    setFontStyle(e.target.value as string);
    if (view === "type") {
      drawTypedSignature();
    }
  };

  const clearSignature = () => {
    signatureRef.current?.clear();
    onSignatureChange({
      signature: "",
      typedText: "",
    });
    setTypedSignature("");
    setIsSignatureProvided(false);
  };

  const loadImage = (src: any) => {
    return new Promise((resolve, reject) => {
      const image = new Image();
      image.onload = () => resolve(image);
      image.onerror = (error) => reject(error);
      image.src = src;
    });
  };

  const saveSignature = async () => {
    const signatureSrc = signatureRef.current?.toDataURL("image/png");
    if (!signatureSrc) return;
    const signatureImage = await loadImage(signatureSrc);
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    //@ts-ignore
    canvas.width = signatureImage?.width;
    //@ts-ignore
    canvas.height = signatureImage?.height;
    //@ts-ignore
    context?.drawImage(signatureImage, 0, 0);
    const imageData = context?.getImageData(0, 0, canvas.width, canvas.height);
    const data = imageData?.data;
    if (data) {
      for (let i = 0; i < data.length; i += 4) {
        data[i] = 0;
        data[i + 1] = 0;
        data[i + 2] = 0;
      }
      context?.putImageData(imageData, 0, 0);
      const base64Data = canvas.toDataURL("image/png");
      const base64Substringed = base64Data?.substring(
        "data:image/png;base64,".length
      );
      if (base64Substringed) {
        onSignatureChange({
          signature: base64Substringed,
          typedText: typedSignature,
        });
        setIsSignatureProvided(true);
      }
    }
  };

  const handleChange = (
    event: React.SyntheticEvent,
    newValue: "draw" | "type"
  ) => {
    setView(newValue);
    clearSignature();
  };

  useEffect(() => {
    const updatePenColor = async () => {
      const newPenColor = getPenColor();

      if (signatureRef.current && !signatureRef.current.isEmpty()) {
        const canvas = signatureRef.current.getCanvas();
        const dataURL = signatureRef.current.toDataURL();
        const newSignatureData = await changeSignatureColor(dataURL, newPenColor, canvas.width, canvas.height);
        signatureRef.current.clear();
        signatureRef.current.fromDataURL(newSignatureData);
      }
    };

    updatePenColor();
  }, [theme.palette.mode]);

  const changeSignatureColor = (signatureData: string, newColor: string, canvasWidth: number, canvasHeight: number): Promise<string> => {

    return new Promise((resolve) => {
      const image = new Image();
      image.src = signatureData;

      const offscreenCanvas = document.createElement('canvas');
      offscreenCanvas.width = canvasWidth;
      offscreenCanvas.height = canvasHeight;
      const offscreenContext = offscreenCanvas.getContext('2d');

      image.onload = () => {
        // Draw the image on the offscreen canvas
        if (offscreenContext) {
          offscreenContext.drawImage(image, 0, 0);

          // Get image data and replace color
          const imageData = offscreenContext.getImageData(0, 0, offscreenCanvas.width, offscreenCanvas.height);
          const data = imageData.data;

          const r = parseInt(newColor.substring(1, 3), 16); // Red
          const g = parseInt(newColor.substring(3, 5), 16); // Green
          const b = parseInt(newColor.substring(5, 7), 16); // Blue

          for (let i = 0; i < data.length; i += 4) {
            if (data[i + 3] > 0) { // Check alpha channel for non-transparent pixels
              data[i] = r; // Red
              data[i + 1] = g; // Green
              data[i + 2] = b; // Blue
            }
          }

          offscreenContext.putImageData(imageData, 0, 0);

          resolve(offscreenCanvas.toDataURL());
        }
      };
    });
  };

  return (
    <Box
      sx={{
        border: 1,
        borderRadius: 3,
        padding: 1,
        borderColor: colors.grey[700],
      }}
    >
      <Tabs
        value={view}
        onChange={handleChange}
        textColor={theme.palette.mode === "dark" ? "secondary" : "inherit"}
        TabIndicatorProps={{
          sx: { backgroundColor: "red" },
        }}
      >
        <Tab
          // icon={<DrawOutlined />}
          label="Draw signature"
          value="draw"
          sx={{
            color: view === "draw" ? "red" : "inherit",
            p: 1,
            m: 0,
          }}
          disabled={disabled}
        />
        <Tab
          // icon={<TextFieldsOutlined />}
          label="Type signature"
          value="type"
          sx={{
            color: view === "type" ? "red" : "inherit",
            p: 1,
            m: 0,
          }}
          disabled={disabled}
        />
      </Tabs>

      <Grid
        container
        spacing={2}
        sx={{
          textAlign: "center",
          alignItems: "center",
          justifyContent: "center",
          mt: "1px",
        }}
      >
        <Grid item xs={12}>
          <CustomTextField
            controllerName={textName ? textName : "name"}
            control={control}
            variant="outlined"
            fullWidth
            value={typedSignature}
            onChange={handleTypedSignatureChange}
            onBlur={view === "type" ? drawTypedSignature : undefined}
            placeholder="Confirm your name and signature"
            rules={validationRules.name || {}}
            disabled={disabled}
            sx={{pl:2, pr:2}}
          />
        </Grid>

        <Controller
          name={name}
          control={control}
          rules={validationRules.signature || {}}
          render={({ field, fieldState: { error } }) => (
            <>
              <Grid
                item
                xs={12}
                md={12}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    border: "1px solid",
                    width: isMobile ? "280px" : "500px",
                    borderRadius: 2,
                    borderColor:
                      error && !isSignatureProvided
                        ? theme.palette.error.main
                        : theme.palette.mode === "dark"
                        ? "white"
                        : "black",
                    marginBottom: 1,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  style={{
                    pointerEvents: view === "type" ? "none" : "auto",
                  }}
                >
                  <SignatureCanvas
                    penColor={theme.palette.mode === "dark" ? "white" : "black"}
                    canvasProps={{
                      width: isMobile ? 280 : 500,
                      height: isMobile ? 180 : 100,
                      // className: "sigCanvas",
                    }}
                    ref={signatureRef}
                    onEnd={view === "draw" ? saveSignature : undefined}
                  />
                </Box>
                {error && !isSignatureProvided && (
                  <Typography
                    variant="body2"
                    color="error"
                    sx={{ marginLeft: 2, marginBottom: 1 }}
                  >
                    {error.message || "Signature is required"}
                  </Typography>
                )}
                <Box
                mt={1}
                 sx={{
                  width: isMobile ? "280px" : "500px",
                }}
>
                  <Grid
                    container
                    justifyContent={(view === "type" && typedSignature !=="") ? "space-between" : "flex-end"}
                    spacing={1}
                  >
                    {(view === "type" && typedSignature !=="") && (
                      <>
                        <Grid item xs={12} md>
                          <DropdownField
                            options={[
                              { value: 20, label: "20px" },
                              { value: 40, label: "40px" },
                              { value: 50, label: "50px" },
                              { value: 60, label: "60px" },
                            ]}
                            label="Font Size"
                            disableSearch
                            size="small"
                            onChangeSelect={handleFontSizeChange}
                            value={fontSize}
                          />
                        </Grid>
                        <Grid item xs={12} md="auto">
                          <DropdownField
                            size="small"
                            options={availableFonts}
                            label="Font Style"
                            disableSearch
                            onChangeSelect={handleFontStyleChange}
                            value={fontStyle}
                          />
                        </Grid>
                      </>
                    )}
                    <Grid item xs={12} md="auto">
                      <Button
                        onClick={clearSignature}
                        color="error"
                        startIcon={<BackspaceOutlined />}
                        disabled={disabled}
                        fullWidth
                      >
                        Clear
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </>
          )}
        />
      </Grid>
    </Box>
  );
};

export default SignaturePad;
