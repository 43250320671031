import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Dialog,
  DialogContent,
  Divider,
  useTheme,
  Alert,
} from "@mui/material";
import Button from "../../common/Button";
import { useForm } from "react-hook-form";
import { tokens } from "../../../theme";
import { useLocation } from 'react-router-dom';
import CustomPasswordTextField from "../../common/CustomPasswordTextField";
import { FAILED_CODE } from "../../../service/ServiceConstants";
import { useAppDispatch } from "../../../redux/slice/redux-hooks";
import { downloadDocumentById, validateManufacturerCredentials } from "../../../redux/slice/manufacturerNotifications/ManufacturerNotificationSlice";
import { decryptAES } from "../../../utils/EncryptorUtils";

interface ManufacturerNotificationIVRDownloadProps {
  onClose: () => void;
}

const ManufacturerNotificationIVRDownload: React.FC<
  ManufacturerNotificationIVRDownloadProps
> = ({ onClose }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const location = useLocation();
  const { control } = useForm();
  const dispatch = useAppDispatch();

  const [password, setPassword] = React.useState("");
  const [ivrDocId, setIvrDocId] = useState("");
  const [ivrNumber, setIvrNumber] = useState("");
  const [email, setEmail] = useState("");
  const [orderDocId, setorderDocId] = useState("");
  const [orderNumber, setorderNumber] = useState("");
  const [tissueTrackingDocId, setTissueTrackingDocId] = useState("");
  const [productReturnDocId, setProductReturnDocId] = useState("");
  const [errorMessageContent, setErrorMessageContent] = React.useState("");
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const encryptedIvrDocId = params.get('ivrDocId') || null;
    const encryptedIvrNumber = params.get('ivrNumber') || null;
    const encryptedOrderDocId = params.get('orderDocId') || null;
    const encryptedOrderNumber = params.get('orderNumber') || null;
    const encryptedTissueTrackingDocId = params.get('tissueTrackingDocId') || null;
    const encryptedProductReturnDocId = params.get('productReturnDocId') || null;
    const encryptedEmail = params.get('email') || null;
    const iv = params.get('iv');
    const salt = params.get('salt');

    // Decrypt parameters only if they are non-null and non-empty
    if (encryptedIvrDocId != null && encryptedIvrDocId !== '') {
      setIvrDocId(decryptAES(encryptedIvrDocId, iv, salt));
    }
    if (encryptedIvrNumber != null && encryptedIvrNumber !== '') {
      setIvrNumber(decryptAES(encryptedIvrNumber, iv, salt));
    }
    if (encryptedOrderDocId != null && encryptedOrderDocId !== '') {
      setorderDocId(decryptAES(encryptedOrderDocId, iv, salt));
    }
    if (encryptedOrderNumber != null && encryptedOrderNumber !== '') {
      setorderNumber(decryptAES(encryptedOrderNumber, iv, salt));
    }
    if (encryptedTissueTrackingDocId != null && encryptedTissueTrackingDocId !== '') {
      setTissueTrackingDocId(decryptAES(encryptedTissueTrackingDocId, iv, salt));
    }
    if (encryptedProductReturnDocId != null && encryptedProductReturnDocId !== '') {
      setProductReturnDocId(decryptAES(encryptedProductReturnDocId, iv, salt));
    }
    if (encryptedEmail != null && encryptedEmail !== '') {
      setEmail(decryptAES(encryptedEmail, iv, salt));
    }
  }, [location.search]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const password: string = data.get("password") as string;

    handleLogin(email, password);
  };

  const handleLogin = async (userName: string, password: string) => {
    if (userName && userName.trim() !== "" && password) {
      if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i.test(userName)) {
        setErrorMessageContent("Invalid email address.");
        setOpen(true);
        return;
      }
      try {
        const response = await dispatch(
          validateManufacturerCredentials({
            loginInfo: { userName, password },
            interceptorOption: { skipErrorPopup: true },
          })
        ).unwrap();

        if (response) {
          onClose();
        }

      } catch (e: any) {
        if (e?.code === FAILED_CODE) {
          setErrorMessageContent(e?.message);
        } else if (e?.message === "Rejected") {
          setErrorMessageContent("Unknown error. Please contact support.");
        } else {
          setErrorMessageContent("Unknown error. Please contact support.");
        }
        setOpen(true);
      }
    } else {
      setErrorMessageContent("Please enter both email and password.");
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={true}
      onClose={(_, reason) => {
        if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
          onClose();
        }
      }}
      disableEscapeKeyDown
      PaperProps={{
        sx: {
          borderRadius: "20px",
          padding: "20px",
          minWidth: "400px",
          boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.8)",
        },
      }}
      BackdropProps={{
        style: {
          backgroundColor: "rgba(0, 0, 0, 0.6)",
          backdropFilter: "blur(2px)",
        },
      }}
    >
      <DialogContent>
        <Typography
          variant="h4"
          fontWeight="bold"
          sx={{ display: "flex", alignItems: "center" }}
        >
          <img
            src={require("../../../assets/download_title.png")}
            alt="Excel Icon"
            style={{ width: 35, height: 35, marginRight: "0.5rem" }}
          />
          Download {ivrDocId ? `IVR (${ivrNumber})` : orderDocId ? `Order (${orderNumber})` : tissueTrackingDocId ? `Tissue Tracking` : productReturnDocId ? `Product Return` : ""}
        </Typography>

        <Typography variant="body2" sx={{ marginBottom: "1rem" }}>
          Please enter your password for Access {ivrDocId ? "IVR" : orderDocId ? "Order" : tissueTrackingDocId ? "Tissue Tracking" : productReturnDocId ? "Product Return" : ""}
        </Typography>

        <Divider sx={{ mb: "1rem" }} />

        <Box
          component="form"
          onSubmit={handleSubmit}
          noValidate
          sx={{ mt: 1 }}
        >

          {errorMessageContent == null ||
            errorMessageContent === "" ? (
            ""
          ) : (
            <Box mb={2}>
              {open && (
                <Alert
                  variant="filled"
                  severity="error"
                  onClose={handleClose}
                >
                  {errorMessageContent}
                </Alert>
              )}
            </Box>
          )}
          <Box sx={{ marginBottom: "1rem" }}>
            <Typography variant="body2">
              Email
            </Typography>
            <Typography variant="body2" fontWeight="bold">
              {email}
            </Typography>
          </Box>

          <Box sx={{ marginBottom: "1.5rem" }}>
            <Typography variant="body2">Password</Typography>
            <CustomPasswordTextField
              control={control}
              margin="normal"
              fullWidth
              controllerName="password"
              label="Password"
              id="password"
              autoComplete="current-password"
              sx={{ borderRadius: 2 }}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </Box>
          <Box sx={{ justifyContent: "center" }}>
            <Button variant="contained" type="submit" fullWidth>
              Access {ivrDocId ? " IVR" : orderDocId ? " Order" : tissueTrackingDocId ? " Tissue Tracking" : productReturnDocId ? " Product Return" : ""}
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ManufacturerNotificationIVRDownload;
