import React from "react";
import { Box, Grid, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import { tokens } from "../../theme";

interface IconDetailToolBarProps {
    items: Array<{
        icon: React.ReactElement;
        text: string;
    }>;
}

const IconDetailToolBar: React.FC<IconDetailToolBarProps> = ({ items }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    return (
      <>
        {isMobile ? (
          <Stack direction="row" alignItems="center" spacing={2}>
            <Grid container spacing={1} justifyContent="flex-start">
              {items.map((item, index) => (
                <Grid
                  item
                  key={index}
                  xs={isMobile ? 6 : "auto"}
                  sx={{
                    display: "flex",
                    whiteSpace: "nowrap",
                  }}
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    {item.icon}
                    <Typography
                      variant="caption"
                      sx={{ ml: 1, fontWeight: "bold" }}
                    >
                      {item.text}
                    </Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Stack>
        ) : (
          <Stack direction="row" alignItems="center" spacing={2}>
            {items.map((item, index) => (
              <Box sx={{ display: "flex", padding: "5px 10px" }}>
                {item.icon}
                <Typography
                  variant="caption"
                  sx={{ ml: 1, fontWeight: "bold" }}
                >
                  {item.text}
                </Typography>
              </Box>
            ))}
          </Stack>
        )}
      </>
    );
};

export default IconDetailToolBar;