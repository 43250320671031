import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  Grid,
  Box,
  Typography,
  Paper,
  Stack,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { Cancel, SaveAlt, CloudDownload, FileUploadOutlined } from "@mui/icons-material";
import Button from "../../common/Button";
import CustomTextField from "../../common/CustomTextField";
import Dropzone, { ExtendedFile } from "../../common/Dropzone";
import { xls, xlsx } from "../../../constants/applicationConstants";
import { convertBase64 } from "../../../utils/Utility";
import { Attachment, fetchInvoiceList, uploadInvoices } from "../../../redux/slice/invoice/InvoiceSlice";
import { TransitionProps } from "@mui/material/transitions";
import { useAppDispatch } from "../../../redux/slice/redux-hooks";
import {
  PaymentRequest,
  uploadCommissionPayment,
  uploadPayment,
} from "../../../redux/slice/payment/paymentSlice";
import {
  setSingleAlertObj,
  SingleAlertInfo,
} from "../../../redux/slice/commonSlice";
import { FAILED_CODE, SUCCESS_CODE } from "../../../service/ServiceConstants";
import OptionsPopup from "../../common/OptionsPopup";

interface UploadPaymentsProps {
  open: boolean;
  onClose: () => void;
  title: string;
  downloadFileName?: string;
  downloadFilePath?: string;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="down" ref={ref} {...props} />;
});



const UploadPaymentsPopup: React.FC<UploadPaymentsProps> = ({
  open,
  onClose,
  title,
  downloadFileName,
  downloadFilePath,
}) => {
  const dispatch = useAppDispatch();
  const { control, handleSubmit, setValue, clearErrors } = useForm<PaymentRequest>({});
  const [showInfoPopup, setShowInfoPopup]= useState<{message:string | JSX.Element, show: boolean}>({message: "", show: false });
  const [showUploadedFiles, setShowUploadedFiles] = useState(false); 

  const setErrorMessage = (errors:{errorMessage: string, field: string}[], fileName: string) => {
    const message = (<>
      <span style={{ color: 'red' }}>Error processing the</span>{" "}
       <span style={{ cursor: 'pointer', color: 'DodgerBlue', textDecoration: 'underline' }} onClick={() => downloadableText(errors, fileName)}>
          {fileName}
        </span>
      </>)
    setShowUploadedFiles(true);
    return message;
  } 
  
  const onSubmit = async (data: any) => {
    try {
      if (title === "Import Invoices") {
        const response = await dispatch(uploadInvoices({ invoiceData: data, interceptorOption : { skipErrorPopup: true } }));
        if (
          response?.payload?.data?.code === SUCCESS_CODE
        ) {
          const alertObj: SingleAlertInfo = {
            message: "Invoice File Upload Successfully!",
            alertType: "success",
          };
          dispatch(setSingleAlertObj(alertObj));
          onClose();
        } else if (response?.payload?.code === FAILED_CODE) {
          
          const message = setErrorMessage(response?.payload?.errors, "import_invoices_errors.txt") || "";
          setShowInfoPopup({ message:message , show: true });
        }
      } else if (title === "Import Invoice Payments") {
        const response = await dispatch(uploadPayment({ paymentData: data, interceptorOption : { skipErrorPopup: true } }));
        if (
          response?.payload?.data?.code === SUCCESS_CODE
        ) {
          const alertObj: SingleAlertInfo = {
            message: "Invoice Payment File Upload Successfully!",
            alertType: "success",
          };
          dispatch(setSingleAlertObj(alertObj));
          onClose();
        } else if(response?.payload?.code === FAILED_CODE) {
          const message = setErrorMessage(response?.payload?.errors, "import_invoice_payments_errors.txt") || "";
          setShowInfoPopup({ message:message , show: true });
        }
      } else {
        const response = await dispatch(uploadCommissionPayment({ commissionData: data, interceptorOption : { skipErrorPopup: true } }));
        if (
          response?.payload?.data?.code === SUCCESS_CODE
        ) {
          const alertObj: SingleAlertInfo = {
            message: "Commission Payment File Upload Successfully!",
            alertType: "success",
          };
          dispatch(setSingleAlertObj(alertObj));
          onClose();
        } else if(response?.payload?.code === FAILED_CODE) {
          const message = setErrorMessage(response?.payload?.errors, "import_manufacturer_payments.txt") || "";
          setShowInfoPopup({ message:message , show: true });
        }
      } 
    } catch (error) {
      console.log("Error saving note:", error);
    }
  };

  const downloadableText = (errors: {errorMessage: string, field: string}[], fileName: string) => {
    try {
      let errorSentence = ''
      errors?.forEach((error: any) => {
        if (error.field && error.errorMessage) {
          errorSentence += `${error.field}: ${error.errorMessage}\n`;
        } else if (error.errorMessage) {
          errorSentence += +`${error.errorMessage}\n`;
        }
        if (error.reason) {
          errorSentence +=  `${error.reason}\n`;
        }
      });
      const blob = new Blob([errorSentence], { type: 'text/plain' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(link.href); 
    } catch (error) {
      console.error('Error downloading the file:', error)
    }
  }


  const handleDropzoneChange = async (uploadedFiles: ExtendedFile[]) => {
    if (uploadedFiles && uploadedFiles.length > 0) {
      const base64File = String(await convertBase64(uploadedFiles[0]));
      const fileName = uploadedFiles[0].name;
      const fileExtension = fileName
        .slice(fileName.lastIndexOf(".") + 1)
        .toLowerCase();
      const base64Substring = base64File.substring(
        fileExtension === "xls"
          ? "data:application/vnd.ms-excel;base64,".length
          : "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,"
            .length,
        base64File.length
      );
      setValue("content", base64Substring);
      setValue("documentName", fileName);
      setValue("fileType", fileExtension);
      setValue("type", "PAYMENT_INVOICE");

      clearErrors(["content", "documentName", "fileType", "type"]);
    }
  };

  const handleDeleteFile = () => {
    setValue("content", "");
    setValue("documentName", "");
    setValue("fileType", "");
    setValue("type", "");
  };

  const onClosePopup = () => {
    setShowUploadedFiles(false);
    setShowInfoPopup({ message: "", show: false })
  }

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
      aria-describedby="alert-dialog-slide-description"
      fullWidth
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          {showInfoPopup.show &&
            <OptionsPopup
              open={showInfoPopup.show}
              onClose={onClosePopup}
              variant="danger"
              message={showInfoPopup.message}
              title="Error"
              buttons={[
                { name: "Cancel", color: "secondary", onClick: onClosePopup }
              ]}
            />
          }
          <Box p={1}>
            {downloadFilePath && (
              <Grid item xs={12}>
                <Paper sx={{ p: 2, mb: 2, width: "100%" }}>
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <img
                      src={require("../../../assets/excel_icon.png")}
                      alt="Excel Icon"
                      style={{ width: 24, height: 24 }}
                    />
                    <Typography variant="h6" component="div">
                      Download Excel Template
                    </Typography>
                  </Stack>
                  <Grid container spacing={2} sx={{ pt: "5px" }}>
                    <Grid item md xs={12}>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        component="p"
                      >
                        You can download the attached example and use them as a
                        starting point for your own file.
                      </Typography>
                    </Grid>
                    <Grid item md={4} xs={12}>
                      <a
                        href={downloadFilePath}
                        download={downloadFileName}
                        style={{ textDecoration: "none" }}
                      >
                        <Button
                          variant="outlined"
                          color="warning"
                          startIcon={<CloudDownload />}
                          fullWidth
                        >
                          Download
                        </Button>
                      </a>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            )}
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Dropzone
                  name="content"
                  onFileChange={() => { }}
                  onFileChangeTest={handleDropzoneChange}
                  onDeleteFile={handleDeleteFile}
                  control={control}
                  maxFileCount={1}
                  allowedFileTypes={{ ...xls, ...xlsx }}
                  label={title === "Import Invoices" ? "Upload Invoices" : "Attach Payment"}
                  rules={{required: "Attachment is required."}}
                  showUploadedFiles={showUploadedFiles}
                />
                <Box display="flex" justifyContent="space-between" mt={1}>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    Support Format : XLS, XLSX
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    Maximum size : 15MB
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <CustomTextField
                  control={control}
                  controllerName="comments"
                  label="Comments"
                  multiline
                  rows={3}
                />
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Grid container justifyContent="flex-end" spacing={1} p={2}>
            <Grid item xs={12} sm="auto">
              <Button type="submit" startIcon={<FileUploadOutlined />} fullWidth>
                Upload
              </Button>
            </Grid>
            <Grid item xs={12} sm="auto">
              <Button
                color="error"
                startIcon={<Cancel />}
                onClick={onClose}
                fullWidth
              >
                Cancel
              </Button>
             </Grid>
          </Grid>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default UploadPaymentsPopup;
