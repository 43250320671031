import {
  DarkModeOutlined,
  LightModeOutlined,
  ListAltOutlined,
  LockOutlined,
  ManageAccountsOutlined,
} from "@mui/icons-material";
import {
  Alert,
  Avatar,
  Box,
  Container,
  Grid,
  IconButton,
  Typography,
  useTheme,
} from "@mui/material";
import { ColorModeContext, tokens } from "../../../theme";
import React, { useEffect } from "react";
import { useAppDispatch } from "../../../redux/slice/redux-hooks";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "../../common/Button";
import Footer from "../../common/Footer";
import Card from "../../common/Card";
import CustomPasswordTextField from "../../common/CustomPasswordTextField";
import TextField from "../../common/TextField";
import { useForm } from "react-hook-form";
import { login } from "../../../redux/slice/authSlice";
import {
  removeItemFromLocalStorage,
  setDataToLocalStorage,
} from "../../../utils/StorageUtils";
import { hideMainErrorPopup } from "../../../redux/slice/commonSlice";
import { FAILED_CODE } from "../../../service/ServiceConstants";

const ManufacturerNotificationSignUp: React.FC = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = React.useContext(ColorModeContext);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { control } = useForm();

  const [errorMessageContent, setErrorMessageContent] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [rememberMe, setRememberMe] = React.useState(false);
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");

  useEffect(() => {
    const userEmail = location.state?.email;
    if (userEmail) {
      setUsername(userEmail);
    }
  }, []);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const email: string = data.get("email") as string;
    const password: string = data.get("password") as string;

    handleLogin(email, password);
  };

  const handleLogin = async (userName: string, password: string) => {
    if (userName && userName.trim() !== "" && password) {
      setUsername(userName.trim());
      userName = userName.trim();
      if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i.test(userName)) {
        setErrorMessageContent("Invalid email address.");
        setOpen(true);
        return;
      }
      try {
        const response = await dispatch(
          login({
            loginInfo: { userName, password },
            interceptorOption: { skipErrorPopup: true },
          })
        ).unwrap();

        // Set localStorage item to inform other tabs of the login action
        localStorage.setItem("login", Date.now().toString());

        if (rememberMe) {
          setDataToLocalStorage("savedUsername", userName);
          setDataToLocalStorage("rememberMe", "true");
        } else {
          removeItemFromLocalStorage("savedUsername");
          removeItemFromLocalStorage("rememberMe");
        }

        dispatch(hideMainErrorPopup());
      } catch (e: any) {
        if (e?.code === FAILED_CODE) {
          setErrorMessageContent(e?.message);
        } else if (e?.message === "Rejected") {
          setErrorMessageContent("Unknown error. Please contact support.");
        } else {
          setErrorMessageContent("Unknown error. Please contact support.");
        }
      }
    } else {
      setErrorMessageContent("Please enter both email and password.");
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box>
      <Box
        sx={{
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          borderBottom: 1,
          zIndex: 1100,
          borderColor:
            theme.palette.mode === "dark" ? "primary.dark" : "secondary.main",
        }}
      >
        <Grid
          container
          spacing={2}
          p={2}
          display="flex"
          style={{ backgroundColor: colors.grey[800] }}
        >
          <Grid item xs={4}></Grid>
          <Grid item xs={4} style={{ textAlign: "center" }}>
            <img
              alt="logo"
              src={require("../../../assets/V3_logo-gradient.png")}
              style={{ maxWidth: "100%", height: "auto" }}
            />
          </Grid>
          <Grid item xs={4} style={{ textAlign: "end" }}>
            <IconButton onClick={colorMode.toggleColorMode}>
              {theme.palette.mode === "dark" ? (
                <LightModeOutlined />
              ) : (
                <DarkModeOutlined />
              )}
            </IconButton>
          </Grid>
        </Grid>
      </Box>

      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        sx={{ minHeight: "100vh", pt: { xs: 10, md: 0 } }}
      >
        <Container
          component="main"
          maxWidth="md"
          style={{ paddingTop: "40px", paddingBottom: "50px" }}
        >
          <Card>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Avatar sx={{ m: 1 }}>
                    <LockOutlined />
                  </Avatar>
                  <Typography component="h1" variant="h5">
                    Sign in
                  </Typography>

                  <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                    {errorMessageContent == null ||
                      errorMessageContent === "" ? (
                      ""
                    ) : (
                      <Box mb={2}>
                        {open && (
                          <Alert
                            variant="filled"
                            severity="error"
                            onClose={handleClose}
                          >
                            {errorMessageContent}
                          </Alert>
                        )}
                      </Box>
                    )}
                    <TextField
                      fullWidth
                      id="email"
                      label="Email Address"
                      name="email"
                      autoComplete="email"
                      color={
                        theme.palette.mode === "dark" ? "secondary" : "primary"
                      }
                      style={{
                        borderRadius: "0",
                      }}
                      InputProps={{ sx: { borderRadius: 2 } }}
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                    />
                    <CustomPasswordTextField
                      control={control}
                      margin="normal"
                      fullWidth
                      controllerName="password"
                      label="Password"
                      id="password"
                      autoComplete="current-password"
                      sx={{ borderRadius: 2 }}
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />

                    <Grid container display="flex" justifyContent="center">
                      <Grid item md={6} xs={12}>
                        <Button
                          type="submit"
                          variant="contained"
                          color="success"
                          size="large"
                          fullWidth
                          style={{
                            borderRadius: 50,
                            padding: "15px",
                            marginTop: "10px",
                          }}
                        >
                          Sign In
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box
                  sx={{
                    backgroundColor: colors.grey[900],
                    borderRadius: 3,
                    border: 1,
                    borderColor: colors.grey[700],
                    textAlign: "center",
                    padding: "30px",
                  }}
                >
                  <Typography
                    variant="h4"
                    component="div"
                    sx={{
                      fontWeight: "bold",
                      textAlign: "center",
                      mb: 2,
                    }}
                  >
                    Welcome to V3 Biomed 👋
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      position: "relative",
                      width: "200px",
                      height: "150px",
                      margin: "0 auto",
                    }}
                  >
                    <Box
                      sx={{
                        width: "120px",
                        height: "120px",
                        backgroundColor: colors.primary[500],
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        position: "absolute",
                        top: 20,
                        left: 0,
                        zIndex: 1,
                        borderRadius: 3,
                        boxShadow: 5,
                        p: 1,
                      }}
                    >
                      <ListAltOutlined sx={{ fontSize: '35px' }} />
                      <Typography
                        variant="body1"
                        sx={{
                          color: colors.success[300],
                          mt: 1,
                          fontWeight: "bold",
                        }}
                      >
                        Order Management
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        width: "120px",
                        height: "120px",
                        backgroundColor: colors.grey[800],
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        position: "absolute",
                        top: 40,
                        right: 0,
                        zIndex: 2,
                        borderRadius: 3,
                        boxShadow: 5,
                        p: 1,
                      }}
                    >
                      <ManageAccountsOutlined sx={{ fontSize: '35px' }} />
                      <Typography
                        variant="body1"
                        sx={{
                          color: colors.success[300],
                          mt: 1,
                          fontWeight: "bold",
                        }}
                      >
                        IVR Management
                      </Typography>
                    </Box>
                  </Box>

                  <Typography
                    variant="body1"
                    sx={{ fontWeight: "bold", mt: 3 }}
                  >
                    LOG IN TO ACCESS PREMIUM FEATURES
                  </Typography>
                  <Typography variant="body2" sx={{ mt: 1 }}>
                    Exclusive tools and benefits await.
                    <br />
                    Please log in to explore all the premium features available
                    to you.
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Card>
        </Container>
      </Grid>
      <Box
        sx={{ position: "fixed", bottom: 0, left: 0, right: 0, borderTop: 5 }}
      >
        <Footer />
      </Box>
    </Box>
  );
};

export default ManufacturerNotificationSignUp;
