import { PayloadAction } from "@reduxjs/toolkit";
import { UserInfo } from "../redux/slice/authSlice";

/**
 * This function bypasses the type checker to update the value of an element. Use at your own risk.
 * @param state the redux state
 * @param action your payload action
 */

export const updateElementValue = (
  state: any,
  action: PayloadAction<{ elementName: string; value: string | number | UserInfo }>
) => {
  // @ts-ignore
  state[action.payload.elementName] = action.payload.value;
};
