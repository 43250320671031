
import { OrderReturn, TissueTrackingInfo, AddOrderInfo, OrderFulfillmentInfo, claimInfo, reimburseClaimInfo, OrderInfo } from "../redux/slice/order/OrderSlice";
import { ADD_TISSUETRACKING_URL, CANCEL_ORDER, CREATE_ORDER, GET_DOCUMENT_URL, GET_DOCUMENTS_URL, GET_ORDER_ITEMS_BY_ID, REIMBURSE_CLAIM_URL, RETURN_ORDER, SEARCH_ORDER, SUBMIT_CLAIM_URL, UPDATE_ORDER } from "./URL";
import { http } from "./http";

export const orderCreate = async (data: AddOrderInfo | null) => {
    try {
        const response = await http.post(CREATE_ORDER, data);
        return response
    } catch (error) {
        throw error;
    }
}

export const orderUpdate = async (data: OrderFulfillmentInfo | null) => {
    try {
        const response = await http.post(UPDATE_ORDER, data);
        return response
    } catch (error) {
        throw error;
    }
}

export const getOrderItemsById = async (orderId: number) => {
    try {
        const response = await http.get(`${GET_ORDER_ITEMS_BY_ID}?orderId=${orderId}`);
        return response
    } catch (error) {
        throw error;
    }
}

export const returnOrder = async (data: OrderReturn | null) => {
    try {
        const response = await http.post(RETURN_ORDER, data);
        return response
    } catch (error) {
        throw error;
    }
}

export const searchOrder = async (data: any) => {
    try {
      const response = await http.post(SEARCH_ORDER, data);
      return response;
    } catch (error) {
      throw error;
    }
  };

export const tissueTracking = async (data: TissueTrackingInfo) => {
    try {
        const response = await http.post(ADD_TISSUETRACKING_URL, data);
        return response;
    } catch (error) {
        throw error;
    }

};

export const submitClaim = async (data: claimInfo) => {
    try {
        const response = await http.post(SUBMIT_CLAIM_URL, data);
        return response;
    } catch (error) {
        throw error;
    }
};

export const submitReimbursedClaim = async (data: reimburseClaimInfo) => {
    try {
        const response = await http.post(REIMBURSE_CLAIM_URL, data);
        return response;
    } catch (error) {
        throw error;
    }
};

export const getOrderDocumentByDocumentId = async (orderDocumentId: number) => {
    try {
      const response = await http.get(`${GET_DOCUMENT_URL}/${orderDocumentId}`);
      return response;
    } catch (error) {
      throw error;
    }
  };


export const getOrderDocumentsByDocumentIds = async (orderDocumentIds: number[]) => {
    try {
        const response = await http.get(`${GET_DOCUMENTS_URL}/${orderDocumentIds}`);
        return response;
    } catch (error) {
        throw error;
    }
    };

  export const orderCancel = async (orderId: number) => {
    try {
        const response = await http.post(`${CANCEL_ORDER}/${orderId}`);
        return response
    } catch (error) {
        throw error;
    }
}