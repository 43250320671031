import React, { useEffect, useMemo } from "react";
import { Grid } from "@mui/material";
import CustomTextField from "./CustomTextField";
import { Control, Controller } from "react-hook-form";
import CustomDropdownField from "./CustomDropdownFiled";
import { CodeTable, getCodeTableValuesByTableName, useCodetablesSlice } from "../../redux/slice/codetableSlice";

interface AddressTextFieldData {
    stateAddress: string;
    suite: string;
    city: string;
    state: string;
    zipCode: string;
}

export type AddressTextFieldProps = {
    addressFieldsInfo?: any;
    onAddressFieldsChange?: (data: AddressTextFieldData, name: any) => void;
    context?: string;
    name?: string;
    control?: Control<any, any>;
    xs?: number;
    md?: number;
    lg?: number;
    isRequired?: boolean;
    isDisabled?:boolean;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const CustomAddressTextField: React.FC<AddressTextFieldProps> = ({
    control,
    addressFieldsInfo,
    onAddressFieldsChange,
    context = "",
    name = "",
    xs,
    md,
    lg,
    isRequired,
    isDisabled,
    onChange,
    ...props
}) => {
    const streetAddressLabel = `${context} Street Address`;
    const unitNumberLabel = `${context} Unit Number`;
    const cityLabel = `${context} City`;
    const stateLabel = `${context} State`;
    const zipLabel = `${context} Zip Code`;

    const handleFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (onChange) {
          onChange(event);
        }
      };

    const { allCodeTablesValuesMap, allCodeTableValueFetchStatus } = useCodetablesSlice();
    const [stateList, setStateList] = React.useState<CodeTable[]>([]); 
    
    const stateListOptions = useMemo(() => {
      return stateList.map(item => ({
        label: item.displayValue,
        value: item.keyName
      }));
    }, [stateList]);
  
    useEffect(() => {
        if (allCodeTableValueFetchStatus === "success" && allCodeTablesValuesMap) {
          const sortedStateList = [
            ...getCodeTableValuesByTableName(allCodeTablesValuesMap, "StateList"),
          ].sort((a, b) => a.displayValue.localeCompare(b.displayValue));
          setStateList(sortedStateList);
        }
      }, [allCodeTablesValuesMap, allCodeTableValueFetchStatus]);

    if (name)
        return (
            <Controller
                name={name}
                control={control}
                render={({ field, fieldState }) => (
                    <Grid container spacing={2}>
                        <Grid item xs={xs ? xs : 12} md={md ? md : 4} lg={lg ? lg : 4}>
                            <CustomTextField
                                controllerName={`${field.name}.${context.toLowerCase()}StreetAddress`} control={control}
                                label={streetAddressLabel} isDisabled={isDisabled} onChange={handleFieldChange}
                                rules={isRequired ? {
                                    required: `${context} Street Address is required.`,
                                    validate: {
                                        customValidation: (value: string) => {
                                            if (value?.trim() === "") {
                                                return `${context} Street Address is required.`;
                                            }
                                        }
                                    }
                                } : {}}
                            />
                        </Grid>
                        <Grid item xs={xs ? xs : 12} md={md ? md : 2} lg={lg ? lg : 2}>
                            <CustomTextField controllerName={`${field.name}.${context.toLowerCase()}UnitNumber`} control={control}
                                label={unitNumberLabel} isDisabled={isDisabled} onChange={handleFieldChange} />
                        </Grid>
                        <Grid item xs={xs ? xs : 12} md={md ? md : 2} lg={lg ? lg : 2}>
                            <CustomTextField controllerName={`${field.name}.${context.toLowerCase()}City`} control={control}
                                label={cityLabel} isDisabled={isDisabled} onChange={handleFieldChange}
                                rules={isRequired ? {
                                    required: `${context} City is required.`,
                                    validate: {
                                        customValidation: (value: string) => {
                                            if (value?.trim() === "") {
                                                return `${context} City is required.`;
                                            }
                                        }
                                    }
                                } : {}}
                            />
                        </Grid>
                        <Grid item xs={xs ? xs : 12} md={md ? md : 2} lg={lg ? lg : 2}>
                            <CustomDropdownField name={`${field.name}.${context.toLowerCase()}State`} control={control}
                                label={stateLabel} options={stateListOptions} disabled={isDisabled} onChangeSelect={handleFieldChange}
                                rules={isRequired ? {
                                    required: `${context} State is required.`,
                                    validate: {
                                        customValidation: (value: string) => {
                                            console.log("kkkkssssssss==" + value + "==");
                                            if (!value && value.trim() === "") {
                                                return `${context} State is required.`;
                                            }
                                        }
                                    }
                                } : {}}
                            />
                        </Grid>
                        <Grid item xs={12} md={2}>
                            <CustomTextField controllerName={`${field.name}.${context.toLowerCase()}Zipcode`} control={control}
                                label={zipLabel} isDisabled={isDisabled} onChange={handleFieldChange}
                                rules={isRequired ? {
                                    required: `${context} Zip Code is required.`,
                                    validate: {
                                        customValidation: (value: string) => {
                                            if (value?.trim() === "") {
                                                return `${context} Zip Code is required.`;
                                            }
                                        }
                                    }
                                } : {}}
                            />
                        </Grid>
                    </Grid>
                )}
            />
        );
    else
        return (
            <Grid container spacing={2}>
                <Grid item xs={xs ? xs : 12} md={md ? md : 4} lg={lg ? lg : 4}>
                    <CustomTextField controllerName={`${context.toLowerCase()}streetAddress`} control={control}
                        label={streetAddressLabel} isDisabled={isDisabled} onChange={handleFieldChange}
                        rules={isRequired ? {
                            required: `${context} Street Address is required.`,
                            validate: {
                                customValidation: (value: string) => {
                                    if (value?.trim() === "") {
                                        return `${context} Street Address is required.`;
                                    }
                                }
                            }
                        } : {}}
                    />
                </Grid>
                <Grid item xs={xs ? xs : 12} md={md ? md : 4} lg={lg ? lg : 4}>
                    <CustomTextField controllerName={`${context.toLowerCase()}unitNumber`} control={control}
                        label={unitNumberLabel} isDisabled={isDisabled} onChange={handleFieldChange} />
                </Grid>
                <Grid item xs={xs ? xs : 12} md={md ? md : 4} lg={lg ? lg : 4}>
                    <CustomTextField controllerName={`${context.toLowerCase()}city`} control={control}
                        label={cityLabel} isDisabled={isDisabled} onChange={handleFieldChange}
                        rules={isRequired ? {
                            required: `${context} City is required.`,
                            validate: {
                                customValidation: (value: string) => {
                                    if (value?.trim() === "") {
                                        return `${context} City is required.`;
                                    }
                                }
                            }
                        } : {}}
                    />
                </Grid>
                <Grid item xs={xs ? xs : 12} md={md ? md : 4} lg={lg ? lg : 4}>
                    <CustomDropdownField name={`${context.toLowerCase()}state`} control={control}
                        label={stateLabel} options={stateListOptions} disabled={isDisabled} onChangeSelect={handleFieldChange}
                        rules={isRequired ? {
                            required: `${context} State is required.`,
                            validate: {
                                customValidation: (value: string) => {
                                    if (!value) {
                                        return `${context} State is required.`;
                                    }
                                }
                            }
                        } : {}}
                    />
                </Grid>
                <Grid item xs={xs ? xs : 12} md={md ? md : 4} lg={lg ? lg : 4}>
                    <CustomTextField controllerName={`${context.toLowerCase()}zipCode`} control={control}
                        label={zipLabel} isDisabled={isDisabled} onChange={handleFieldChange}
                        rules={isRequired ? {
                            required: `${context} Zip Code is required.`,
                            validate: {
                                customValidation: (value: string) => {
                                    if (value?.trim() === "") {
                                        return `${context} Zip Code is required.`;
                                    }
                                }
                            }
                        } : {}}
                    />
                </Grid>
            </Grid>
        )
};

export default CustomAddressTextField;