import {
  Box,
  Chip,
  FormControlLabel,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Switch,
  Typography,
  useTheme,
} from "@mui/material";
import { tokens } from "../../theme";
import { CodeTable, fetchAllCodeTablesValues, getCodeTableValuesByTableName, useCodetablesSlice } from "../../redux/slice/codetableSlice";
import { useEffect, useMemo, useState } from "react";
import { NOTIFICATION_TYPE, NOTIFICATION_TYPE_LIST } from "../../constants/applicationConstants";
import { useDispatch } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { saveUserNotificationPreference, searchUserNotificationPreference, UserNotificationPreference, useUserReducer } from "../../redux/slice/userSlice";
import { AppDispatch } from "../../redux/store";

const SettingProfile = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const dispatch = useDispatch<AppDispatch>();
  const { allCodeTablesValuesMap } = useCodetablesSlice();
  const { userObject, userNotificationPreferenceData, fetchAllUserRoleStatus } = useUserReducer();
  // Initialize state for switches
  const [switchStates, setSwitchStates] = useState<{ [key: string]: boolean }>({});



  //Calling these again when notfication preference is set true
  useEffect(() => {
    if (fetchAllUserRoleStatus === "success") {
      const data: UserNotificationPreference = {
        userId: userObject?.userId,
        notificationType: NOTIFICATION_TYPE,
        notificationSubType: null
      }
      dispatch(searchUserNotificationPreference(data))
    }

  }, [fetchAllUserRoleStatus])


  //handling the toggle switch
  useEffect(() => {
    // Initialize state based on userNotificationPreferenceData
    if (Array.isArray(userNotificationPreferenceData)) {
      const initialStates: { [key: string]: boolean } = {};
      userNotificationPreferenceData.forEach((pref) => {
        initialStates[pref.notificationSubType] = pref.isEnabled;
      });
      setSwitchStates(initialStates);
    }
  }, [userNotificationPreferenceData]);
  //
  useEffect(() => {
    const data: UserNotificationPreference = {
      userId: userObject?.userId,
      notificationType: NOTIFICATION_TYPE,
      notificationSubType: null
    }
    dispatch(searchUserNotificationPreference(data))
  }, [])
  const notificationOptions = useMemo(() => {
    let notificationTypes: CodeTable[] = [];
    if (allCodeTablesValuesMap) {
      notificationTypes = getCodeTableValuesByTableName(allCodeTablesValuesMap, NOTIFICATION_TYPE_LIST);
    }
    let options: { label: string, value: any }[] = [];
    notificationTypes?.map((option) => {
      options.push({ label: option.displayValue, value: option.displayValue });
    });
    return options;
  }, [allCodeTablesValuesMap]);

  const handleSwitchToggle = async (data: { value: string }, newValue: boolean) => {

    const preference = userNotificationPreferenceData.find((pref: { notificationSubType: string; }) =>
      pref.notificationSubType === data.value
    );

    const preferenceSelection = {
      userNotificationPreferenceId: preference ? preference.userNotificationPreferenceId : null,
      userId: userObject?.userId,
      notificationType: NOTIFICATION_TYPE,
      notificationSubType: data.value,
      isEnabled: newValue,
    };

    // Call the API to update/save the preference
    await dispatch(saveUserNotificationPreference(preferenceSelection));

    // Update the local state
    setSwitchStates((prevStates) => ({
      ...prevStates,
      [data.value]: newValue,
    }));
  };

  return (
    <Box
      sx={{
        padding: 3,
        borderRadius: "16px",
        backgroundColor: colors.grey[900],
      }}
    >
      <Typography
        variant="h6"
        sx={{ fontWeight: "bold", marginBottom: 2 }}
        color={colors.info[300]}
      >
        Notifications Settings
      </Typography>

      <Grid container spacing={3}>
        {notificationOptions && notificationOptions.map((data, index) => {
          return (
            <>
              <Grid item xs={12} sm={9}>
                <Typography variant="subtitle1" sx={{ color: "gray" }}>
                  {data.label}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={3} key={index}>
                <FormControlLabel
                  control={<Switch color="success"
                    checked={switchStates[data.value] || false} 
                    onChange={(e) => {
                      const newValue = e.target.checked;
                      handleSwitchToggle(data, newValue); 
                    }} />}
                  label=""
                  sx={{ marginLeft: "auto" }}
                />
              </Grid>
            </>
          )

        })}
      </Grid>
    </Box>
  );
};

export default SettingProfile;
