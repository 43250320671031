export const COMMON_SERVICE_URL = "http://localhost:8080/v3biomed"

export const LOGIN_URL = "/authenticateservice/authenticate";
export const LOGOUT_URL = "/authenticateservice/logout";
export const CHECK_AUTHENTICATION_URL = "/authenticateservice/checkAuthentication";
export const RE_ACCESS_TOKEN_URL = "/authenticateservice/renewAccessToken";
export const FETCH_ALL_CODE_TABLES_VALUES_URL = "/codetableservice/getAllCodeTableValue";
export const FETCH_CODE_TABLE_VALUES_BY_TABLE_NAME_URL = "/codetableservice/getAllCodeTableValueByTableName";
export const CREATE_PRACTICE_URL = "/practiceservice/createPractice";
export const FETCH_PRACTICES_URL = "/practiceservice/getPracticeList";
export const FETCH_PRACTICE_URL = "/practiceservice/getPracticeById";
export const SAVE_PRACTICE_URL = "/practiceservice/submitPractice";
export const SUBMIT_PRACTICE_URL = "/practiceservice/submitPractice";
export const EDIT_PRACTICE_URL = "/practiceservice/editPractice";
export const FETCH_WOUNDCAREINFO_URL = "/codetableservice/codetable";
export const APPROVE_PRACTICE_REQUEST_URL = "/practiceservice/approvePracticeRequest";
export const FETCH_PRACTICE_LOCATIONS_URL = "/practiceservice/getPracticeLocationList";
export const FETCH_PRACTICE_PROVIDERS_URL = "/practiceservice/getPracticeProviderList";
export const FETCH_PRACTICE_PRODUCTS_URL = "/practiceservice/getPracticeProductList";
export const FETCH_PRACTICE_PRODUCTS_WITHOUT_DETAILS_URL = "/practiceservice/getPracticeProductListWithoutDetails";
export const FETCH_ALL_PRODUCTS_URL = "/productservice/getProductList";
export const SAVE_PRACTICE_LOCATION_URL = "/practiceservice/practicelocation/save";
export const INACTIVE_PRACTICE_LOCATION_URL = "/practiceservice/practicelocation/inactivate";
export const SAVE_PRACTICE_PROVIDER_URL = "/practiceservice/practiceprovider/save";
export const INACTIVE_PRACTICE_PROVIDER_URL = "/practiceservice/practiceprovider/inactivate";
export const FETCH_PRACTICE_BILLER_URL = "/practiceservice/getPracticeBillerById";
export const UPDATE_PRACTICE_BILLER_URL = "/practiceservice/updatePracticeBillerById";
export const FETCH_PRACTICE_EXECUTIVE_URL = "/practiceservice/getPracticeExecutiveById";
export const UPDATE_PRACTICE_EXECUTIVE_URL = "/practiceservice/updatePracticeExecutiveById";
export const GET_PRACTICE_LOCATION_BY_LOCATIONID_URL = "/practiceservice/getPracticeLocationById";
export const FORGOT_PASSWORD_RESET_URL = "/authenticateservice/forgotPassword";
export const CHANGE_PASSWORD_RESET_URL = "/authenticateservice/changePassword";
 
// patient related URL - START
export const CREATE_PATIENT_URL = "/patientservice/createPatient";
export const FETCH_PATIENTLIST_URL = "/patientservice/getPatientList";
export const FETCH_PATIENT_URL = "/patientservice/patient";
export const DELETE_PATIENT_URL = "patientservice/deletePatient";
export const FETCH_PATIENT_LIST_BY_PRACTICE_ID = "patientservice/patient/byPractice";
export const GET_PATIENT_LIST_BY_USER_ROLE = "/patientservice/patient/byUserRole";
// patient related URL - END
//cs-433
export const GET_APP_CONFIG_LIST_URL = "/commonservice/getAppConfigList";
export const EDIT_APP_CONFIG_URL = "/commonservice/appconfig/edit";
//
export const FETCH_MACS_URL = "/commonservice/getMacs";
export const FETCH_ACTIVE_AGENCY_EXECUTIVES_URL = "/commonservice/getActiveAgencyExecutives"
export const FETCH_PRODUCT_WITH_PRICE_URL = "/productservice/getProductsWithPricing"
export const SEARCH_PRACTICES_URL = "/practiceservice/searchPracticeList";
export const FETCH_BILLERS_URL = "/commonservice/getBillers";
export const GET_MANUFACTURERS_URL = "/commonservice/getManufacturers";
export const GET_ALL_AGENCIES_URL = "/commonservice/getAllAgencies";
export const FETCH_PRODUCTS_PRICE_BY_MAC = "/productservice/getCurrentProductPricingByMAC";
export const REJECT_PRACTICE_REQUEST_URL = "/practiceservice/rejectPracticeRequest";
export const GET_DOCUMENT_URL = "/documentservice/getDocument";
export const GET_DOCUMENTS_URL = "/documentservice/getDocuments";
export const GET_TEMPLATE_BY_ID_URL = "/documentservice/getTemplate";
export const FETCH_PRACTICE_BY_USER_ROLE = "/practiceservice/getPracticesByUserRole";
export const GET_PRACTICE_PRODUCT_BY_PRODUCTID = '/practiceservice/getPracticeProductById';
export const GET_PRODUCT_ITEM_BY_PRODUCT_ID = "/productservice/productitem/byProductId";
export const GET_PRACTICE_PRODUCT_AGREEMENT_BY_PRACTICE_PRODUCT_URL = '/practiceservice/getPracticeProductAgreementById';
export const FETCH_UPDATED_PRACTICE_REGISTRATION_BY_PRACTICE_ID_URL = '/practiceservice/getPracticeProductListByPracticeAndProduct';
export const DOWNLOAD_UPDATED_REGISTRATION = "/practiceservice/downloadUpdatedRegistration";


// product related URL - START
export const FETCH_PRODUCTLIST_URL = "/productservice/getProductList";
export const CREATE_PRODUCT_URL = "/productservice/saveProduct";
export const FETCH_PRODUCT_DETAILS = "/productservice/getProductDetails";
export const FETCH_PRODUCT_DETAILS_BY_ID = "/productservice/getProductById";
export const DELETE_PRODUCT_URL = "productservice/deleteProduct"
export const GET_PRODUCT_LIST_WITH_LOGO_URL = "productservice/getProductListWithLogo";
export const FETCH_PRODUCTS_BY_USER_ROLE_URL = "productservice/getProductsByUserRole";
export const SAVE_PRODUCT_LOGO_URL = "productservice/saveProductLogo";
export const GET_PRODUCT_DOCUMENTS_URL = "productdocumentsservice/getProductDocuments";
export const FETCH_PRODUCTS_INCLUDING_INACTIVE_BY_USER_ROLE_URL = "productservice/getProductsByUserRoleIncludingInactive";

export const SAVE_PRODUCT_ITEM_URL = "productservice/productitem/save";
export const SAVE_PRODUCT_PRICE_ITEM_URL = "productservice/productprice/save";
export const INACTIVE_PRODUCT_PRICE_ITEM_URL = "productservice/productprice/inactivate";
export const DELETE_PRODUCT_PRICE_ITEM_URL = "productservice/productprice/delete";
export const INACTIVE_PRODUCT_ITEM_URL = "productservice/productitem/inactivate";
export const ACTIVE_PRODUCT_ITEM_URL = "productservice/productitem/activate";
export const GET_PRODUCT_ITEM_HISTORY_URL = "productservice/productitem/getProductItemsHistory";
export const GET_PRODUCT_PRICE_ITEM_HISTORY_URL = "productservice/productprice/getProductPricingHistory";
export const UPDATE_PRODUCT = "productservice/updateProduct";

export const FETCH_PRODUCTS_AGREEMENTS = "/practiceservice/searchPracticeProductAgreementsList";
export const CREATE_PRODUCT_AGREEMENT = "/productservice/practiceproduct/createProductAgreement";
export const PRACTICE_SIGN_PRODUCT_AGREEMENT = "/practiceservice/practiceSignProductAgreement";
export const MANUFACTURE_SIGN_PRODUCT_AGREEMENT = "/productservice/practiceproduct/manufacturerSignProductAgreement";
export const PREVIEW_PRACTICE_PRODUCT_AGREEMENT = "/productservice/practiceproduct/previewPracticeProductAgreement";
export const INITIATE_PRODUCT_AGREEMENT_URL = "/productservice/practiceproduct/initiateProductAgreements";
export const GET_AGREEMENT_COUNT_FOR_EXPIRED_PRODUCT_PRICE = "/productservice/practiceproduct/getAgreementCountForExpiredProductPrice";
export const GENERATE_NEW_AGREEMENTS_FOR_EXPIRED_PRODUCT_PRICE = "/productservice/practiceproduct/generateNewAgreementsForExpiredProductPrice";
export const UPLOAD_SIGNED_AGREEMENT_BY_ID = "/productservice/practiceproduct/uploadSignedAgreementById";

// product related URL - END

export const FETCH_WOUNDCARE_INFO = "/codetableservice/getAllCodeTableValueByTableName";
export const DELETE_PRODUCT_AGREEMENT_BY_ID_URL = "/practiceservice/deleteProductAgreementById";
export const INACTIVATE_PRODUCT_AGREEMENT_BY_ID_URL = "/practiceservice/inactivateProductAgreementsById";


// user related URL - start
export const GET_ALL_USERROLES_URL = "userservice/userrole/list";
export const GET_USERPROFILE_BY_ID = "userservice/userprofile/get";
export const SAVE_USER_PROFILE_URL = "userservice/saveUserProfile";
export const SEARCH_USER_LIST_URL = "userservice/searchUserList";
export const RESET_PASSWORD_URL = "userservice/resetPassword";
export const INACTIVATE_ACTIVATE_USER_URL = "userservice/inactivateActivateUser";
//
export const SEARCH_NOTIFICATION_PREFERENCE_URL = "userservice/searchUserNotificationPreferenceList";
export const SAVE_NOTIFICATION_PREFERENCE_URL = "userservice/saveUserNotificationPreference";

// user related URL - end

// IVR related URL - START
export const GET_ALL_IVR_URL = "/ivrservice/searchIvrList";
export const SAVE_IVR_URL = "/ivrservice/saveIvr";
export const SUBMIT_IVR_URL = "/ivrservice/submitIvr";
export const UPLOAD_IVR_DOCUMENT_URL = "/ivrservice/uploadIVRDocument";
export const REMOVE_IVR_DOCUMENT_URL = "/ivrservice/removeIVRDocument";
export const FETCH_IVR_BY_ID = "/ivrservice/ivr";
export const ADD_IVR_NOTE_URL = "/commonservice/note/add";
export const LIST_IVR_NOTES_URL = "/commonservice/note/list";
export const UPDATE_IVR_RESPOND = "/ivrservice/updateIVRRespond";
export const GET_IVR_DOCUMENTS_LIST_URL = "/ivrservice/getIVRDocumentsList";
export const DELETE_IVR_BY_ID = "/ivrservice/deleteIVRById";
export const CANCEL_IVR_BY_ID = "/ivrservice/cancelIVRById";
export const SEARCH_IVR_BY_PATIENT_NAME = "/ivrservice/searchIvrByPatientInfo";
export const SEARCH_IVR_BY_PATIENT_ID = "/ivrservice/searchIvrByPatientId";
// IVR related URL - END

// Order related URL -START
export const CREATE_ORDER = "/orderservice/order/add";
export const UPDATE_ORDER = "/orderservice/order/update";
export const CANCEL_ORDER = "/orderservice/order/cancel";
export const GET_ORDER_ITEMS_BY_ID = "/orderitemservice/getOrderItemsByOrderId";
export const RETURN_ORDER = "/orderservice/order/producreturn/add";
export const SEARCH_ORDER = "/orderservice/order/search";
export const ADD_TISSUETRACKING_URL = "/orderservice/order/tissuetracking/add";
export const SUBMIT_CLAIM_URL = "/orderservice/order/claim/add";
export const REIMBURSE_CLAIM_URL = "/orderservice/order/claim/reimburse";
export const ADD_AGENCY_PRODUCT_COMMISSION_URL = "/orderservice/agencyproductcommission/add";
export const EDIT_AGENCY_PRODUCT_COMMISSION_URL = "/orderservice/agencyproductcommission/edit";
// Order related URL -START

// Agency related URL -START
export const SAVE_AGENCY_URL = "/agencyservice/add";
export const EDIT_AGENCY_URL = "/agencyservice/agency/edit";
export const EDIT_PRODUCT_COMMISSIONS_URL = "/agencyservice/agency/edit";
export const GET_AGENCY_LIST_URL = "/agencyservice/searchAgencyList";
//CS-431
export const GET_AGENCY_INFO_WITH_PRODUCT_USER_AND_COMMISSION_URL = "/agencyservice/getAgencyInfoWithProductUserAndCommission";
export const INACTIVATE_AGENCY = "/agencyservice/inactivate";
//
export const GET_AGENCY_BY_ID_URL = "/agencyservice/getAgencyById";
export const GET_ASSOCIATE_AGENCY_LIST_URL = "/agencyservice/getAssociateAgencyList";
export const GET_PRODUCT_COMMISSIONS_BY_AGENCY_ID_URL = "/agencyservice/listAgencyCommission";
export const GET_IS_V3_AGENCY_EXISTS_URL = "/agencyservice/isV3AgencyExists";  
export const GET_AGENCY_BY_USER_URL = "/agencyservice/getAgencyByUser";  
// Agency related URL -START

//Two Factor related URL - START
export const INACTIVATE_TWO_FACTOR_OPTION = "userservice/user/2fa/inactivate";
export const SAVE_TWO_FACTOR_COMMUNICATION_DETAIL = "userservice/user/2fa/add";
export const SEND_OTP = "userservice/user/2fa/generate2facode/";
export const TWO_FACTOR_COMMUNICATION_DETAILS = "userservice/user/2fa/list";
export const VALIDATE_OTP = "userservice/user/2fa/validate2facode";
export const GET_ALL_USER2FA_LIST = "userservice/user/2fa/list";
//Two Factor related URL - END

// Invoice Related URL - START
export const SEARCH_INVOICE_URL = "/invoiceservice/invoice/searchInvoiceList";
export const ADD_INVOICE_URL = "/invoiceservice/invoice/addInvoice";
export const GET_INVOICE_BY_ID_URL = "/invoiceservice/invoice";
export const UPLOAD_INVOICE_URL = "/invoiceservice/invoice/uploadInvoice";
// Invoice Related URL - END

// Payment Related URL - START
export const UPLOAD_PAYMENT_URL = "/paymentservice/payment/uploadPayments";
export const UPLOAD_COMMISSION_PAYMENT_URL = "/commissionservice/commission/uploadCommissionPayments";
export const SAVE_COMMISSION_PAYOUTS_URL = "/commissionservice/commission/saveCommission";
// Payment Related URL - END

// WoundCode Related URL - START
export const GET_ALL_WOUND_CODE = "/woundcodeservice/getAllWoundCodes";
// WoundCode Related URL - END

// BAA Related URL - START
export const SEARCH_BAA_LIST_URL = "/baaService/seachBaaDetail";
export const SIGN_BAA_DETAIL_URL = "/baaService/signBaaDetail";
export const PREVIEW_BAA_DETAIL_URL = "/baaService/previewBaaDetail";
export const UPLOAD_BAA_DETAIL_URL = "/baaService/uploadBaaDetail";
// BAA Related URL - END

// Manufacturer Notification Related URL - START
export const VALIDATE_CREDENTAILS_URL = "/manufacturerNotificationService/validateManufacturerCredentails";
export const DOWNLOAD_DOCUMENT_URL = "/manufacturerNotificationService/downloadDocument";
export const VALIDATE_MANUFACTURER_LINK = "/manufacturerNotificationService/validateManufacturerEmail";
// Manufacturer Notification Related URL - END