import { fetchInvoiceList, invoiceInfo, SearchInvoiceInfo } from "../../redux/slice/invoice/InvoiceSlice";
import ExcelJS from 'exceljs';
import { AppDispatch } from "../../redux/store";
import { fetchOrdersList, OrderInfo, OrderItemDTO } from "../../redux/slice/order/OrderSlice";
import { EXPORTABLE_ITEMS } from "../../constants/applicationConstants";

interface invoiceTableRow extends invoiceInfo {
    checkBox: JSX.Element;
  }

  interface Column {
    id: string;
    label: string | JSX.Element;
    minWidth?: number;
    align?: "right" | "center" | "left";
    format?: (value: number) => string;
    padding?: string;
    props?: ColumnProps;
  }
  
  interface ColumnProps {
    control?: any; 
    controllerName?: string;
    isDisabled?: boolean;
  } 

const ExportToExcel = async (initData: any, formattedColumns: any, dispatch: AppDispatch, sectionName: string, hiddenColumns?: string[]) => {

      try {  

        let allRecords: invoiceTableRow[] | OrderInfo[] = []; 
        let response: any = null; 
        let fetchedData: invoiceTableRow[] | OrderInfo[] = [];

        if(sectionName===EXPORTABLE_ITEMS.INVOICE){      
            response = await dispatch(fetchInvoiceList(initData));  
            fetchedData = response.payload?.data?.downloadData || []; 
            allRecords = [...(allRecords as invoiceTableRow[]), ...(fetchedData as invoiceTableRow[])];  
        } else if(sectionName===EXPORTABLE_ITEMS.ORDER){      
            response = await dispatch(fetchOrdersList(initData));   
            fetchedData = response.payload?.data?.downloadData || [];
            allRecords = [...(allRecords as OrderInfo[]), ...(fetchedData as OrderInfo[])];    
      } 

      const headerRow = [];
      
        if (allRecords.length > 0) {
          const workbook = new ExcelJS.Workbook();
          const worksheet = workbook.addWorksheet(sectionName);

          if (sectionName === EXPORTABLE_ITEMS.ORDER) {

            const headerRow = [
              'V3 Order ID', 'Order Date', 'Practice', 'PT', 'Product',
              'QTY', 'Size', 'Units', 'COGs/Unit', 'COGs', 'Provider',
              'DOS', 'Actual DOS', 'Order Type', 'Ship Status',
              'Usage Status', 'Serial Number'
          ];
          worksheet.addRow(headerRow);
          
          const headerRowCells = worksheet.getRow(1);
          headerRowCells.font = { bold: true, color: { argb: 'FF000000' }, size: 15, underline: 'single' };

          const cogsUnitIndex = headerRow.indexOf('COGs/Unit') + 1;
          const cogsIndex = headerRow.indexOf('COGs') + 1;  

          allRecords.forEach(order => {
            const orderInfo = order as OrderInfo;
            if (Array.isArray(order.orderItems)) {
              order.orderItems.forEach((orderItem: OrderItemDTO) => {
                  const rowData = headerRow.map(header => {
                      switch (header) {
                          case 'V3 Order ID':
                              return orderInfo.orderNumber;
                          case 'Order Date':
                              return orderInfo.orderDate;
                          case 'Practice':
                              return orderInfo.practiceName;
                          case 'PT':
                            return (orderInfo.hasMiddleName==false ? orderInfo.patientName
                                      .trim()
                                      .split(' ')
                                      .map(name => name.charAt(0).toUpperCase())
                                      .join('') :
                                      orderInfo.patientName
                                      .trim()
                                      .split(' ')
                                      .filter((_, i, arr) => i === 0 || i < arr.length - 1)
                                      .map(name => name.charAt(0).toUpperCase())
                                      .join('')
                                    );
                          case 'Product':
                              return orderInfo.productName;
                          case 'QTY':
                              return orderItem.quantity;
                          case 'Size':
                              return orderItem.size;
                          case 'Units':
                              return orderItem.units;
                          case 'COGs/Unit':
                              return (orderItem.extendedPrice? orderItem.extendedPrice : "N/A"); 
                          case 'COGs':
                              return (orderItem.extendedPrice ? orderItem.units * orderItem.extendedPrice : "N/A"); 
                          case 'Provider':
                              return orderInfo.providerName || orderInfo.orderInfo?.provider || '';
                          case 'DOS':
                              return orderInfo.dateOfService;
                          case 'Actual DOS':
                              return (orderItem.actualDateOfService ? orderItem.actualDateOfService : 'N/A');
                          case 'Order Type':
                              return orderInfo.orderType;
                          case 'Ship Status':
                              const trackingNumber = orderInfo.trackingNumber;
                              return trackingNumber
                                  ? `${orderInfo.orderStatus} (${trackingNumber})`
                                  : orderInfo.orderStatus;
                          case 'Usage Status':
                              if (orderItem?.tissueTrackingDocumentId) {
                                if (orderItem?.invoiceId) {
                                  return "Applied (Invoiced)";
                                }
                                return "Applied";
                              } else if (orderItem?.productReturnId) {
                                if (orderItem?.invoiceId) {
                                  return "Returned (Invoiced)";
                                }
                                return "Returned";
                              } else if (orderItem?.invoiceId) {
                                return "Invoiced";
                              }
                              return "Available";
                          case 'Serial Number':
                              return orderItem.serialNumber;
                          default:
                              return '';
                      }
                  });
                  
                  const newRow = worksheet.addRow(rowData);

                  if (orderItem.extendedPrice) {  
                    newRow.getCell(cogsUnitIndex).numFmt = '_("$"* #,##0.00_);_("$"* (#,##0.00);_("$"* "-"??_);_(@_)';
                    newRow.getCell(cogsIndex).numFmt = '_("$"* #,##0.00_);_("$"* (#,##0.00);_("$"* "-"??_);_(@_)';
                }
                });
              }
          });

          }else{

            const headerRow = formattedColumns.filter((column: Column, index: any) => 
              !hiddenColumns?.includes(column.id) &&                          
              !(index === 0 && 
                  (typeof column.label !== 'string' || column.label.trim() === '') 
                ) &&
              !(index == formattedColumns.length - 1 && column.label == 'Action')           
            ).map((column: Column) => column.label); 
    
            worksheet.addRow(headerRow);
            const headerRowCells = worksheet.getRow(1);
            headerRowCells.font = { bold: true, color: { argb: 'FF000000' }, size: 15, underline: 'single' }
            allRecords.forEach(row => {
              const rowData = headerRow.map((label: String) => {             
                const column = formattedColumns.find((col: Column) => col.label === label);         
  
                if (column) {
                  if (sectionName === EXPORTABLE_ITEMS.INVOICE) {     
                    return (row as invoiceTableRow)[column.id as keyof invoiceTableRow];
                  }
                }
            
                return ''; 
              });
              worksheet.addRow(rowData);
          })
        }

 
          const columnWidth = 25; 
          for (let i = 1; i <= headerRow.length; i++) {
            worksheet.getColumn(i).width = columnWidth;
          }
         
          worksheet.eachRow({ includeEmpty: true }, (row, rowNumber) => {
            if (rowNumber !== 1) { 
              row.eachCell(cell => {
                cell.font = { size: 12 }; 
                cell.alignment = {  horizontal: 'left' }; 
              });
            }
          });
 
          const buffer = await workbook.xlsx.writeBuffer();
          const blob = new Blob([buffer], { type: 'application/octet-stream' });
          const link = document.createElement('a');
 
          link.href = URL.createObjectURL(blob);
 
          let fileName = "";
          if(sectionName===EXPORTABLE_ITEMS.INVOICE){
            fileName = `invoices_${new Date().toISOString().slice(0, 10)}.xlsx`;
          } else if (sectionName === EXPORTABLE_ITEMS.ORDER){
            fileName = `orders_${new Date().toISOString().slice(0, 10)}.xlsx`;
          }
          
          link.download = fileName;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          console.log('Excel file is ready for download: invoices.xlsx');

        } else {
          console.log('No order data available to export.');
        }

 
      } catch (error) {
 
        console.error('Error exporting to Excel:', error);
 
      }
 
   };

export default ExportToExcel;