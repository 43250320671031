import * as React from "react";
import { useNavigate } from "react-router-dom";
import DatePicker from "../../common/DatePicker";
import Dropzone from "../../common/Dropzone";
import {
  Box,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Stack,
} from "@mui/material";
import Header from "../../common/Header";
import Button from "../../common/Button";
import { useTheme } from "@mui/material/styles";
import { ColorModeContext, tokens } from "../../../theme";
import {
  AddCircleOutlineOutlined,
  DarkModeOutlined,
  DeleteOutlineOutlined,
  LightModeOutlined,
  ModeOutlined,
  Save,
  SaveAlt,
} from "@mui/icons-material";
import { useAppDispatch } from "../../../redux/slice/redux-hooks";
import CustomTextField from "../../common/CustomTextField";
import { useForm } from "react-hook-form";
import CustomNametField from "../../common/CustomNametField";
import CustomPatternTextField from "../../common/CustomPatternTextField";
import CustomAddressTextField from "../../common/CustomAddressTextField";
import { useCallback, useEffect, useMemo } from "react";
import {
  useCodetablesSlice,
  getCodeTableValuesByTableName,
  CodeTable,
} from "../../../redux/slice/codetableSlice";
import {
  editPracticeData,
  usePracticeEditSlice,
  updatePracticeEditInfoObject,
} from "../../../redux/slice/practice/practiceEditSlice";
import { PracticeInfo } from "../../../redux/slice/practice/practiceRegistrationSlice";
import Card from "../../common/Card";
import {
  usePracticeProductsSlice
} from "../../../redux/slice/practice/practiceProductSlice";
import Table from "../../common/CustomTable";
import CustomAlert from "../../common/CustomAlert";
import CustomCheckbox from "../../common/CustomCheckBox";
import { submitPracticeData } from "../../../redux/slice/practice/practiceRegistrationSlice";
import {
  SingleAlertInfo,
  setMainErrorObj,
  setSingleAlertObj,
} from "../../../redux/slice/commonSlice";
import CustomEmailChipField from "../../common/CustomEmailChipField";
import OptionsPopup from "../../common/OptionsPopup";
import CustomRadioButton from "../../common/CustomRadioButton";

interface ContactFormProps {
  activeStep: number;
  contentChangedStatus: () => void;
}

interface Column {
  id: string;
  label: string;
  minWidth?: number;
  align?: "right" | "center" | "left";
  format?: (value: number) => string;
}

const PracticeEditViewForm: React.FC<ContactFormProps> = ({ activeStep, contentChangedStatus }) => {
  const columns3: Column[] = [
    { id: "productName", label: "Product Name", minWidth: 100 },
    { id: "agreementStatus", label: "Agreement Status", minWidth: 100 },
  ];

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { practiceEditInfo } = usePracticeEditSlice();
  const { control, handleSubmit, reset, getValues, setValue } =
    useForm<PracticeInfo>();
  const { practiceProductsInfo } = usePracticeProductsSlice();
  const [isAlertShow, setIsAlertShow] = React.useState(false);
  // const [isBillerNotApplicable, setIsBillerNotApplicable] =
  //   React.useState(false);
  const defaultValues = useCallback(() => {
    if (practiceEditInfo) {
      reset(practiceEditInfo);
    }
  }, [practiceEditInfo, reset]);
  const [optionPopupShow, setOptionPopupShow] = React.useState<{
    message: string;
    show: boolean;
  }>({ message: "", show: false });

  useEffect(() => {
    defaultValues();
  }, [defaultValues]);

  // useEffect(() => {
  //   setIsBillerNotApplicable(
  //     !!getValues("practiceRequestInfo.billerNotApplicable")
  //   );
  // }, []);

  // useEffect(() => {
  //   if (isBillerNotApplicable) {
  //     setValue("practiceRequestInfo.billerContactEmail", "", {
  //       shouldValidate: false,
  //     });
  //     setValue("practiceRequestInfo.billerContactPhone", "", {
  //       shouldValidate: false,
  //     });
  //     setValue("practiceRequestInfo.billerFirstName", "", {
  //       shouldValidate: false,
  //     });
  //     setValue("practiceRequestInfo.billerMiddleName", "", {
  //       shouldValidate: false,
  //     });
  //     setValue("practiceRequestInfo.billerLastName", "", {
  //       shouldValidate: false,
  //     });
  //     setValue("practiceRequestInfo.billerStreetAddress", "", {
  //       shouldValidate: false,
  //     });
  //     setValue("practiceRequestInfo.billerUnitNumber", "", {
  //       shouldValidate: false,
  //     });
  //     setValue("practiceRequestInfo.billerCity", "", { shouldValidate: false });
  //     setValue("practiceRequestInfo.billerState", "", {
  //       shouldValidate: false,
  //     });
  //     setValue("practiceRequestInfo.billerZipCode", "", {
  //       shouldValidate: false,
  //     });
  //   }
  // }, [isBillerNotApplicable, setValue]);

  const confirmOptionPopup = () => {
    handleSubmit(handleSavePractice)();
    setOptionPopupShow({ message: "", show: false });
  };

  const cancelOptionPopup = () => {
    setOptionPopupShow({ message: "", show: false });
  };

  const handleSavePractice = async (practiceInfoData: any) => {
    try {
      const response = await dispatch(editPracticeData(practiceInfoData));
      console.log(response);
      if (response.payload && response.payload.message === "SUCCESS") {
        let successObj: SingleAlertInfo = {
          message: "Practice Successfully Updated.",
          alertType: "success",
        };
        dispatch(setSingleAlertObj(successObj));
        contentChangedStatus();
      } else {
        let errorSentence = "";
        if (response.payload.errors.length <= 0) {
          errorSentence =
            response.payload.message || "An unknown error occurred.";
        } else {
          response.payload.errors.forEach((error: any, index: any) => {
            if (error.field) {
              errorSentence += `${error.field}: ${error.errorMessage}\n`;
            } else {
              errorSentence += `${error.reason}\n`;
            }
          });
        }
        let errorObj: SingleAlertInfo = {
          message: errorSentence,
          alertType: "error",
        };
        dispatch(setSingleAlertObj(errorObj));
      }
    } catch (error) {
      let errorObj: SingleAlertInfo = {
        message: "Error submitting practice data",
        alertType: "error",
      };
      dispatch(setSingleAlertObj(errorObj));
    }
  };

  // const handleBillerNotApplicableChange = (event: any) => {
  //   setIsBillerNotApplicable(event.target.checked);
  // };

  const stepContent: any = (
    <div>
      <Header
        title="Practice Information"
        subtitle="Enter n/a if information is not available or applicable."
      />
      <Grid container spacing={2}>
        <Grid item md={4} xs={12}>
          <CustomTextField
            control={control}
            controllerName="practiceName"
            label="Practice Name"
            rules={{ required: "Practice name is required." }}
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <CustomTextField
            control={control}
            controllerName="taxId"
            label="Practice Tax ID"
            rules={{
              required: "Practice Tax ID is required.",
              pattern: {
                value: /^((?!\s).)*$/,
                message: "Tax ID cannot contain spaces.",
              },
            }}
          />
        </Grid>
        <Grid item md={4}></Grid>
        <Grid item md={4} xs={12}>
          <CustomTextField
            control={control}
            controllerName="npi"
            label="Practice NPI"
            rules={{
              required: "Practice NPI is required.",
              validate: {
                doesNotContainSpaces: (value: string) =>
                  !/\s/.test(value) || "NPI cannot contain spaces.",
                isAlphanumeric: (value: string) =>
                  /^[a-zA-Z0-9]+$/.test(value) ||
                  "NPI cannot contain special characters.",
              },
            }}
            maxLength={10}
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <CustomTextField
            control={control}
            controllerName="ptan"
            label="Practice PTAN"
            rules={{
              required: "Practice PTAN is required.",
              validate: {
                doesNotContainSpaces: (value: string) =>
                  !/\s/.test(value) || "PTAN cannot contain spaces.",
                isAlphanumeric: (value: string) =>
                  /^[a-zA-Z0-9]+$/.test(value) ||
                  "PTAN cannot contain special characters.",
              },
            }}
            maxLength={10}
          />
        </Grid>
        <Grid item md={4}></Grid>
        <Grid item md={12}>
          <CustomNametField
            control={control}
            context="Contact"
            xs={12}
            md={4}
            isRequired={true}
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <CustomTextField
            control={control}
            controllerName="contactEmail"
            label="Contact Email"
            rules={{
              required: "Contact Email is required.",
              pattern: {
                value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i,
                message: "Invalid email.",
              },
            }}
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <CustomPatternTextField
            control={control}
            label="Contact Phone"
            name="contactPhone"
            rules={{
              required: "Contact Phone is required.",
              pattern: {
                value: /^(\+\d{1,2}\s)?\(\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/,
                message: "Invalid phone number.",
              },
            }}
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <CustomPatternTextField
            control={control}
            label="Contact Fax"
            name="contactFax"
            rules={{
              pattern: {
                value: /^(\+\d{1,2}\s)?\(\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/,
                message: "Invalid fax number.",
              },
            }}
          />
        </Grid>
        {/* CS-458 */}
        <Grid item md={4} xs={12}>
          <FormControl>
            <Grid container spacing={1} alignItems="center">
              <Grid item>
                <label>Billing Type</label>
              </Grid>
              <Grid item>
                <CustomRadioButton control={control} controllerName="billingType"
                  options={[
                    { value: "INDIVIDUAL", label: "Individual NPI" }, { value: "GROUP", label: "Group NPI" },
                  ]}
                  isDisabled={true}
                />
              </Grid>
            </Grid>
          </FormControl>
        </Grid>
        {/* // */}
        <Grid item md={4}></Grid>
      </Grid>

      <div style={{ height: "30px" }}></div>
      <Header
        title="Account Payable Information"
        subtitle="Enter n/a if information is not available or applicable."
      />
      <Grid container spacing={2}>
        <Grid item md={12}>
          <CustomNametField
            control={control}
            context="ap"
            label="AP Contact"
            xs={12}
            md={4}
            isRequired={true}
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <CustomPatternTextField
            control={control}
            label="AP Contact Phone"
            name="apPhone"
            rules={{
              required: "AP Contact Phone is required.",
              pattern: {
                value: /^(\+\d{1,2}\s)?\(\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/,
                message: "Invalid phone number.",
              },
            }}
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <CustomTextField
            control={control}
            controllerName="apEmail"
            label="AP Contact Email"
            rules={{
              required: "AP Contact Email is required.",
              pattern: {
                value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i,
                message: "Invalid email.",
              },
            }}
          />
        </Grid>
      </Grid>
      <div style={{ height: "10px" }}></div>
      <Grid item md={12}>
        <Divider
          orientation="horizontal"
          variant="middle"
          flexItem
          sx={{ padding: 0, margin: "30px 0" }}
        />
      </Grid>
      <Grid container spacing={2}>
        <Grid item md={6} xs={12}>
          <CustomEmailChipField
            control={control}
            label="Notification Emails"
            controllerName="notificationEmails"
          />
        </Grid>
      </Grid>

      {/* <div style={{ height: "30px" }}></div>
      <Header
        title="Biller Information"
        subtitle=""
      />
      <Grid container spacing={2}>
        <Grid item md={12}>
          <CustomCheckbox
            id={'12'}
            control={control}
            controllerName={`practiceRequestInfo.${'billerNotApplicable'}`}
            label='Not Applicable'
            checked={isBillerNotApplicable}
            onChange={handleBillerNotApplicableChange}
          />
        </Grid>
        <Grid item md={12}>
          <CustomNametField
            control={control}
            context="Biller"
            name={isBillerNotApplicable ? "": "practiceRequestInfo"}
            xs={12}
            md={4}
            isRequired={!isBillerNotApplicable}
            isDisabled={isBillerNotApplicable}
          />
        </Grid>
        <Grid item md={4}>
          <CustomPatternTextField 
            control={control} 
            label="Biller Contact Phone" 
            name={isBillerNotApplicable ? "" : `practiceRequestInfo.${'billerContactPhone'}`} 
            rules={{
              required: isBillerNotApplicable ? false : "Biller Contact Phone is required.",
              pattern: {
                value: /^(\+\d{1,2}\s)?\(\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/,
                message: "Invalid phone number."
              }
            }}
            isDisabled={isBillerNotApplicable}
            isEmpty={isBillerNotApplicable}
            />
        </Grid>
        <Grid item md={4}>
          <CustomTextField
            control={control}
            controllerName={isBillerNotApplicable ? "" : `practiceRequestInfo.${'billerContactEmail'}`}
            label="Biller Contact Email"
            rules={{
              required: isBillerNotApplicable ? false : "Biller Contact Email is required.",
              pattern: {
                value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i,
                message: "Invalid email.",
              },
            }}
            isDisabled={isBillerNotApplicable}
            isEmpty={isBillerNotApplicable}
          />
        </Grid>
        <Grid item md={12}>
          <CustomAddressTextField 
            control={control} 
            context="Biller" 
            name={isBillerNotApplicable ? "" : "practiceRequestInfo"} 
            isRequired={!isBillerNotApplicable}
            isDisabled={isBillerNotApplicable}
            />
        </Grid>
      </Grid> */}

      {/* <div style={{ height: "30px" }}></div>
      <Header
        title="Practice Products"
        subtitle=""
      />
      <Grid container spacing={1}>
      <Grid item md={6} xs={12}>
      <Card>
        {practiceProductsInfo != null && practiceProductsInfo.length > 0 ?
          <Table
            columns={columns3}
            rows={practiceProductsInfo ? practiceProductsInfo : []}
            disablePagination={true}
          />
          :
          <p style={{ fontSize: "small" }}>No Products Added.</p>
        }
      </Card>
      </Grid>
      </Grid> */}

      {/* <div style={{ height: "30px" }}></div>
        <Grid container spacing={2}></Grid>
        <Grid container spacing={2}>
          <Grid item md={12}>
            <Divider orientation="horizontal" variant="middle" flexItem sx={{ padding: 0, margin: '20px 0' }} />
          </Grid>
          <Grid container item spacing={2}>
            <Grid item md={6} xs={12}>
              <CustomTextField
                control={control}
                controllerName={`practiceRequestInfo.${'currentlyUsedProducts'}`}
                label="Currently Used Products"
                multiline
                rows={4}
                maxRows={4}

              />
            </Grid>

            <Grid item md={6} xs={12}>
              <CustomTextField
                control={control}
                controllerName={`practiceRequestInfo.${'anyQuestions'}`}
                label="Please List Any Questions You Have"
                multiline
                rows={4}
                maxRows={4}

              />
            </Grid>
          </Grid>
        </Grid> */}
    </div>
  );

  return (
    <>
      <form>
        <CustomAlert
          open={isAlertShow}
          onClose={() => {
            setIsAlertShow(false);
          }}
          severity="info"
          message="Feature currently under development. Stay tuned for updates!"
        />
        <FormGroup>
          {stepContent}
          <Grid
            container
            justifyContent="flex-end"
            sx={{ mt: 1, flexWrap: "wrap" }}
            spacing={1}
          >
            <Grid item xs={12} sm="auto">
              <Button
                sx={{ mr: 1 }}
                onClick={() => {
                  setOptionPopupShow({
                    message: "Do you really want to update practice details? ",
                    show: true,
                  });
                }}
                fullWidth
                startIcon={<Save />}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </FormGroup>
      </form>
      <OptionsPopup
        open={optionPopupShow.show}
        onClose={() => setOptionPopupShow({ message: "", show: false })}
        variant="confirm"
        message={<>{optionPopupShow.message}</>}
        buttons={[
          { name: "Confirm", color: "primary", onClick: confirmOptionPopup },
          { name: "Cancel", color: "secondary", onClick: cancelOptionPopup },
        ]}
      />
    </>
  );
};

export default PracticeEditViewForm;
