import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { useAppSelector } from "../redux-hooks";
import { GeneralV3Response, LoadingType } from "../../../types/CommonTypes";
import { addAgencyProductCommissionData, editAgencyData, editAgencyProductCommissionData, getAgencyByUser, getAgencyDataById, getAgencyInfoWithProductUserAndCommissionUrl, getAssociateAgencyList, getIsV3AgencyExists, getProductCommissionsByAgencyId, inactivateAgency, saveAgencyData, searchAgencyData } from "../../../service/AgencyService";
import { fetchPracticesByUserRole } from "../../../service/PracticeService";
import { http } from "../../../service/http";
import { FETCH_ACTIVE_AGENCY_EXECUTIVES_URL } from "../../../service/URL";
import { PracticeInfo } from "../practiceSlice";

export type SearchAgency = {
  agencyName: string | undefined;
  phone: string | undefined;
  email: string | undefined;
  address: string | undefined;
  commType: string | undefined;
  agencyStatus: string | undefined;
  currentPage: number;
  itemsPerPage: number;
};

export type Option = {
  value: string,
  label: string
};

export type AgencyOption = {
  value: number,
  label: string
};

interface PaginationAgencyList {
  content: CreateSearchAgency[];
  totalElements: number;
  totalPages: number;
  size: number;
}

interface PaginationProductCommissionList {
  content: ProductCommissionAgency[];
  totalElements: number;
  totalPages: number;
  size: number;
}

export type CreateSearchAgency = {
  inactiveDate: any;
  agencyId: number | undefined
  agencyName: string | undefined;
  commissionType: string | undefined;
  email: string | undefined;
  phone: string | undefined;
  address: string | undefined;
  streetAddress: string | undefined;
  unitNumber: string | undefined;
  zipcode: string | undefined;
  city: string | undefined;
  state: string | undefined;
  agencyProductCommisions?: ProductCommissionAgency[]
};

export type ProductCommissionAgency = {
  rowIndex: any;
  productCommissionId: number | undefined;
  productId: number | undefined;
  productName: string | undefined;
  associateType: string | undefined;
  agencyId: number | undefined;
  associateAgencyId: number | undefined;
  associatePracticeId: number | undefined;
  associateAccountRepId: number | undefined;
  commissionRate: number | string | undefined;
  commissionPayTerms: string | undefined;
  effectiveDate: string | undefined;
  inactiveDate: string | undefined;
}
 type AgencyExecutives = {
  userId: number;
  agencyId: number;
  agencyName: string
  firstName: string;
  lastName: string;
  middleName: string;
}

type AgencyState = {
  agency?: CreateSearchAgency | null;
  agencyInfoList?: PaginationAgencyList | null;
  agencyProductCommission?: ProductCommissionAgency | null;
  agencyProductCommissionList?: PaginationProductCommissionList | null;
  associateAgencyList?: CreateSearchAgency[] | null;
  isV3AgencyExists?: boolean;
  status: string;
  AgencyInactivateStatus?: LoadingType;
  editStatus?:LoadingType;
  error: string | null;
  getPracticesByRoleStatusAgencySlice?: LoadingType;
  practiceByUserRoleAgencySlice?: PracticeInfo[] | null;
  agencyExecutives?: AgencyExecutives[] | null;

}

const initialState: AgencyState = {
  agency: {
    agencyId: 0,
    agencyName: "",
    commissionType: "",
    email: "",
    phone: "",
    address: "",
    streetAddress: "",
    unitNumber: "",
    zipcode: "",
    city: "",
    state: "",
    inactiveDate: "",
    agencyProductCommisions: []
  },
  agencyExecutives: undefined,
  agencyInfoList: undefined,
  agencyProductCommission: undefined,
  agencyProductCommissionList: undefined,
  associateAgencyList: undefined,
  isV3AgencyExists: false,
  status: "idle",
  getPracticesByRoleStatusAgencySlice: "idle",
  practiceByUserRoleAgencySlice: [],
  error: null

};

export const saveAgency = createAsyncThunk("saveAgency", async (data: CreateSearchAgency, { rejectWithValue }) => {
  try {
    const response = await saveAgencyData(data);
    return response.data;
  } catch (error) {
    //@ts-ignore
    return rejectWithValue(error?.data);
  }
});

export const editAgency = createAsyncThunk("editAgency", async (data: CreateSearchAgency, { rejectWithValue }) => {
  try {
    const response = await editAgencyData(data);
    return response.data;
  } catch (error) {
    //@ts-ignore
    return rejectWithValue(error?.data);
  }
});

export const searchAgency = createAsyncThunk("searchAgency", async (data: SearchAgency, { rejectWithValue }) => {
  try {
    const response = await searchAgencyData(data);
    return response.data;
  } catch (error) {
    //@ts-ignore
    return rejectWithValue(error?.response?.data);
  }
});

export const fetchAgencyById = createAsyncThunk("fetchAgencyById", async (data: number, { rejectWithValue }) => {
  try {
    const response = await getAgencyDataById(data);
    return response.data;
  } catch (error) {
    //@ts-ignore
    return rejectWithValue(error?.response?.data);
  }
});

export const getAgencyInfoById = createAsyncThunk("getAgencyInfoById", async (agencyId: number) => {
  const response = await getAgencyInfoWithProductUserAndCommissionUrl(agencyId);
  const v3Response = response.data;
  return v3Response;
});
export const inactivateAgencyById = createAsyncThunk("inactivateAgencyById", async (agencyId: number) => {
  const response = await inactivateAgency(agencyId);
  const v3Response = response.data;
  return v3Response;
});


export const fetchAssociateAgencyList = createAsyncThunk("fetchAssociateAgencyList", async () => {
  try {
    const response = await getAssociateAgencyList();
    return response.data;
  } catch (error) {
    //@ts-ignore
    return rejectWithValue(error?.response?.data);
  }
});

export const fetchAgencyByUser = createAsyncThunk("fetchAgencyByUser", async () => {
  try {
    const response = await getAgencyByUser();
    return response.data;
  } catch (error) {
    //@ts-ignore
    return rejectWithValue(error?.response?.data);
  }
});

export const addAgencyProductCommission = createAsyncThunk("addAgencyProductCommission", async (data: ProductCommissionAgency, { rejectWithValue }) => {

  try {
    const response = await addAgencyProductCommissionData(data);
    const v3Response = response.data;
    return v3Response;
  } catch (error) {
    //@ts-ignore
    return rejectWithValue(error?.data);
  }

});

export const editAgencyProductCommission = createAsyncThunk("editAgencyProductCommission", async (data: ProductCommissionAgency, { rejectWithValue }) => {

  try {
    const response = await editAgencyProductCommissionData(data);
    const v3Response = response.data;
    return v3Response;
  } catch (error) {
    //@ts-ignore
    return rejectWithValue(error?.data);
  }

});

export const fetchProductCommissionsByAgencyId = createAsyncThunk("fetchProductCommissionsByAgencyId", async (data: any, { rejectWithValue }) => {
  try {
    const response = await getProductCommissionsByAgencyId(data.agencyId, data.currentPage, data.itemsPerPage);
    return response.data;
  } catch (error) {
    //@ts-ignore
    return rejectWithValue(error?.response?.data);
  }
});

export const fetchIsV3AgencyExist = createAsyncThunk("fetchIsV3AgencyExist", async () => {
  try {
    const response = await getIsV3AgencyExists();
    return response.data;
  } catch (error) {
    //@ts-ignore
    return rejectWithValue(error?.response?.data);
  }
});

export const getPracticeListByUserRoleInAgency = createAsyncThunk('getPracticeListByUserRoleInAgency', async (isActivePracticeOnly?: boolean) => {
  const response = await fetchPracticesByUserRole(isActivePracticeOnly);
  const v3Response = response.data;
  return v3Response.data;
});

export const fetchActiveAgencyExecutives = createAsyncThunk("activeAgencyExecutives", async (data: { getAgencyAdmins: boolean, isInactive: boolean }) => {
  const { getAgencyAdmins, isInactive } = data;
  const response = await http.get(`${FETCH_ACTIVE_AGENCY_EXECUTIVES_URL}?getAgencyAdmins=${getAgencyAdmins}&isInactive=${isInactive}`);
  const v3Response = response.data;
  return v3Response.data;
});

const agencySlice = createSlice({
  name: "agency",
  initialState,
  reducers: {
    updateAgencyInfoObject: (state: AgencyState, action) => {
      state.agency = action.payload;
    },
    clearAgencyObject: (state: AgencyState) => {
      state.agency = null;
    },
    clearAgencyProductCommissionList: (state: AgencyState) => {
      state.agencyProductCommissionList = null;
    },
    clearAgencyInfoList: (state) => {
      state.agencyInfoList = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchActiveAgencyExecutives.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchActiveAgencyExecutives.fulfilled, (state, action) => {
        state.status = "success";
        state.agencyExecutives = action.payload;
      })
      .addCase(fetchActiveAgencyExecutives.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message || "Failed to fetch Active Account Representatives";
      })
      .addCase(saveAgency.pending, (state) => {
        state.status = "loading";
      })
      .addCase(saveAgency.fulfilled, (state, action) => {
        state.status = "success";
        state.agency = action.payload.data;
      })
      .addCase(saveAgency.rejected, (state, action) => {
        state.status = "error";
        state.error = action.error.message ?? null;
      })
      .addCase(editAgency.pending, (state) => {
        state.status = "loading";
      })
      .addCase(editAgency.fulfilled, (state, action) => {
        state.status = "success";
        state.agency = action.payload.data;
      })
      .addCase(editAgency.rejected, (state, action) => {
        state.status = "error";
        state.error = action.error.message ?? null;
      })
      .addCase(searchAgency.pending, (state) => {
        state.status = "loading";
      })
      .addCase(searchAgency.fulfilled, (state, action) => {
        state.status = "success";
        state.agencyInfoList = action.payload.data;
      })
      .addCase(searchAgency.rejected, (state, action) => {
        state.status = "error";
        state.error = action.error.message || "Failed to fetch agencies";
      })
      .addCase(addAgencyProductCommission.pending, (state, action) => {
        state.status = "loading"
      })
      .addCase(addAgencyProductCommission.fulfilled, (state, action) => {
        state.status = "success"
        state.agencyProductCommission = action.payload.data
      })
      .addCase(addAgencyProductCommission.rejected, (state, action) => {
        state.status = "error"
      })
      .addCase(editAgencyProductCommission.pending, (state, action) => {
        state.editStatus = "loading";
        state.status = "loading"
      })
      .addCase(editAgencyProductCommission.fulfilled, (state, action) => {
        state.status = "success"
        state.editStatus = "success";
        state.agencyProductCommission = action.payload.data
      })
      .addCase(editAgencyProductCommission.rejected, (state, action) => {
        state.editStatus = "error";
        state.status = "error"
      })
      .addCase(fetchProductCommissionsByAgencyId.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchProductCommissionsByAgencyId.fulfilled, (state, action) => {
        state.status = "success";
        state.agencyProductCommissionList = action.payload.data;
      })
      .addCase(fetchProductCommissionsByAgencyId.rejected, (state, action) => {
        state.status = "error";
        state.error = action.error.message || "Failed to fetch agency product commission data";
      })
      .addCase(fetchAgencyById.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAgencyById.fulfilled, (state, action) => {
        state.status = "success";
        state.agency = action.payload.data;
      })
      .addCase(fetchAgencyById.rejected, (state, action) => {
        state.status = "error";
        state.error = action.error.message || "Failed to fetch agency data";
      })
      .addCase(getAgencyInfoById.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getAgencyInfoById.fulfilled, (state, action: PayloadAction<GeneralV3Response>) => {
        state.status = "success";
      })
      .addCase(getAgencyInfoById.rejected, (state, action) => {
        state.status = "error";
        state.error = action.error.message || "Failed to fetch agency data";
      })
      .addCase(inactivateAgencyById.pending, (state) => {
        state.AgencyInactivateStatus = "loading";
      })
      .addCase(inactivateAgencyById.fulfilled, (state, action: PayloadAction<GeneralV3Response>) => {
        state.AgencyInactivateStatus = "success";
      })
      .addCase(inactivateAgencyById.rejected, (state, action) => {
        state.AgencyInactivateStatus = "error";
        state.error = action.error.message || "Failed to inactivate agency";
      })
      .addCase(fetchAssociateAgencyList.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAssociateAgencyList.fulfilled, (state, action) => {
        state.status = "success";
        state.associateAgencyList = action.payload.data;
      })
      .addCase(fetchAssociateAgencyList.rejected, (state, action) => {
        state.status = "error";
        state.error = action.error.message || "Failed to fetch associate agencies";
      })
      .addCase(fetchIsV3AgencyExist.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchIsV3AgencyExist.fulfilled, (state, action) => {
        state.status = "success";
        state.isV3AgencyExists = action.payload.data;
      })
      .addCase(fetchIsV3AgencyExist.rejected, (state, action) => {
        state.status = "error";
        state.error = action.error.message || "Failed to fetch V3 Agency Existence";
      })
      .addCase(fetchAgencyByUser.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAgencyByUser.fulfilled, (state, action) => {
        state.status = "success";
        state.agency = action.payload.data;
      })
      .addCase(fetchAgencyByUser.rejected, (state, action) => {
        state.status = "error";
        state.error = action.error.message || "Failed to fetch  Agency";
      })
      .addCase(getPracticeListByUserRoleInAgency.pending, (state, action) => {
        state.getPracticesByRoleStatusAgencySlice = 'loading';
      })
      .addCase(getPracticeListByUserRoleInAgency.fulfilled, (state, action) => {
        state.getPracticesByRoleStatusAgencySlice = 'success';
        state.practiceByUserRoleAgencySlice = action.payload;
      })
      .addCase(getPracticeListByUserRoleInAgency.rejected, (state, action) => {
        state.getPracticesByRoleStatusAgencySlice = 'error';
      })
  },
});

export const { updateAgencyInfoObject, clearAgencyObject, clearAgencyProductCommissionList, clearAgencyInfoList } = agencySlice.actions;
export const useAgencyReducer = () => useAppSelector((state) => state.agencySlice);
export default agencySlice.reducer;