import * as React from "react";
import { TextField, useTheme, TextFieldProps, InputAdornment } from "@mui/material";
import { NumericFormat } from "react-number-format";
import { Control, Controller } from "react-hook-form";
import { tokens } from "../../theme";

export type NumberFieldProps = {
    size?: "small" | "medium";
    color?: "primary" | "secondary";
    label?: any | "";
    variant?: "filled" | "outlined" | "standard" | undefined;
    name?: string;
    control?: Control<any, any>;
    rules?: Object;
    isDisabled?: boolean;
    maxLength?: number;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>, values?: any) => void;
    onBlur?: (event: React.FocusEventHandler<HTMLInputElement>) => void;
    controllerName?: string;
    isEmpty?: boolean;
    externalValue?: number | string;
    displayType?: "input" | "text" | undefined;
    helpText?: string;
    allowNegative?: boolean;
    allowDecimal?: boolean;
    decimalScale?: number;
    inputAdornment?: React.ReactNode; // Prefix/Suffix text
    adornmentPosition?: "start" | "end"; // Position of the adornment
    hideAdornmentUntilInput?: boolean; // Hide adornment until user input
} & TextFieldProps;

const CustomNumberField: React.FC<NumberFieldProps> = ({
    size,
    color,
    label,
    variant,
    name,
    control,
    rules,
    isDisabled,
    maxLength,
    onChange,
    onBlur,
    controllerName,
    isEmpty,
    externalValue,
    displayType,
    helpText,
    allowNegative = false,
    allowDecimal = false,
    decimalScale = 0,
    inputAdornment,
    adornmentPosition = "start",
    hideAdornmentUntilInput = false,
    ...props
}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    
    const formatDecimalScale = allowDecimal ? decimalScale : 0;

    const isRequired = () => {
        if (rules && typeof rules === "object" && "required" in rules && label) {
            return true;
        }
        return false;
    };

    const getLabel = () => {
        if (isRequired()) {
            return (
                <span>
                    {label}{" "}
                    <span style={{ color: colors.success[200], fontSize: "18px" }}>*</span>
                </span>
            );
        }
        return label;
    };

    return (
        <Controller
            name={controllerName || ""}
            control={control}
            rules={rules || {}}
            render={({ field: { ref, ...field }, fieldState }) => (
                //@ts-ignore
                <NumericFormat
                    customInput={TextField}
                    id={field.name}
                    {...field}
                    value={isEmpty ? '' : externalValue ? externalValue : field.value}
                    name={name || controllerName}
                    label={getLabel() || ""}
                    displayType={displayType || "input"}
                    fullWidth
                    allowNegative={allowNegative}
                    decimalScale={formatDecimalScale}
                    thousandSeparator={true}
                    onValueChange={(values: any, sourceInfo: any) => {
                        field.onChange(values.value ? values.floatValue : "");
                        onChange && onChange(sourceInfo.event, values);
                    }}
                    onBlur={(e: any) => {
                        field.onBlur();
                        onBlur && onBlur(e);
                    }}
                    size={size || "medium"}
                    color={color || theme.palette.mode === "dark" ? "secondary" : "primary"}
                    variant={variant || "outlined"}
                    InputLabelProps={{
                        shrink: isDisabled ? true : undefined,
                    }}
                    inputProps={{
                        ...(maxLength ? { maxLength } : {})
                    }}
                    error={!!fieldState.error}
                    helperText={fieldState.error ? fieldState.error.message : helpText || null}
                    disabled={isDisabled}
                    InputProps={{
                        startAdornment: adornmentPosition === "start" && inputAdornment && (!hideAdornmentUntilInput || field.value) ? (
                            <InputAdornment position="start">{inputAdornment}</InputAdornment>
                        ) : null,
                        endAdornment: adornmentPosition === "end" && inputAdornment && (!hideAdornmentUntilInput || field.value) ? (
                            <InputAdornment position="end">{inputAdornment}</InputAdornment>
                        ) : null,
                    }}                   
                    {...props}
                />
            )}
        />
    );
};

export default CustomNumberField;
