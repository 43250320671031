import React from "react";
import { Snackbar, SnackbarProps, Divider, Typography, useTheme, Box, Dialog, Slide, SxProps, AlertTitle, IconButton } from "@mui/material";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Alert } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { useCommonReducer, setErrorDetailExpand, hideMainErrorPopup, MainErrorInfo } from "../../redux/slice/commonSlice";
import { useAppDispatch } from "../../redux/slice/redux-hooks";
import { tokens } from "../../theme";
import { CheckCircle, Close, Error, Info, Warning } from "@mui/icons-material";
import Button from "./Button";

interface CustomSnackbarOrigin {
    vertical: "top" | "bottom";
    horizontal: "left" | "right" | "center";
}

interface CustomAlertProps {
    open: boolean;
    onClose?: () => void;
    severity: "error" | "success" | "warning" | "info";
    mainErrorObj?: MainErrorInfo | null;
    messageStream?: string;
    messageList?: string[];
    content?: React.ReactNode;
    variant?: "filled" | "outlined";
    snackbarProps?: SnackbarProps;
    alertTitle?: string;
    anchorOrigin?: CustomSnackbarOrigin;
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const CustomErrorAlert: React.FC<CustomAlertProps> = ({
    open,
    onClose,
    severity,
    mainErrorObj,
    messageStream,
    messageList,
    content,
    snackbarProps,
    variant,
    alertTitle,
    anchorOrigin,
}) => {

    const dispatch = useAppDispatch();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const sx: SxProps = {
        "& .MuiDialog-container": {
          alignItems: "flex-start"
        }
     };

    const { errorDetailExpand } = useCommonReducer();

    let alertTitleValue: string = "Error";

    if (mainErrorObj) {
        alertTitle = mainErrorObj.title;
        messageStream = mainErrorObj.errorMessageStream;
        messageList = mainErrorObj.errorMessageList;
    }

    if (alertTitle) {
        alertTitleValue = alertTitle;
    }

    let subComponentColor = severity === "error" ? colors.error[400]
        : severity === "warning" ? colors.warning[400] : severity === "info" ? colors.info[400] : colors.success[400];

    const replaceNbsp = (str: string) => str.replace(/&nbsp;/g, '');

    if (content) {
        content = typeof content === "string" ? replaceNbsp(content) : content;
    } else if (messageStream) {
        content = <div style={{ marginTop:"8px" }}>
            {replaceNbsp(messageStream).split('\n').map((line, index) => (
                <Typography key={index} variant="body1" paragraph>
                    {line}
                </Typography>
            ))}
        </div>;
    } else if (messageList) {
        content = <div style={{ marginTop:"8px" }}>
            {messageList.map((line, index) => (
                <Typography key={index} variant="body1" paragraph>
                    {replaceNbsp(line)}
                </Typography>
            ))}
        </div>;
    }

    const handleExpanding = () => {
        dispatch(setErrorDetailExpand(!errorDetailExpand));
    }

    const onCloseErrorPopup = () => {
        dispatch(hideMainErrorPopup());
    }

    const pupopBody: React.ReactNode = (
        <Alert
            onClose={onClose ? onClose : mainErrorObj?.onCloseExt ? mainErrorObj?.onCloseExt : onCloseErrorPopup}
            icon={false}
            severity={severity}
            sx={{ width: "100%" }}
            variant={variant || "filled"}
        >
            <Accordion expanded={errorDetailExpand}
                sx={{
                    backgroundColor: subComponentColor,
                    padding: 1,
                    margin: 0,
                    boxShadow: "none",
                }}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon onClick={handleExpanding} />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                    sx={{
                        backgroundColor: 'transparent',
                        width: '100%',
                    }}
                >
                    <Typography variant="h6" fontWeight="bold">{alertTitleValue}</Typography>
                </AccordionSummary>
                <Divider />
                <AccordionDetails sx={{ padding: 0, margin: 0 }}>
                    {content}
                </AccordionDetails>
            </Accordion>
            {mainErrorObj?.buttonName &&
                <Box sx={{ display: 'flex', flexDirection: 'row-reverse' }}>
                    <Button variant="outlined"  onClick={mainErrorObj?.onOkExt} >
                        {mainErrorObj?.buttonName}
                    </Button>
                </Box>
            }
        </Alert>
    );

    const renderSeverityIcon = () => {
        
        switch (severity) {
            case "error":
                return (
                    <Box
                      sx={{
                        width: "40px",
                        height: "40px",
                        backgroundColor: colors.error[300],
                        borderRadius: "50%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                        marginRight: 2,
                      }}
                    >
                      <Error sx={{ color: "#FFFFFF", fontSize: "24px" }} />
                    </Box>
                  );
            case "success":
                return (
                    <Box
                      sx={{
                        width: "40px",
                        height: "40px",
                        backgroundColor: colors.success[300],
                        borderRadius: "50%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                        marginRight: 2,
                      }}
                    >
                      <CheckCircle sx={{ color: "#FFFFFF", fontSize: "24px" }} />
                    </Box>
                  );
            case "warning":
                return (
                  <Box
                    sx={{
                      width: "40px",
                      height: "40px",
                      backgroundColor: colors.warning[300],
                      borderRadius: "50%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                      marginRight: 2,
                    }}
                  >
                    <Warning sx={{ color: "#FFFFFF", fontSize: "24px" }} />
                  </Box>
                );
            case "info":
                return (
                    <Box
                      sx={{
                        width: "40px",
                        height: "40px",
                        backgroundColor: colors.info[300],
                        borderRadius: "50%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                        marginRight: 2,
                      }}
                    >
                      <Info sx={{ color: "#FFFFFF", fontSize: "24px" }} />
                    </Box>
                  );
            default:
                return null;
        }
    };

    if (mainErrorObj?.popupType === "Dialog") {
        return (
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={onClose}
                aria-describedby="alert-dialog-slide-description"
                onClick={(event) => {
                    event.stopPropagation();
                }}
                maxWidth={"lg"}
                scroll="paper"
                sx={sx}
            >
                {pupopBody}
            </Dialog>
        );
    } else 
    if (mainErrorObj?.popupType === "ServerErrorAlert") 
        {
        return (
            <Snackbar
            open={open}
            anchorOrigin={anchorOrigin || { vertical: "top", horizontal: "center" }}
            onClick={(event) => {
              event.stopPropagation();
            }}
            {...snackbarProps}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column", 
                alignItems: "center", 
                backgroundColor: theme.palette.mode === "dark" ? "#2C2C2E" : "#FDECEC",
                borderRadius: "20px",
                padding: "16px 24px 16px 24px", 
                position: "relative",
                border: "2px solid",
                borderColor: colors.grey[700],
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
                {renderSeverityIcon()}
      
                <Box sx={{ flexGrow: 1 }}>
                  <Typography
                    variant="h6"
                    component="div"
                    sx={{ fontWeight: 'bold', marginBottom: '4px', color: colors.grey[100], fontSize: '16px' }}
                  >
                    {alertTitle}
                  </Typography>
                  <Typography variant="body2" sx={{ color: colors.grey[400] }}>
                    {content}
                  </Typography>
                </Box>
                <IconButton
                  aria-label="close"
                  onClick={onClose ? onClose : mainErrorObj?.onCloseExt ? mainErrorObj?.onCloseExt : onCloseErrorPopup}
                  sx={{
                    position: "absolute",
                    right: "16px",
                    top: "16px",
                    color: colors.grey[400],
                  }}
                >
                  <Close />
                </IconButton>
              </Box>

              {mainErrorObj?.buttonName &&
                <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  width: '100%', 
                }}
              >
                    <Button variant="contained" color="success" onClick={mainErrorObj?.onOkExt} >
                        {mainErrorObj?.buttonName}
                    </Button>
                </Box>
            }
            </Box>
          </Snackbar>
        );
    }
    else {
        return (
            <Snackbar
                open={open}
                anchorOrigin={anchorOrigin || { vertical: "top", horizontal: "center" }}
                onClick={(event) => {
                    event.stopPropagation();
                }}
                {...snackbarProps}
            >
                {pupopBody}
            </Snackbar>
        );
    }
};

export default CustomErrorAlert;