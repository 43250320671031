import { Box, Collapse, Grid, useMediaQuery, useTheme } from "@mui/material";
import Header from "../../common/Header";
import { useEffect, useMemo, useState } from "react";
import Card from "../../common/Card";
import { useForm } from "react-hook-form";
import CustomMultipleDropDown from "../../common/CustomMultipleDropDown";
import { getPracticeListByUserRoleAgreementSlice, useCreateAgreementSlice } from "../../../redux/slice/productAgreement/createAgreementSlice";
import { fetchDocumentById, PracticeInfo, usePracticeSlice } from "../../../redux/slice/practiceSlice";
import { hideElement, sortNames } from "../../../utils/Utility";
import { AppRegistrationOutlined, DoDisturbAltOutlined, FormatItalicOutlined, ModeOutlined, PictureAsPdfOutlined, SearchOutlined, VisibilityOutlined } from "@mui/icons-material";
import Button from "../../common/Button";
import LegacyToolBar from "../../common/LegacyToolBar";
import Table from "../../common/CustomTable";
import { AgencyInfo, Column, ManufacturerInfo, showHideLoadingDialog } from "../../../redux/slice/commonSlice";
import CustomIconButton from "../../common/CustomIconButton";
import CustomDropdownField from "../../common/CustomDropdownFiled";
import { fetchActiveBillersUserSlice, fetchAgenciesListUserSlice, fetchAllUserRoles, fetchManufacturersListUserSlice, UserRole, useUserReducer } from "../../../redux/slice/userSlice";
import { selectIsValueFoundInToken, selectTokenValues, useAuthSlice } from "../../../redux/slice/authSlice";
import { RootState } from "../../../redux/store";
import { useSelector } from "react-redux";
import { AGENCY_USER, BAA_ACTIONS, BAA_STATUS, CAN_ACCESS_APPLICATION, MANUFACTURE_USER, PRACTICE_USER, USER_ROLES } from "../../../constants/applicationConstants";
import CustomTextField from "../../common/CustomTextField";
import { useAppDispatch } from "../../../redux/slice/redux-hooks";
import { BaaDetailDTO, previewAgreement, searchBaaDetails, SearchBaaRequest, updateElementInBusinessAgreement, useCreateBusinessAgreementSlice } from "../../../redux/slice/baa/createBusinessAgreementSlice";
import { BillerInfo } from "../../../redux/slice/practice/practiceApproveSlice";
import { useLocation, useNavigate } from "react-router-dom";
import PDFViewer from "../../common/pdf/PDFViewer";
import { getSearchCriteriaFromSessionStorage, setSearchCriteriaToSessionStorage } from "../../../utils/StorageUtils";


type Option = {
    value: string,
    label: string
};

const columns: Column[] = [
    { id: "email", label: "Email", minWidth: 100 },
    { id: "userType", label: "User Type", minWidth: 100 },
    { id: "practiceName", label: "Practice", minWidth: 100 },
    { id: "inactiveDate", label: "Inactive Date", minWidth: 100 },
    { id: "agreementType", label: "Agreement Type", minWidth: 100 },
    { id: "agreementStatus", label: "Status", minWidth: 100 },
    { id: "action", label: "Action", minWidth: 150 }
];

const PRACTICE_ENTITY: string = "Practice";
const AGENCY_ENTITY: string = "Agency";
const MANUFACTURER_ENTITY: string = "Manufacturer";
const BILLER_ENTITY: string = "Biller";
const NONE_ENTITY: string = "None";

export type SearchBaaFields = {
    userRoleId: number | string;
    entityIds: number[];
    firstName: string;
    lastName: string;
    status: string[];
    practiceIds?: number[];
    manufactureIds?:number[];
    agencyIds?: number[];
    billerIds?: number[]
}

const BaaList: React.FC = () => {
    const ITEMS_PER_PAGE = 10;
    const [showCard, setShowCard] = useState(false);
    const { control, handleSubmit, reset, setValue, getValues, watch } = useForm<SearchBaaFields>();
    const { practiceByUserRoleAgreementSlice } = useCreateAgreementSlice();
    const theme = useTheme();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const managingUserRoles = useSelector((state: RootState) => selectTokenValues("canManageUsers")(state));
    const { userInfo } = useAuthSlice();
    const loggedInUserRole = userInfo.userRole;
    const [selectedRoleEntity, setSelectedRoleEntity] = useState<string>("");
    const [userEntities, setUserEntities] = useState<{entity: string, value: number | string}[]>([]);
    const [showPDF, setShowPDF] = useState<{ isShow: boolean, row: BaaDetailDTO | null }>({ isShow: false, row: null });
    const { baaList } = useCreateBusinessAgreementSlice();
    const { searchUsers, userRoles, agencyInfoListUserSlice, billerListUserSlice, manufacturerInfoListUserSlice } = useUserReducer();
    const { baaDocument } = useCreateBusinessAgreementSlice();
    const { document } = usePracticeSlice();
    const [searchCriteria, setSearchCriteria] = useState<SearchBaaRequest>({
        userRoleIds: [],
        entityIds: [],
        firstName: "",
        lastName: "",
        status: [],
        currentPage: 1,
        itemsPerPage: ITEMS_PER_PAGE
      });

    const canSignBaa = useSelector((state: RootState) => selectIsValueFoundInToken("canAccessBaaActions", BAA_ACTIONS.SIGN)(state));
    const canPreviewBaa = useSelector((state: RootState) => selectIsValueFoundInToken("canAccessBaaActions", BAA_ACTIONS.PREVIEW)(state));
    //Mount
    useEffect(() => {
        fetchInitialLists();
    }, []);

    const fetchInitialLists = async () => {
        dispatch(showHideLoadingDialog(true));
        const responsePractices = await dispatch((getPracticeListByUserRoleAgreementSlice(true)));
        const responseAllUserRoles = await dispatch(fetchAllUserRoles());
        const responseAgencies = await dispatch(fetchAgenciesListUserSlice());
        const responseBillers = await dispatch(fetchActiveBillersUserSlice());
        const responseManufacturers = await dispatch(fetchManufacturersListUserSlice());
        dispatch(showHideLoadingDialog(false));
    }

    const practiceOptions = useMemo(() => {
        let options: Option[] = [];
        if (practiceByUserRoleAgreementSlice && practiceByUserRoleAgreementSlice.length > 0) {
            if (practiceByUserRoleAgreementSlice.length === 1) {
                setValue('entityIds', [Number(practiceByUserRoleAgreementSlice[0]?.practiceId)]);
            }
            const sortedPracticeInfo: PracticeInfo[] = sortNames(practiceByUserRoleAgreementSlice, 'practiceName');
            sortedPracticeInfo?.map(practice => {
                options.push({ value: practice.practiceId, label: practice.practiceName });
            });
        };
        return options;
    }, [practiceByUserRoleAgreementSlice]);

    const statusOptions = useMemo(() => {
        let options: Option[] = [
            { value: BAA_STATUS.SIGNED, label: BAA_STATUS.SIGNED }
        ];
        return options;
    }, []);

    const baaRows = useMemo(() => {
        const rows: BaaDetailDTO[] = [];
        baaList?.content?.map(row => {
            const status = row.agreementStatus.replace("_", " ");
            rows.push({ ...row, agreementStatus: status })
        })
        return rows;
    }, [baaList]);

    
  const agencyList = useMemo(() => {
    let options: Option[] = [];
    if (agencyInfoListUserSlice && agencyInfoListUserSlice.length > 0) {
      const sortedInfo: AgencyInfo[] = sortNames(agencyInfoListUserSlice, 'agencyName');
      sortedInfo?.map(option => {
        options.push({ label: option.agencyName, value: option.agencyId });
      });
    }
    return options;
  }, [agencyInfoListUserSlice]);

  const billerUsersList = useMemo(() => {
    let options: Option[] = [];
    if (billerListUserSlice && billerListUserSlice.length > 0) {
      const sortedInfo: BillerInfo[] = sortNames(billerListUserSlice, 'billerName');
      sortedInfo?.map(option => {
        options.push({ label: option.billerName, value: option.billerId });
      });
    }
    return options;
  }, [billerListUserSlice]);

  const manufacturersList = useMemo(() => {
    let options: Option[] = [];
    if (manufacturerInfoListUserSlice && manufacturerInfoListUserSlice.length > 0) {
      const sortedInfo: ManufacturerInfo[] = sortNames(manufacturerInfoListUserSlice, 'manufacturerName');
      sortedInfo?.map(option => {
        options.push({ label: option.manufacturerName, value: (option.manufacturerId).toString() });
      });
    }
    return options;
  }, [manufacturerInfoListUserSlice]);

    const legacyItems = [
        { icon: <DoDisturbAltOutlined color="warning" />, text: "Inactivate" }
    ];

    const loadInitialBaaList = (pNumber: number = 1) => {
        const data : SearchBaaRequest = {
            userRoleIds: [],
            entityIds: [],
            firstName: "",
            lastName: "",
            status: [],
          currentPage: pNumber,
          itemsPerPage: ITEMS_PER_PAGE,
        };
        setSearchCriteria(data);
        setValuesToCriteriaFields(data);
        
        searchBaaList(data);
      };

    const setValuesToCriteriaFields = (searchCriteria: SearchBaaRequest) => {
        setValue("firstName", searchCriteria.firstName);
        setValue("lastName", searchCriteria.lastName);
        setValue("status", searchCriteria.status);
        if (searchCriteria.userRoleIds?.length > 1) {
            setValue("userRoleId", searchCriteria.userRoleIds[0] + "-" + searchCriteria.userRoleIds[1])
        } else {
            setValue("userRoleId", searchCriteria.userRoleIds[0]);
        }

        const user = getValues('userRoleId');
        const selectedUser = userEntities?.find(entity => entity.value === user);
        if (selectedUser) {
            if (selectedUser.entity === PRACTICE_ENTITY) {
                setValue('practiceIds', searchCriteria.entityIds);
            } else if (selectedUser.entity === MANUFACTURER_ENTITY) {
                setValue('manufactureIds', searchCriteria.entityIds);
            } else if (selectedUser.entity === AGENCY_ENTITY) {
                setValue('agencyIds', searchCriteria.entityIds);
            } else if (selectedUser.entity === BILLER_ENTITY) {
                setValue('billerIds', searchCriteria.entityIds);
            }
        }
    }

      const searchBaaList = async (searchCriteria: SearchBaaRequest) => {
        setSearchCriteriaToSessionStorage(CAN_ACCESS_APPLICATION.BAA_MANAGEMENT + "SearchCriteria" + userInfo.userId, searchCriteria);
        
        let dataToSearch = {
          ...searchCriteria
        }
        const response = await dispatch(searchBaaDetails(dataToSearch));
      }
    

    const onSubmit = async (data: SearchBaaFields) => {
        const selectedRoleId  = getValues('userRoleId');
        let userRoleIds: (string | number)[]= [];

        if(selectedRoleId) {
            if(selectedRoleId.toString().includes("-")) {
                const selectedRoleIds = selectedRoleId.toString().split("-");
                userRoleIds = selectedRoleIds.map(id => parseInt(id));
            } else {
                userRoleIds = [selectedRoleId];
            }
        }

        let selectedEntityIds : any;

        if(selectedRoleEntity === PRACTICE_ENTITY) {
            selectedEntityIds = data.practiceIds;
        } else if(selectedRoleEntity === MANUFACTURER_ENTITY) {
            selectedEntityIds = data.manufactureIds;
        } else if(selectedRoleEntity === AGENCY_ENTITY) {
            selectedEntityIds = data.agencyIds;
        } else if(selectedRoleEntity === BILLER_ENTITY) {
            selectedEntityIds = data.billerIds;
        }

        const searchRequest: SearchBaaRequest = {
            userRoleIds: userRoleIds,
            entityIds: selectedEntityIds || [],
            firstName: data.firstName,
            lastName: data.lastName,
            status: data.status,
            currentPage: 1,
            itemsPerPage: ITEMS_PER_PAGE
        }
        setSearchCriteria(searchRequest);
        navigate("/baa_detail", {state: {baaListSearchCriteria : searchRequest}});
    }

    const handlePagination = (pageNumber: number) => {
        const data = {
          ...searchCriteria,
          currentPage: pageNumber + 1
        }
        setSearchCriteria(data);
    
        //Below navigate line call to useEffect[location.state, productsByUserRoleAgreementSlice] and search
        navigate("/baa_detail", { state: { baaListSearchCriteria: data } });
      }

  const handlePreview = async (row: BaaDetailDTO) => {
    if(row.agreementDocumentId) {
        const actionResult = await dispatch( fetchDocumentById((row.agreementDocumentId).toString()) );
        const documentData = actionResult.payload
        if (documentData && documentData.content) {
            setShowPDF({isShow: true, row:row});
          }
    }
  }

    const actionComponent = (row: BaaDetailDTO): JSX.Element => {
        return (
            <>
                <CustomIconButton
                    color="info"
                    size="small"
                    variant="outlined"
                    onClick={() => { navigate("/baa_agreement", {state: {baaDetailId : row.baaDetailId, searchBaaDetails: searchCriteria}}) }}
                    tooltipMessage="Sign"
                    tooltipPlacement="top"
                    style={{ display: `${hideElement(!canSignBaa || row.agreementDocumentId !== null)}` }}
                >
                    <ModeOutlined fontSize="small" />
                </CustomIconButton>
                <CustomIconButton
                    color="info"
                    size="small"
                    variant="outlined"
                    onClick={() => { handlePreview(row) }}
                    tooltipMessage="PDF"
                    tooltipPlacement="top"
                    style={{ display: `${hideElement(!canPreviewBaa)}` }}
                >
                    <PictureAsPdfOutlined fontSize="small" />
                </CustomIconButton>
            </>
        );
    }

    const userTypesList = useMemo(() => {
        let allUserRoles: Option[] = [];
        let entities: {entity: string, value: number | string}[] = [];
        if (userRoles && userRoles.length > 0 && managingUserRoles && managingUserRoles.length > 0) {
            let availableUserRoles: UserRole[] | undefined = undefined;

            availableUserRoles = userRoles?.filter((role: UserRole) => managingUserRoles.includes(role.roleName));

            let practiceAdminRoleId: string | number = 0;
            let practiceStaffRoleId: string | number = 0;
            let manufacturerAdminRoleId: string | number = 0;
            let manufacturerStaffRoleId: string | number = 0;
            let agencyAdminRoleId: string | number = 0;
            let accountRepresentativeRoleId: string | number = 0;

            // const userTypeToRemove = [USER_ROLES.ROLE_PRACTICEADMIN, USER_ROLES.ROLE_PRACTICESTAFF];
            availableUserRoles?.forEach((option: UserRole) => {
                // if(!(userTypeToRemove.includes(option.roleName))) {
                //     allUserRoles.push({ label: option.roleDisplayValue, value: option.userRoleId });
                // }

                allUserRoles.push({ label: option.roleDisplayValue, value: option.userRoleId });

                if (option.roleName === USER_ROLES.ROLE_PRACTICEADMIN) {
                    practiceAdminRoleId = option.userRoleId;
                    entities.push({ entity: PRACTICE_ENTITY ,value: option.userRoleId });
                }
                if (option.roleName === USER_ROLES.ROLE_PRACTICESTAFF) {
                    practiceStaffRoleId = option.userRoleId;
                    entities.push({ entity: PRACTICE_ENTITY ,value: option.userRoleId });
                }
                if (option.roleName === USER_ROLES.ROLE_MANUFACTURERADMIN) {
                    manufacturerAdminRoleId = option.userRoleId;
                    entities.push({ entity: MANUFACTURER_ENTITY ,value: option.userRoleId });
                }
                if (option.roleName === USER_ROLES.ROLE_MANUFACTURERSTAFF) {
                    manufacturerStaffRoleId = option.userRoleId;
                    entities.push({ entity: MANUFACTURER_ENTITY ,value: option.userRoleId });
                }
                if (option.roleName === USER_ROLES.ROLE_MANUFACTURERIVR) {
                    entities.push({ entity: MANUFACTURER_ENTITY ,value: option.userRoleId });
                }
                if (option.roleName === USER_ROLES.ROLE_AGENCYADMIN) {
                    agencyAdminRoleId = option.userRoleId;
                    entities.push({ entity: AGENCY_ENTITY ,value: option.userRoleId });
                }
                if (option.roleName === USER_ROLES.ROLE_ACCOUNTEXECUTIVE) {
                    accountRepresentativeRoleId = option.userRoleId;
                    entities.push({ entity: AGENCY_ENTITY ,value: option.userRoleId });
                }
                if (option.roleName === USER_ROLES.ROLE_BILLER) {
                    entities.push({ entity: BILLER_ENTITY ,value: option.userRoleId });
                }
            });

            if ((managingUserRoles.includes(USER_ROLES.ROLE_PRACTICEADMIN) && managingUserRoles.includes(USER_ROLES.ROLE_PRACTICESTAFF))
                && managingUserRoles.length > 2) {
                allUserRoles.push({ label: PRACTICE_USER, value: practiceAdminRoleId + "-" + practiceStaffRoleId });
                entities.push({ entity: PRACTICE_ENTITY ,value: practiceAdminRoleId + "-" + practiceStaffRoleId });
            }

            if ((managingUserRoles.includes(USER_ROLES.ROLE_MANUFACTURERADMIN) && managingUserRoles.includes(USER_ROLES.ROLE_MANUFACTURERSTAFF))
                && managingUserRoles.length > 2) {
                allUserRoles.push({ label: MANUFACTURE_USER, value: manufacturerAdminRoleId + "-" + manufacturerStaffRoleId });
                entities.push({ entity: MANUFACTURER_ENTITY ,value: manufacturerAdminRoleId + "-" + manufacturerStaffRoleId });
            }

            if ((managingUserRoles.includes(USER_ROLES.ROLE_AGENCYADMIN) && managingUserRoles.includes(USER_ROLES.ROLE_ACCOUNTEXECUTIVE))
                && managingUserRoles.length > 2) {
                allUserRoles.push({ label: AGENCY_USER, value: agencyAdminRoleId + "-" + accountRepresentativeRoleId });
                entities.push({ entity: AGENCY_ENTITY ,value: agencyAdminRoleId + "-" + accountRepresentativeRoleId });
            }

            allUserRoles = sortNames(allUserRoles, 'label');

            if (allUserRoles.length === 1) {
                setValue('userRoleId', parseInt(allUserRoles[0]?.value));
            }
        }
        setUserEntities(entities);
        return allUserRoles;
    }, [userRoles]);

    const handleUserTypeChange = () => {
        setValue('entityIds', []);
        let criteria = getSearchCriteriaFromSessionStorage(CAN_ACCESS_APPLICATION.BAA_MANAGEMENT + "SearchCriteria" + userInfo.userId);
        const user = getValues('userRoleId');
        let userRoleIds: (string | number)[] = [];
        if (user?.toString().includes("-")) {
            const selectedRoleIds = user.toString().split("-");
            userRoleIds = selectedRoleIds.map(id => parseInt(id));
        } else {
            userRoleIds = [user];
        }
        if (criteria) {
            const searchData = {
                ...criteria,
                entityIds: [],
                userRoleIds: userRoleIds,
                firstName: getValues('firstName'),
                lastName: getValues('lastName'),
                status: getValues('status')
            }
            setSearchCriteriaToSessionStorage(CAN_ACCESS_APPLICATION.BAA_MANAGEMENT + "SearchCriteria" + userInfo.userId, searchData);
            setValuesToCriteriaFields(searchData);
        }
    };

    useEffect(() => {
        setValue('entityIds', []);
        const user = getValues('userRoleId');
        const selectedUser = userEntities?.find(entity => entity.value === user);
        if (selectedUser) {
            setSelectedRoleEntity(selectedUser.entity);
        } else {
            setSelectedRoleEntity("");
        }
    }, [watch('userRoleId')]);

    useEffect(() => {
        //1. When login another user pagination is remain in the history and can redirect that page
          let criteria = getSearchCriteriaFromSessionStorage(CAN_ACCESS_APPLICATION.BAA_MANAGEMENT + "SearchCriteria" + userInfo.userId);
          if ((location.state && location.state.baaListSearchCriteria) || (location.state && location.state.withSearchHistory && criteria)) {
            if (location.state && location.state.withSearchHistory) {
              //Below navigate line call back to this useEffect - This for refreshing issue when navigate with state.withSearchHistory=true
              navigate("/baa_Detail", { state: { baaListSearchCriteria: criteria } });
              return;
            }
            let searchCriteria: SearchBaaRequest = {
                userRoleIds: [],
                entityIds: [],
                firstName: "",
                lastName: "",
                status: [],
                currentPage: 0,
                itemsPerPage: 0
            };
            if (location.state && location.state.baaListSearchCriteria) {
              searchCriteria = location.state.baaListSearchCriteria;
            } else {
              searchCriteria = criteria ? criteria : searchCriteria;
            }
            setSearchCriteria(searchCriteria);
            setValuesToCriteriaFields(searchCriteria);
            searchBaaList(searchCriteria);
          } else {
            loadInitialBaaList(1);
          }
      }, [location.state]);

    // Unmount
    useEffect(() => () => {
        dispatch(updateElementInBusinessAgreement({
            elementName: "baaList",
            value: ""
        }))
    }, []);

    function HandlePracticeBAAs(): void {
        navigate("/practice_list", {state: {fromBaa : true}});
    }

    function handleUserBAAs(): void {
        navigate("/user_list", {state: {fromBaa : true}});
    }

    return <>
        <Box my="8px" ml="12px" mr="10px">
            <Header title="Business Association Agreements" subtitle="Agreement Management" />
            {showPDF && <PDFViewer
                isOpen={showPDF.isShow}
                onClose={() => setShowPDF({isShow: false, row: null})}
                title={"Business Associate Agreement"}
                base64String={ document?.content || "" }
                fileName={"Business Associate Agreement" + userInfo.userId}
            />}
            <Collapse in={showCard}>
                <Card>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Grid container spacing={1.5} mt={-3} mb={-1}>
                            <Grid item md={3} xs={12}>
                                <CustomDropdownField name="userRoleId" onChangeSelect={handleUserTypeChange} control={control} label="User Type" options={userTypesList} />
                            </Grid>
                            {selectedRoleEntity === AGENCY_ENTITY &&
                                <Grid item md={3} xs={12}>
                                    <CustomMultipleDropDown name="agencyIds"
                                        label="Agency" options={agencyList} control={control} />
                                </Grid>
                            }
                            {selectedRoleEntity === BILLER_ENTITY &&
                                <Grid item md={3} xs={12}>
                                    <CustomMultipleDropDown name="billerIds"
                                        label="Biller" options={billerUsersList} control={control} />
                                </Grid>
                            }
                            {selectedRoleEntity === MANUFACTURER_ENTITY &&
                                <Grid item md={3} xs={12}>
                                    <CustomMultipleDropDown name="manufactureIds"
                                        label="Manufacture" options={manufacturersList} control={control} />
                                </Grid>
                            }
                            {selectedRoleEntity === PRACTICE_ENTITY &&
                                <Grid item md={3} xs={12}>
                                    <CustomMultipleDropDown name="practiceIds"
                                        label="Practice" options={practiceOptions} control={control} />
                                </Grid>
                            }
                            <Grid item md={3} xs={12}>
                                <CustomTextField controllerName="firstName" label="First Name" control={control} />
                            </Grid>
                            <Grid item md={3} xs={12}>
                                <CustomTextField controllerName="lastName" label="Last Name" control={control} />
                            </Grid>
                            <Grid item md={3} xs={12}>
                                <CustomMultipleDropDown name="status" label="Status" options={statusOptions} control={control} />
                            </Grid>
                            <Grid item md={2} xs={12}>
                                <Button fullWidth size="large" startIcon={<SearchOutlined />} style={{ padding: "15px" }} type="submit" >
                                    Search
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </Card>
            </Collapse>
            <Box pt={2}>
                <Card>
                    <Grid container justifyContent="flex-end" alignItems="center" spacing={1} mt={-2}>
                        {/* <Grid item xs={12} md>
                            {isMobile ? <LegacyToolBar items={legacyItems} /> : ""}
                        </Grid> */}
                        <Grid item xs={12} md="auto">
                            <Button variant="outlined" color="info" onClick={() => setShowCard(!showCard)} startIcon={<SearchOutlined />} fullWidth>
                                {showCard ? "Hide Filters" : "Show Filters"}
                            </Button>
                        </Grid>
                        <Grid item xs={12} sm="auto">
                            <Button color="success" startIcon={<FormatItalicOutlined />} onClick={HandlePracticeBAAs} fullWidth >
                                Practice BAA(s)
                            </Button>
                        </Grid>
                        <Grid item xs={12} sm="auto">
                            <Button color="warning" startIcon={<AppRegistrationOutlined />}
                                onClick={handleUserBAAs} fullWidth >
                                User BAA(s)
                            </Button>
                        </Grid>
                        <Grid item xs={12} sm="auto">
                            <Grid item xs={12} sm="auto">
                            </Grid>
                        </Grid>
                    </Grid>
                    <Table
                        columns={columns}
                        rows={baaRows || []}
                        pageNumber={handlePagination}
                        totalPages={baaList?.totalPages}
                        actionComponent={actionComponent}
                        totalElementText={"Records: " + (baaList?.totalElements ? baaList?.totalElements : "0")}
                        currentPageNumber={searchCriteria.currentPage - 1}
                        emptyText="No Business Association Agreement(s) Found"
                        showMenu={true}
                    />
                </Card>
            </Box>
        </Box>
    </>
}

export default BaaList;