import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import Button from "../../common/Button";
import { Box, Divider, Grid, Typography } from "@mui/material";
import CustomTextField from "../../common/CustomTextField";
import { useForm } from "react-hook-form";
import { Cancel, SaveAlt } from "@mui/icons-material";
import CustomAlert from "../../common/CustomAlert";
import CustomDropdownField from "../../common/CustomDropdownFiled";
import { useCodetablesSlice } from "../../../redux/slice/codetableSlice";
import { sortNames } from "../../../utils/Utility";
import CustomMultipleDropDown from "../../common/CustomMultipleDropDown";
import { useAppDispatch } from "../../../redux/slice/redux-hooks";
import { getWoundCodeValuesByWoundCodeType, fetchAllWoundCodes, useWoundCodesSlice, WoundCodes } from "../../../redux/slice/ivr/woundCodeSlice"
import BorderedSection from "../../common/BorderedSection";
import CustomNumberField from "../../common/CustomNumberField";
import CustomCheckbox from "../../common/CustomCheckBox";
import { setSingleAlertObj, SingleAlertInfo } from "../../../redux/slice/commonSlice";

interface WoundInfoProps {
  open: boolean;
  onClose: () => void;
  onAddWoundInfo: (woundData: any) => void;
  currentWoundInfo?: any;
}

export const woundInfoInitialState: any = {
  woundType: "",
  // noOfApplications: "",
  cpt: "",
  eCode: "",
  iCode: "",
  lCode: "",
  otherDxCodes: "",
  length: "",
  width: "",
  depth: "",
  totalSize: "",
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const woundTypesOptions = [
  { value: "Diabetic Ulcer", label: "Diabetic Ulcer" },
  { value: "Venous Ulcer", label: "Venous Ulcer" },
  { value: "Pressure Ulcer", label: "Pressure Ulcer" },
  { value: "Chronic Wound", label: "Chronic Wound" },
  { value: "Other", label: "Other" },
];

const noOfAppOptions: { value: string | number; label: string }[] = [
  { value: "1-3", label: "1 - 3" },
  { value: "4-6", label: "4 - 6" },
  { value: "7-10", label: "7 - 10" },
];

const IVRFormWoundInfoPopup: React.FC<WoundInfoProps> = ({
  open,
  onClose,
  onAddWoundInfo,
  currentWoundInfo,
}) => {
  const { control, handleSubmit, reset, setValue, getValues, watch, clearErrors, trigger, formState: { errors } } = useForm({
    defaultValues: woundInfoInitialState,
  });
  const dispatch = useAppDispatch();
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const length = watch("length");
  const width = watch("width");
  const woundType = watch("woundType");
  const { allCodeTablesValuesMap, allCodeTableValueFetchStatus } =
    useCodetablesSlice();
  const { allWoundCodesValuesMap, woundCodeFetchStatus } = useWoundCodesSlice();
  const [cptOptions, setCptOptions] = useState<
    { value: string | number; label: string }[]
  >([]);
  const [iCodeOptions, setICodeOptions] = useState<
    { value: string | number; label: string }[]
  >([]);
  const [lCodeOptions, setLCodeOptions] = useState<
    { value: string | number; label: string }[]
  >([]);
  const [eCodeOptions, setECodeOptions] = useState<
    { value: string | number; label: string }[]
  >([]);
  const [selectedCPTs, SetSelectedCPTs] = useState<WoundCodes[] | null>(null);
  const [specifyOtherDxCodes, setSpecifyOtherDxCodes] = useState(false);
  const [shouldShowDxCodesField, setShouldShowDxCodesField] = useState(false);
 
  // React.useEffect(() => {
  //   if (allCodeTableValueFetchStatus === "success" && allCodeTablesValuesMap) {
  //     setCptOptions(
  //       getCodeTableValuesByTableName(
  //         allCodeTablesValuesMap,
  //         "WoundInfo_CPTCode"
  //       ).map((item) => ({
  //         value: item.keyName,
  //         label: item.displayValue,
  //       }))
  //     );
  //     setICodeOptions(
  //       getCodeTableValuesByTableName(
  //         allCodeTablesValuesMap,
  //         "WoundInfo_ICode"
  //       ).map((item) => ({
  //         value: item.keyName,
  //         label: item.displayValue,
  //       }))
  //     );

  //     const woundInfoLCode = getCodeTableValuesByTableName(allCodeTablesValuesMap, "WoundInfo_LCode")
  //     const sortedLCodeData = woundInfoLCode ? sortNames(woundInfoLCode, 'keyName') : [];
  //     setLCodeOptions(
  //       sortedLCodeData.map((item: any) => ({
  //         value: item.keyName,
  //         label: item.displayValue,
  //       }))
  //     );

  //     setECodeOptions(
  //       getCodeTableValuesByTableName(
  //         allCodeTablesValuesMap,
  //         "WoundInfo_ECode"
  //       ).map((item) => ({
  //         value: item.keyName,
  //         label: item.displayValue,
  //       }))
  //     );
  //   }
  // }, [allCodeTablesValuesMap, allCodeTableValueFetchStatus]);

  React.useEffect(() => {
    dispatch(fetchAllWoundCodes());
  }, [dispatch]);

  React.useEffect(() => {
    if (woundCodeFetchStatus === "success" && allWoundCodesValuesMap) {

      const cptCodes = getWoundCodeValuesByWoundCodeType(allWoundCodesValuesMap, "CPTCODE")
      const sortedCPTCodes = cptCodes ? sortNames(cptCodes, 'woundKey') : [];
      setCptOptions(
        sortedCPTCodes.map((item: any) => ({
          value: item.woundKey,
          label: item.woundDisplayValue,
        }))
      );

      const eCodes = getWoundCodeValuesByWoundCodeType(allWoundCodesValuesMap, "ECODE")
      const sortedECodes = eCodes ? sortNames(eCodes, 'woundKey') : [];
      setECodeOptions(
        sortedECodes.map((item: any) => ({
          value: item.woundKey,
          label: item.woundDisplayValue,
        }))
      );

      const iCodes = getWoundCodeValuesByWoundCodeType(allWoundCodesValuesMap, "ICODE")
      const sortedICodes = iCodes ? sortNames(iCodes, 'woundKey') : [];
      setICodeOptions(
        sortedICodes.map((item: any) => ({
          value: item.woundKey,
          label: item.woundDisplayValue,
        }))
      );

      const lCodes = getWoundCodeValuesByWoundCodeType(allWoundCodesValuesMap, "LCODE")
      const sortedLCodes = lCodes ? sortNames(lCodes, 'woundKey') : [];
      setLCodeOptions(
        sortedLCodes.map((item: any) => ({
          value: item.woundKey,
          label: item.woundDisplayValue,
        }))
      );
    }
  }, [woundCodeFetchStatus, allWoundCodesValuesMap]);

  useEffect(() => {
    if (open && !currentWoundInfo) {
      reset(woundInfoInitialState);
    } else if (currentWoundInfo) {
      Object.keys(currentWoundInfo).forEach((key) => {
        if(key === 'cpt' && typeof currentWoundInfo[key] === 'string' && currentWoundInfo[key].trim() !== "") {
          const cptCodes = currentWoundInfo[key]?.split(",").map((code: string )=> code.trim());
          setValue(key, cptCodes, {
            shouldValidate: true,
          });
        } else if(key === 'eCode' && typeof currentWoundInfo[key] === 'string' && currentWoundInfo[key].trim() !== "") {
          const eCodes = currentWoundInfo[key]?.split(",").map((code: string )=> code.trim());
          setValue(key, eCodes, {
            shouldValidate: true,
          });
        } else if(key === 'iCode' && typeof currentWoundInfo[key] === 'string' && currentWoundInfo[key].trim() !== "") {
          const iCodes = currentWoundInfo[key]?.split(",").map((code: string )=> code.trim());
          setValue(key, iCodes, {
            shouldValidate: true,
          });
        } else if(key === 'lCode' && typeof currentWoundInfo[key] === 'string' && currentWoundInfo[key].trim() !== "") {
          const lCodes = currentWoundInfo[key]?.split(",").map((code: string )=> code.trim());
          setValue(key, lCodes, {
            shouldValidate: true,
          });
        } else {
          setValue(key, currentWoundInfo[key], {
            shouldValidate: true,
          });
        }
      });
    }
  }, [open, currentWoundInfo]);

  React.useEffect(() => {
    if (length && width && !isNaN(length) && !isNaN(width)) {
      const totalSize = parseFloat(length) * parseFloat(width);
      setValue("totalSize", totalSize.toFixed(2), { shouldValidate: true });
    }
  }, [length, width, setValue]);

  const onSubmit = (data: any) => {
    const formattedData = {
      ...data,
      cpt: data.cpt,
    };
    onAddWoundInfo(formattedData);
    // setShowErrorAlert(true);
    reset(woundInfoInitialState);
    onClose();
    
    let errorObj: SingleAlertInfo = {
      message: "Wound data added to IVR form successfully.",
      alertType: "success"
    };
    dispatch(setSingleAlertObj(errorObj));
  };

  const onError = (_errors: any, e: any) => {

    const checked = specifyOtherDxCodes;
    let hasError = false;

    if (checked) {
      hasError = false;
      clearErrors("eCode");
      clearErrors("iCode");
      clearErrors("lCode");
    } else {
      hasError = true;
      trigger("eCode");
      trigger("iCode");
      trigger("lCode");
    };

    if (!(Object.keys(errors).length > 0 || hasError)) {
      const data = getValues();
      onSubmit(data);
    };
  };

  useEffect(() => {
    const selectedCptCodes: string[] = getValues('cpt');
    const cptCodes = allWoundCodesValuesMap && getWoundCodeValuesByWoundCodeType(allWoundCodesValuesMap, "CPTCODE");
    const selectedCpts = cptCodes?.filter(cptCode => cptCode.woundKey && selectedCptCodes.includes(cptCode.woundKey));
    selectedCpts && SetSelectedCPTs(selectedCpts);
  }, [allWoundCodesValuesMap, watch('cpt'), getValues('cpt')]);

  const eCodeDropdown = () => {
    return (
      <>
        <Grid item xs={12}>
          <CustomMultipleDropDown
            name="eCode"
            control={control}
            options={eCodeOptions}
            label="E Code"
            rules={ specifyOtherDxCodes ? undefined: { required: "E Code is required." }}
          />
        </Grid>
      </>
    )
  };

  const lCodeDropdown = () => {
    return (
      <>
        <Grid item xs={12}>
          <CustomMultipleDropDown
            name="lCode"
            control={control}
            options={lCodeOptions}
            label="L Code"
            rules={ specifyOtherDxCodes ? undefined: { required: "L Code is required." }}
          />
        </Grid>
      </>
    )
  };

  const iCodeDropdown = () => {
    return (
      <>
        <Grid item xs={12}>
          <CustomMultipleDropDown
            name="iCode"
            control={control}
            options={iCodeOptions}
            label="I Code"
            rules={ specifyOtherDxCodes ? undefined: { required: "I Code is required." }}
          />
        </Grid>
      </>
    )
  };

  const handleCheckboxChange = (event: { target: { checked: any; }; }) => {
    const checked = event.target.checked;
    
    setSpecifyOtherDxCodes(checked);
    setValue("checkboxSpecifiedDxCodes", checked);
    if (getValues("otherDxCodes") !== ""){
      setSpecifyOtherDxCodes(true);
    }
    setShouldShowDxCodesField(checked || getValues("otherDxCodes") !== "");
  };

  useEffect(() => {
    const otherDxCodesValue = getValues("otherDxCodes");
  
    if (otherDxCodesValue === "") {
      setSpecifyOtherDxCodes(false);
      setValue("checkboxSpecifiedDxCodes", false);
      setShouldShowDxCodesField(false);
    } 
    else if (otherDxCodesValue !== "") {
      setShouldShowDxCodesField(true);
      setSpecifyOtherDxCodes(true);
    }
  }, [watch("otherDxCodes")]);

  useEffect(() => {
    if (woundType === "Diabetic Ulcer" && (currentWoundInfo?.iCode !== "")) {
      setValue("iCode", []); 
    } else if (woundType === "Venous Ulcer"  && currentWoundInfo?.eCode !== "") {
      setValue("eCode", []);
    } else if ((woundType === "Pressure Ulcer" || woundType === "Chronic Wound") && (currentWoundInfo?.iCode !== "" || currentWoundInfo?.eCode !== "")) {
      setValue("eCode", []);
      setValue("iCode", []);
    }
  }, [woundType, setValue, currentWoundInfo]);

  return (
    <>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={onClose}
        aria-describedby="alert-dialog-slide-description"
        onClick={(event) => {
          event.stopPropagation();
        }}
      >
        <form onSubmit={handleSubmit(onSubmit, onError)}>
          <DialogTitle>Add Wound Information</DialogTitle>
          <Divider orientation="horizontal" variant="fullWidth" flexItem style={{marginBottom:"-10px"}}/>
          <DialogContent>
            <Box p={1}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <CustomDropdownField
                    name="woundType"
                    control={control}
                    options={woundTypesOptions}
                    label="Wound Type"
                    rules={{ required: "Type of wound is required." }}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} mt={1}>
                <Grid item xs={12} md={6}>
                  <CustomDropdownField
                    name="noOfApplications"
                    control={control}
                    options={noOfAppOptions}
                    label="# of Applications"
                    rules={{ required: "No of application is required." }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <CustomMultipleDropDown
                    name="cpt"
                    control={control}
                    options={cptOptions}
                    label="CPT Code"
                    rules={{ required: "CPT Code is required." }}
                  />
                </Grid>

                {selectedCPTs && selectedCPTs.length > 0 &&
                  <Grid item mt={1} mb={1} md={12}>
                    <Box>
                      <BorderedSection title={'Description'}>
                        <Grid container spacing={2} mt={-3}>
                          {selectedCPTs?.map(cpt => {
                            return (
                              <Grid item md={12} style={{ textAlign: 'justify' }} >
                                {cpt.woundKey}{" - "}{cpt.woundDescription}
                              </Grid>
                            )
                          })}
                        </Grid>
                      </BorderedSection>
                    </Box>
                  </Grid>
                }

                {woundType === "Diabetic Ulcer" && (
                  <>
                    {eCodeDropdown()}
                    {lCodeDropdown()}
                  </>
                )}

                {woundType === "Venous Ulcer" && (
                  <>
                    {iCodeDropdown()}
                    {lCodeDropdown()}
                  </>
                )}

                {(woundType === "Pressure Ulcer" ||
                  woundType === "Chronic Wound") && (
                    <>
                      {lCodeDropdown()}
                    </>
                  )}

                {!(woundType === "Other" || getValues("woundType") === "") && (
                  <Grid
                    item
                    xs={12}
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Typography mr={4}>Specify Other DX Codes?</Typography>
                    <CustomCheckbox
                      control={control}
                      label="Yes"
                      controllerName="checkboxSpecifiedDxCodes"
                      checked={specifyOtherDxCodes}
                      onChange={handleCheckboxChange}
                    />
                  </Grid>
                )}

                {(shouldShowDxCodesField || woundType === "Other") && (
                  <Grid item xs={12}>
                    <CustomTextField
                      controllerName="otherDxCodes"
                      control={control}
                      label="Specify Other Dx Code(s) / ICD-10"
                      rules={{ required: "Other is required." }}
                      multiline
                      rows={4}
                      maxRows={4}
                    />
                    <p>Separate codes with comma(,)</p>
                  </Grid>
                )}

                <Grid item xs={12} md={3}>
                  <CustomNumberField
                    controllerName="length"
                    control={control}
                    label="Length"
                    allowDecimal={true}
                    decimalScale={2}
                    allowNegative={false}
                    rules={{ required: "Length is required." }}
                  />
                </Grid>

                <Grid item xs={12} md={3}>
                  <CustomNumberField
                    controllerName="width"
                    control={control}
                    label="Width"
                    allowDecimal={true}
                    decimalScale={2}
                    allowNegative={false}
                    rules={{ required: "Width is required." }}
                  />
                </Grid>

                <Grid item xs={12} md={3}>
                  <CustomNumberField
                    controllerName="depth"
                    control={control}
                    label="Depth"
                    allowDecimal={true}
                    decimalScale={2}
                    allowNegative={false}
                    rules={{ required: "Depth is required." }}
                  />
                </Grid>

                <Grid item xs={12} md={3}>
                  <CustomNumberField
                    controllerName="totalSize"
                    control={control}
                    label="Total Size"
                    allowDecimal={true}
                    decimalScale={2}
                    allowNegative={false}
                    rules={{ required: "Total Size is required." }}
                  />
                </Grid>
              </Grid>
            </Box>
          </DialogContent>
          <Divider orientation="horizontal" variant="fullWidth" flexItem style={{marginTop:"-6px"}}/>
          <DialogActions style={{marginTop:"-6px"}}>
            <Grid
              container
              justifyContent="flex-end"
              sx={{flexWrap: "wrap" }}
              spacing={1}
              p={2}
            >
              <Grid item xs={12} sm="auto">
                <Button type="submit" startIcon={<SaveAlt />} fullWidth>
                  Add to IVR
                </Button>
              </Grid>
              <Grid item xs={12} sm="auto">
                <Button color="error" startIcon={<Cancel />} onClick={onClose} fullWidth>
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </form>
      </Dialog>
      <CustomAlert
        open={showErrorAlert}
        onClose={() => setShowErrorAlert(false)}
        severity="info"
        message="Feature currently under development. Stay tuned for updates!"
      />
    </>
  );
};

export default IVRFormWoundInfoPopup;
