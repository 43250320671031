import React, { useState } from "react";
import DatePicker from "react-datepicker";
import { format } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";
import CustomTextField from "./CustomTextField";
import { Control, Controller } from "react-hook-form";
import "./datepicker-custom.css";
import { Box, IconButton, InputAdornment, useTheme } from "@mui/material";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { tokens } from "../../theme";

interface CustomMonthYearPickerProps {
  control: Control<any>;
  label?: string;
  controllerName: string;
  rules?: object;
  onDateChange?: (date: Date | null, endDate?: Date | null, isDateRange?: boolean | false) => void;
  isDisabled?: boolean;
  name?: string;
  isRange?: boolean;
  onClear?: () => void;
}

const MonthYearPicker: React.FC<CustomMonthYearPickerProps> = ({
  label,
  control,
  controllerName,
  rules,
  onDateChange,
  isDisabled,
  name,
  isRange,
  onClear,
  ...props
}) => {
  const theme = useTheme();

  const datePickerStyles = {
    ".react-datepicker__month-container": {
      backgroundColor: theme.palette.mode === "dark" ? "#312f2f" : "#fff",
    },
    ".react-datepicker__header": {
      backgroundColor: theme.palette.mode === "dark" ? "#312f2f" : "#f0f0f0",
    },
    ".dark-theme .react-datepicker__month-text, .dark-theme .react-datepicker__year-text": {
      backgroundColor: theme.palette.mode === "dark" ? "#c4c7ca" : "#312f2f",
    },
    ".react-datepicker-year-header": {
      color: theme.palette.mode === "dark" ? "white" : "#474747",
    },
    ".react-datepicker__month-text": {
      color: theme.palette.mode === "dark" ? "#d1d1d1" : "#474747",
    },
  };

  return (
    <>
      {isRange ? (
        <Box sx={datePickerStyles}>
          <Controller
            name={controllerName || ""}
            control={control}
            rules={rules ? rules : {}}
            render={({ field }) => {
              const fieldStartDate = field.value && Array.isArray(field.value) ? field.value[0] : null;
              const fieldEndDate = field.value && Array.isArray(field.value) ? field.value[1] : null;

              return (
                <DatePicker
                  selectsRange={true}
                  startDate={fieldStartDate || undefined}
                  endDate={fieldEndDate || undefined}
                  onChange={(update: [Date | null, Date | null]) => {
                    field.onChange(update);
                    if (onDateChange) {
                      onDateChange(update[0], update[1], true);
                    }
                    
                    if (!update[0] && !update[1] && onClear) {
                      onClear();
                      
                    }
                  }}
                  showMonthYearPicker
                  dateFormat="MMM yy"
                  isClearable={true}
                  customInput={
                    <CustomTextField
                      id={field.name}
                      {...field}
                      control={control}
                      value={
                        fieldStartDate && fieldEndDate
                          ? `${format(fieldStartDate, "MMM yy")} - ${format(fieldEndDate, "MMM yy")}`
                          : fieldStartDate
                          ? `${format(fieldStartDate, "MMM yy")}`
                          : ""
                      }
                      name={name ? name : controllerName}
                      label={label || ""}
                      fullWidth
                      type="text"
                      disabled={isDisabled}
                      InputLabelProps={{
                        shrink: fieldStartDate || fieldEndDate ? true : false,
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton>
                              <CalendarTodayIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      {...props}
                    />
                  }
                />
              );
            }}
          />
        </Box>
      ) : (
        <Box sx={datePickerStyles}>
          <Controller
            name={controllerName || ""}
            control={control}
            rules={rules ? rules : {}}
            render={({ field }) => (
              <DatePicker
                selected={field.value ? new Date(field.value) : null}
                onChange={(date: Date | null) => {
                  field.onChange(date);
                  if (onDateChange) {
                    onDateChange(date);
                  }
                  if (!date && onClear) {
                    onClear();
                  }
                }}
                showMonthYearPicker
                dateFormat="MMM yy"
                isClearable={true}
                customInput={
                  <CustomTextField
                    id={field.name}
                    {...field}
                    control={control}
                    value={field.value ? format(new Date(field.value), "MMM yy") : ""}
                    name={name ? name : controllerName}
                    label={label || ""}
                    fullWidth
                    type="text"
                    disabled={isDisabled}
                    InputLabelProps={{
                      shrink: field.value ? true : false,
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton>
                            <CalendarTodayIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    {...props}
                  />
                }
              />
            )}
          />
        </Box>
      )}
    </>
  );
};

export default MonthYearPicker;
