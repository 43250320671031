import { useEffect } from "react";
import { Menu, MenuItem, Sidebar } from "react-pro-sidebar";
import { useProSidebar, MenuItemStyles } from "react-pro-sidebar";
import { useTheme, Box, Typography, IconButton } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import {
  CloseOutlined as CloseOutlinedIcon, MenuOutlined as MenuOutlinedIcon, SwitchRightOutlined as SwitchRightOutlinedIcon,
  SwitchLeftOutlined as SwitchLeftOutlinedIcon, PeopleAltOutlined, ListAltOutlined, ForumOutlined, ManageAccountsOutlined,
  GridViewOutlined, PhotoCameraFrontOutlined, QrCodeOutlined, HandshakeOutlined, VillaOutlined, ReceiptOutlined, Settings,
  BusinessOutlined,
  TrackChanges,
  Healing,
  Engineering
} from "@mui/icons-material";
import { tokens } from "../../../theme";
import { useSidebarContext } from "./sidebarContext";
import useToken from "../../../hooks/useToken";
import { CAN_ACCESS_APPLICATION, USER_ROLES } from "../../../constants/applicationConstants";
import CustomTooltip from "../CustomTooltip";
import { useAuthSlice, setSelectedModule } from "../../../redux/slice/authSlice";
import { useAppDispatch } from "../../../redux/slice/redux-hooks";
import { getPracticesByUserRole } from "../../../redux/slice/practiceSlice";
import { removeItemFromSessionStorage } from "../../../utils/StorageUtils";
import { fetchAgencyByUser } from "../../../redux/slice/agency/AgencySlice";
import { ReactComponent as HealingBiologixIcon } from "../../../assets/HealingBiologix.svg";


export const sideBarItems = [
  {
    title: "Dashboard",
    to: "/dashboard",
    icon: <GridViewOutlined />,
    token: CAN_ACCESS_APPLICATION.DASHBOARD,
  },
  {
    title: "IVRs",
    to: "/ivr_list",
    icon: <ManageAccountsOutlined />,
    token: CAN_ACCESS_APPLICATION.IVR_MANAGEMENT,
  },
  {
    title: "Orders",
    to: "/order_list",
    icon: <ListAltOutlined />,
    token: CAN_ACCESS_APPLICATION.ORDER_MANAGEMENT,
  },
  {
    title: "Users",
    to: "/user_list",
    icon: <PeopleAltOutlined />,
    token: CAN_ACCESS_APPLICATION.USER_MANAGEMENT,
  },
  {
    title: "User Create",
    to: "/form",
    icon: <ForumOutlined />,
    token: CAN_ACCESS_APPLICATION.USER_CREATE,
  },
  {
    title: "Practice",
    to: "/practice_list",
    icon: <PhotoCameraFrontOutlined />,
    token: CAN_ACCESS_APPLICATION.PRACTICE,
  },
  {
    title: "Products",
    to: "/product_list",
    icon: <QrCodeOutlined />,
    token: CAN_ACCESS_APPLICATION.PRODUCTS,
  },
  {
    title: "Patients",
    to: "/patients",
    icon: <PhotoCameraFrontOutlined />,
    token: CAN_ACCESS_APPLICATION.PATIENT,
  },
  {
    title: "Agreements",
    to: "/practice_product_agreement",
    icon: <HandshakeOutlined />,
    token: CAN_ACCESS_APPLICATION.PRODUCT_AGREEMENT,
  },
  {
    title: "Agency",
    to: "/agency_list",
    icon: <VillaOutlined />,
    token: CAN_ACCESS_APPLICATION.AGENCY_MANAGEMENT,
  },
  //CS-520 && CS-556
  {
    title: "Collagen",
    to: "",
    icon: (
      <Box
        component={HealingBiologixIcon}
        sx={{
          width: "20px",
          height: "20px",
          transition: "fill 0.3s ease",
        }}
      />
    ),
	token: CAN_ACCESS_APPLICATION.HEALING_BIOLOGIX,
  },
  //
  {
    title: "Invoices",
    to: "/invoice_list",
    icon: <ReceiptOutlined />,
    token: CAN_ACCESS_APPLICATION.INVOICE_MANAGEMENT,
  },
  {
    title: "BAA",
    to: "/baa_Detail",
    icon: <BusinessOutlined />,
    token: CAN_ACCESS_APPLICATION.BAA_MANAGEMENT,
  },
  {
    title: "Configuration",
    to: "/configuration",
    icon: <Settings />,
    token: CAN_ACCESS_APPLICATION.CONFIG_MANAGEMENT,
  },
  // //CS-518
  // {
  //   title: "Audit Log",
  //   to: "/auditlog",
  //   icon: <TrackChanges />,
  //   token: CAN_ACCESS_APPLICATION.AUDITLOG_MANAGEMENT,
  // },
  // //
  // //CS-554 Manufacturer ui
  // {
  //   title: "Manufacturer",
  //   to: "/manufacturer",
  //   icon: <Engineering />,
  //   token: CAN_ACCESS_APPLICATION.MANUFACTURER_MANAGEMENT,
  // },
  // //
];

const Item = ({
  title,
  to,
  icon,
  selected,
  setSelected,
  broken,
  toggleSidebar,
  collapsed,
  collapseSidebar,
}: {
  title: string;
  to: string;
  icon: JSX.Element;
  selected: string;
  setSelected: (selectItem: string) => void;
  broken: boolean;
  toggleSidebar: () => void;
  collapsed: boolean;
  collapseSidebar: () => void;
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const { userInfo } = useAuthSlice();
  const dispatch = useAppDispatch();

  const handleClick = async () => {
    setSelected(title);
    if (title === "Practice" && userInfo.userRole === USER_ROLES.ROLE_PRACTICEADMIN) {
      dispatch(getPracticesByUserRole(false)).then((res) => {
        //CS-442
        if (res.payload.length === 1) {
          navigate("/practice_edit_view", { state: { practiceId: res.payload[0].practiceId } });
        } else {
          navigate("/practice_list");
        }
        //
      })
    } else if (title === "Agency" && (userInfo.userRole === USER_ROLES.ROLE_AGENCYADMIN || userInfo.userRole === USER_ROLES.ROLE_ACCOUNTEXECUTIVE)) {
      dispatch(fetchAgencyByUser()).then((res) => {
        if (res.payload && res.payload.code === "V3OK" && res.payload.message === "SUCCESS") {
          navigate("/agency_form", { state: { agency: { agencyId: res.payload.data.agencyId } } });
        }
      })
    }
    //CS-520 && CS-556
    else if(title === "Collagen") {
      window.open(process.env.REACT_APP_HEALINGBIOLOGIX_URL,'_blank')
    }
    else {
      navigate(to);
    }

    if (broken) {
      toggleSidebar();
    }
  }

  return (
    <CustomTooltip title={collapsed ? title : ""} placement="right" arrow color={colors.success[400]}>
      <MenuItem
        style={{
          backgroundColor:
            selected === title
              ? theme.palette.mode === "dark"
                ? "#0e0f0b"
                : "#c8c8c8"
              : "transparent",
          borderRadius: 10,
        }}
        onClick={handleClick}
        icon={icon}
      >
        <Typography style={{ fontWeight: 600, fontSize: "14px" }}>
          {title}
        </Typography>
      </MenuItem>
    </CustomTooltip>
  );
};

const MyProSidebar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();

  const { sidebarRTL, setSidebarRTL, sidebarImage } = useSidebarContext();
  const { collapseSidebar, toggleSidebar, collapsed, broken } = useProSidebar();
  const isTokenValueAvailable = useToken();
  const { selectedModule } = useAuthSlice();
  const { userInfo } = useAuthSlice();

  const menuItemStyles: MenuItemStyles = {
    root: {
      fontSize: "14px",
      fontWeight: 600,
    },
    button: {
      "&:hover": {
        backgroundColor: "transparent",
        color:
          theme.palette.mode === "dark"
            ? colors.warning[200] + "!important"
            : colors.success[300] + "!important",
      },
    },
    subMenuContent: ({ }) => ({
      backgroundColor:
        theme.palette.mode === "dark" ? colors.grey[800] : colors.grey[900],
    }),
  };

  const setSelected = (selectedItem: string) => {
    dispatch(setSelectedModule(selectedItem));
  }

  //Mount
  useEffect(() => {
    removeItemFromSessionStorage("searchCriterias");
    if (!collapsed) {
      collapseSidebar();
    }
  }, []);

  //Unmount
  useEffect(() => () => {
    removeItemFromSessionStorage("searchCriterias");
  }, []);

  useEffect(() => {
    const currentItem = sideBarItems.find((item) => item.to === location.pathname);
    if (currentItem) {
      if (isTokenValueAvailable(currentItem.token)) {
        dispatch(setSelectedModule(currentItem.title));
      } else {
        navigate("/");
      }
    }
  }, [location.pathname]);

  return (
    <Box
      sx={{
        position: "sticky",
        display: "flex",
        height: "100vh",
        top: 0,
        bottom: 0,
        zIndex: 1200,
      }}
    >
      <Sidebar
        breakPoint="md"
        rtl={sidebarRTL}
        backgroundColor={colors.grey[800]}
        rootStyles={{ border: "none" }}
        image={sidebarImage}
      >
        <Menu menuItemStyles={menuItemStyles}>
          <MenuItem
            icon={
              collapsed ? (
                <MenuOutlinedIcon onClick={() => collapseSidebar()} />
              ) : sidebarRTL ? (
                <SwitchLeftOutlinedIcon
                  onClick={() => setSidebarRTL(!sidebarRTL)}
                />
              ) : (
                <SwitchRightOutlinedIcon
                  onClick={() => setSidebarRTL(!sidebarRTL)}
                />
              )
            }
            style={{
              margin: "10px 0 10px 0",
              color: colors.grey[100],
            }}
          >
            {!collapsed && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                ml={sidebarRTL ? "" : "125px"}
                mr={sidebarRTL ? "125px" : ""}
              >
                <IconButton
                  onClick={
                    broken ? () => toggleSidebar() : () => collapseSidebar()
                  }
                >
                  <CloseOutlinedIcon />
                </IconButton>
              </Box>
            )}
          </MenuItem>
          {!collapsed && (
            <Box mb="25px">
              <Box display="flex" justifyContent="center" alignItems="center">
                <img
                  alt="logo"
                  width="80px"
                  height="80px"
                  src={require("../../../assets/v3-logo.png")}
                />
              </Box>
            </Box>
          )}
          <Box paddingLeft="5%" paddingRight="5%">
            {sideBarItems.map((item, index) =>
              isTokenValueAvailable(item.token) ? (
                <Item
                  key={index}
                  title={item.title}
                  to={item.to}
                  icon={item.icon}
                  selected={selectedModule}
                  setSelected={setSelected}
                  broken={broken}
                  toggleSidebar={toggleSidebar}
                  collapsed={collapsed}
                  collapseSidebar={collapseSidebar}
                />
              ) : null
            )}
          </Box>
        </Menu>
      </Sidebar>
    </Box>
  );
};

export default MyProSidebar;