import React, { ChangeEvent } from "react";
import {
    Switch as MaterialSwitch,
    SwitchProps,
    useTheme,
    FormControlLabel,
    Typography,
    Tooltip,
} from "@mui/material";
import { tokens } from "../../theme";
import { Control, Controller } from "react-hook-form";

export type MaterialSwitchProps = {
    controllerName: string;
    control: Control<any, any>;
    onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
    checked?: boolean;
    color?: "primary" | "secondary" | "success" | "error" | "info" | "warning";
    size?: "small" | "medium";
    label?: string;
    labelPlacement?: "start" | "end" | undefined;
    tooltipPlacement?:
    | "right"
    | "left"
    | "top"
    | "bottom"
    | "bottom-end"
    | "bottom-start"
    | "left-end"
    | "left-start"
    | "right-end"
    | "right-start"
    | "top-end"
    | "top-start"
    | undefined;
    tooltipMessage?: string;
    sx?: object;
} & SwitchProps;

const CustomSwitchButton: React.FC<MaterialSwitchProps> = ({
    controllerName,
    control,
    onChange,
    checked,
    color,
    size,
    label,
    labelPlacement,
    tooltipPlacement,
    tooltipMessage,
    sx,
    ...props
}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return (
        <Controller
            name={controllerName || ""}
            control={control}
            render={({ field, fieldState }) => (
                <Tooltip
                    title={tooltipMessage}
                    placement={tooltipPlacement || "bottom"}
                    arrow
                >
                    <FormControlLabel
                        control={
                            <MaterialSwitch
                                size={size || "medium"}
                                color={color || "secondary"}
                                {...field}
                                {...props}
                                checked={checked ?? field.value}
                                onChange={(e) => {
                                    field.onChange(e);
                                    onChange && onChange(e);
                                }}
                                sx={sx}
                            />
                        }
                        label={<Typography color={colors.grey[900]}>{label}</Typography>}
                        labelPlacement={labelPlacement}
                    />
                </Tooltip>
            )}
        />
    );
};

export default CustomSwitchButton;
