import React, { useEffect, useMemo, useState } from "react";
import { Box, Collapse, Grid, useMediaQuery, useTheme } from "@mui/material";
import {
  AddCircleOutlineOutlined, AppRegistrationOutlined, DeleteOutlineOutlined, DoDisturbAltOutlined, DriveFileRenameOutline,
  FormatItalicOutlined, SearchOutlined, VisibilityOutlined, DriveFolderUpload,
  PictureAsPdfOutlined
} from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { ProductInfo } from "../../../redux/slice/product/productListSlice";
import { useAppDispatch } from "../../../redux/slice/redux-hooks";
import { SingleAlertInfo, setSingleAlertObj, showHideLoadingDialog } from "../../../redux/slice/commonSlice";
import {
  PracticeProductAgreement, SearchPracticeProductAgreementRequest, deleteProductAgreementById, fetchProductAgreementList,
  fetchProductListIncludingInactiveByUserRoleAgreementSlice,
  generateNewAgreementsForExpiredProductPrice, getAgreementCountForExpiredProductPrice, getPracticeListByUserRoleAgreementSlice, inactivateProductAgreementsById,
  initiateProductAgreements, previewAgreement, resetProductAgreementsList, useCreateAgreementSlice
} from "../../../redux/slice/productAgreement/createAgreementSlice";
import Button from "../../common/Button";
import { PracticeInfo } from "../../../redux/slice/practiceSlice";
import DatePickerPopup from "../../common/DatePickerPopup";
import Header from "../../common/Header";
import Table from "../../common/CustomTable";
import Card from "../../common/Card";
import PDFViewer from "../../common/pdf/PDFViewer";
import CustomCheckbox from "../../common/CustomCheckBox";
import CustomMultipleDropDown from "../../common/CustomMultipleDropDown";
import CustomCurrencyField from "../../common/CustomCurrencyField";
import OptionsPopup, { OptionPopupObjectProps } from "../../common/OptionsPopup";
import { selectIsTokenAvailable, selectIsValueFoundInToken, useAuthSlice } from "../../../redux/slice/authSlice";
import { hideElement, sortNames } from "../../../utils/Utility";
import {
  AGREEMENT_ACTIONS, AGREEMENT_STATUS, CAN_ACCESS_APPLICATION, PRODUCT_AGREEMENT_STATUS_LIST,
  RESPONSE_CODES, USER_ROLES
} from "../../../constants/applicationConstants";
import LegacyToolBar from "../../common/LegacyToolBar";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import CustomIconButton from "../../common/CustomIconButton";
import { CodeTable, fetchAllCodeTablesValues, getCodeTableValuesByTableName, useCodetablesSlice } from "../../../redux/slice/codetableSlice";
import dayjs from "dayjs";
import { getSearchCriteriaFromSessionStorage, setSearchCriteriaToSessionStorage } from "../../../utils/StorageUtils";
import UploadSignedAgreementPopup from "./UploadSignedAgreementPopup";

interface ProductAgreement {
  effectiveDate: string;
  inactiveDate: string;
  product: string;
  practice: string;
  pricingType: string;
  discount: string;
  mac: string;
  discountPrice: string;
};

interface Row {
  checkboxes: JSX.Element;
  productId: number;
  practiceProductId: number,
  product: string;
  inactiveDate: string;
  practice: string;
  effectiveDate: string;
  fileType: string;
  extendedPrice: JSX.Element;
  status: string;
  auditTimestamp : string;
  action?: JSX.Element;
  provider: string;
};

type Option = {
  value: string,
  label: string
};

const ITEMS_PER_PAGE = 20;

const ProductAgreement: React.FC = () => {

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  
  const { control, handleSubmit, reset, setValue, getValues } = useForm<SearchPracticeProductAgreementRequest>();

  const [showInactivePopup, setShowInactivePopup] = useState({isShow: false, productAgreementObject: {}});
  const [showPDF, setShowPDF] = useState<{isShow:boolean, productName: string, fileName: string}>({isShow:false, productName: "", fileName: ""});
  const [showCard, setShowCard] = useState(false);
  const [formattedPracticeInfo, setFormattedPracticeInfo] = useState<Row[]>();
  const [isCheck, setIsCheck] = useState<number[]>([]);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [showOptionPopup, setShowOptionsPopup] = useState<OptionPopupObjectProps>({message:'', show:false});
  const [heldAgreementList, setHeldAgreementList] = useState<(number | undefined)[]>([]);
  const [ showUploadSignedAgreementPopup, setShowUploadSignedAgreementPopup ] = useState<{ show: boolean; practiceProductId: number | null }>({ show: false, practiceProductId: null });

  const [searchCriteria, setSearchCriteria] = useState<SearchPracticeProductAgreementRequest>({
    productIds: [],
    practiceIds: [],
    agreementStatus: [],
    currentPage: 1,
    itemsPerPage: ITEMS_PER_PAGE
  });

  const { userInfo } = useAuthSlice();
  const { allCodeTablesValuesMap } = useCodetablesSlice();
  const { document } = useCreateAgreementSlice();
  const { productAgreementsList, practiceByUserRoleAgreementSlice, productsByUserRoleAgreementSlice } = useCreateAgreementSlice();

  const canPracticeSignAgreement = useSelector((state: RootState) => selectIsTokenAvailable("canPracticeSignAgreement")(state));
  const canGenerateAgreements = useSelector((state: RootState) => selectIsTokenAvailable("canGenerateAgreements")(state));
  const canManufacturerSignAgreement = useSelector((state: RootState) => selectIsTokenAvailable("canManufacturerSignAgreement")(state));
  const canDeleteAgreement = useSelector((state: RootState) => selectIsValueFoundInToken("canAccessProductAgreementActions", AGREEMENT_ACTIONS.DELETE)(state));
  const canPreviewAgreement = useSelector((state: RootState) => selectIsValueFoundInToken("canAccessProductAgreementActions", AGREEMENT_ACTIONS.PREVIEW)(state));
  const canInitiateAgreement = useSelector((state: RootState) => selectIsValueFoundInToken("canAccessProductAgreementActions", AGREEMENT_ACTIONS.INITIATE)(state));
  const canInactivateAgreement = useSelector((state: RootState) => selectIsValueFoundInToken("canAccessProductAgreementActions", AGREEMENT_ACTIONS.INACTIVATE)(state));
  const canUploadAgreement = useSelector((state: RootState) => selectIsValueFoundInToken("canAccessProductAgreementActions", AGREEMENT_ACTIONS.AGREEMENT)(state));

  const statusOptions = useMemo(() => {
    let statuses: CodeTable[] = [];
    if (allCodeTablesValuesMap) {
      statuses = getCodeTableValuesByTableName(allCodeTablesValuesMap, PRODUCT_AGREEMENT_STATUS_LIST);
    }
    if (!statuses || statuses.length === 0) {
      dispatch(fetchAllCodeTablesValues());
    }
    let options: { label: string, value: any }[] = [];
    statuses?.map((option) => {
      options.push({ label: option.displayValue, value: option.displayValue });
    });
    if (userInfo.userRole === USER_ROLES.ROLE_PRACTICEADMIN) {
      options = options?.filter(option => (option.label !== AGREEMENT_STATUS.HELD && option.label !== AGREEMENT_STATUS.INACTIVE));
    } else if (userInfo.userRole === USER_ROLES.ROLE_MANUFACTURERADMIN) {
      options = options?.filter(option => (option.label !== AGREEMENT_STATUS.HELD && option.label !== AGREEMENT_STATUS.INACTIVE
        && option.label !== AGREEMENT_STATUS.INITIATED));
    }
    return options;
  }, [allCodeTablesValuesMap]);

  const practiceOptions = useMemo(() => {
    let options: Option[] = [];
    if (practiceByUserRoleAgreementSlice && practiceByUserRoleAgreementSlice.length > 0) {
      if (practiceByUserRoleAgreementSlice.length === 1) {
        setValue('practiceIds', [Number(practiceByUserRoleAgreementSlice[0]?.practiceId)]);
      }
      const sortedPracticeInfo: PracticeInfo[] = sortNames(practiceByUserRoleAgreementSlice, 'practiceName');
      sortedPracticeInfo?.map(practice => {
        options.push({ value: practice.practiceId, label: practice.practiceName });
      });
    };
    return options;
  }, [practiceByUserRoleAgreementSlice]);

  const productOptions = useMemo(() => {
    let options: Option[] = [];
    if (productsByUserRoleAgreementSlice && productsByUserRoleAgreementSlice.length > 0) {
      if (productsByUserRoleAgreementSlice.length === 1) {
        setValue('productIds', [Number(productsByUserRoleAgreementSlice[0]?.productId)]);
      }
      const sortedProductList: ProductInfo[] = sortNames(productsByUserRoleAgreementSlice, 'productName');
      sortedProductList?.map((option) => {
        options.push({ label: option.productName, value: option.productId })
      });
    };
    return options;
  }, [productsByUserRoleAgreementSlice]);

  //Mount
  useEffect(() => {
    fetchInitialLists();
  }, []);

  const fetchInitialLists = async () => {
    dispatch(showHideLoadingDialog(true));
    //Don't remove below unused variables
    const responsePractices = await dispatch((getPracticeListByUserRoleAgreementSlice(true)));
    const responseProducts = await dispatch(fetchProductListIncludingInactiveByUserRoleAgreementSlice(false));
    dispatch(showHideLoadingDialog(false));
  }

  //Unmount
  useEffect(() => () => {
    dispatch(resetProductAgreementsList());
  }, []);

  useEffect(() => {
    //1. When login another user pagination is remain in the history and can redirect that page
    if (productsByUserRoleAgreementSlice && productsByUserRoleAgreementSlice.length > 0) {
      let criteria = getSearchCriteriaFromSessionStorage(CAN_ACCESS_APPLICATION.PRODUCT_AGREEMENT + "SearchCriteria" + userInfo.userId);
      if ((location.state && location.state.agreementListSearchCriteria) || (location.state && location.state.withSearchHistory && criteria)) {
        if (location.state && location.state.withSearchHistory) {
          //Below navigate line call back to this useEffect - This for refreshing issue when navigate with state.withSearchHistory=true
          navigate("/practice_product_agreement", { state: { agreementListSearchCriteria: criteria } });
          return;
        }
        let searchCriteria: SearchPracticeProductAgreementRequest = {
          productIds: [],
          practiceIds: [],
          agreementStatus: [],
          currentPage: 0,
          itemsPerPage: 0
        };
        if (location.state && location.state.agreementListSearchCriteria) {
          searchCriteria = location.state.agreementListSearchCriteria;
        } else {
          searchCriteria = criteria ? criteria : searchCriteria;
        }
        setSearchCriteria(searchCriteria);
        setValuesToCriteriaFields(searchCriteria);
        
        searchAgreementsList(searchCriteria);
      } else {
        loadInitialAgreementList(1);
      }
    }
  }, [location.state, productsByUserRoleAgreementSlice]);

  const setValuesToCriteriaFields = (searchCriteria: SearchPracticeProductAgreementRequest) => {
    setValue("practiceIds", searchCriteria.practiceIds);
    setValue("productIds", searchCriteria.productIds);
    setValue("agreementStatus", searchCriteria.agreementStatus);
  }

  const loadInitialAgreementList = (pNumber: number = 1) => {
    const practiceIdList = practiceByUserRoleAgreementSlice && practiceByUserRoleAgreementSlice.length > 0
      ? practiceByUserRoleAgreementSlice?.map(practice => Number(practice.practiceId)) : [];
    const productIdList = productsByUserRoleAgreementSlice && productsByUserRoleAgreementSlice.length > 0
      ? productsByUserRoleAgreementSlice?.map(product => Number(product.productId)) : [];
    const data: SearchPracticeProductAgreementRequest = {
      practiceIds: practiceIdList && practiceIdList.length === 1 ? practiceIdList : [],
      productIds: productIdList && productIdList.length === 1 ? productIdList : [],
      agreementStatus: [],
      currentPage: pNumber,
      itemsPerPage: ITEMS_PER_PAGE,
    };
    setSearchCriteria(data);
    setValuesToCriteriaFields(data);
    
    searchAgreementsList(data);
  }

  const searchAgreementsList = (searchCriteria: SearchPracticeProductAgreementRequest) => {
    setSearchCriteriaToSessionStorage(CAN_ACCESS_APPLICATION.PRODUCT_AGREEMENT + "SearchCriteria" + userInfo.userId, searchCriteria);

    let dataToSearch = {
      ...searchCriteria
    }
    if (!dataToSearch.practiceIds || dataToSearch.practiceIds.length === 0) {
      dataToSearch.practiceIds = practiceByUserRoleAgreementSlice && practiceByUserRoleAgreementSlice.length > 0
        ? practiceByUserRoleAgreementSlice?.map(practice => Number(practice.practiceId)) : [];
    }
    if (!dataToSearch.productIds || dataToSearch.productIds.length === 0) {
      dataToSearch.productIds = productsByUserRoleAgreementSlice && productsByUserRoleAgreementSlice.length > 0
        ? productsByUserRoleAgreementSlice?.map(product => Number(product.productId)) : [];
    }
    if (!dataToSearch.agreementStatus || dataToSearch.agreementStatus.length === 0) {
      dataToSearch.agreementStatus = statusOptions && statusOptions.length > 0 ? statusOptions?.map(status => status.value) : [];
    }

    dispatch(fetchProductAgreementList(dataToSearch));
    setIsCheck([]);
    setIsCheckAll(false);
  }

  const onSubmit = (data: SearchPracticeProductAgreementRequest) => {
    const data2: SearchPracticeProductAgreementRequest = {
      practiceIds: data.practiceIds,
      productIds: data.productIds,
      agreementStatus: data.agreementStatus,
      currentPage: 1,
      itemsPerPage: ITEMS_PER_PAGE,
    }
    setSearchCriteria(data2);

    //Below navigate line call to useEffect[location.state, productsByUserRoleAgreementSlice] and search
    navigate("/practice_product_agreement", { state: { agreementListSearchCriteria: data2 } });
  }

  const handlePagination = (pageNumber: number) => {
    const data = {
      ...searchCriteria,
      currentPage: pageNumber + 1
    }
    setSearchCriteria(data);

    //Below navigate line call to useEffect[location.state, productsByUserRoleAgreementSlice] and search
    navigate("/practice_product_agreement", { state: { agreementListSearchCriteria: data } });
  }

  const handleInactiveAction = (row: Row) => {
    setShowInactivePopup({isShow: true, productAgreementObject: row});
  }

  const handleDeleteAction = async (row: Row) => {
    {setShowOptionsPopup({message: "Do you really want to delete the agreement? ", 
    show: true, 
    variant: "confirm", 
    buttons: [{name: "Confirm", color: 'primary', onClick:() => deleteProductAgreement(row) },{name: "Cancel", color: 'secondary', onClick:cancelOptionPopup }]})}
  }

  const deleteProductAgreement = async (row: Row) => {
    const response = await dispatch(deleteProductAgreementById(row?.practiceProductId));
   if(response.payload.message === 'SUCCESS') {
      let successObj: SingleAlertInfo = {
        message: "The Agreement is successfully marked as Deleted.",
        alertType: "success"
      }
      dispatch(setSingleAlertObj(successObj));
    }
    
    searchAgreementsList(searchCriteria);
    cancelOptionPopup();
  }

  const cancelOptionPopup = () => {
    setShowOptionsPopup({ message: "", show: false });
  }

  const handleSignAction = (row: Row) => {
    navigate(`/sign_product_agreement?practiceProductId=${row.practiceProductId}`);
  }

  const handlePreviewAction = async (row: Row) => {
    const actionResult = await dispatch(previewAgreement(row.practiceProductId));
    const documentData = actionResult.payload;
    if (documentData && documentData.content) {
      setShowPDF({ isShow: true, productName: row.product, fileName: row.product + "_OnBoarding_Agreement" });
    }
  }

  const handleInitiateClick = async (row: Row) => {

    {
      setShowOptionsPopup({
        message: `Do you really want to initiate agreement? `,
        show: true,
        variant: "confirm",
        buttons: [{ name: "Confirm", color: 'primary', onClick: () => initiateSingleAgreement(row) }, { name: "Cancel", color: 'secondary', onClick: cancelOptionPopup }]
      })
    };
  }

  const initiateSingleAgreement = async (row : Row) => {
    const response = await dispatch(initiateProductAgreements([row.practiceProductId]));
    if (response.meta.requestStatus === 'fulfilled') {
      let successObj: SingleAlertInfo = {
        message: "Agreement successfully initiated.",
        alertType: "success"
      };
      dispatch(setSingleAlertObj(successObj));
    }
    
    searchAgreementsList(searchCriteria);
    cancelOptionPopup()
  }

  const handleCheckbox = (e: React.ChangeEvent<HTMLInputElement>, command: string, row: PracticeProductAgreement | null) => {
    const {checked} = e.target;
    if(checked){
      if(command === "ALL" && productAgreementsList){
          setIsCheck(prevValues=> {
            const agreementIdList = productAgreementsList.content.map(agreement => {
              return agreement.practiceProductId;
            });
            return agreementIdList;
          });
        setIsCheckAll(true);
      } else if(command !== "ALL") {
        row && setIsCheck(prevValues => {
          const newValues = [...prevValues];
          newValues.push(row.practiceProductId);
          return newValues
        })
      };
    } else {
      if(command === 'ALL'){
        setIsCheck([]);
        setIsCheckAll(false);
      } else {
        if(row){
          setIsCheck(isCheck.filter(item => item !== row.practiceProductId))
        }
      }
    }
  }

  const submitInactiveDate = async (date: string) => {
    const selectedDate = dayjs(date);
    //@ts-ignore
    if (dayjs(showInactivePopup?.productAgreementObject?.effectiveDate).isAfter(selectedDate)) {
      let errObj: SingleAlertInfo = {
        message: "Please select a date after the effective date.",
        alertType: "error"
      };
      dispatch(setSingleAlertObj(errObj));
    } else {
      //@ts-ignore
      const submitObj = { id: showInactivePopup?.productAgreementObject?.practiceProductId, inactiveDate: date };
      const response = await dispatch(inactivateProductAgreementsById({ inactiveData: submitObj, interceptorOption: { skipErrorPopup: true } }));

      if (response?.payload?.code === RESPONSE_CODES.SUCCESS_CODE) {
        let successObj: SingleAlertInfo = {
          message: "The agreement inactivate date was added successfully.",
          alertType: "success"
        };
        dispatch(setSingleAlertObj(successObj));

        searchAgreementsList(searchCriteria);
        setShowInactivePopup({ isShow: false, productAgreementObject: {} });
      } else if (response?.payload?.code === RESPONSE_CODES.FAILED_CODE) {
        let errObj: SingleAlertInfo = {
          message: response.payload?.message || "Active agreement already exists.",
          alertType: "error"
        }
        dispatch(setSingleAlertObj(errObj));
      }
    }
  }

  const handleInitiateMultipleClick = async () => {
    setShowOptionsPopup({message: `Do you really want to initiate ${heldAgreementList.length} agreement(s)? `, 
    show: true, 
    variant: "confirm", 
    buttons: [{name: "Confirm", color: 'primary', onClick:() => initiateMultipleAgreements() },{name: "Cancel", color: 'secondary', onClick:cancelOptionPopup }]});
  }

  const initiateMultipleAgreements = async () => {
    let heldAgreementIdList = getHeldAgreementList();
    if(heldAgreementIdList){
      const response = await dispatch(initiateProductAgreements(heldAgreementIdList));
      if(response.meta.requestStatus === 'fulfilled') {
        let successObj: SingleAlertInfo = {
          message: `${heldAgreementIdList.length} Agreement(s) successfully initiated.`,
          alertType: "success"
        };
        dispatch(setSingleAlertObj(successObj));
      }

      searchAgreementsList(searchCriteria);
    };
    cancelOptionPopup();
  }

  const getHeldAgreementList = () => {
    let heldAgreementIdList: (number | undefined)[]= [];
    if (isCheck && isCheck.length > 0) {
      let heldAgreementList = isCheck?.map(practiceProductId => {
        const heldAgreement = productAgreementsList?.content.find((agreement : PracticeProductAgreement) => agreement.practiceProductId === practiceProductId);
        return heldAgreement;
      });
      heldAgreementList = heldAgreementList?.filter(agreement => agreement?.agreementStatus === AGREEMENT_STATUS.HELD);
      heldAgreementIdList = heldAgreementList?.map(agreement => agreement?.practiceProductId);
    }
    return heldAgreementIdList;
  }

  const checkHeldAgreementList = () => {
    const heldList = getHeldAgreementList();
    setHeldAgreementList(heldList);
  }

  const handleGenerateClick = async () => {
    const response = await dispatch(getAgreementCountForExpiredProductPrice());
    if (response?.payload) {
      setShowOptionsPopup({message: `${response.payload}`, 
      show: true, 
      variant: "confirm", 
      buttons: [{name: "Confirm", color: 'primary', onClick:() => generateAgreements() },{name: "Cancel", color: 'secondary', onClick:cancelOptionPopup }]});
    }
  }

  const generateAgreements = async () => {
    const response = await dispatch(generateNewAgreementsForExpiredProductPrice());
    if(response.meta.requestStatus === 'fulfilled') {
      let successObj: SingleAlertInfo = {
        message: `Agreements successfully generated.`,
        alertType: "success"
      }
      dispatch(setSingleAlertObj(successObj));
    }
    cancelOptionPopup();

    searchAgreementsList(searchCriteria);
  }

  useEffect (() => {
    if (productAgreementsList) {
      const productAgreementObj: Row[] = productAgreementsList.content.map((productAgreement: PracticeProductAgreement) => {
        return ({
          checkboxes: checkboxComponent("", productAgreement),
          productId: productAgreement.productId,
          practiceProductId: productAgreement.practiceProductId,
          product: productAgreement.productName,
          practice: (productAgreement.practiceName ? productAgreement.practiceName : "") + (productAgreement.providerName ? " - " + productAgreement.providerName : ""),
          effectiveDate: productAgreement.effectiveDate,
          fileType: productAgreement.fileType,
          inactiveDate: productAgreement.inactiveDate,
          extendedPrice: <CustomCurrencyField externalValue={productAgreement.extendedPrice} displayType="text" />,
          status: productAgreement.agreementStatus,
          auditTimestamp: productAgreement.auditTimestamp,
          provider: productAgreement.providerName
        }); 
      });
      checkHeldAgreementList();
      setFormattedPracticeInfo(productAgreementObj || []);
    }
  }, [productAgreementsList, isCheck]);

  const actionComponent = (row: Row): JSX.Element => {
    return (
      <>
        <CustomIconButton color="info" size="small" variant="outlined" style={{ display: `${hideElement(row.status === AGREEMENT_STATUS.DELETED || !canPreviewAgreement)}` }}
          onClick={() => handlePreviewAction(row)} tooltipMessage="PDF" tooltipPlacement='top'><PictureAsPdfOutlined fontSize="small" /></CustomIconButton>
        <CustomIconButton
          color="warning" size="small" variant="outlined"
          style={{
            display: `${hideElement(!(row.status === AGREEMENT_STATUS.EXECUTED || row.status === AGREEMENT_STATUS.PRACTICE_SIGNED)
              || dayjs(row.inactiveDate).isValid() || !canInactivateAgreement)}`
          }}
          onClick={() => handleInactiveAction(row)} tooltipMessage="Inactivate" tooltipPlacement='top'><DoDisturbAltOutlined fontSize="small" /></CustomIconButton>
        <CustomIconButton color="success" size="small" variant="outlined" onClick={() => handleSignAction(row)}
          style={{
            display: `${hideElement(
              !(row.status === AGREEMENT_STATUS.INITIATED || row.status === AGREEMENT_STATUS.PRACTICE_SIGNED)
              || !(canPracticeSignAgreement || canManufacturerSignAgreement)
              || (canPracticeSignAgreement && row.status === AGREEMENT_STATUS.PRACTICE_SIGNED)
              || (canManufacturerSignAgreement && (row.status === AGREEMENT_STATUS.INITIATED))
            )}`
          }}
          tooltipMessage={(row.status === AGREEMENT_STATUS.INITIATED) ? 'Practice Sign' : (row.status === AGREEMENT_STATUS.PRACTICE_SIGNED) ? 'Manufacture Sign' : ''}
          tooltipPlacement='top'><DriveFileRenameOutline fontSize="small" /></CustomIconButton>
        <CustomIconButton color='success' size="small" variant="outlined" onClick={() => handleInitiateClick(row)}
          style={{ display: `${hideElement(!canInitiateAgreement || row.status !== AGREEMENT_STATUS.HELD)}` }}
          tooltipMessage="Initiate" tooltipPlacement="top"
        ><FormatItalicOutlined fontSize="small" /></CustomIconButton>
        <CustomIconButton color='info' size="small" variant="outlined" onClick={() => setShowUploadSignedAgreementPopup({ show: true, practiceProductId: row.practiceProductId })}
          style={{ display: `${hideElement(!canUploadAgreement || row.status === AGREEMENT_STATUS.EXECUTED || row.status === AGREEMENT_STATUS.DELETED)}` }}
          tooltipMessage="Upload Signed Agreement" tooltipPlacement="top"
        ><DriveFolderUpload fontSize="small" /></CustomIconButton>
        <CustomIconButton color="error" size="small" variant="outlined"
          style={{ display: `${hideElement(!(row.status === AGREEMENT_STATUS.HELD || row.status === AGREEMENT_STATUS.INITIATED) || !canDeleteAgreement)}` }}
          onClick={() => handleDeleteAction(row)}
          tooltipMessage="Delete" tooltipPlacement='top'><DeleteOutlineOutlined fontSize="small" /></CustomIconButton>
      </>
    );
  }

  const checkboxComponent = (command:string, row: PracticeProductAgreement | null): JSX.Element => {
      return (
        <div style={{ display: `${hideElement(command === "" && row?.agreementStatus !== AGREEMENT_STATUS.HELD)}` }}>
          <CustomCheckbox control={control} color="primary" checked={command==='ALL' ? isCheckAll : command === "" && row ? isCheck.includes(row.practiceProductId) :  undefined}
          onChange={(e) => handleCheckbox(e, command, row)} />
        </div>
      );
  }

  const legacyItems = [
    { icon: <DoDisturbAltOutlined color="warning" />, text: "Inactivate" },
    { icon: <DeleteOutlineOutlined color="error" />, text: "Delete" },
    { icon: <DriveFileRenameOutline color="success" />, text: "Practice Sign/Manufacture Sign" },
    { icon: <VisibilityOutlined color="info" />, text: "Preview" },
    { icon: <FormatItalicOutlined color="success" />, text: "Initiate" },
    { icon: <DriveFolderUpload color="info" />, text: "Upload Agreement" },
  ];

  interface Column {
    id: string;
    label: string | JSX.Element;
    minWidth?: number;
    align?: "right" | "center" | "left";
    format?: (value: number) => string;
  }

  const columns: Column[] = [
    // { id: "checkboxes", label: checkboxComponent("ALL", null), minWidth: 20},
    { id: "checkboxes", label: "Select", minWidth: 20},
    { id: "product", label: "Product", minWidth: 100 },
    { id: "practice", label: "Practice", minWidth: 100 },
    { id: "provider", label:"Provider", minWidth: 100},
    { id: "effectiveDate", label: "Effective Date", minWidth: 100 },
    { id: "inactiveDate", label: "Inactive Date", minWidth: 100},
    { id: "fileType", label: "File Type", minWidth: 100 },
    { id: "extendedPrice", label: "Extended Price", minWidth: 100, align: 'right' },
    { id: "status", label: "Status", minWidth: 100 },
    { id: "auditTimestamp", label: "Time", minWidth: 100 },
    { id: "action", label: "Action", minWidth: 50 }
  ];

  return (
    <>
      {showUploadSignedAgreementPopup.show && <UploadSignedAgreementPopup
        open={showUploadSignedAgreementPopup.show}
        onClose={() => {
          setShowUploadSignedAgreementPopup({ show: false, practiceProductId: null });
          searchAgreementsList(searchCriteria);
        }}
        practiceProductId={showUploadSignedAgreementPopup.practiceProductId}
      />}
      { showOptionPopup.show &&
        <OptionsPopup
          open={showOptionPopup.show}
          onClose={() => setShowOptionsPopup({ message: "", show: false })}
          variant={showOptionPopup.variant}
          message={<>{showOptionPopup.message}</>}
          buttons={showOptionPopup.buttons}
          title={showOptionPopup.title}
          obj={showOptionPopup.obj}
        />
      }
      { showPDF.isShow &&
        <PDFViewer
          isOpen={showPDF.isShow}
          onClose={() => setShowPDF({ isShow: false, productName: "", fileName: "" })}
          title={showPDF.productName + " OnBoarding Agreement"}
          fileName={showPDF.fileName}
          base64String={document?.content || ""}
        />
      }
      { showInactivePopup?.isShow &&
        <DatePickerPopup
          open={showInactivePopup?.isShow}
          onClose={() => setShowInactivePopup({ isShow: false, productAgreementObject: {} }) }
          title={"Set Inactive Date"} label={""}
          submitDate={submitInactiveDate}
          //@ts-ignore
          maxDate={ dayjs(showInactivePopup.productAgreementObject?.effectiveDate).add(20, 'year') }
        />
      }
      <Box my="8px" ml="12px" mr="10px">
        <Header title="Manufacturer Onboarding Agreements" subtitle="Agreement Management" />
        <Collapse in={showCard}>
          <Card>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={1.5} mt={-3} mb={-1}>
                <Grid item md={2} xs={12}>
                  <CustomMultipleDropDown name="productIds" label="Product" disabled={productOptions && productOptions.length === 1}
                    options={productOptions} control={control} />
                </Grid>
                <Grid item md={3} xs={12}>
                  <CustomMultipleDropDown name="practiceIds" disabled={practiceOptions && practiceOptions.length === 1}
                    label="Practice" options={practiceOptions} control={control} />
                </Grid>
                <Grid item md={4} xs={12}>
                  <CustomMultipleDropDown name="agreementStatus" label="Status" options={statusOptions} control={control} />
                </Grid>
                <Grid item md={2} xs={12}>
                  <Button fullWidth size="large" startIcon={<SearchOutlined />} style={{ padding: "15px" }} type="submit" >
                    Search
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Card>
        </Collapse>
        <Box pt={2}>
          <Card>
            <Grid container justifyContent="flex-end" alignItems="center" spacing={1} mt={-2}>
               <Grid item xs = {12} md>
               {/* {isMobile ?<LegacyToolBar items={legacyItems} /> : ""} */}
              </Grid>
              <Grid item xs={12} md="auto">
                <Button variant="outlined" color="info" onClick={() => setShowCard(!showCard)} startIcon={<SearchOutlined />} fullWidth>
                  {showCard ? "Hide Filters" : "Show Filters"}
                </Button>
              </Grid>
              <Grid item xs={12} sm="auto">
                <Button color="success" startIcon={<FormatItalicOutlined />} style={{ display: `${hideElement(userInfo.userRole !== USER_ROLES.ROLE_ADMIN)}`, }}
                  disabled={heldAgreementList && heldAgreementList.length === 0} onClick={handleInitiateMultipleClick} fullWidth >
                  Initiate Agreement(s)
                </Button>
              </Grid>
              <Grid item xs={12} sm="auto">
                <Button color="warning" startIcon={<AppRegistrationOutlined />} style={{ display: `${hideElement(!canGenerateAgreements  )}`, }}
                  onClick={handleGenerateClick} fullWidth >
                  Generate Agreements
                </Button>
              </Grid>
              <Grid item xs={12} sm="auto">
                <Button color="info" startIcon={<AddCircleOutlineOutlined />} onClick={() => navigate("/create_new_agreement")}
                  style={{ display: `${hideElement(userInfo.userRole !== USER_ROLES.ROLE_ADMIN)}`, }} fullWidth >
                  Create Agreement
                </Button>
              </Grid>
            </Grid>
            <Table
              columns={columns}
              rows={formattedPracticeInfo || []}
              pageNumber={handlePagination}
              totalPages={productAgreementsList?.totalPages}
              totalElementText={"Records: " + (productAgreementsList?.totalElements ? productAgreementsList?.totalElements : "0")}
              currentPageNumber={searchCriteria.currentPage - 1}
              actionComponent={(row: Row) => actionComponent(row)}
              emptyText="No Manufacturer Onboarding Agreement(s) Found"
              showMenu={true}
            />
          </Card>
        </Box>
      </Box>
    </>
  );
};

export default ProductAgreement;