import CryptoJS from "crypto-js";

export function encrypt(text: string, shift: number): string {
    return text.split('').map(char => {
      const charCode = char.charCodeAt(0);
      let shiftedCode = charCode;
  
      if (char >= 'a' && char <= 'z') {
        shiftedCode = charCode + shift;
        if (shiftedCode > 'z'.charCodeAt(0)) {
          shiftedCode = shiftedCode - 26;
        } else if (shiftedCode < 'a'.charCodeAt(0)) {
          shiftedCode = shiftedCode + 26;
        }
      }
  
      if (char >= 'A' && char <= 'Z') {
        shiftedCode = charCode + shift;
        if (shiftedCode > 'Z'.charCodeAt(0)) {
          shiftedCode = shiftedCode - 26;
        } else if (shiftedCode < 'A'.charCodeAt(0)) {
          shiftedCode = shiftedCode + 26;
        }
      }
  
      if (char >= '0' && char <= '9') {
        shiftedCode = charCode + shift;
        if (shiftedCode > '9'.charCodeAt(0)) {
          shiftedCode = shiftedCode - 10;
        } else if (shiftedCode < '0'.charCodeAt(0)) {
          shiftedCode = shiftedCode + 10;
        }
      }
  
      return String.fromCharCode(shiftedCode);
    }).join('');
  }
  
  export function decrypt(encryptedText: string, shift: number): string {
    return encrypt(encryptedText, -shift);
  }
  
export function decryptAES(encryptedData: any, iv: any, salt: any) {
  // Derive key using PBKDF2
  const key = CryptoJS.PBKDF2("v3biomed", CryptoJS.enc.Hex.parse(salt), {
    keySize: 128 / 32, // 128-bit key
    iterations: 1000,
  });

  // Decrypt the data
  const decrypted = CryptoJS.AES.decrypt(encryptedData, key, {
    iv: CryptoJS.enc.Hex.parse(iv),
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });

  return decrypted.toString(CryptoJS.enc.Utf8);
}