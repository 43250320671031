import { useForm } from "react-hook-form";
import React, { useEffect, useState } from "react";
import { Box, Container, Grid, Stack, Typography, useTheme } from "@mui/material";
import Button from "../../common/Button";
import {
  Cancel,
  CheckCircle,
  DriveFileRenameOutline,
  VisibilityOutlined,
} from "@mui/icons-material";
import CustomDatePicker from "../../common/CustomDatePicker";
import CustomTextField from "../../common/CustomTextField";
import Header from "../../common/Header";
import Card from "../../common/Card";
import SignaturePad, {
  SignatureData,
} from "../../common/signature/SignaturePad";
import { useAppDispatch } from "../../../redux/slice/redux-hooks";
// import { previewAgreement, useCreateAgreementSlice } from "../../../redux/slice/productAgreement/createAgreementSlice";
import PDFViewer from "../../common/pdf/PDFViewer";
import { SingleAlertInfo, setSingleAlertObj } from "../../../redux/slice/commonSlice";
import OptionsPopup, { OptionPopupObjectProps } from "../../common/OptionsPopup";
import authSlice, { logout, relogin, updateElementInAuthSlice, useAuthSlice } from "../../../redux/slice/authSlice";
import { fullName } from "../../../utils/Utility";
import { baaDetailSign, previewAgreement, resetBusinessAgreementsList, useCreateBusinessAgreementSlice } from "../../../redux/slice/baa/createBusinessAgreementSlice";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { setDataToLocalStorage } from "../../../utils/StorageUtils";
import { CAN_ACCESS_APPLICATION } from "../../../constants/applicationConstants";
import { GridCheckCircleIcon } from "@mui/x-data-grid";
import { tokens } from "../../../theme";

type BaaAgreement = {
  // product: string;
  // effectiveDate: string;
  signature: string,
  name: string
};

const initialBaaAgreement: BaaAgreement = {
  // product: "",
  // effectiveDate: "",
  signature: "",
  name: ""
};

const facts = [
  { title: "As part of our commitment to compliance with the Health Insurance Portability and Accountability Act (HIPAA), all users who access this system must sign our revised Business Associate Agreement (BAA). By signing this agreement, you acknowledge and agree to the terms and conditions for the proper use, security, and confidentiality of PHI." },
  { title: "Please review the BAA carefully by clicking the preview button. If you agree to the terms, proceed by signing the document." },
];

interface BaaAgreementProps {}

const BaaAgreement: React.FC<BaaAgreementProps> = () => {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { control, handleSubmit, reset, getValues, setValue } =
    useForm<BaaAgreement>({
      defaultValues: initialBaaAgreement,
    });

  const { userInfo } = useAuthSlice();
  const location = useLocation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const baaDetailId = location.state?.baaDetailId;
  const searchBaaDetails = location.state?.searchBaaDetails;
  // const from = '/dashboard';
  const [showPDF, setShowPDF] = React.useState(false);
  const [isPreviewClicked, setIsPreviewClicked] =  React.useState(0);
  const { baaDocument } = useCreateBusinessAgreementSlice();
  const [showOptionPopup, setShowOptionsPopup] = React.useState<OptionPopupObjectProps>({ message: "", show: false });
  const [errorIdentifiers, setErrorIdentifiers] = React.useState<string[]>([]);
  const [alertShown, setAlertShown] = useState(false);
  const [isBAASkipped, setIsBAASkipped] = useState(false);
  const [isLoading, setIsLoading] = useState(true); 

  useEffect(()=>{
    if (userInfo && userInfo.canAccessApplicationValue) {
      const items = userInfo.canAccessApplicationValue.split(";");
      items.forEach(applicationName => {
        if(CAN_ACCESS_APPLICATION.BAA_MANAGEMENT === applicationName && baaDetailId) {
          setIsBAASkipped(true);
        }
      });
    } 
    setIsLoading(false);
    
  },[userInfo.canAccessApplicationValue]);

  const skipBAASigning = () => {
    dispatch(updateElementInAuthSlice({
      elementName: "userInfo",
      value: { 
        ...userInfo, 
        baaSigningSkipped: true 
      }
    }));

    navigate("/");
  }

  const handleSignatureChange = (signatureData: SignatureData) => {

    setValue("signature", signatureData.signature);
    setValue("name", signatureData.typedText);

    setErrorIdentifiers((prevValues) => {
      prevValues = prevValues.filter((value) => value !== "signature");
      return prevValues;
    });

  };

  const cancelOptionPopup = () => {
    setShowOptionsPopup({ message: "", show: false });
  };

  const cancelBAASigning = () => {
    if(baaDetailId && searchBaaDetails){
      navigate("/baa_Detail", {state: { baaListSearchCriteria : searchBaaDetails }});
    } else {
      dispatch(logout());
      navigate("/");
    }
  };

  const onPreviewClick = async () => {
    
    const signature = getValues("signature");
    const name = getValues("name");
    const data = {
      signature : signature,
      baaDetailId : baaDetailId
    }

    const actionResult = await dispatch(
      previewAgreement(data)
    );
    const documentData = actionResult.payload;
    if (documentData && documentData.content) {
      setShowPDF(true);
    }
    setIsPreviewClicked(isPreviewClicked + 1);
    cancelOptionPopup();
  };

  const loadStartingPageAfterSign = () => {


    let successObj: SingleAlertInfo = {
      message: `Business Associate Agreement has been signed`,
      alertType: "success",
    };
    dispatch(setSingleAlertObj(successObj));

    // userInfo.userSigned = true;
    //update the userInfo in authSclice

    dispatch(updateElementInAuthSlice({
      elementName: "userInfo",
      value: { 
        ...userInfo, 
        userSigned: true 
      }
    }));

  };

  const signBusinessAgreement = async (sideAgreementObj: any) => {

    const data = {
      baaDetailId : baaDetailId ? baaDetailId : null,
      signature : sideAgreementObj.signature,
      name : sideAgreementObj.name
    }

    const response = await dispatch(
      baaDetailSign(data)
    );
    if (response?.payload?.data === "SUCCESS") {
      loadStartingPageAfterSign();

      if(baaDetailId && searchBaaDetails){
        navigate("/baa_Detail", {state: { baaListSearchCriteria : searchBaaDetails }});
      } else {
        navigate("/");
      }
    } else {
      let errorObj: SingleAlertInfo = {
        message: `There is an error while signing `,
        alertType: "error",
      };
      dispatch(setSingleAlertObj(errorObj));
      dispatch(logout());
      navigate("/");
    }
    cancelOptionPopup();
  };

  const onSubmit = async (obj: any) => {

    let {
      documents,
      ...sideAgreementObj
    } = obj;
    if (isPreviewClicked <= 0 ) {
      let previewAgreementAlertObj: SingleAlertInfo = {
        message: `Please "Preview" the agreement to continue`,
        alertType: "error",
      };
      dispatch(setSingleAlertObj(previewAgreementAlertObj));
    } 
    else if (
      sideAgreementObj?.signature === "" 
    ) {
      setErrorIdentifiers((prevValues) => {
        const newValues = [...prevValues];
        newValues.push("signature");
        return newValues;
      });
    } 
    else {
      setShowOptionsPopup({
        message: "Do you really want to sign the agreement? ",
        show: true,
        variant: "confirm",
        buttons: [
          {
            name: "Confirm",
            color: "primary",
            onClick: () => signBusinessAgreement(sideAgreementObj),
          },
          { name: "Cancel", color: "secondary", onClick: cancelOptionPopup },
        ],
      });
    }
  };

  let signatureFieldName = "signature";
  let name = "name";

  const signaturePadValidations = {
    name: {
      required: "Name is required.",
    },
    signature: {
      required: "Signature is required.",
    },
  };

  return (
    <>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        padding={2}
      >
        {showOptionPopup.show && (
        <OptionsPopup
          open={showOptionPopup.show}
          onClose={() => setShowOptionsPopup({ message: "", show: false })}
          variant={showOptionPopup.variant}
          message={<>{showOptionPopup.message}</>}
          buttons={showOptionPopup.buttons}
          title={showOptionPopup.title}
          obj={showOptionPopup.obj}
        />
      )}
      {showPDF && <PDFViewer
        isOpen={showPDF}
        onClose={() => setShowPDF(false)}
        title={"Business Associate Agreement"}
        base64String={baaDocument?.content || ""}
        fileName={"Business Associate Agreement" + userInfo.userId}
      />}

      <Box m="20px">
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <Container
            component="main"
            maxWidth="lg"
            style={{ paddingBottom: "50px" }}
          >
            <Card>
              <Box style={{ textAlign: "center" }}>
              <img
                alt="logo"
                src={require("../../../assets/V3_logo-gradient.png")}
              />
              <Header
                title="BAA Agreement"
                subtitle=''
              />
              </Box>
            
              <Box mt={4}>
                  {facts.map((item, index) => (
                    <Box
                      key={index}
                      sx={{
                        display: "flex",
                        alignItems: "self-start",
                        marginBottom: "10px",
                      }}
                    >
                      <Box
                        sx={{
                          width: "30px",
                          height: "30px",
                          backgroundColor: colors.grey[700],
                          borderRadius: "50%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          mr: 1,
                          p:1
                        }}
                      >
                        <CheckCircle
                          sx={{
                            color: colors.grey[500],
                            fontSize: "20px"
                          }}
                        />
                      </Box>
                      <Typography variant="body1" sx={{ml:1, pt:"3px"}}>{item.title}</Typography>
                    </Box>
                  ))}
                </Box>

                <form onSubmit={handleSubmit(onSubmit)}>
                  <Grid
                    container
                    spacing={2}
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    mt={2}
                  >
                    <Grid item xs={12} md={8}>
                      <SignaturePad
                        isError={errorIdentifiers?.includes("signature")}
                        onSignatureChange={handleSignatureChange}
                        name={signatureFieldName}
                        textName={name}
                        control={control}
                        validationRules={signaturePadValidations}
                        setSignOutside={
                          userInfo &&
                          fullName(
                            null,
                            userInfo.firstName,
                            userInfo.middleName,
                            userInfo.lastName
                          )
                        }
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}  justifyContent="center" sx={{mt:1}}>
                    {!isBAASkipped && !isLoading &&
                    <Grid item md={6} xs={12}>
                      <React.Fragment>
                        <Stack
                          direction={{ xs: "column", sm: "row" }}
                          spacing={2}
                        >
                            <Grid item xs={12} md={4}>
                              <Button fullWidth 
                                color="warning" 
                                onClick={skipBAASigning}>
                                  Skip&nbsp;for&nbsp;Now
                              </Button>
                            </Grid>
                        </Stack>
                      </React.Fragment>
                    </Grid>
                    }
                    <Grid item md={6} xs={12}>
                      <React.Fragment>
                        <Stack
                          direction={{ xs: "column", sm: "row" }}
                          justifyContent="flex-end"
                          spacing={2}
                        >
                          <Grid item xs={12}>
                            <Button
                              fullWidth
                              startIcon={<VisibilityOutlined />}
                              color="info"
                              onClick={onPreviewClick}
                            >
                              Preview
                            </Button>
                          </Grid>
                          <Grid item xs={12}>
                            <Button
                              fullWidth
                              type="submit"
                              startIcon={<DriveFileRenameOutline />}
                              data-allow-submit="true"
                            >
                              Sign
                            </Button>
                          </Grid>
                          <Grid item xs={12}>
                            <Button fullWidth startIcon={<Cancel />} color="error"
                              onClick={ cancelBAASigning} >
                              
                              Cancel
                            </Button>
                          </Grid>
                        </Stack>
                      </React.Fragment>
                    </Grid>
                  </Grid>
                </form>
            </Card>
          </Container>
        </Grid>
      </Box>
      </Box>
    </>
  );
};

export default BaaAgreement;
