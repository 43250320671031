import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { useAppSelector } from "../redux-hooks";
import { getBaaDetailsList, previewBusinessAgreement, signBAADetail, uploadBusinessAgreement } from "../../../service/BaaDetailService";
import { updateElementValue } from "../../../utils/updateElementValue";

export type SearchBaaRequest = {
    userRoleIds: (number | string)[];
    entityIds: number[];
    firstName: string;
    lastName: string;
    status: string[];
    currentPage: number;
    itemsPerPage: number;
}

export type BaaDetailDTO = {
    baaDetailId: number,
    userId: number,
    email: string,
    userType: number,
    practiceId: number,
    inactiveDate: string,
    agreementType: string,
    agreementStatus: string,
    agreementDocumentId: number
}


export type DocumentInfo = {
    documentName: string;
    contentPath: string;
    type: any;
    fileType: string;
    content: string;
}

type CreateBusinessAgreement = {
    userSignedDate: string | null;
    adminSignedDate: string | null;
};

type CreateBusinessAgreementState = {

    createBusinessAgreement: CreateBusinessAgreement | null;
    baaList?: {
        content: BaaDetailDTO[],
        totalElements: number,
        totalPages: number,
        size: number

    },
    baaDocument?: DocumentInfo | null;
    status: string;
    error: string | null;
    isValid: boolean;
}

export type UploadBaaInfo = {
    practiceId?: number,
    userId?: number,
    documentName: string,
    content: string,
    type: string,
    fileType: string
}

let initialState: CreateBusinessAgreementState = {
    createBusinessAgreement: {
        userSignedDate: null,
        adminSignedDate: null
    },
    status: "idle",
    error: null,
    isValid: false,
};


export const searchBaaDetails = createAsyncThunk('searchBaaDetails', async (searchBaaRequest: SearchBaaRequest) => {
    const response = await getBaaDetailsList(searchBaaRequest);
    const v3Response = response.data;
    return v3Response.data;
})

export const baaDetailSign = createAsyncThunk("baaDetailSign", async(data: any) => {
    const response = await signBAADetail(data);
    const v3Response = response.data;
    return v3Response; 
});

export const previewAgreement = createAsyncThunk('previewAgreement',async(data?:any) => {
    const response = await previewBusinessAgreement(data);
    const v3Response = response.data;
    return v3Response.data;
});

export const uploadSignedBaa = createAsyncThunk('uploadSignedBaa',async(data?:UploadBaaInfo) => {
    const response = await uploadBusinessAgreement(data);
    const v3Response = response.data;
    return v3Response.data;
});

const createBusinessAgreementSlice = createSlice({
    name: "createBusinessAgreementSlice",
    initialState,
    reducers: {
        updateElementInBusinessAgreement: updateElementValue,
        updateCreateAgreementObject: (state: CreateBusinessAgreementState, action) => {
            // state.createAgreement = action.payload;
        },
        resetBusinessAgreementsList: (state) => {
            state.createBusinessAgreement = null
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(baaDetailSign.pending, (state) => {
                state.status = "loading";
                state.error = null;
            })
            .addCase(baaDetailSign.fulfilled, (state, action) => {
                state.status = "success";
                state.error = null;
            })
            .addCase(baaDetailSign.rejected, (state, action) => {
                state.status = "rejected"
            })
            .addCase(previewAgreement.pending, (state) => {
                state.status = "loading";
                state.error = null;
            })
            .addCase(previewAgreement.fulfilled, (state, action) => {
                state.status = "success";
                state.baaDocument = action.payload;
                state.error = null;
            })
            .addCase(previewAgreement.rejected, (state, action) => {
                state.status = "rejected"
            })
            .addCase(searchBaaDetails.pending, (state) => {
                state.status = "loading";
                state.error = null;
            })
            .addCase(searchBaaDetails.fulfilled, (state, action) => {
                state.status = "success";
                state.baaList = action.payload;
                state.error = null;
            })
            .addCase(searchBaaDetails.rejected, (state, action) => {
                state.status = "rejected"
            })
            .addCase(uploadSignedBaa.pending, (state) => {
                state.status = "loading";
                state.error = null;
            })
            .addCase(uploadSignedBaa.fulfilled, (state, action) => {
                state.status = "success";
                state.error = null;
            })
            .addCase(uploadSignedBaa.rejected, (state, action) => {
                state.status = "rejected"
            })
    }
});

export const useCreateBusinessAgreementSlice = () => useAppSelector((state) => state.createBusinessAgreementSlice);
export const { updateElementInBusinessAgreement, updateCreateAgreementObject, resetBusinessAgreementsList } = createBusinessAgreementSlice.actions;
export default createBusinessAgreementSlice.reducer;