import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GeneralV3Response, LoadingType } from "../../../types/CommonTypes";
import { fetchPracticeProductsById, fetchPracticeProductsWithoutDetailsById, fetchPracticeProductsByIdUsingDateOfServiceAndProvider } from "../../../service/PracticeService";
import { useAppSelector } from "./../redux-hooks";

export type PracticeProductInfo = {
    productId: string;
    discount?: any;
    productName: string;
    discountedPrice: any;
    agreementStatus: string;
    practiceProductId: string;
    billingType: string;
    providerName?: string;
    practiceProviderId?: string
};

export type FetchPracticeProductsParams = {
  practiceId: number;
  dateOfService?: string;
  providerId?: number;
};

type PracticeProductState = {
    practiceProductsInfo?: PracticeProductInfo[] | null;
    practiceProductsDataFetchStatus?: LoadingType
  };

  const initialState: PracticeProductState = {
    practiceProductsInfo: undefined,
    practiceProductsDataFetchStatus: "idle",
  };

  export const fetchPracticeProductsData = createAsyncThunk("fetchPracticeProductsData", async (practiceId: string) => {
    const response = await fetchPracticeProductsById(practiceId);
    return response.data;
  });

  export const fetchPracticeProductsDataWithoutDetails = createAsyncThunk("fetchPracticeProductsDataWithoutDetails", async (practiceId: string) => {
    const response = await fetchPracticeProductsWithoutDetailsById(practiceId);
    return response.data;
  });

export const fetchPracticeProductsDataUsingDateOfServiceAndProvider = createAsyncThunk("fetchPracticeProductsDataUsingDateOfServiceAndProvider", async ({ practiceId, dateOfService, providerId }: FetchPracticeProductsParams) => {
    const response = await fetchPracticeProductsByIdUsingDateOfServiceAndProvider(practiceId, dateOfService, providerId);
    return response.data;
  });

  const practiceProductsSlice = createSlice({
    name: "practiceProducts",
    initialState,
    reducers: {
      updatePracticeProductsInfoObject: (state: PracticeProductState, action) => {
        state.practiceProductsInfo = action.payload;
      },
    },
    extraReducers: (builder) => {
      builder
        .addCase(fetchPracticeProductsData.pending, (state) => {
          state.practiceProductsDataFetchStatus = "loading";
        })
        .addCase(fetchPracticeProductsData.fulfilled, (state, action: PayloadAction<GeneralV3Response>) => {
          state.practiceProductsDataFetchStatus = "success";
          state.practiceProductsInfo = action.payload.data;
        })
        .addCase(fetchPracticeProductsData.rejected, (state, action) => {
          state.practiceProductsDataFetchStatus = 'error';
        })
        .addCase(fetchPracticeProductsDataWithoutDetails.pending, (state) => {
          state.practiceProductsDataFetchStatus = "loading";
        })
        .addCase(fetchPracticeProductsDataWithoutDetails.fulfilled, (state, action: PayloadAction<GeneralV3Response>) => {
          state.practiceProductsDataFetchStatus = "success";
          state.practiceProductsInfo = action.payload.data;
        })
        .addCase(fetchPracticeProductsDataWithoutDetails.rejected, (state, action) => {
          state.practiceProductsDataFetchStatus = 'error';
        })
        .addCase(fetchPracticeProductsDataUsingDateOfServiceAndProvider.pending, (state) => {
          state.practiceProductsDataFetchStatus = "loading";
        })
        .addCase(fetchPracticeProductsDataUsingDateOfServiceAndProvider.fulfilled, (state, action: PayloadAction<GeneralV3Response>) => {
          state.practiceProductsDataFetchStatus = "success";
          state.practiceProductsInfo = action.payload.data;
        })
        .addCase(fetchPracticeProductsDataUsingDateOfServiceAndProvider.rejected, (state, action) => {
          state.practiceProductsDataFetchStatus = 'error';
        });
    },
  });

export const { updatePracticeProductsInfoObject } = practiceProductsSlice.actions;
export const usePracticeProductsSlice = () => useAppSelector((state) => state.practiceProductsSlice);
export default practiceProductsSlice.reducer;