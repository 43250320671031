import React, { useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import Button from "../../common/Button";
import { Box, Grid, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { Cancel, SaveAlt } from "@mui/icons-material";
import { convertBase64 } from "../../../utils/Utility";
import Dropzone, { ExtendedFile } from "../../common/Dropzone";
import { BAA_CONTRACT_TYPES, pdf } from "../../../constants/applicationConstants";
import { uploadSignedAgreementById } from "../../../redux/slice/productAgreement/createAgreementSlice";
import { useAppDispatch } from "../../../redux/slice/redux-hooks";
import { setSingleAlertObj, SingleAlertInfo } from "../../../redux/slice/commonSlice";
import { UploadBaaInfo, uploadSignedBaa } from "../../../redux/slice/baa/createBusinessAgreementSlice";




interface UploadBaaPopup {
    open: boolean;
    onClose: () => void;
    userEntity: "PRACTICE" | "USER";
    details: any;
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const UploadBaaPopup: React.FC<UploadBaaPopup> = ({
    open,
    onClose,
    userEntity,
    details
}) => {
    const { control, handleSubmit,getValues, reset, setValue, watch } = useForm<UploadBaaInfo>({});
    const dispatch = useAppDispatch();

    useEffect(() => {
        if(userEntity === BAA_CONTRACT_TYPES.PRACTICE) {
            setValue("practiceId", details.practiceId);
        } else if(userEntity === BAA_CONTRACT_TYPES.USER) {
            setValue("userId", details.userId);
        }
    }, [open])

    const onSubmit = async (data: UploadBaaInfo) => {
        const response = await dispatch(uploadSignedBaa(data));
         if (response.meta.requestStatus === 'fulfilled') {
             let successObj: SingleAlertInfo = {
                 message: `Business Association Agreement for ${userEntity=== BAA_CONTRACT_TYPES.PRACTICE ? 
                    details?.practiceName : "(subcontractor version) " + details?.userName} has been successfully uploaded.`,
                 alertType: "success"
             }
             dispatch(setSingleAlertObj(successObj));
             onClosePopup();
        };
    };

    const handleDropzoneChange = async (uploadedFiles: ExtendedFile[], fieldName: any) => {
        if (uploadedFiles && uploadedFiles.length > 0) {
            const base64File = String(await convertBase64(uploadedFiles[0]));
            const fileName = uploadedFiles[0].name;
            const fileExtension = fileName.slice(fileName.lastIndexOf('.') + 1).toLowerCase();
            const base64Substring = base64File.substring("data:application/pdf;base64,".length, base64File.length);
            setValue('content', base64Substring);
            setValue('documentName', fileName);
            setValue('fileType', fileExtension);
            setValue('type', "BUSINESS_ASSOCIATE_AGREEMENT")
        }
    };

    const handleDeleteFile = () => {
        setValue('content', "");
        setValue('documentName', "");
        setValue('fileType', "");
        setValue('type', "");
    };

    
  const onClosePopup = () => {
    reset();
    onClose();
  }

    return (
        <>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={onClosePopup}
                aria-describedby="alert-dialog-slide-description"
                onClick={(event) => {
                    event.stopPropagation();
                }}
                fullWidth
            >
                <form onSubmit={handleSubmit(onSubmit)}>
                    <DialogTitle>Upload Business Association Agreement{userEntity === BAA_CONTRACT_TYPES.USER ? " (subcontractor version)" : ""}</DialogTitle>
                    <DialogContent>
                        <Box p={1}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography component="label" fontWeight="bold">
                                        Name :
                                    </Typography>
                                    <Typography component="label" style={{ paddingLeft: "20px" }}>
                                        { userEntity=== BAA_CONTRACT_TYPES.PRACTICE ? details?.practiceName : details?.userName}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Dropzone
                                        name="content"
                                        onFileChange={() => { }}
                                        onFileChangeTest={handleDropzoneChange}
                                        onDeleteFile={handleDeleteFile}
                                        control={control}
                                        maxFileCount={1}
                                        allowedFileTypes={pdf}
                                        label="Attach Business Association Agreement"
                                        rules={{required: "Business Association Agreement is required."}}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Grid
                            container
                            justifyContent="flex-end"
                            sx={{ flexWrap: "wrap" }}
                            spacing={1}
                            p={2}
                        >
                            <Grid item xs={12} sm="auto">
                                <Button type="submit" startIcon={<SaveAlt />} fullWidth>
                                    Upload
                                </Button>
                            </Grid>
                            <Grid item xs={12} sm="auto">
                                <Button color="error" startIcon={<Cancel />} onClick={onClose} fullWidth>
                                    Cancel
                                </Button>
                            </Grid>
                        </Grid>
                    </DialogActions>
                </form>
            </Dialog>
        </>
    );
};

export default UploadBaaPopup;
