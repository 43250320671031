import React, { useCallback, useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import Button from "./Button";
import { Cancel, VpnKey } from "@mui/icons-material";
import { useForm } from "react-hook-form";
import {
  MainErrorInfo,
  SingleAlertInfo,
  setMainErrorObj,
  setSingleAlertObj,
} from "../../redux/slice/commonSlice";
import { useAppDispatch } from "../../redux/slice/redux-hooks";
import { Box, Grid } from "@mui/material";
import CustomPasswordTextField from "./CustomPasswordTextField";
import CustomTextField from "./CustomTextField";
import OptionsPopup from "./OptionsPopup";
import { ResetPassword, clearResetPasswordObject, resetPassword, useUserReducer } from "../../redux/slice/userSlice";
import { useNavigate } from "react-router-dom";
import { inactivateAgency } from "../../service/AgencyService";
import { inactivateAgencyById } from "../../redux/slice/agency/AgencySlice";

interface DeleteAgencyPopupProps {
  open: boolean;
  onClose: () => void;
  agencyData: any;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const DeleteAgencyPopup: React.FC<DeleteAgencyPopupProps> = ({
  open,
  onClose,
  agencyData,
}) => {
  const { control, handleSubmit, reset, setValue, watch, setError } = useForm({});
  const dispatch = useAppDispatch();
  const [enableButton, setEnableButton] = useState(true);
  const [inputData, setInputData] = useState("")
  const { resetPasswordObject } = useUserReducer();

  const navigate = useNavigate();
  // const defaultValues = useCallback(() => {
  //   if (resetPasswordObject) {
  //     reset(resetPasswordObject);
  //   } else {
  //     reset({
  //       // userId,
  //       // email,
  //       // password: '',
  //       // confirmPassword: '',
  //     });
  //   }
  // }, [resetPasswordObject, reset, agencyName, agencyId]);

  // const onSubmit = (data: any) => {
  //   let errorObj: SingleAlertInfo = {
  //     message: "Feature currently under development. Stay tuned for updates!",
  //     alertType: "info",
  //   };
  //   dispatch(setSingleAlertObj(errorObj));
  //   onClose();
  //   setConfirmOpen(true);
  // };

  const onSubmit = async () => {
    await dispatch(inactivateAgencyById(agencyData?.agencyId));
    onClose();
  };

  // const handleChangePassword = async (data: ResetPassword, successMessage: string, errorMessage: string) => {
  //   const resetPWData = {
  //       userId: userId,
  //       password: data.password,
  //       email: '',
  //       confirmPassword: '',
  //       oldPassword: '',
  //   };

  //   try {
  //       const response = await dispatch(resetPassword(resetPWData));

  //       if (response.payload && response.payload.message !== "SUCCESS") {
  //           const alertObj: SingleAlertInfo = {
  //               message: successMessage,
  //               alertType: "success",
  //           };
  //           dispatch(setSingleAlertObj(alertObj));
  //       } else {
  //           const alertObj: SingleAlertInfo = {
  //               message: errorMessage,
  //               alertType: "error",
  //           };
  //           dispatch(setSingleAlertObj(alertObj));
  //       }
  //       return response;
  //   } catch (error) {
  //       console.error("Error resetting password:", error);
  //       throw error;
  //   }
  // };

  const handleClose = () => {
    onClose();
    // defaultValues();
  };
  useEffect(() => {
    if (inputData === agencyData?.agencyName) {
      setEnableButton(false);
    } else {
      setEnableButton(true);
    }

  }, [agencyData?.agencyName, inputData])
  // useEffect(() => {
  //   defaultValues();
  // }, [defaultValues]);

  // useEffect(() => {
  //   return () => {
  //     dispatch(clearResetPasswordObject());
  //   };
  // }, [dispatch]);

  return (
    <>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={onClose}
        aria-describedby="alert-dialog-slide-description"
        onClick={(event) => {
          event.stopPropagation();
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle>Inactivate Agency</DialogTitle>
          <DialogContent>
            <Box p={1}>
              <Grid container spacing={2}>
                <Grid item md={12} xs={12}>
                  {`Are you sure you want to inactivate agency "${agencyData?.agencyName}" ?`}
                </Grid>
                <Grid item md={12} xs={12}>
                  <span style={{ color: "red" }}>The data provided below will also get affected.</span>
                </Grid>
                <Grid item md={12} xs={12}>
                  {agencyData?.practices?.length > 0 ? (<>
                    Practice :  [{agencyData?.practices?.map((data: any) => data).join(', ')}]</>) : (
                    ''
                  )}
                </Grid>
                <Grid item md={12} xs={12}>
                  {agencyData?.agencyUsers?.length > 0 ? (
                    <>Users : [{agencyData?.agencyUsers?.map((data: any) => data).join(', ')}]</>) : (
                    ''
                  )}
                </Grid>
                <Grid item md={12} xs={12}>
                  {agencyData?.productCommissions?.length > 0 ? (
                    <> Products : [{agencyData?.productCommissions?.map((data: any) => data).join(', ')}]</>) : (
                    ''
                  )}
                </Grid>
                <Grid item md={12} xs={12}>
                  <span>Type <span style={{ color: '#66bb6a', fontSize: '20px' }}> {agencyData?.agencyName} </span> to confirm your action. </span>
                </Grid>
                <Grid item md={12} xs={12}>
                  <CustomTextField
                    control={control}
                    controllerName="agencyName"
                    label="Agency Name"
                    onChange={(e) => {
                      const inputAgencyName = e.target.value;
                      setInputData(inputAgencyName);
                    }}
                  // isDisabled={true}
                  // value={email}
                  // rules={{
                  //   required: "Email is required.",
                  //   pattern: {
                  //     value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i,
                  //     message: "Invalid email.",
                  //   },
                  // }}
                  />
                </Grid>
              </Grid>
            </Box>
          </DialogContent>
          <DialogActions>
            <Grid container justifyContent="flex-end" spacing={2} pt={2}>
              <Grid item xs={12} sm="auto">
                <Button type="submit" disabled={enableButton} startIcon={<VpnKey />} fullWidth>
                  Inactivate
                </Button>
              </Grid>
              <Grid item xs={12} sm="auto">
                <Button color="error" startIcon={<Cancel />} onClick={() => handleClose()} fullWidth>
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </form>
      </Dialog>
      {/* <OptionsPopup
        open={confirmOpen}
        onClose={() => setConfirmOpen(false)}
        onSubmit={onSubmit}
        variant="confirm"
        message="Are you sure you want to reset the password?"
        buttons={[
          { name: "Confirm", color: "primary", onClick: () => onSubmit },
          { name: "Cancel", color: "secondary", onClick: () => setConfirmOpen(false) }
        ]}
      /> */}
    </>
  );
};

export default DeleteAgencyPopup;
