import { Box, Divider, FormControlLabel, Grid, InputLabel, Switch, Typography, useTheme, } from "@mui/material";
import Header from "../../common/Header";
import Card from "../../common/Card";
import Button from "../../common/Button";
import {
  AddCircleOutlineOutlined, CancelOutlined, DeleteOutlineOutlined, DoDisturbAltOutlined, Download,
  ModeOutlined, Restore, SaveOutlined, ToggleOffOutlined, ToggleOnOutlined, Upgrade,
} from "@mui/icons-material";
import BorderedSection from "../../common/BorderedSection";
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import ProductItemPopup from "./ProductItemPopup";
import ProductItemPricePricePopup from "./ProductItemPricePopup";
import {
  DocumentItemInfo, ProductInfo, ProductItemInfo, ProductItemPriceInfo, createProduct, deleteProductPriceObj, fetchProductDocumentsByProductId, getProduct,
  inactivateProductPrice, initialValuesOfProductItem, initialValuesOfProductItemPrice, resetCreateProductSlice,
  resetFetchStatus, saveProductItem, saveProductLogo, saveProductPriceItem, updateProductDetails, useProductReducer
} from "../../../redux/slice/product/createProductSlice";
import { Controller, useForm } from "react-hook-form";
import CustomTextField from "../../common/CustomTextField";
import CustomDatePicker from "../../common/CustomDatePicker";
import moment from "moment";
import { useAppDispatch } from "../../../redux/slice/redux-hooks";
import { useNavigate } from "react-router-dom";
import CustomDropdownField from "../../common/CustomDropdownFiled";
import CustomTable from "../../common/CustomTable";
import { CodeTable, getCodeTableValuesByTableName, useCodetablesSlice } from "../../../redux/slice/codetableSlice";
import { calculateValue, hideElement } from "../../../utils/Utility";
import { fetchMac, fetchManufacturersList, useCommonReducer, setSingleAlertObj, SingleAlertInfo, hideSingleAlert } from "../../../redux/slice/commonSlice";
import { PRODUCTS_DOCUMENT_TYPE, png } from "../../../constants/applicationConstants";
import { FileUploader } from "../../common/FileUploader";
import ProductItemHistoryPopup from "./ProductItemHistoryPopup";
import ProductItemPriceHistoryPopup from "./ProductItemPriceHistoryPopup";
import OptionsPopup, { OptionPopupObjectProps } from "../../common/OptionsPopup";
import CustomCurrencyField from "../../common/CustomCurrencyField";
import DatePickerPopup from "../../common/DatePickerPopup";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { selectIsTokenAvailable } from "../../../redux/slice/authSlice";
import { fetchDocumentById, fetchTemplateById } from "../../../redux/slice/practiceSlice";
import IconDetailToolBar from "../../common/IconDetailToolBar";
import { tokens } from "../../../theme";
import CustomIconButton from "../../common/CustomIconButton";
import dayjs from "dayjs";
import CustomSwitchButton from "../../common/CustomSwitchButton";

type Column = {
  id: string;
  label: string;
  minWidth?: number;
  align?: "right" | "center" | "left";
  format?: (value: number) => string;
}

const Product = () => {

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [productItemObject, setProductItemObject] = useState<ProductItemInfo>(initialValuesOfProductItem);
  const [priceItemObject, setPriceItemObject] = useState<ProductItemPriceInfo>(initialValuesOfProductItemPrice);
  const [tableRowIndex, setTableRowIndex] = useState<number | null>(null);
  const [editForm, setEditForm] = useState(false);
  const [editItem, setEditItem] = useState(false);
  const [inactiveOnly, setInactiveOnly] = useState(false);

  const [showProductItemPopup, setShowProductItemPopup] = useState(false);
  const [showProductItemPricePopup, setShowProductItemPricePopup] = useState(false);

  const [showProductItemHistoryPopup, setShowProductItemHistoryPopup] = useState(false);
  const [showProductItemPriceHistoryPopup, setShowProductItemPriceHistoryPopup] = useState(false);

  const [productPriceRows, setProductPriceRows] = useState<any[]>([]);

  const [optionPopupShow, setOptionPopupShow] = useState<OptionPopupObjectProps>({ message: "", show: false });

  const [datePickerPopupObj, setDatePickerPopupObj] = useState
                                                  <{label: string, title: string, open: boolean, validationArray: any, submitDate: (date: string)=> void}>
                                                  ({label: "", title: "", open: false, validationArray: [], submitDate: (date: string)=> {}})

  const { control, handleSubmit, getValues, setValue, watch, reset } = useForm<ProductInfo>();

  const { productObject, getProductStatus, saveProductItemStatus, saveProductPriceStatus,
    updateProductStatus, saveProductLogoStatus, productDocumentInfo, createProductStatus } = useProductReducer();
  const { allCodeTablesValuesMap } = useCodetablesSlice();
  const { macInfoList, manufacturerInfoList } = useCommonReducer();

  // validate user level authorizations
  const canEditProduct = useSelector((state: RootState) => selectIsTokenAvailable("canEditProduct")(state));
  const canViewProductHistory = useSelector((state: RootState) => selectIsTokenAvailable("canViewProductHistory")(state));

  const manufacturersList = useMemo(() => {
    let options: { label: string, value: any }[] = [];
    manufacturerInfoList?.map((option) => {
      options.push({ label: option.manufacturerName, value: option.manufacturerId })
    });
    return options;
  }, [manufacturerInfoList]);

  const discounts = useMemo(() => {
    if (allCodeTablesValuesMap)
      return getCodeTableValuesByTableName(allCodeTablesValuesMap, "ProductDiscount")
    return [];
  }, [allCodeTablesValuesMap]);

  // added discount prices to the ProductItemPrice table data
  const generateProductItemPriceRows = useCallback((productPrices: any) => {
    let modifiedArray: any[] = [];
    //@ts-ignore
    productPrices?.forEach(obj => {
      let modifiedObj = setDiscountPricesToTable(obj, discounts);
      if (modifiedObj.priceType === "MAC") {
        let macName = macInfoList?.find(option => option.macId === obj.macId)?.macName;
        modifiedObj["priceType"] = "Local Fee Schedule" + (macName ? " - " + macName : "");
      } else if (modifiedObj.priceType === "NATIONAL") {
        modifiedObj["priceType"] = "National";
      }
      modifiedArray.push(modifiedObj);
    });
    return modifiedArray;
  }, [discounts, macInfoList]);

  const setDiscountPricesToTable = (data: any, discounts: CodeTable[]) => {
    let obj = {
      ...data,
      listPrice: <CustomCurrencyField externalValue={data?.listPrice} displayType="text" />,
      customPrice: <CustomCurrencyField externalValue={data?.customPrice} displayType="text" />,
      allowableUnitPrice: <CustomCurrencyField externalValue={data?.allowableUnitPrice} displayType="text" />,
      inactiveDate: data.inactiveDate !== "" ? data.inactiveDate : "-"

    }
    discounts?.map((discount) => {
      obj[discount.displayValue] = <CustomCurrencyField externalValue={calculateValue(discount.displayValue, data?.allowableUnitPrice)} displayType="text" />
    })
    return obj;
  }

  //mount
  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    let id = urlSearchParams.get('id');
    if (id) {
      setEditForm(true);
      dispatch(getProduct({ productId: parseInt(id) }));
      dispatch(fetchProductDocumentsByProductId(parseInt(id)));
    } else {
      setValue("isAgreementRequired", true);
    }
    dispatch(fetchMac());
    dispatch(fetchManufacturersList());
  }, []);

  // unmount
  useEffect(() => () => {
    dispatch(resetCreateProductSlice());
  }, []);

  useEffect(() => {
    if (productObject.productId > 0 && getProductStatus === "success") {
      let productObj = { ...productObject };

      let docObj: DocumentItemInfo = {
        documentId: 0,
        documentName: "",
        type: "",
        fileType: "",
        content: ""
      };

      if (productObj.documents && productObj.documents.length > 0) {
        for (let i: number = 0; i < productObj.documents.length; i++) {
          docObj = productObj.documents[i];
          if (docObj && docObj.type === "PRODUCTLOGO" && docObj.content && docObj.content.length > 0) {
            productObj.productLogo = "data:image/png;base64," + docObj.content;
          }
        }
      }

      reset(productObj);
      setProductPriceRows(generateProductItemPriceRows(productObject.productPrices))
    }
  }, [productObject, getProductStatus]);

  useEffect(()=>{
    if (saveProductItemStatus === "success"){
      setValue("productItems", productObject.productItems);
    }
  },[saveProductItemStatus])

  useEffect(()=>{
    if (saveProductPriceStatus === "success"){
      setProductPriceRows(generateProductItemPriceRows(productObject.productPrices))
    }
  },[saveProductPriceStatus])

  useEffect(() => {
    if (!showProductItemPopup)
      setProductItemObject(initialValuesOfProductItem);
  }, [showProductItemPopup]);

  useEffect(() => {
    if (!showProductItemPricePopup)
      setPriceItemObject(initialValuesOfProductItemPrice);
  }, [showProductItemPricePopup]);

  useEffect(() => {
    if (createProductStatus === "success") {
      let Obj: SingleAlertInfo = {
        title: "Success",
        message: `Product: ${getValues("productName")} has been created successfully.`,
        alertType: "success",
      }
      dispatch(setSingleAlertObj(Obj));
    }
    else if (createProductStatus === "error") {
      let Obj: SingleAlertInfo = {
        title: "Error",
        message: `Product: ${getValues("productName")} creation unsuccessful.`,
        alertType: "error",
      }
      dispatch(setSingleAlertObj(Obj));
    }
  }, [createProductStatus]);

  useEffect(()=>{
    if (updateProductStatus ==="success"){
      let Obj: SingleAlertInfo = {
        title: "Success",
        message: "Successfully updated the product details",
        alertType:"success",
      }
      dispatch(setSingleAlertObj(Obj));
    }
    if (saveProductLogoStatus ==="success"){
      let Obj: SingleAlertInfo = {
        title: "Success",
        message: "Successfully save the product logo",
        alertType:"success",
      }
      dispatch(setSingleAlertObj(Obj))
    }
    setTimeout(()=>{
      dispatch(hideSingleAlert());
    },5000)
    dispatch(resetFetchStatus());
  },[updateProductStatus, saveProductLogoStatus]);

  const onConfirmInactivateProductPrice = useCallback(async (obj: { date: string, productPriceId: number }) => {
    setOptionPopupShow({ message: "", show: false });
    continueInactivateFuther(obj.date, obj.productPriceId);
  }, []);

  const continueInactivateFuther = useCallback((date: string, productPriceId: number) => {
    dispatch(inactivateProductPrice({ productPriceId: productPriceId, date: date }));
    closePricingPopup();
  }, []);

  const onInactivePriceClick = useCallback((row: any, rowIndex: number) => {
    setTableRowIndex(rowIndex);
    setEditItem(true);
    setInactiveOnly(true);
    setPriceItemObject(watch("productPrices") ? getValues("productPrices")[rowIndex] : initialValuesOfProductItemPrice);
    setShowProductItemPricePopup(true);
  }, []);

  const onEditClick = useCallback((row: any, rowIndex: number, fieldName: string) => {
    setTableRowIndex(rowIndex);
    setEditItem(true);
    if (fieldName === "productItems") {
      setProductItemObject(row);
      setShowProductItemPopup(true);
    } else if (fieldName === "productPrices") {
      setPriceItemObject(watch("productPrices")?getValues("productPrices")[rowIndex]: initialValuesOfProductItemPrice);
      setShowProductItemPricePopup(true);
    }
  }, []);

  const onDeleteProductItemClick = useCallback((rowIndex: number) => {
    let existingProductItem = getValues("productItems");
    existingProductItem.splice(rowIndex, 1);
    setValue("productItems", existingProductItem);
  }, []);

  const onDeleteProductPriceClick = useCallback((rowIndex: number, productPriceId: number) => {
    if (productPriceId > 0) {
      setOptionPopupShow({
        message: "Do you really want to delete this price?",
        show: true,
        variant: "custom",
        title: "Confirm",
        buttons: [{ name: "YES", color: 'primary', onClick: onConfirmDeleteProductPrice }, { name: "NO", color: 'secondary', onClick: cancelOptionPopup }],
        obj: {rowIndex: rowIndex, productPriceId: productPriceId}
      });
    } else {
      removeProductPriceFromTable(rowIndex);
    }
  }, []);

  const onConfirmDeleteProductPrice = useCallback(async (obj: { rowIndex: number, productPriceId: number }) => {
    setOptionPopupShow({ message: "", show: false });

    try {
      let response = await dispatch(deleteProductPriceObj({ productPriceId: obj.productPriceId }));
      
      if (response.payload && response.payload.code === "V3OK" && response.payload.message === "SUCCESS"
        && (!response.payload.errors || response.payload.errors.length === 0)) {
        let Obj: SingleAlertInfo = {
          title: "Success",
          message: "Successfully deleted the pricing.",
          alertType: "success",
        }
        dispatch(setSingleAlertObj(Obj));
      }
    } catch (error) {
      console.log("response.error");
      console.log(error);
    }
  }, []);

  const removeProductPriceFromTable = useCallback((rowIndex: number) => {
    let existingProductItemPrice = getValues("productPrices");
    existingProductItemPrice.splice(rowIndex, 1);
    setValue("productPrices", existingProductItemPrice);
    setProductPriceRows(generateProductItemPriceRows(existingProductItemPrice));
  }, []);

  const addProductItem = useCallback((productItem: ProductItemInfo) => {
    if (editForm)
      saveProductItems(productItem)
    else {
      let existingProductItem = getValues("productItems");
      if (tableRowIndex !== undefined && tableRowIndex !== null && tableRowIndex >= 0) {
        existingProductItem[tableRowIndex] = productItem;
        setValue("productItems", existingProductItem);

      } else if (existingProductItem) {
        setValue("productItems", [...existingProductItem, productItem]);
      } else {
        setValue("productItems", [productItem]);
      }
    }
    setTableRowIndex(null);
  }, [watch("productItems"), tableRowIndex, editForm, productObject.productId]);

  const addProductItemPrice = useCallback((productItemPrice: ProductItemPriceInfo) => {
    if (editForm) {
      if (inactiveOnly) {
        if ((!productItemPrice.practiceProducts || productItemPrice.practiceProducts.length === 0)) {
          continueInactivateFuther(productItemPrice.inactiveDate, productItemPrice.productPriceId);
        } else {
          setOptionPopupShow({
            message: productItemPrice.practiceProducts.length + " number of agreements will be inactivated effective of " + productItemPrice.inactiveDate + " do you want to continue?",
            show: true,
            variant: "custom",
            title: "Confirm",
            buttons: [{ name: "YES", color: 'primary', onClick: onConfirmInactivateProductPrice }, { name: "NO", color: 'secondary', onClick: cancelOptionPopup }],
            obj: {date: productItemPrice.inactiveDate, productPriceId: productItemPrice.productPriceId}
          });
        }
      } else {
        saveProductPriceItems(productItemPrice);
        closePricingPopup();
      }
    } else {
      let existingProductItemPrice = getValues("productPrices");
      if (tableRowIndex !== undefined && tableRowIndex !== null && tableRowIndex >= 0) {
        existingProductItemPrice[tableRowIndex] = productItemPrice;
        setValue("productPrices", existingProductItemPrice);
        setProductPriceRows(generateProductItemPriceRows(existingProductItemPrice))
      } else if (existingProductItemPrice) {
        setValue("productPrices", [...existingProductItemPrice, productItemPrice]);
        setProductPriceRows(generateProductItemPriceRows([...existingProductItemPrice, productItemPrice]))
      } else {
        setValue("productPrices", [productItemPrice]);
        setProductPriceRows(generateProductItemPriceRows([productItemPrice]))
      }
      closePricingPopup();
    }
  }, [watch("productPrices"), tableRowIndex, productObject.productId, macInfoList]);

  const closePricingPopup = useCallback(() => {
    setShowProductItemPricePopup(false);
    setTableRowIndex(null);
    setEditItem(false);
    setInactiveOnly(false);
  }, []);

  const saveProductItems = useCallback((productItem: ProductItemInfo) =>{
    let Obj: ProductItemInfo= {
      ...productItem,
      inactiveDate: productItem.inactiveDate? moment(productItem.inactiveDate?.toString())?.format("MM/DD/YYYY") : "",
      productId: productObject.productId,
    };
    dispatch(saveProductItem({productItem: Obj}))
  }, [productObject]);

  const saveProductPriceItems = useCallback((productPriceItem: ProductItemPriceInfo) =>{
    let Obj: ProductItemPriceInfo= {
      ...productPriceItem,
      productId: productObject.productId,
    }
    dispatch(saveProductPriceItem({productPriceItem: Obj}));
  }, [productObject])

  const handleDropzoneChange = async (uploadedFiles: File, fieldName: any) => {
    let base64File = "";
    let fileName: string = "";
    if (uploadedFiles) {
      base64File = String(await convertBase64(uploadedFiles));
      fileName = uploadedFiles.name;
    }
    setValue(fieldName, base64File);
    
    let existingdocuments: DocumentItemInfo[] = getValues("documents");

    let docObj : DocumentItemInfo = {
      documentId: 0,
      documentName: "",
      type: "",
      fileType: "",
      content: ""
    };

    if (existingdocuments) {
      
        docObj = existingdocuments[0];
        if (fieldName === "productLogo" && docObj.type === "PRODUCTLOGO") {
          docObj.documentName = fileName;
          docObj.fileType = "png";
        } else {
          docObj.type = "PRODUCTLOGO";
          docObj.documentName = fileName;
          docObj.fileType = "png";

          existingdocuments.push(docObj);
        }
      
    } else {
      existingdocuments = [];
      docObj.type = "PRODUCTLOGO";
      docObj.documentName = fileName;
      docObj.fileType = "png";

      existingdocuments.push(docObj);
    }

    setValue("documents", existingdocuments);
    if (editForm){
      docObj.content = base64File && base64File.length > 23
      ? base64File.substring("data:image/png;base64,".length, base64File.length) : base64File;
      dispatch(saveProductLogo({logoData: {productLogoId: docObj.documentId,productId: productObject.productId, document: docObj}}));
    }
  }

  const convertBase64 = (file: any) => {
    return new Promise((resolve) => {
      let reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = function () {
        resolve(reader.result);
      };
      reader.onerror = function (error) {
        console.log("Error: ", error);
      };
    });
  }

  const confirmOptionPopup = () => {
    handleSubmit(updateProduct)()
    setOptionPopupShow({ message: "", show: false });
  }

  const cancelOptionPopup = () => {
    setOptionPopupShow({ message: "", show: false });
  }

  const updateProduct = (data: ProductInfo) =>{
    let Obj = {
      productId: data.productId,
      manufacturerId: data.manufacturerId,
      productName: data.productName,
      hcpcsCode: data.hcpcsCode,
      inactiveDate: data.inactiveDate ? dayjs(data.inactiveDate).format("MM/DD/YYYY") : "",
      productDescription: data.productDescription,
      composition: data.composition,
      insuranceCoverage: data.insuranceCoverage,
      isAgreementRequired: data.isAgreementRequired,
      mues: data.mues,
    }
    console.log(Obj)
    dispatch(updateProductDetails({product: Obj}));
  }

  const openPriceAddingPopup = useCallback(() => {
    let existingProductPriceList = getValues("productPrices");
    if (!editForm && existingProductPriceList && existingProductPriceList.length > 0) {
      for (let i: number = 0; i < existingProductPriceList.length; i++) {
        if (existingProductPriceList[i].priceType === "NATIONAL") {
          let errorObj: SingleAlertInfo = {
            message: "A National pricing is already exists. If you want to change pricing please update existing one or delete it first.",
            alertType: "error",
          };
          dispatch(setSingleAlertObj(errorObj));
          return;
        }
      }
    }
    setShowProductItemPricePopup(true);
  }, [editForm, watch("productPrices")]);

  const updateProductValues = () =>{
    {setOptionPopupShow({message: "Do You Really want to update product details? ", 
    show: true, 
    variant: "confirm", 
    buttons: [{name: "Confirm", color: 'primary', onClick:confirmOptionPopup },{name: "Cancel", color: 'secondary', onClick:cancelOptionPopup }]})}
  }

  const onSubmit = useCallback((data: ProductInfo) => {
    let obj: ProductInfo = {
      ...data,
      inactiveDate: data.inactiveDate ? dayjs(data.inactiveDate).format("MM/DD/YYYY") : "",
    }

    obj.saveMode = "FULLPRODUCT";

    console.log(obj)

    let docObj : DocumentItemInfo = {
      documentId: 0,
      documentName: "",
      type: "",
      fileType: "",
      content: ""
    };

    if (obj.documents && obj.documents.length > 0) {
      for (let i: number = 0; i < obj.documents.length; i++) {
        docObj = obj.documents[i];
        if (docObj.type === "PRODUCTLOGO") {
          docObj.content = obj.productLogo && obj.productLogo.length > 23
            ? obj.productLogo.substring("data:image/png;base64,".length, obj.productLogo.length) : obj.productLogo;
          obj.productLogo = "";
        }
      }
    }
    console.log(obj)
    dispatch(createProduct({ product: obj, navigate: navigate }));
  }, []);

  // handle product document download
  const handleProductDocumentView = async (documentId: string, entityName: string) => {
    if (entityName && entityName === PRODUCTS_DOCUMENT_TYPE.PRODUCT_DOCUMENT) {
      const actionResult = await dispatch(fetchDocumentById(documentId));
      const documentData = actionResult.payload;
      if (documentData && documentData.content) {
        downloadBase64File(documentData.content, documentData.documentName, documentData.fileType);
      }
    } else if (entityName && entityName === PRODUCTS_DOCUMENT_TYPE.PRODUCT_TEMPLATE) {
      const actionResult = await dispatch(fetchTemplateById(documentId));
      const documentData = actionResult.payload;
      if (documentData && documentData.content) {
        downloadBase64File(documentData.content, documentData.templateFileName, 'image/jpeg');
      }
    } else {
      let errorObj: SingleAlertInfo = {
        message: 'No Document found for the product',
        alertType: 'error'
      };
      dispatch(setSingleAlertObj(errorObj));
    }
  }

  // convert image string to byte array and download file
  const downloadBase64File = (base64String: string, fileName: string, mimeType: string) => {
    const byteCharacters = atob(base64String);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: mimeType });
    const blobUrl = URL.createObjectURL(blob);
    const anchor = document.createElement('a');
    anchor.href = blobUrl;
    anchor.download = fileName;
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
    URL.revokeObjectURL(blobUrl);
  }

  const productItemActionComponent = useCallback((row: any, rowIndex: number): JSX.Element => {
    return (
      <>
        <CustomIconButton color="info" size="small" variant="outlined" onClick={() => onEditClick(row, rowIndex, "productItems")}
          tooltipMessage="Edit" tooltipPlacement='bottom'>
          <ModeOutlined fontSize="small" />
        </CustomIconButton>
        {(!row.productItemId || row.productItemId === 0) ?
          <>
            {" "}
            <CustomIconButton color="error" sx={{ pl: 2 }} size="small" variant="outlined" onClick={() => onDeleteProductItemClick(rowIndex)}
              tooltipMessage="Delete" tooltipPlacement='bottom'>
              <DeleteOutlineOutlined fontSize="small" />
            </CustomIconButton>
          </>
          : null
        }
      </>
    );
  }, [editForm]);

  const priceItemActionComponent = useCallback((row: any, rowIndex: number): JSX.Element => {
    return (
      <>{!editForm ?
        <CustomIconButton color="info" size="small" variant="outlined" onClick={() => onEditClick(row, rowIndex, "productPrices")}
          tooltipMessage="Edit" tooltipPlacement='bottom'>
          <ModeOutlined fontSize="small" />
        </CustomIconButton> : null}
        {((!row.productPriceId || row.productPriceId === 0)
          || (row.productPriceId > 0 && (!row.practiceProducts || row.practiceProducts.length === 0))) ?
          <>{" "}<CustomIconButton color="error" size="small" variant="outlined"
            onClick={() => onDeleteProductPriceClick(rowIndex, row.productPriceId)} tooltipMessage="Delete" tooltipPlacement='bottom'>
            <DeleteOutlineOutlined fontSize="small" />
          </CustomIconButton></>
          : null
        }
        {editForm ?
          <>{" "}<CustomIconButton color="warning" size="small" variant="outlined"
            onClick={() => onInactivePriceClick(row, rowIndex)} 
            style={{ display: `${hideElement(row && dayjs(row.inactiveDate).isValid())}` }}
            tooltipMessage="Inactivate" tooltipPlacement='bottom'>
            <DoDisturbAltOutlined fontSize="small" />
          </CustomIconButton></>
          : null
        }
      </>
    );
  }, [editForm]);

  const productDocActionComponent = useCallback((row: any, rowIndex: number): JSX.Element => {
    return (
      <>
        <CustomIconButton color="info" size="small" variant="outlined" onClick={() => handleProductDocumentView(row.entityId, row.entityName)}
          tooltipMessage="Download" tooltipPlacement='bottom'>
          <Download />
        </CustomIconButton>
      </>
    );
  }, [editForm]);

  const handleToggleButtonChange = useCallback((e: ChangeEvent<HTMLInputElement>, row: any, rowIndex: number) => {
    const newState = e.target.checked;
    const productItems = getValues('productItems');

    let productItem = productItems.find(pi => pi.productItemId === row.productItemId);
    productItem = productItem && {...productItem, isSizeActive: newState}
    productItem && saveProductItems(productItem);

    const alertObj: SingleAlertInfo = {
      message: newState ? "Product Item is now marked as Available." : "Product Item is now marked as Unavailable.",
      alertType: newState ? "success" : "warning",
    };
    dispatch(setSingleAlertObj(alertObj));

  }, [getValues, setValue, productObject]);

  const toggleAvailabilityButton = useCallback((row: any, rowIndex: number) => {
    const isInactivateSwitchToggled = row?.isSizeActive === null ? true : row.isSizeActive;

    return (
      <CustomSwitchButton
        controllerName={`isSizeActive.${rowIndex}`}
        control={control}
        checked={isInactivateSwitchToggled}
        onChange={(e) => handleToggleButtonChange(e, row, rowIndex)}
        // color="success"
        size="small"
        sx={{
          marginLeft: 2,
          "& .MuiSwitch-switchBase": {
            color: theme.palette.error.main, // Use theme color for off state
          },
          "& .MuiSwitch-switchBase.Mui-checked": {
            color: theme.palette.success.main, // Use theme color when checked
          },
          "& .MuiSwitch-switchBase + .MuiSwitch-track": {
            backgroundColor: theme.palette.error.main, // Track color for off state
          },
          "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
            backgroundColor: theme.palette.success.main, // Track color when checked
          },
        }}
      />
    );
  }, [control, handleToggleButtonChange]);
  
  const columnsItem: Column[] = useMemo(() => {
    let columnArray: Column[] = [
      { id: "sku", label: "SKU", minWidth: 100 },
    ]
    if (editForm) {
      columnArray.push({ id: "inactiveDate", label: "Inactive Date", minWidth: 100 },);
    }
    columnArray.push(
      { id: "size", label: "Size", minWidth: 100 },
      { id: "units", label: "# of Units", minWidth: 100 }
    );
    if (canEditProduct || !editForm) {
      columnArray.push({ id: "action", label: "Action", minWidth: 100, align: "center" });
    }

    if (canEditProduct && editForm) {
      columnArray.push({ id: "isSizeActive", label: "Availability", minWidth: 100, align: "center",});
       
     }
    return columnArray;
  }, [canEditProduct, editForm]);    

  const columnsPriceItem: Column[] = useMemo(() => {
    let columnArray: Column[] = [
      { id: "effectiveDate", label: "Effective Date", minWidth: 100 },
    ]
    if (editForm) {
      columnArray.push({ id: "inactiveDate", label: "Inactive Date", minWidth: 100 });
    }
    columnArray.push(
      { id: "priceType", label: "File Type", minWidth: 100 },
      { id: "listPrice", label: "List Price", minWidth: 100, align: "right" },
      { id: "allowableUnitPrice", label: "Allowable Price", minWidth: 100, align: "right" }
    );
    // discounts?.map((discount) => {
    //   columnArray.push({ id: discount.displayValue, label: discount.displayValue, minWidth: 100, align: "right" });
    // });
    columnArray.push({ id: "customPrice", label: "Custom Price", minWidth: 100, align: "right" });
    if (canEditProduct || !editForm) {
      columnArray.push({ id: "action", label: "Action", minWidth: 100, align: "center" });
    }
    return columnArray;
  }, [discounts, editForm]);

  const columnsDocs: Column[] = useMemo(() => {
    let columnArray: Column[] = [
      { id: "type", label: "Type", minWidth: 80 },
      { id: "docName", label: "Name", minWidth: 100 },
      { id: "description", label: "Description", minWidth: 120 },
      { id: "action", label: "Download", minWidth: 80 },
    ];
    return columnArray;
  }, [editForm]);

  const legacyItemsProduct = [
    { icon: <ModeOutlined color="info" />, text: "Edit" },
    { icon: <DeleteOutlineOutlined color="error" />, text: "Delete" },
    { icon: <ToggleOffOutlined color="error" />, text: "Unavailable" },
    { icon: <ToggleOnOutlined color="success" />, text: "Available" },
  ];

  const legacyItemsPrice = [
    { icon: <ModeOutlined color="info" />, text: "Edit" },
    { icon: <DeleteOutlineOutlined color="error" />, text: "Delete" },
    { icon: <DoDisturbAltOutlined color="warning" />, text: "Inactivate" }
  ];

  useEffect(() => {
    let productItems = getValues("productItems") || [];
    const formattedProductRows = productItems.map(prev => {
      return {
        ...prev,
        isSizeActive : prev.isSizeActive === null ? true : prev.isSizeActive
      }
    });

    const sortedProductRows = formattedProductRows.sort((a, b) => {
      return Number(a.units) - Number(b.units);
    });

    setValue('productItems', sortedProductRows);
  }, [watch('productItems')]);


  return (
    <>
      { showProductItemPopup && <ProductItemPopup
        open={showProductItemPopup}
        onClose={() => {setShowProductItemPopup(false); setTableRowIndex(null); setEditItem(false)}}
        addProductItem={addProductItem}
        productItemDetails={productItemObject}
        isEdit={editItem}
      /> }

      { showProductItemPricePopup && <ProductItemPricePricePopup
        open={showProductItemPricePopup}
        onClose={() => {closePricingPopup();}}
        addProductItemPrice={addProductItemPrice}
        productItemPriceObject={priceItemObject}
        editPricing={editItem}
        editProduct={editForm}
        rowIndex={tableRowIndex}
        inactiveOnly={inactiveOnly}
        productItemPriceList = {getValues("productPrices")}
      /> }
      <ProductItemHistoryPopup
        open={showProductItemHistoryPopup}
        onClose={() => setShowProductItemHistoryPopup(false)}
      />
      <ProductItemPriceHistoryPopup
        open={showProductItemPriceHistoryPopup}
        onClose={() => setShowProductItemPriceHistoryPopup(false)}
      />
      <Box m="15px">
        <Header title="Product Management"
          subtitle={(canEditProduct || !editForm) ? "Please enter the information related to the product that you are adding or updating" : ""} />
        <Card>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box sx={{ display: 'flex', justifyContent: 'center' }} mb={2}>
              <FileUploader
                name="productLogo"
                label={<>Upload Your Logo here <br/>in <InputLabel sx={{ color: 'darkorange', display: 'inline'}}>.png</InputLabel > format</>}
                onFileChange={() => { }}
                onFileChangeTest={handleDropzoneChange}
                allowedFileTypes={png}
                document={productObject?.documents?.[0]}
                optionPopupObject={editForm ? (productObject?.documents?.[0] ? { show: true, message: "Do you really want to change the product logo?" }
                  : { show: true, message: "Do you really want to save the product logo?" }) : undefined}
                disableActions={!(canEditProduct || !editForm)}
              />
            </Box>
            
            <Grid container spacing={2} alignContent={"center"}>
              <Grid item md={4} xs={12}>
                <CustomTextField label="Product Name" controllerName="productName" control={control} rules={{ required: "Product Name is required." }}
                  disabled={!(canEditProduct || !editForm)} />
              </Grid>
              <Grid item md={4} xs={12}>
                <CustomTextField label="HCPCS Code" controllerName="hcpcsCode" control={control} rules={{required: "HCPCS Code is required."}}
                  disabled={!(canEditProduct || !editForm)} />
              </Grid>
              <Grid item md={4} xs={12}>
                <CustomTextField label="Composition" control={control} controllerName="composition" rules={{required: "Composition is required."}}
                  disabled={!(canEditProduct || !editForm)} />
              </Grid>
              <Grid item md={4} xs={12}>
                <CustomTextField label="Insurance Coverage" control={control} controllerName="insuranceCoverage" rules={{required: "Insurance Coverage is required."}}
                  disabled={!(canEditProduct || !editForm)} />
              </Grid>
              <Grid item md={4} xs={12}>
                <CustomTextField label="MUEs" controllerName="mues" control={control} rules={{required: "MUEs is required."}}
                  disabled={!(canEditProduct || !editForm)} />
              </Grid>
              <Grid item md={4} xs={12}>
                <CustomDropdownField label="Manufacturer" name="manufacturerId" disabled={!(canEditProduct || !editForm)}
                  options={manufacturersList} control={control} rules={{required: "Manufacturer is required."}} />
              </Grid>
              <Grid item md={8} xs={12}>
                <CustomTextField label="Product Description" controllerName="productDescription" control={control} rules={{required: "Product Description is required."}}
                  disabled={!(canEditProduct || !editForm)} />
              </Grid>
              {/* {editForm ?
                <Grid item md={2} xs={12}>
                  <CustomDatePicker label="Inactive Date" control={control} controllerName="inactiveDate"
                    disabled={!canEditProduct} />
                </Grid>
                : <></>
              } */}
              <Grid item md={2} xs={12}>
                <Controller name={"isAgreementRequired"} control={control}
                  render={({ field, fieldState }) => (
                    <>
                      <FormControlLabel
                        control={
                          <Switch {...field} name={field.name} checked={field.value}
                            size="medium" disabled={!(canEditProduct || !editForm)} onChange={(e) => { field.onChange(e); }} />
                        }
                        label={"Agreement Required"} labelPlacement="start"
                        sx={{ color: fieldState.error ? theme.palette.error.main : 'inherit',
                          "& .MuiSwitch-switchBase": {
                            color: theme.palette.error.main, // Use theme color for off state
                          },
                          "& .MuiSwitch-switchBase.Mui-checked": {
                            color: theme.palette.success.main, // Use theme color when checked
                          },
                          "& .MuiSwitch-switchBase + .MuiSwitch-track": {
                            backgroundColor: theme.palette.error.main, // Track color for off state
                          },
                          "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
                            backgroundColor: theme.palette.success.main, // Track color when checked
                          },
                         }}
                      />
                    </>
                  )}
                />
              </Grid>
            </Grid>
           
            {(canEditProduct && editForm) ?
              <>
                <Grid container justifyContent="flex-end">
                  <Button sx={{ marginTop: 2 }} onClick={updateProductValues} startIcon={<Upgrade />}>Update Product</Button>
                </Grid>
                <Divider orientation="horizontal" variant="middle" flexItem sx={{ padding: 0, margin: 0, mt: 2 }} />
              </>
              :
              <></>
            }

            <Box mt={3}>
              <BorderedSection title="Product Items">
                <Grid container direction="row" justifyContent="flex-end" alignItems="center" sx={{ borderRadius: 1, boxShadow: 1, mb: 1, p: 1, backgroundColor: colors.grey[900] }} spacing={1}>
                    {(canEditProduct || !editForm) &&
                      <Grid item md={"auto"} textAlign={"end"} alignItems="center" xs={12}>
                        <IconDetailToolBar items={legacyItemsProduct} />
                      </Grid>
                    }
                    <Grid item md={"auto"} textAlign={"end"} alignItems="center" xs={12}>
                      {(canViewProductHistory && editForm) ?
                        <Button sx={{ marginRight: 2 }} color="info"
                          onClick={() => setShowProductItemHistoryPopup(true)} startIcon={<Restore />} fullWidth>
                          Items History
                        </Button> : <></>
                      }
                      
                    </Grid>
                    <Grid item md={"auto"} textAlign={"end"} alignItems="center" xs={12}>
                      {(canEditProduct || !editForm) ?
                        <Button color="info" startIcon={<AddCircleOutlineOutlined />}
                          onClick={() => setShowProductItemPopup(true)} fullWidth>
                          Add Item
                        </Button>
                        : <></>
                      }
                    </Grid>
                </Grid>
                <CustomTable columns={columnsItem} rows={getValues('productItems') || []}
                  actionComponent={(row: any, rowIndex: number) => productItemActionComponent(row, rowIndex)}
                  availabilitySwitchComponent={(row: any, rowIndex: number) => toggleAvailabilityButton(row, rowIndex)}
                  disablePagination={true} borderBox={true} emptyText="No Product Item(s) Found" showMenu={true}/>
              </BorderedSection>
            </Box>
            
            <Box mt={3}>
              <BorderedSection title="Product Pricing">
                <Grid container direction="row" justifyContent="flex-end" alignItems="center" sx={{ borderRadius: 1, boxShadow: 1, mb: 1, p: 1, backgroundColor: colors.grey[900] }} spacing={1}>
                     {(canEditProduct || !editForm) && 
                      <Grid item md={"auto"} textAlign={"end"} alignItems="center" xs={12}>
                        <IconDetailToolBar items={legacyItemsPrice} />
                      </Grid>
                    }
                    <Grid item md={"auto"} textAlign={"end"} alignItems="center" xs={12}>
                      {(canViewProductHistory && editForm) ?
                        <Button sx={{ marginRight: 2 }} color="info"
                          onClick={() => setShowProductItemPriceHistoryPopup(true)} startIcon={<Restore />} fullWidth>
                          Pricing History
                        </Button> : <></>
                      }
                    </Grid>
                    <Grid item md={"auto"} textAlign={"end"} alignItems="center" xs={12}>
                      {(canEditProduct || !editForm) ?
                        <Button color="info" startIcon={<AddCircleOutlineOutlined />} onClick={openPriceAddingPopup} fullWidth>
                          Add Pricing
                        </Button> : <></>
                      }
                    </Grid>
                </Grid>
                <CustomTable columns={columnsPriceItem} rows={productPriceRows}
                  actionComponent={(row: any, rowIndex: number) => priceItemActionComponent(row, rowIndex)}
                  disablePagination={true} borderBox = {true} emptyText="No Product Pricing(s) Found" showMenu={true}/>
              </BorderedSection>
            </Box>
            
            {editForm &&
              <Box mt={3}>
                <BorderedSection title="Product Documents">
                  {(productDocumentInfo && productDocumentInfo.length > 0) ?
                    <CustomTable columns={columnsDocs} rows={productDocumentInfo}
                      actionComponent={(row: any, rowIndex: number) => productDocActionComponent(row, rowIndex)}
                      disablePagination={true} borderBox={true}  showMenu={true}/>
                    :
                    <Grid item xs={12}>
                      <Typography variant="body2" align="center" sx={{ fontSize: '16px', fontWeight: 'bold', fontFamily: 'Arial' }}>
                        No product documents found
                      </Typography>
                    </Grid>
                  }
                </BorderedSection>
              </Box>
            }

            <Grid container justifyContent={{ xs: 'center', md: 'flex-end' }} spacing={2} pt={2}>
              <Grid item xs={12} md="auto">
                {!editForm ?
                  <Button color="success" startIcon={<SaveOutlined />} type="submit" fullWidth={true}>
                    Save Product
                  </Button> : <></>
                }
              </Grid>
              <Grid item xs={12} md="auto">
                <Button color="error" startIcon={<CancelOutlined />} onClick={() => navigate("/product_list")} fullWidth={true}>
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </form>
        </Card>
      </Box>
      
      {optionPopupShow.show &&
        <OptionsPopup
          open={optionPopupShow.show}
          onClose={() => setOptionPopupShow({ message: "", show: false })}
          variant={optionPopupShow.variant}
          message={<>{optionPopupShow.message}</>}
          buttons={optionPopupShow.buttons}
          title={optionPopupShow.title}
          obj={optionPopupShow.obj}
        />
      }
      {datePickerPopupObj.open &&
        <DatePickerPopup
          open={datePickerPopupObj.open}
          title={datePickerPopupObj.title}
          label={datePickerPopupObj.label}
          submitDate={(date: string) => datePickerPopupObj.submitDate(date)}
          validationArray={datePickerPopupObj.validationArray}
          onClose={() => setDatePickerPopupObj({ label: "", title: "", open: false, validationArray: [], submitDate: (date: string) => { } })}
        />
      }
    </>
  );
};

export default Product;