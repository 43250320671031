import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { http } from "../../../service/http";
import { InterceptorOption } from "../../../types/CommonTypes";
import { RootState } from "../../store";
import { VALIDATE_CREDENTAILS_URL } from "../../../service/URL";
import { updateElementValue } from "../../../utils/updateElementValue";
import { useAppSelector } from "../redux-hooks";
import { downloadDocumentByDocumentId, validateEmailLinkByToken } from "../../../service/ManufacturerNotificationService";

export type UserInfo = {
    accessTokenRenewTime: number,
    canAccessApplicationValue: string,
    firstName: string,
    inactivityTimeExceeded: boolean,
    lastName: string,
    middleName: string,
    mobileNumber: string,
    refreshToken: string,
    refreshTokenExpired: boolean,
    token: string,
    userId: number,
    userName: string,
    userRole: string,
    userRoleId: number,
    roleDisplayValue: string,
    securityTokens: {
        [key: string]: string[];
    },
    userSigned: boolean,
    baaEnabled: boolean,
    baaSigningSkipped: boolean
}

export type CredentailsInfo = {
    userName: string;
    password: string;
};

type AuthApiState = {
    userInfo: UserInfo
    loginInfo?: CredentailsInfo | null;
    status: "idle" | "loading" | "failed" | "success";
    error: string | null;
    isValidCredentials: true | false;
    documentInfo?: IVRDocumentInfo | null;
    validateMessage: string | null;
    isLinkExpired: true | false;
};

const initialState: AuthApiState = {
    userInfo: {
        accessTokenRenewTime: 0,
        canAccessApplicationValue: "",
        firstName: "",
        inactivityTimeExceeded: false,
        lastName: "",
        middleName: "",
        mobileNumber: "",
        refreshToken: "",
        refreshTokenExpired: false,
        token: "",
        userId: 0,
        userName: "",
        userRole: "",
        roleDisplayValue: "",
        securityTokens: {},
        userRoleId: 0,
        userSigned: false,
        baaEnabled: false,
        baaSigningSkipped: false
    },
    loginInfo: undefined,
    status: "idle",
    error: null,
    isValidCredentials: false,
    validateMessage: null,
    isLinkExpired: false
};

export type IVRDocumentInfo = {
    ivrId: any;
    documentId?: number;
    documentName: string;
    content: string;
    documentType?: string;
    type?: string;
    fileType: string;
    auditTimestamp?: string;
};

export const validateManufacturerCredentials = createAsyncThunk<
    any,
    { loginInfo: CredentailsInfo; interceptorOption?: InterceptorOption },
    { state: RootState; rejectValue: string }
>("validateManufacturerCredentials", async (data, { rejectWithValue }) => {
    const { loginInfo, interceptorOption } = data;
    try {
        const response = await http.post(VALIDATE_CREDENTAILS_URL, loginInfo, undefined, interceptorOption);
        const resData = response.data;
        return resData.data;
    } catch (error) {
        //@ts-ignore
        return rejectWithValue(error?.data);
    }
});

export const downloadDocumentById = createAsyncThunk('downloadDocumentById', async (documentId: number) => {
    const response = await downloadDocumentByDocumentId(documentId);
    const v3Response = response.data;
    return v3Response.data;
});

export const validateEmailLink = createAsyncThunk('validateEmailLink', async (token: string) => {
    const response = await validateEmailLinkByToken(token);
    const v3Response = response.data;
    return v3Response.data;
});

const manufacturerNotificationSlice = createSlice({
    name: "manufacturerNotification",
    initialState,
    reducers: {
        updateElementInManufactureNotificationSlice: updateElementValue,
        resetManufactureNotificationState: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(validateManufacturerCredentials.pending, (state) => {
                state.status = "loading";
                state.error = null;
            })
            .addCase(validateManufacturerCredentials.fulfilled, (state, action: PayloadAction<UserInfo>) => {
                state.status = "success";
                state.isValidCredentials = true;
                state.userInfo = action.payload;
            })
            .addCase(validateManufacturerCredentials.rejected, (state, action) => {
                state.status = "failed";
                state.isValidCredentials = false;
                state.error = action.error.message || "Login failed";
            })
            .addCase(downloadDocumentById.pending, (state) => {
                state.status = "loading";
            })
            .addCase(downloadDocumentById.fulfilled, (state, action: PayloadAction<IVRDocumentInfo>) => {
                state.status = "success";
                state.documentInfo = action.payload;
            }
            )
            .addCase(downloadDocumentById.rejected, (state, action) => {
                state.status = "failed";
            })
            .addCase(validateEmailLink.pending, (state) => {
                state.status = "loading";
            })
            .addCase(validateEmailLink.fulfilled, (state, action: PayloadAction<IVRDocumentInfo>) => {
                state.status = "success";
                state.validateMessage = action.payload.content;
                state.isLinkExpired = false;
            }
            )
            .addCase(validateEmailLink.rejected, (state, action) => {
                state.status = "failed";
                state.isLinkExpired = true;
            });
    },
});

export const useValidateManufacturerCredentials = () => useAppSelector((state) => state.manufacturerNotificationSlice);
export default manufacturerNotificationSlice.reducer;