import { Box, Collapse, Grid, IconButton, Link, ListItemIcon, Menu, MenuItem, Typography, useMediaQuery, useTheme } from "@mui/material";
import Header from "../../common/Header";
import Card from "../../common/Card";
import Table from "../../common/CustomTable";
import {
  AddCircleOutlineOutlined, AppRegistrationOutlined, ListAltOutlined, SearchOutlined, TroubleshootOutlined, UTurnLeftOutlined,
  AssignmentTurnedInOutlined, ReceiptLongOutlined, VisibilityOutlined,
  EditNoteOutlined,
  RemoveShoppingCartOutlined,
  ReplayOutlined,
  CloudDownload,
  PictureAsPdfOutlined,
  TrackChanges,
} from "@mui/icons-material";
import Button from "../../common/Button";
import { useLocation, useNavigate } from "react-router-dom";
import CustomTextField from "../../common/CustomTextField";
import { useForm } from "react-hook-form";
import {
  OrderInfo, SearchOrder, fetchOrderDocumentById, fetchOrdersList, resetOrderListInfo, useOrderSlice,
  fetchProductListIncludingInactiveByUserRoleOrderSlice, getPracticeListByUserRoleOrderSlice,
  cancelOrder,
  resetCancelStatus
} from "../../../redux/slice/order/OrderSlice";
import LegacyToolBar from "../../common/LegacyToolBar";
import { useEffect, useMemo, useState } from "react";
import { PracticeInfo } from "../../../redux/slice/practiceSlice";
import { CAN_ACCESS_APPLICATION, EXPORTABLE_ITEMS, ORDERTYPE, ORDER_ACTIONS, ORDER_INVOICE_STATUS, ORDER_STATUS, PRODUCT_STATUS } from "../../../constants/applicationConstants";
import { formatDate, hideElement, sortNames } from "../../../utils/Utility";
import { selectIsTokenAvailable, selectIsValueFoundInToken, selectTokenValues, useAuthSlice } from "../../../redux/slice/authSlice";
import { ProductInfo } from "../../../redux/slice/product/productListSlice";
import React from "react";
import CustomMultipleDropDown from "../../common/CustomMultipleDropDown";
import { useAppDispatch } from "../../../redux/slice/redux-hooks";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import CustomDatePicker from "../../common/CustomDatePicker";
import moment from "moment";
import CreateOrderIVRListPopup from "./CreateOrderIVRListPopup";
import CustomIconButton from "../../common/CustomIconButton";
import PDFViewer from "../../common/pdf/PDFViewer";
import { setSingleAlertObj, showHideLoadingDialog, SingleAlertInfo } from "../../../redux/slice/commonSlice";
import { getSearchCriteriaFromSessionStorage, setSearchCriteriaToSessionStorage } from "../../../utils/StorageUtils";
import { tokens } from "../../../theme";
import CustomDateRange from "../../common/CustomDateRange";
import { DateObject } from "react-multi-date-picker";
import OptionsPopup from "../../common/OptionsPopup";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ExportToExcel from "../../common/ExportToExcel";

interface Column {
  id: string;
  label: string;
  minWidth?: number;
  align?: "right" | "center" | "left";
  format?: (value: number) => string;
}

interface Row {
  orderNumber: string;
  practiceName: string;
  patientName: string;
  productName: string;
  providerName: string;
  orderDate: string;
  dateOfService: string;
  orderType: string;
  orderStatus: string;
  invoiceStatus?: JSX.Element; // Optional if this might be hidden
  action: JSX.Element;
}

type Option = {
  value: string,
  label: string
};

const ITEMS_PER_PAGE = 20;

const OrderList = () => {

  const location = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const { control, reset, watch, setValue, getValues, handleSubmit, setError, clearErrors, formState: { errors }, } = useForm<SearchOrder>();

  const [showCard, setShowCard] = useState(false);
  const [showIVRListPopup, setShowIVRListPopup] = useState(false);
  const [showPDF, setShowPDF] = useState<{ isShow: boolean, productName: string, fileName: string }>({ isShow: false, productName: "", fileName: "" });

  const [searchCriteria, setSearchCriteria] = useState<SearchOrder>({
    practiceIds: [],
    productIds: [],
    patientName: "",
    providerName: "",
    orderNumber: "",
    invoiceNumber: "",
    // dateOfOrder: "",
    //cs-387
    fromDate: "",
    toDate: "",
    //
    // dateOfService: "",
    status: [],
    invoiceStatus: [],
    currentPage: 1,
    itemsPerPage: ITEMS_PER_PAGE,
    downloadState: false,

    fromServiceDate: "",
    toServiceDate: "",
  });

  const { userInfo } = useAuthSlice();
  const { orderListInfo, orderDocumentInfo, productsByUserRoleOrderSlice, practiceByUserRoleOrderSlice, cancelStatus } = useOrderSlice();
  const [isConfirmationOpen, setIsConfirmationOpen] = useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState<OrderInfo>();

  const canCreateConsignmentOrder = useSelector((state: RootState) => selectIsTokenAvailable("canCreateConsignmentOrder")(state));
  const canCreateDirectOrder = useSelector((state: RootState) => selectIsTokenAvailable("canCreateDirectOrder")(state));
  const canAccessOrderActions = useSelector((state: RootState) => selectIsTokenAvailable("canAccessOrderActions")(state));
  const canReturnOrder = useSelector((state: RootState) => selectIsValueFoundInToken("canAccessOrderActions", ORDER_ACTIONS.RETURN)(state));
  const canTrackOrder = useSelector((state: RootState) => selectIsValueFoundInToken("canAccessOrderActions", ORDER_ACTIONS.TRACKING)(state));
  const canDetailOrder = useSelector((state: RootState) => selectIsValueFoundInToken("canAccessOrderActions", ORDER_ACTIONS.DETAILS)(state));
  const canFulfilOrder = useSelector((state: RootState) => selectIsValueFoundInToken("canAccessOrderActions", ORDER_ACTIONS.FULFILL)(state));
  const canBillOrder = useSelector((state: RootState) => selectIsValueFoundInToken("canAccessOrderActions", ORDER_ACTIONS.BILLING)(state));
  const canViewHiddenOrderColumns = useSelector((state: RootState) => selectTokenValues("canViewHiddenOrderColumns")(state));
  const canReOrder = useSelector((state: RootState) => selectIsValueFoundInToken("canAccessOrderActions", ORDER_ACTIONS.REORDER)(state));
  const canCancelOrder = useSelector((state: RootState) => selectIsValueFoundInToken("canAccessOrderActions", ORDER_ACTIONS.CANCEL)(state));
  const [anchorElMenu, setAnchorElMenu] = useState<null | HTMLElement>(null);


  const handleMenuClose = () => {
    setAnchorElMenu(null);
  };
  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElMenu(event.currentTarget);
  };
  
  const hiddenColumns = useMemo(() => {
    let cols: Column[] = [
      { id: "invoiceStatus", label: "Invoice Status", minWidth: 100, },
    ];
    return cols;
  }, []);
  const columns = useMemo(() => {
    let cols: Column[] = [
      { id: "orderNumber", label: "V3 Order ID", minWidth: 100 },
      { id: "practiceName", label: "Practice", minWidth: 100 },
      { id: "patientName", label: "Patient", minWidth: 100 },
      { id: "productName", label: "Product", minWidth: 100 },
      { id: "providerName", label: "Provider", minWidth: 100 },
      { id: "orderDate", label: "Order Date", minWidth: 100 },
      { id: "dateOfService", label: "Date of Service", minWidth: 100 },
      { id: "orderType", label: "Order Type", minWidth: 100 },
      { id: "orderStatus", label: "Status", minWidth: 100, align: "center" },
    ];
    canViewHiddenOrderColumns && canViewHiddenOrderColumns.forEach(colId => {
      const hideCol = hiddenColumns.find(col => col.id == colId);
      if (hideCol) {
        cols.push(hideCol);
      };
    });
    cols.push({ id: "action", label: "Action", minWidth: 50, });
    return cols;
  }, [canViewHiddenOrderColumns]);

  const statusOptions = useMemo(() => {
    let options: { label: string, value: any }[] = [
      { value: PRODUCT_STATUS.PLACED, label: PRODUCT_STATUS.PLACED },
      { value: PRODUCT_STATUS.SHIPPED, label: PRODUCT_STATUS.SHIPPED },
      { value: PRODUCT_STATUS.DELIVERED, label: PRODUCT_STATUS.DELIVERED },
      { value: PRODUCT_STATUS.CANCELED, label: PRODUCT_STATUS.CANCELED },
    ];
    return options;
  }, []);

  const orderInvoiceStatusOptions = useMemo(() => {
    let options: { label: string, value: any }[] = [
      { value: ORDER_INVOICE_STATUS.PENDING, label: ORDER_INVOICE_STATUS.PENDING },
      { value: ORDER_INVOICE_STATUS.PARTIAL, label: ORDER_INVOICE_STATUS.PARTIAL },
      { value: ORDER_INVOICE_STATUS.INVOICED, label: ORDER_INVOICE_STATUS.INVOICED },
    ];
    return options;
  }, []);

  const practiceOptions = useMemo(() => {
    let options: Option[] = [];
    if (practiceByUserRoleOrderSlice && practiceByUserRoleOrderSlice.length > 0) {
      if (practiceByUserRoleOrderSlice.length === 1) {
        setValue('practiceIds', [Number(practiceByUserRoleOrderSlice[0]?.practiceId)]);
      }
      const sortedPracticeInfo: PracticeInfo[] = sortNames(practiceByUserRoleOrderSlice, 'practiceName');
      sortedPracticeInfo?.map(practice => {
        options.push({ value: practice.practiceId, label: practice.practiceName });
      });
    };
    return options;
  }, [practiceByUserRoleOrderSlice]);

  const productOptions = useMemo(() => {
    let options: Option[] = [];
    if (productsByUserRoleOrderSlice && productsByUserRoleOrderSlice.length > 0) {
      if (productsByUserRoleOrderSlice.length === 1) {
        setValue('productIds', [Number(productsByUserRoleOrderSlice[0]?.productId)]);
      }
      const sortedProductList: ProductInfo[] = sortNames(productsByUserRoleOrderSlice, 'productName');
      sortedProductList?.map((option) => {
        options.push({ label: option.productName, value: option.productId })
      });
    };
    return options;
  }, [productsByUserRoleOrderSlice]);

  //Mount
  useEffect(() => {
    fetchInitialLists();
    const orderDocId = location.state?.orderDocId;
    const tissueTrackingDocId = location.state?.tissueTrackingDocId;
    const productReturnDocId = location.state?.productReturnDocId;
    const orderNumber = location.state?.orderNumber;
    if (orderNumber) {
      setValue("orderNumber", orderNumber);
    }
    if (orderDocId || tissueTrackingDocId || productReturnDocId) {
      previewDoc(orderDocId || tissueTrackingDocId || productReturnDocId)
    }
  }, []);

  const previewDoc = async (docId: string) => {
    const actionResult = await dispatch(fetchOrderDocumentById(parseInt(docId)));
    const documentData = actionResult.payload;

    if (documentData && documentData.content) {
      setShowPDF({ isShow: true, productName: docId + ` - Order`, fileName: docId });
    };
  }

  const fetchInitialLists = async () => {
    dispatch(showHideLoadingDialog(true));
    //Don't remove below unused variables
    const responsePractices = await dispatch((getPracticeListByUserRoleOrderSlice(false)));
    const responseProducts = await dispatch(fetchProductListIncludingInactiveByUserRoleOrderSlice(false));
    dispatch(showHideLoadingDialog(false));
  }

  //Unmount
  useEffect(() => () => {
    dispatch(resetOrderListInfo());
  }, []);

  useEffect(() => {
    const orderNumber = location.state?.orderNumber;
    if (orderNumber) {
      setShowCard(true)
      setValue("orderNumber", orderNumber);
      const data = {
        ...searchCriteria,
        orderNumber: orderNumber
      }
      searchOrdersList(data);
      console.log(4);
    }
    //1. When login another user pagination is remain in the history and can redirect that page
    else if (productsByUserRoleOrderSlice && productsByUserRoleOrderSlice.length > 0) {
      let criteria = getSearchCriteriaFromSessionStorage(CAN_ACCESS_APPLICATION.ORDER_MANAGEMENT + "SearchCriteria" + userInfo.userId);
      if ((location.state && location.state.orderListSearchCriteria) || (location.state && location.state.withSearchHistory && criteria)) {
        if (location.state && location.state.withSearchHistory) {
          //Below navigate line call back to this useEffect - This for refreshing issue when navigate with state.withSearchHistory=true
          navigate("/order_list", { state: { orderListSearchCriteria: criteria } });
          return;
        }
        let searchCriteria: SearchOrder = {
          practiceIds: [],
          patientName: "",
          productIds: [],
          providerName: "",
          orderNumber: "",
          invoiceNumber: "",
          // dateOfOrder: "",
          //cs-387
          fromDate: "",
          toDate: "",
          //
          // dateOfService: "",
          status: [],
          invoiceStatus: [],
          currentPage: 0,
          itemsPerPage: 0,
          downloadState: false,
          fromServiceDate: "",
          toServiceDate: "",
        };
        if (location.state && location.state.orderListSearchCriteria) {
          searchCriteria = location.state.orderListSearchCriteria;
        } else {
          searchCriteria = criteria ? criteria : searchCriteria;
        }
        setSearchCriteria(searchCriteria);
        setValuesToCriteriaFields(searchCriteria);
        console.log(3);
        searchOrdersList(searchCriteria);
      } else {
        loadInitialOrderList(1);
      }
    }
  }, [location.state, productsByUserRoleOrderSlice]);

  const setValuesToCriteriaFields = (searchCriteria: SearchOrder) => {
    setValue("practiceIds", searchCriteria.practiceIds);
    setValue("productIds", searchCriteria.productIds);
    setValue("patientName", searchCriteria.patientName);
    setValue("providerName", searchCriteria.providerName);
    // setValue("dateOfOrder", searchCriteria.dateOfOrder);
    //CS-387
    setValue("fromDate", searchCriteria.fromDate);
    setValue("toDate", searchCriteria.toDate);
    //
    // setValue("dateOfService", searchCriteria.dateOfService);
    setValue("status", searchCriteria.status);
    setValue("invoiceStatus", searchCriteria.invoiceStatus);

    setValue("fromServiceDate", searchCriteria.fromDate);
    setValue("toServiceDate", searchCriteria.toDate);
  }

  const loadInitialOrderList = (pNumber: number = 1) => {
    const practiceIdList = practiceByUserRoleOrderSlice && practiceByUserRoleOrderSlice.length > 0
      ? practiceByUserRoleOrderSlice?.map(practice => Number(practice.practiceId)) : [];
    const productIdList = productsByUserRoleOrderSlice && productsByUserRoleOrderSlice.length > 0
      ? productsByUserRoleOrderSlice?.map(product => Number(product.productId)) : [];
    const data: SearchOrder = {
      practiceIds: practiceIdList && practiceIdList.length === 1 ? practiceIdList : [],
      productIds: productIdList && productIdList.length === 1 ? productIdList : [],
      patientName: "",
      providerName: "",
      orderNumber: "",
      invoiceNumber: "",
      // dateOfOrder: "",
      //cs-387
      fromDate: "",
      toDate: "",
      //
      // dateOfService: "",
      status: [],
      invoiceStatus: [],
      currentPage: pNumber,
      itemsPerPage: ITEMS_PER_PAGE,
      downloadState: false,
      fromServiceDate: "",
      toServiceDate: "",
    };
    setSearchCriteria(data);
    setValuesToCriteriaFields(data);
    
    searchOrdersList(data);
  }

  const searchOrdersList = (searchCriteria: SearchOrder) => {
    setSearchCriteriaToSessionStorage(CAN_ACCESS_APPLICATION.ORDER_MANAGEMENT + "SearchCriteria" + userInfo.userId, searchCriteria);

    console.log(1);
    console.log(searchCriteria);

    let dataToSearch = {
      ...searchCriteria
    }
    if (!dataToSearch.practiceIds || dataToSearch.practiceIds.length === 0) {
      dataToSearch.practiceIds = practiceByUserRoleOrderSlice && practiceByUserRoleOrderSlice.length > 0
        ? practiceByUserRoleOrderSlice?.map(practice => Number(practice.practiceId)) : [];
    }
    if (!dataToSearch.productIds || dataToSearch.productIds.length === 0) {
      dataToSearch.productIds = productsByUserRoleOrderSlice && productsByUserRoleOrderSlice.length > 0
        ? productsByUserRoleOrderSlice?.map(product => Number(product.productId)) : [];
    }
    if (!dataToSearch.status || dataToSearch.status.length === 0) {
      dataToSearch.status = statusOptions && statusOptions.length > 0 ? statusOptions?.map(status => status.value) : [];
    }
    if (!dataToSearch.invoiceStatus || dataToSearch.invoiceStatus.length === 0) {
      dataToSearch.invoiceStatus = orderInvoiceStatusOptions && orderInvoiceStatusOptions.length > 0 ? orderInvoiceStatusOptions?.map(status => status.value) : [];
    }
    dispatch(fetchOrdersList(dataToSearch));
  }

  const onSubmit = (data: SearchOrder) => {
    const data2: SearchOrder = {
      practiceIds: data.practiceIds,
      productIds: data.productIds,
      patientName: data.patientName,
      providerName: data.providerName,
      orderNumber: data.orderNumber,
      invoiceNumber: data.invoiceNumber,
      // dateOfOrder: data.dateOfOrder ? moment(data.dateOfOrder?.toString())?.format("MM/DD/YYYY") : "",
      //cs-387
      fromDate: data.fromDate,
      toDate: data.toDate,
      //
      // dateOfService: "",
      status: data.status,
      invoiceStatus: data.invoiceStatus,
      currentPage: 1,
      itemsPerPage: ITEMS_PER_PAGE,
      downloadState: false,
      fromServiceDate: data.fromServiceDate,
      toServiceDate: data.toServiceDate,
    }
    setSearchCriteria(data2);

    //Below navigate line call to useEffect[location.state, productsByUserRoleOrderSlice] and search
    navigate("/order_list", { state: { orderListSearchCriteria: data2 } });
  }

  const handlePagination = (pageNumber: number) => {
    const data = {
      ...searchCriteria,
      currentPage: pageNumber + 1
    }
    setSearchCriteria(data);

    //Below navigate line call to useEffect[location.state, productsByUserRoleOrderSlice] and search
    navigate("/order_list", { state: { orderListSearchCriteria: data } });
  }

  const handleClose = () => {
    setShowIVRListPopup(false);
  }

  const handlePreviewAction = async (row: OrderInfo) => {
    const documentId = row.orderDocumentId ? row.orderDocumentId : 0;
    const actionResult = await dispatch(fetchOrderDocumentById(documentId));
    const documentData = actionResult.payload;

    if (documentData && documentData.content) {
      setShowPDF({ isShow: true, productName: row.productName + ` - ORDER`, fileName: row.orderNumber });
    };
  };

  const handleNavigate = (row: OrderInfo) => {
    if (row.orderType === ORDERTYPE.CONSIGNMENT_ORDER) {
      navigate("/create_consignment_order", { state: { selectedOrder: row } });
    } else {
      navigate("/tissue_tracking", { state: { selectedOrder: row } });
    }
  }

  const redirectToTheOrderFulfillment = (row: OrderInfo) => {
    navigate("/fulfill_order", { state: { selectedOrderDetail: row } });
  }

  const handleBilling = (row: OrderInfo) => {
    navigate("/billing", { state: { selectedOrderBilling: row } });
  }

  const handleActionDetails = (row: OrderInfo) => {
    navigate("/details/1", { state: { orderDetails: row } })
  }

  const handleCancelAndReOrder = (row: OrderInfo) => {
    navigate("/order", { state: { orderType: ORDERTYPE.DIRECT_ORDER, orderDetails: row } })
  };

  const handleCancelOrder = async (row: any) => {
    setSelectedRow(row);
    setIsConfirmationOpen(true);
  };

  const handleCancelOrderConfirmation = () => {
    if (selectedRow) {
      dispatch(cancelOrder(selectedRow.orderId));
      setIsConfirmationOpen(false);
    }
  };

  const handleCloseCancelOrderConfirmation = () => {
    setIsConfirmationOpen(false);
  };

  useEffect(() => {
    let message = '';

    if (cancelStatus === 'success') {
      message = `Order ${selectedRow?.orderNumber} canceled successfully.`;
      const alertObj: SingleAlertInfo = {
        message: message,
        alertType: "success",
      };
      dispatch(setSingleAlertObj(alertObj));
      searchOrdersList(searchCriteria);

      const timer = setTimeout(() => {
        dispatch(resetCancelStatus());
      }, 3000);

      return () => clearTimeout(timer);
    }

    else if (cancelStatus === 'error') {
      message = `Order ${selectedRow?.orderNumber} cancelation unsuccessful.`;
      const alertObj: SingleAlertInfo = {
        message: message,
        alertType: "error",
      };
      dispatch(setSingleAlertObj(alertObj));

      const timer = setTimeout(() => {
        dispatch(resetCancelStatus());
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [cancelStatus])
 //CS-518 Passing the Order details to audit log
//  const handleClickOrderAudit = (data: any) => {
//   if (data && data.orderNumber) {
//     const IVRAuditData = {
//       auditedEntity: "ORDER",
//       entityNumber: data?.orderNumber
//     }
//     const url = `/auditlog?auditedEntity=${IVRAuditData.auditedEntity}&entityNumber=${IVRAuditData.entityNumber}`;
//     window.open(url, '_blank');
//     // navigate("/auditlog", { state: { auditedEntity: IVRAuditData.auditedEntity, entityNumber: IVRAuditData.entityNumber } });
//   }
// }
//
  const actionComponent = (row: OrderInfo): JSX.Element => {
    return (
      <>
        <CustomIconButton
          tooltipPlacement="top"
          tooltipMessage="PDF"
          color="info"
          size="small"
          variant="outlined"
          style={{ display: `${hideElement(row.orderDocumentId === null)}` }}
          onClick={() => handlePreviewAction(row)}
        >
          <PictureAsPdfOutlined fontSize="small" />
        </CustomIconButton>
        <CustomIconButton
          color="info"
          size="small"
          variant="outlined"
          onClick={() => handleActionDetails(row)}
          tooltipMessage="Details"
          tooltipPlacement="top"
        // style={{display: `${hideElement(!canDetailOrder)}`}} 
        >
          <ListAltOutlined fontSize="small" />
        </CustomIconButton>
        <CustomIconButton
          color="warning"
          size="small"
          variant="outlined"
          onClick={() => redirectToTheOrderFulfillment(row)}
          tooltipMessage="Fulfill"
          tooltipPlacement="top"
          style={{ display: `${hideElement(!(canFulfilOrder && row?.orderStatus !== ORDER_STATUS.CANCELED))}` }}
        >
          <AssignmentTurnedInOutlined fontSize="small" />
        </CustomIconButton>
        <CustomIconButton
          color="info"
          size="small"
          variant="outlined"
          onClick={() => handleBilling(row)}
          tooltipMessage="Apply INV"
          tooltipPlacement="top"
          style={{ display: `${hideElement(!canBillOrder || row?.orderStatus !== ORDER_STATUS.DELIVERED || row.invoiceStatus === "INVOICED" )}` }}
        >
          <ReceiptLongOutlined fontSize="small" />
        </CustomIconButton>
        <CustomIconButton
          color="success"
          size="small"
          variant="outlined"
          onClick={() => handleCancelAndReOrder(row)}
          tooltipMessage="Re-Order"
          tooltipPlacement="top"
          style={{ display: `${hideElement((!canReOrder || row?.orderStatus !== ORDER_STATUS.PLACED))}` }}
        >
          <ReplayOutlined fontSize="small" />
        </CustomIconButton>
        <CustomIconButton
          color="success"
          size="small"
          variant="outlined"
          onClick={(e) => handleNavigate(row)}
          tooltipMessage="Record Tx"
          tooltipPlacement="top"
          style={{ display: `${hideElement(!(canTrackOrder && row?.orderStatus === ORDER_STATUS.DELIVERED))}` }}
        >
          <TroubleshootOutlined fontSize="small" />
        </CustomIconButton>
        <CustomIconButton
          color="error"
          size="small"
          variant="outlined"
          onClick={() => navigate("/returns", { state: { orderDetails: row } })}
          tooltipMessage="Return"
          tooltipPlacement="top"
          style={{ display: `${hideElement(!(canReturnOrder && row?.orderStatus === ORDER_STATUS.DELIVERED))}` }}
        >
          <UTurnLeftOutlined fontSize="small" />
        </CustomIconButton>
        <CustomIconButton
          color="error"
          size="small"
          variant="outlined"
          onClick={() => handleCancelOrder(row)}
          tooltipMessage="Cancel"
          tooltipPlacement="top"
          style={{ display: `${hideElement((!canCancelOrder || row?.orderStatus !== ORDER_STATUS.PLACED))}` }}
        >
          <RemoveShoppingCartOutlined fontSize="small" />
        </CustomIconButton>
        {/* CS-518 */}
        {/* <CustomIconButton
          tooltipPlacement="top"
          tooltipMessage="Order Audit"
          color="warning"
          size="small"
          variant="outlined"
          // style={{ display: `${hideElement(!canDeleteIVR || row.status !== IVR_STATUS.DRAFTED)}` }}
          onClick={() => handleClickOrderAudit(row) }
        >
          <TrackChanges fontSize="small" />
        </CustomIconButton> */}
      </>
    );
  };

  const handleInvoiceListNavigate = (row: any) => {
    if (row?.invoiceStatus !== "PENDING") {
      // navigate("/invoice_list", { state: { invoiceNumber: row.invoiceNumber } });
      // CS-397 - Insted of Invoice Number , Order Number will be passed
      navigate("/invoice_list", { state: { orderNumber: row.orderNumber } });
      //
    };
  };
  // CS-387
  const handleDateRangeChange = (fromDate: DateObject | null, toDate: DateObject | null) => {
    const fromDateString = fromDate ? fromDate.format("MM/DD/YYYY") : null;
    const toDateString = toDate ? toDate.format("MM/DD/YYYY") : null;
    setValue("fromDate",fromDateString?fromDateString:"");
    setValue("toDate", toDateString?toDateString:"");
  };
  //

  const handleDateOfService = (fromDate: DateObject | null, toDate: DateObject | null) => {
    const fromDateString = fromDate ? fromDate.format("MM/DD/YYYY") : null;
    const toDateString = toDate ? toDate.format("MM/DD/YYYY") : null;
    setValue("fromServiceDate",fromDateString?fromDateString:"");
    setValue("toServiceDate", toDateString?toDateString:"");

    console.log(fromDateString?fromDateString:"");
    console.log(toDateString?toDateString:"");
  };

  const orderListData: Row[] = useMemo(() => {
    let listData: Row[] = [];
    if (orderListInfo) {
      listData = orderListInfo.content.map((details: any) => ({
        orderNumber: details.orderNumber,
        practiceName: details.practiceName,
        patientName: details.patientName || details.orderInfo?.patient ,
        productName: details.productName,
        providerName: details.providerName || details.orderInfo?.provider,
        orderDate: formatDate(details.orderDate),
        dateOfService: formatDate(details.dateOfService),
        orderType: details.orderType,
        orderStatus: details.shippingCarrier && details.trackingNumber
          ? `${details.orderStatus} (${details.shippingCarrier} ${details.trackingNumber})`
          : details.orderStatus,
        invoiceStatus: details.invoiceStatus !== "PENDING" ? (
          <Link
            component="button"
            underline="hover"
            variant="body2"
            onClick={() => handleInvoiceListNavigate(details)}
            color={colors.info[300]}
          >
            {/* CS-397 Start- Removed the invoice number  */}
            {`${details.invoiceStatus}`}
            {/* CS-397 End */}
            {/* {details.invoiceNumber ? `${details.invoiceStatus} (${details.invoiceNumber})` : `${details.invoiceStatus}`} */}
          </Link>
        ) : (
          details.invoiceStatus || ''
        ),
        action: actionComponent(details),
      }));
    }
    return listData;
  }, [orderListInfo]);

  const legacyItems = [
    { icon: <UTurnLeftOutlined color="error" />, text: "Return" },
    { icon: <TroubleshootOutlined color="success" />, text: "Tracking" },
    { icon: <VisibilityOutlined color="info" />, text: "Preview" },
    { icon: <ListAltOutlined color="info" />, text: "Details" },
    { icon: <AssignmentTurnedInOutlined color="warning" />, text: "Fulfill" },
    // { icon: <ReceiptLongOutlined color="info" />, text: "Billing" },
  ];


  const handleExport = () => {

    const searchCriteriaFromStorage = getSearchCriteriaFromSessionStorage(CAN_ACCESS_APPLICATION.ORDER_MANAGEMENT + "SearchCriteria" + userInfo.userId);

    if (searchCriteriaFromStorage) {

      let dataToSearch = {
        ...searchCriteria,
        currentPage: searchCriteriaFromStorage.currentPage,
        downloadState: true
      }
      if (!dataToSearch.practiceIds || dataToSearch.practiceIds.length === 0) {
        dataToSearch.practiceIds = practiceByUserRoleOrderSlice && practiceByUserRoleOrderSlice.length > 0
          ? practiceByUserRoleOrderSlice?.map(practice => Number(practice.practiceId)) : [];
      }
      if (!dataToSearch.productIds || dataToSearch.productIds.length === 0) {
        dataToSearch.productIds = productsByUserRoleOrderSlice && productsByUserRoleOrderSlice.length > 0
          ? productsByUserRoleOrderSlice?.map(product => Number(product.productId)) : [];
      }
      if (!dataToSearch.status || dataToSearch.status.length === 0) {
        dataToSearch.status = statusOptions && statusOptions.length > 0 ? statusOptions?.map(status => status.value) : [];
      }
      if (!dataToSearch.invoiceStatus || dataToSearch.invoiceStatus.length === 0) {
        dataToSearch.invoiceStatus = orderInvoiceStatusOptions && orderInvoiceStatusOptions.length > 0 ? orderInvoiceStatusOptions?.map(status => status.value) : [];
      }

      ExportToExcel(dataToSearch, columns, dispatch, EXPORTABLE_ITEMS.ORDER);
  }
  }
  

  return (
    <Box my="8px" ml="12px" mr="10px">
      {showIVRListPopup && <CreateOrderIVRListPopup open={showIVRListPopup} onClose={handleClose} />}
      {showPDF.isShow &&
        <PDFViewer
          isOpen={showPDF.isShow}
          onClose={() => setShowPDF({ isShow: false, productName: "", fileName: "" })}
          title={showPDF.productName}
          fileName={showPDF.fileName}
          base64String={orderDocumentInfo?.content || ""}
        />
      }
      {isConfirmationOpen && (
        <OptionsPopup
          open={isConfirmationOpen}
          onClose={() => handleCloseCancelOrderConfirmation()}
          variant="confirm"
          message="Do you really want to cancel this order?"
          buttons={[
            { name: "Confirm", color: 'primary', onClick: () => handleCancelOrderConfirmation() },
            { name: "Cancel", color: 'secondary', onClick: () => setIsConfirmationOpen(false) }
          ]}
        />
      )}
      <Box mb="15px">
        <Header title="Order List" subtitle="Order Management" />
        <Collapse in={showCard}>
          <Card>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={1.5} mt={-3} mb={-1}>
                <Grid item md={3} xs={12}>
                  <CustomMultipleDropDown name="practiceIds" label="Practice" disabled={(practiceByUserRoleOrderSlice?.length === 1)}
                    options={practiceOptions} control={control} />
                </Grid>
                <Grid item md={3} xs={12}>
                  <CustomTextField control={control} controllerName="patientName" label="Patient" />
                </Grid>
                <Grid item md={3} xs={12}>
                  <CustomMultipleDropDown label="Product" name="productIds" options={productOptions} control={control} />
                </Grid>
                <Grid item md={3} xs={12}>
                  <CustomTextField control={control} controllerName="orderNumber" label="Order Number" />
                </Grid>
                <Grid item md={3} xs={12}>
                  <CustomTextField control={control} controllerName="invoiceNumber" label="Invoice Number" />
                </Grid>
                <Grid item md={3} xs={12}>
                  <CustomTextField control={control} controllerName="providerName" label="Provider" />
                </Grid>

                <Grid item md={3} xs={12}>
                  {/* <CustomDatePicker control={control} controllerName="dateOfOrder" label="Date Ordered" /> */}
                  {/* //CS-387 Added Order Date Range  */}
                  <CustomDateRange
                    label="Order Date Range"
                    control={control}
                    controllerName="dateRange"
                    onChange={handleDateRangeChange}
                  />
                  {/* // */}
                </Grid>
                <Grid item md={3} xs={12}>
                  <CustomDateRange control={control} controllerName="dateOfService" label="Date Of Service" onChange={handleDateOfService}/>
                </Grid>
                <Grid item md={3} xs={12}>
                  <CustomMultipleDropDown name="status" label="Status" options={statusOptions} control={control} />
                </Grid>
                <Grid item md={3} xs={12}>
                  <CustomMultipleDropDown name="invoiceStatus" label="Invoice Status" options={orderInvoiceStatusOptions} control={control} />
                </Grid>
                <Grid item md={3} xs={12}>
                  <Button fullWidth size="large" startIcon={<SearchOutlined />} style={{ padding: "15px" }} type="submit" >
                    Search
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Card>
        </Collapse>
      </Box>
      <Box>
        <Card>
          <Grid container justifyContent="space-between" alignItems="center" spacing={1} mt={-2} >
            <Grid item xs={12} md>
              {/* {isMobile ? <LegacyToolBar items={legacyItems} /> : ""} */}
            </Grid>
            <Grid item xs={12} md="auto">
              <Button variant="outlined" color="info" onClick={() => setShowCard(!showCard)} startIcon={<SearchOutlined />} fullWidth>
                {showCard ? "Hide Filters" : "Show Filters"}
              </Button>
            </Grid>
            <Grid item xs={12} md="auto">
              <Button fullWidth color="warning" startIcon={<AppRegistrationOutlined />} style={{ display: `${hideElement(!canCreateConsignmentOrder)}` }}
                onClick={() => navigate("/order", { state: { orderType: ORDERTYPE.CONSIGNMENT_ORDER } })} >
                Create Consignment Order
              </Button>
            </Grid>
            <Grid item xs={12} sm="auto">
              <Button fullWidth color="info" startIcon={<AddCircleOutlineOutlined />} style={{ display: `${hideElement(!canCreateDirectOrder)}` }}
                onClick={() => setShowIVRListPopup(true)}>
                Create Order
              </Button>
            </Grid>
            <IconButton onClick={(event) => handleMenuClick(event)}>
                  <MoreVertIcon />
                </IconButton>
            <Box>
                  <Menu
                    anchorEl={anchorElMenu}
                    open={Boolean(anchorElMenu)}
                    onClose={handleMenuClose}
                  >
                    <MenuItem onClick={() => handleExport()}>
                      <ListItemIcon>
                        <CloudDownload   />
                      </ListItemIcon>
                      <Typography variant="inherit" noWrap>
                        Download Search List
                      </Typography>
                    </MenuItem>
                  </Menu>
                </Box>
          </Grid>
          <Table
            columns={columns}
            // rows={orderListInfo?.content || []}
            rows={orderListData}
            actionComponent={(row: OrderInfo) => actionComponent(row)}
            emptyText="No Orders Found"
            totalPages={orderListInfo?.totalPages}
            totalElementText={"Records: " + (orderListInfo?.totalElements ? orderListInfo?.totalElements : "0")}
            pageNumber={handlePagination}
            currentPageNumber={searchCriteria.currentPage - 1}
            showMenu = {true}
          />
        </Card>
      </Box>
    </Box>
  );
};

export default OrderList;