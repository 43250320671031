import React, { useEffect, useState } from "react";
import { Box, Grid, Typography, useTheme, Divider, Avatar, Paper, Stack } from "@mui/material";
import Header from "../../common/Header";
import Button from "../../common/Button";
import Card from "../../common/Card";
import { CancelOutlined, Download, DownloadOutlined, SimCardDownloadOutlined } from "@mui/icons-material";
import { useForm } from "react-hook-form";
import { tokens } from "../../../theme";
import { useLocation, useNavigate } from "react-router-dom";
import { fetchIVRNotesById, fetchIVRDocuments, useIVRDocumentSlice } from "../../../redux/slice/ivr/ivrDocumentSlice";
import { useAppDispatch } from "../../../redux/slice/redux-hooks";
import { setSingleAlertObj } from "../../../redux/slice/commonSlice";
import { fetchDocumentById } from "../../../redux/slice/practiceSlice";
import CustomItemList from "../../common/CustomItemList";
import CustomIconButton from "../../common/CustomIconButton";
import JSZip from 'jszip';
import { saveAs } from 'file-saver';

const IVRDetails = () => {
  const { control } = useForm<any>({});
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { IVRNotesList, ivrDocumentInfoList } = useIVRDocumentSlice();

  const downloadBase64File = (
    base64String: string,
    fileName: string,
    mimeType: string
  ) => {
    const byteCharacters = atob(base64String);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: mimeType });
    const blobUrl = URL.createObjectURL(blob);
    const anchor = document.createElement("a");
    anchor.href = blobUrl;
    anchor.download = fileName;
    document.body.appendChild(anchor); // Required for Firefox
    anchor.click();
    document.body.removeChild(anchor);
    URL.revokeObjectURL(blobUrl);
  };

  const [ivrNumber, setIvrNumber] = useState("");
  const [patientName, setPatientName] = useState("");
  const [patientDOB, setPatientDOB] = useState("");

  useEffect(() => {
    const ivrId = location?.state?.ivrInfo?.ivrId;
    setIvrNumber(location?.state?.ivrInfo?.ivrNumber);
    setPatientName(location?.state?.ivrInfo?.patient);
    setPatientDOB(location?.state?.ivrInfo?.patientDOB);
    dispatch(fetchIVRNotesById(ivrId));
    dispatch(
      fetchIVRDocuments({
        ivrId: location?.state?.ivrInfo?.ivrId,
        patientId: location?.state?.ivrInfo?.patientId,
      })
    );
  }, [location, dispatch]);

  const handleActionPreview = async (row: any, type: string) => {
    const documentId = type === "note" ? row.attachementId : row.documentId;
    if (documentId) {
      const actionResult = await dispatch(fetchDocumentById(documentId.toString()));
      const documentData = actionResult.payload;
      if (documentData && documentData.content) {
        downloadBase64File(
          documentData.content,
          documentData.documentName,
          documentData.fileType
        );
      } else {
        dispatch(
          setSingleAlertObj({
            message: "Requested Document has not found under server location!",
            alertType: "info",
          })
        );
      }
    }
  };

  const isFileAttached = (row: any) => (row.attachementId ? "" : "none");

  const documentColumns = [
    { id: "documentType", label: "Doc Type" },
    { id: "documentName", label: "Doc Name" },
    { id: "auditTimestamp", label: "Time" },
    {
      id: "action",
      label: "Download",
      align: "center" as "center" | "left" | "right",
    },
  ];

  const documentRows = ivrDocumentInfoList?.map((row: any) => ({
    ...row,
    action: (
      <CustomIconButton color="info" size="small" variant="outlined" onClick={() => handleActionPreview(row, "document")}
        tooltipMessage="Download" tooltipPlacement='bottom'>
        <Download />
      </CustomIconButton>
    ),
  }));

  const notesColumns = [
    { id: "auditUserName", label: "User" },
    { id: "action", label: "Note" },
    { id: "auditTimestamp", label: "Time" },
  ];

  const notesRows = IVRNotesList?.map((row: any) => ({
    ...row,
    action: (
      <span>
        {row.content}{" "}
        <CustomIconButton color="info" size="small" variant="outlined" style={{ display: isFileAttached(row) }}
          tooltipMessage={row.documentName} tooltipPlacement='bottom' onClick={() => handleActionPreview(row, "note")} >
          <Download />
        </CustomIconButton>
      </span>
    ),
  }));

  // const downloadAll = async () => {
    // if (ivrDocumentInfoList) {
      // Download all documents
      // for (const row of ivrDocumentInfoList) {
      //   const documentId = row.documentId;
      //   if (documentId) {
      //     const actionResult = await dispatch(fetchDocumentById(documentId.toString()));
      //     const documentData = actionResult.payload;
      //     if (documentData && documentData.content) {
      //       downloadBase64File(
      //         documentData.content,
      //         documentData.documentName,
      //         documentData.fileType
      //       );
      //     } else {
      //       console.log(`Document with ID ${documentId} not found or is empty.`);
      //     }
      //   }
      // }
      // }
  // };

  const downloadAll = async () => {
    if (!ivrDocumentInfoList) return;
    const zip = new JSZip();
    const documentsFolder = zip.folder("documents");
    if (!documentsFolder) {
      console.error("Failed to create documents folder in the zip archive.");
      return;
    }
    for (const row of ivrDocumentInfoList) {
      if (row.documentId) {
        await downloadAndAddToZip(row.documentId.toString(), documentsFolder);
      }
    }
    zip.generateAsync({ type: "blob" }).then((content) => {
      saveAs(content, "all_documents.zip");
    });
  };

  const downloadAndAddToZip = async (documentId: string, zipFolder: JSZip) => {
    const actionResult = await dispatch(fetchDocumentById(documentId.toString()));
    const documentData = actionResult.payload;
    if (documentData && documentData.content) {
      const byteCharacters = atob(documentData.content);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
  
      zipFolder.file(documentData.documentName, byteArray, { binary: true });
    } else {
      console.log(`Document with ID ${documentId} not found or is empty.`);
    }
  };

  return (
    <Box m="15px">
      <Header title="IVR Details" subtitle="IVR Management" />
      <Box mt={-0.5}>
        <Card>
          <Grid container spacing={2} mb={2}>
            <Grid item xs={12} md={6}>
              <Typography>
                <b>IVR # :</b>{" "}
                <span style={{ paddingLeft: "10px" }}>{ivrNumber}</span>
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2} mb={2}>
            <Grid item xs={12} md={4}>
              <Typography component="span" fontWeight="bold">
                Patient:{" "}
              </Typography>
              <Typography component="span" style={{ paddingLeft: "10px" }}>
                {patientName}
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography component="span" fontWeight="bold">
                DOB:{" "}
              </Typography>
              <Typography component="span" style={{ paddingLeft: "10px" }}>
                {patientDOB}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Divider
              orientation="horizontal"
              variant="middle"
              flexItem
              sx={{ padding: 0, margin: 0, mt: 1, mb: 1 }}
            />
          </Grid>
          <Grid
            container
            justifyContent="flex-end"
            alignItems="center"
            spacing={1}
          >
            <Grid item xs={12} md="auto">
              <Button
                fullWidth
                startIcon={<DownloadOutlined />}
                color="info"
                onClick={downloadAll}
              >
                Download All
              </Button>
            </Grid>
          </Grid>
          <Grid container spacing={1} mb={0} mt={0}>
            <Grid item xs={12}>
              <CustomItemList
                columns={documentColumns}
                rows={documentRows || []}
                control={control}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Divider
              orientation="horizontal"
              variant="middle"
              flexItem
              sx={{ padding: 0, margin: 0, mt: 1, mb: 1 }}
            />
          </Grid>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            spacing={1}
            mb={1}
            mt={2}
          >
            <Grid item xs={12} md="auto">
              <Typography variant="h6" fontWeight="bold" p={1}>
                Notes
              </Typography>
            </Grid>
            {/* <Grid item xs={12} md="auto">
              <Button fullWidth startIcon={<DownloadOutlined />} color="info">Download All</Button>
            </Grid> */}
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box
                sx={{
                  backgroundColor: colors.grey[900],
                  padding: 4,
                  borderRadius: 3,
                }}
              >
                {IVRNotesList?.map((note: any, index: number) => {
                  const isLeft = index % 2 === 0;
                  return (
                    <Box
                      key={index}
                      display="flex"
                      justifyContent={isLeft ? "flex-start" : "flex-end"}
                      mb={2}
                    >
                      {isLeft && (
                        <Avatar
                          sx={{
                            marginRight: 2,
                            fontSize: 20,
                            fontWeight: "bold",
                            backgroundColor: colors.info[300],
                          }}
                        >
                          {note.auditUserName.charAt(0)}
                        </Avatar>
                      )}
                      <Box
                        sx={{
                          padding: 2,
                          backgroundColor:
                            theme.palette.mode === "dark"
                              ? "#1c252e"
                              : "e9ebec",
                          minWidth: "30%",
                          maxWidth: "50%",
                          borderRadius: 4,
                        }}
                      >
                        <Typography variant="subtitle1">
                          {note.auditUserName}
                        </Typography>
                        <Typography variant="body1" mt={1} fontWeight="bold">
                          {note.content}
                        </Typography>

                        {note.attachementId && (
                          <Stack
                            alignItems="center"
                            direction="row"
                            gap={1}
                            onClick={() => handleActionPreview(note, "note")}
                            mt={1}
                            color={colors.info[300]}
                            sx={{ cursor: "pointer" }}
                          >
                            <Typography variant="h5">
                              {note.documentName}
                            </Typography>
                            <SimCardDownloadOutlined sx={{ fontSize: 30 }} />
                          </Stack>
                        )}
                        <Box display="flex" justifyContent="flex-end">
                          <Typography variant="h6" color="textSecondary">
                            {note.auditTimestamp}
                          </Typography>
                        </Box>
                      </Box>

                      {!isLeft && (
                        <Avatar
                          sx={{
                            marginLeft: 2,
                            fontSize: 20,
                            fontWeight: "bold",
                          }}
                        >
                          {note.auditUserName.charAt(0)}
                        </Avatar>
                      )}
                    </Box>
                  );
                })}
              </Box>
            </Grid>
          </Grid>
          <Box display="flex" justifyContent="end" mt="20px">
            <Button
              sx={{ mr: 1 }}
              color="error"
              startIcon={<CancelOutlined />}
              onClick={() => navigate("/ivr_list", {state: {withSearchHistory: true}})}
              // onClick={() => navigate(-1)}
            >
              Cancel
            </Button>
          </Box>
        </Card>
      </Box>
    </Box>
  );
};

export default IVRDetails;
