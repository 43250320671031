import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  useTheme,
  Divider,
  useMediaQuery,
} from "@mui/material";

import Alert from '@mui/material/Alert';
import Header from "../../common/Header";
import Button from "../../common/Button";
import Card from "../../common/Card";
import {
  OrderInfo,
  getOrderItemsByOrderId,
  TissueTrackingDetail,
  useOrderSlice,
  OrderItemDTO,
  fetchOrderDocumentById,
  orderItemInvoice,
  fetchOrderDocumentsByIds,
} from "../../../redux/slice/order/OrderSlice";
import { useForm } from "react-hook-form";
import { Cancel, Close, QuestionAnswer, VisibilityOutlined } from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";
import CustomCheckbox from "../../common/CustomCheckBox";
import { tokens } from "../../../theme";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useAppDispatch } from "../../../redux/slice/redux-hooks";
import dayjs from "dayjs";
import CustomIconButton from "../../common/CustomIconButton";
import CustomAlert from "../../common/CustomAlert";
import CustomSingleAlert from "../../common/CustomSingleAlert";
import { useCommonReducer } from "../../../redux/slice/commonSlice";
import PDFViewer from "../../common/pdf/PDFViewer";
import CustomTextField from "../../common/CustomTextField";
import CustomDatePicker from "../../common/CustomDatePicker";
import CustomItemList from "../../common/CustomItemList";
import CustomCommentPreview from "../../common/CustomCommentPreview";

interface Column {
  id: string;
  label: string;
  minWidth?: number;
  align?: "right" | "center" | "left";
  format?: (value: number) => string;
}

interface Row {
  select: any;
  orderedGrafts: string;
  serialNumber: string;
  id: number;
  tissueTrackingId: number;
  productReturnId: number;
  orderStatus: string;
  comment: string;
  tissueTrackingDocumentId: number;
  tissueTrackingInfo: {
    barcodes: number[];       
    imagesOfWounds: number[]; 
  }
  returnDocumentId: number;
  invoice: orderItemInvoice;
  invoiceId: number;
}

const Details = () => {
  const { control, setValue } = useForm<TissueTrackingDetail>({
    defaultValues: {
      patient: "",
      product: "",
      order: "",
      po: "",
      dateOfService: "",
      shippingCarrier: "",
      trackingNumber: "",
      orderStatus: "",
      providerName: "",
      practiceName: "",
    },
  });

  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const dispatch = useAppDispatch();
  const [order, setOrder] = useState<OrderInfo>();
  const { orderItemsListInfo, orderDocumentInfo } = useOrderSlice();
  const [showCommentAlert, setShowCommentAlert] = useState(false);
  const [comment, setComment] = useState("");
  const [selectedRowId, setSelectedRowId] = useState<string | null>(null); 
  const [showPDF, setShowPDF] = useState<{isShow:boolean, productName: string, fileName: string}>({isShow:false, productName: "", fileName: ""})
  const [serialNumbers, setSerialNumbers] = useState<{ [key: number]: string }>({});
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  // Mounting
  useEffect(() => {
    const orderDetails: OrderInfo = location?.state?.orderDetails;
    setOrder(orderDetails);
    if (orderDetails) {
      setInitialValues(orderDetails);
      dispatch(getOrderItemsByOrderId(orderDetails.orderId));
    }
  }, [location?.state?.orderDetails, dispatch]);

  useEffect(() => {
    const handleKeyDown = (event: { key: string; }) => {
      if (event.key === 'Escape') {
        setShowCommentAlert(false);
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const setInitialValues = (orderDetails: OrderInfo) => {
    setValue("order", orderDetails.orderNumber);
    if (orderDetails.patientId) {
      setValue("patient", orderDetails.patientName);
    }
    setValue("product", orderDetails.productName);
    setValue("po", orderDetails.poNumber);
    setValue(
      "dateOfService",
      dayjs(orderDetails.dateOfService).format("MM/DD/YYYY")
    );
    setValue("shippingCarrier", orderDetails.shippingCarrier);
    setValue("trackingNumber", orderDetails.trackingNumber);
    setValue("orderStatus", orderDetails.orderStatus);
    setValue("providerName", orderDetails.providerName || orderDetails?.orderInfo?.provider);
    setValue("practiceName", orderDetails.practiceName);
  };

  const getComment = (item: OrderItemDTO) => {
    if(item.tissueTrackingInfo && item.tissueTrackingInfo.comment){
      return item.tissueTrackingInfo.comment;
    } else if (item?.returnTrackingInfo?.additionalContext) {
      return item.returnTrackingInfo.additionalContext;
    }
    return "";
  }

  const handlePreviewAction = async (row: Row) => {
    let documentId = row.tissueTrackingDocumentId ? row.tissueTrackingDocumentId : row.returnDocumentId ;
    const actionResult = await dispatch(fetchOrderDocumentById(documentId));
    const documentData = actionResult.payload;
    if (documentData && documentData.content) {
      setShowPDF({ 
        isShow: true, 
        productName: order?.productName + (orderItemStatus(row) === "Returned" ? ` - RETURN` : ` - TISSUE TRACKING`), 
        fileName:  orderItemStatus(row) === "Returned" ? `RETURN_${order?.orderNumber}` : `TISSUE_TRACKING_${order?.orderNumber}`,
      });
    };
  };

  const handleImageViewAction = async (row: Row) => {
    let documentIds = row.tissueTrackingInfo ? row.tissueTrackingInfo.imagesOfWounds : [];
    const actionResult = await dispatch(fetchOrderDocumentsByIds(documentIds));
    const documentData = actionResult.payload;
    if (documentData && documentData.content) {
      setShowPDF({ 
        isShow: true, 
        productName: order?.productName + (orderItemStatus(row) === "Returned" ? ` - RETURN` : ` - TISSUE TRACKING`), 
        fileName:  orderItemStatus(row) === "Returned" ? `RETURN_${order?.orderNumber}` : `TISSUE_TRACKING_${order?.orderNumber}`,
      });
    };
  };

  const handleSerialNumberChange = (orderItemId: number, value: string) => {
    setSerialNumbers((prev) => ({ ...prev, [orderItemId]: value }));
  };

  // const checkSerialNumberPresent = (row: OrderItemDTO) => {
  //   const serialNumber = row.serialNumber;
    
  //   if (row?.tissueTrackingId || row?.productReturnId) {
  //     return true;
  //   } else {
  //     if (serialNumber === undefined || serialNumber === null || serialNumber.trim() === "") {
  //       return false;
  //     } else
  //       return true;
  //   }
  // };

  const orderItemRow: Row[] = useMemo(() => {
    let orderItems: Row[] = [];
    if (orderItemsListInfo) {
      orderItems = orderItemsListInfo.map((item: OrderItemDTO) => {
        return {
          select: <CustomCheckbox control={control} name="select" />,
          orderedGrafts: item.size,
          serialNumber: item.serialNumber,
          id: item.orderItemId,
          tissueTrackingId: item.tissueTrackingId,
          productReturnId: item.productReturnId,
          orderStatus: location?.state?.orderDetails?.orderStatus,
          comment: getComment(item),
          tissueTrackingDocumentId: item.tissueTrackingDocumentId,
          tissueTrackingInfo: {
            barcodes: item.tissueTrackingInfo?.barcodes || [],        
            imagesOfWounds: item.tissueTrackingInfo?.imagesOfWounds || [], 
          },
          returnDocumentId: item.returnDocumentId,
          invoice: item.invoice, 
          invoiceId: item.invoiceId
        };
      });
    }
    return orderItems;
  }, [orderItemsListInfo, control, location?.state?.orderDetails?.orderStatus]);

  // const orderItemStatus = (row: Row) => {
  //   if (row?.tissueTrackingId) {
  //     return `Applied (${row.invoice})`;
  //   } else if (row?.productReturnId) {
  //     return "Returned";
  //   }
  //   return row?.orderStatus === "DELIVERED" ? "Available" : "Pending";
  // };

  const orderItemStatus = useCallback((row: Row) => {
    if (row?.tissueTrackingId) {
      if (row?.invoiceId) {
        return "Applied (Invoiced)";
      }
      return "Applied";
    } else if (row?.productReturnId) {
      if (row?.invoiceId) {
        return "Returned";
      }
      return "Returned";
    } else if (row?.invoiceId) {
      return "Invoiced";
    } else if (row?.orderStatus === "DELIVERED") {
      return row?.invoiceId ? "Available (Invoiced)" : "Available";
    }
    return "Pending";
  }, []);

  const detailsColumns = [
    { id: "orderedGrafts", label: "Ordered Size"  },
    { id: "serialNumber", label: "Serial Number" },
    { id: "status", label: "Status"},
    { id: "document", label: "Document"},
    { id: "images", label: "Images" },
    { id: "comment", label: "Comment"},
  ];

  const detailsRows = orderItemRow?.map((row: any) => ({
    ...row,
    serialNumber: row.serialNumber ? (
      <CustomTextField
        controllerName={serialNumbers[row.id] || ""}
        control={control}
        label="Serial Number"
        value={row.serialNumber}
        onChange={(e) => handleSerialNumberChange(row.id, e.target.value)}
        sx={{ width: isMobile? "100%" : "75%", mt: 1}}
        isDisabled={true}
        
      />
    ) : (
      <p>N/A</p>
    ),
    status: orderItemStatus(row),
    document:
      row.tissueTrackingDocumentId === null && row.returnDocumentId == null ? (
        <p>N/A</p>
      ) : (
        <CustomIconButton
          tooltipPlacement="top"
          tooltipMessage="Preview"
          color="info"
          size="small"
          variant="outlined"
          onClick={() => handlePreviewAction(row)}
        >
          <VisibilityOutlined fontSize="small" />
        </CustomIconButton>
      ),
    images:
      row.tissueTrackingInfo.imagesOfWounds.length === 0 ? (
        <p>N/A</p>
      ) : (
        <CustomIconButton
          tooltipPlacement="top"
          tooltipMessage="Preview"
          color="info"
          size="small"
          variant="outlined"
          onClick={() => handleImageViewAction(row)}
        >
          <VisibilityOutlined fontSize="small" />
        </CustomIconButton>
      ),
    comment:
      row.comment === "" ? (
       "N/A"
      ) : (
          <Grid item>
            <CustomCommentPreview
              title={`Comment (${row.tissueTrackingId ? "Record product usage" : "Return product"})`}
              commentBody={row.comment}
            />
          </Grid>
      ),
  }));


  return (
    <Box m="20px">
      <Header title="Details" subtitle="Order Management" />
      {showPDF && <PDFViewer
        isOpen={showPDF.isShow}
        onClose={() => setShowPDF({ isShow: false, productName: "", fileName: "" })}
        title={showPDF.productName}
        fileName={showPDF.fileName}
        base64String={orderDocumentInfo?.content || ""}
      />}
      <Box>
        <Card>
          <Box p={1}>
            <Grid container spacing={2} mb={2}>
              <Grid item xs={12} md={6}>
                <Typography component="label" fontWeight="bold">
                  Patient :
                </Typography>
                <Typography component="label" style={{ paddingLeft: "90px" }}>
                  { order?.patientName || order?.orderInfo?.patient}
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2} mb={2}>
              <Grid item xs={12} md={6}>
                <Typography component="label" fontWeight="bold">
                  Product :
                </Typography>
                <Typography component="label" style={{ paddingLeft: "85px" }}>
                  {order?.productName}
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2} mb={2}>
              <Grid item xs={12} md={4}>
                <CustomTextField
                  controllerName="providerName"
                  control={control}
                  label="Provider"
                  disabled={true}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <CustomTextField
                  controllerName="practiceName"
                  control={control}
                  label="Practice"
                  disabled={true}
                />
              </Grid>
              <Grid item md={4}></Grid>
            </Grid>
            <Grid container spacing={2} mb={2}>
              <Grid item xs={12} md={6}>
                <Typography component="label" fontWeight="bold">
                  Order # :
                </Typography>
                <Typography component="label" style={{ paddingLeft: "88px" }}>
                  {order?.orderNumber}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography component="label" fontWeight="bold">
                  PO # :
                </Typography>
                <Typography component="label" style={{ paddingLeft: "90px" }}>
                  {order?.poNumber}
                </Typography>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Divider
                orientation="horizontal"
                variant="middle"
                flexItem
                sx={{ padding: 0, margin: 0, mt: 1, mb: 1 }}
              />
            </Grid>
            
            <Grid container spacing={1} mb={3} mt={1}>
              <Grid item xs={12}>
                <CustomItemList
                  columns={detailsColumns}
                  rows={detailsRows || []}
                  control={control}
                />
              </Grid>
            </Grid>

            <Grid container spacing={2} mb={2}>
              <Grid item xs={12} md={4}>
                <CustomDatePicker
                  control={control}
                  controllerName="dateOfService"
                  label="Date Of Service"
                  disabled={true}
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <CustomTextField 
                  controllerName="shippingCarrier"
                  control={control}
                  label="Carrier"
                  isDisabled={true}
                />
              </Grid>
              <Grid item md={4} xs={12}></Grid>
              <Grid item md={4}>
                <CustomTextField 
                  controllerName="trackingNumber" 
                  label="Shipping Tracking #" 
                  control={control}
                  isDisabled={true}
                />
              </Grid>
              
              <Grid item md={4} xs={12}>
                <CustomTextField 
                  controllerName="orderStatus" 
                  control={control} 
                  label="Status"
                  isDisabled={true}
                />
              </Grid>
              <Grid item md={4} xs={12}></Grid>
            </Grid>
            <Box mt="20px">
              <Grid
                container
                justifyContent="flex-end"
                sx={{flexWrap: "wrap" }}
                spacing={1}
              >
                <Grid item xs={12} sm="auto">
                  <Button
                    color="error"
                    startIcon={<Cancel />}
                    // onClick={() => navigate("/order_list", {state: {withSearchHistory: true}})}
                    // onClick={() => navigate(-1)}
                    onClick={() => navigate("/order_list")}
                    fullWidth
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Card>
      </Box>
    </Box>
  );
};

export default Details;
