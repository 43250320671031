import React from "react";
import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
  Grid,
  IconButton,
  useTheme,
  useMediaQuery,
  CardContent
} from "@mui/material";
import { tokens } from "../../theme";
import Card from "./Card";

interface Column {
  id: string;
  label: string | JSX.Element;
  minWidth?: number;
  align?: "right" | "center" | "left";
}

interface Row {
  [key: string]: any;
}

interface CustomItemListProps {
  columns: Column[];
  rows: Row[];
  control: any;
  handleAction?: (row: Row, actionType: string) => void;
}

const CustomItemList = ({
  columns,
  rows,
  control,
  handleAction,
}: CustomItemListProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const colors = tokens(theme.palette.mode);

  const renderListView = () => (
    <List sx={{ bgcolor: colors.grey[900], borderRadius: 3 }}>
      <ListItem sx={{ py: 0}}>
        <ListItemButton sx={{ padding: 1, borderRadius: 2, fontSize: 14 }}>
          {columns.map((column, index) => (
            <ListItemText key={index} sx={{ width: `${100 / columns.length}%` }}>
              <Typography fontWeight="bold" color={theme.palette.mode === "dark" ? colors.info[300] : colors.grey[500]}>
                {column.label}
              </Typography>
            </ListItemText>
          ))}
        </ListItemButton>
      </ListItem>
      {rows.map((row, rowIndex) => (
        <ListItem key={rowIndex} sx={{ py: 0}}>
          <ListItemButton sx={{ pl: 1, pr: 1, borderRadius: 2 }}>
            {columns.map((column, colIndex) => (
              <ListItemText key={colIndex} sx={{ width: `${100 / columns.length}%` }}>
                {column.id === 'action' && handleAction ? (
                  <IconButton onClick={() => handleAction(row, column.id)}>
                    {row[column.id]}
                  </IconButton>
                ) : (
                  row[column.id]
                )}
              </ListItemText>
            ))}
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  );

  const renderCardView = (row: any) => (
    <Card sx={{mb:1}}>
      <CardContent>
        <Grid container spacing={2} alignItems="center">
          {columns.map((column, colIndex) => (
            <Grid item xs={12} key={colIndex}>
              <Typography variant="subtitle2" color="textSecondary">
                {column.label}: {column.id === 'action' && handleAction ? (
                  <IconButton onClick={() => handleAction(row, column.id)}>
                    {row[column.id]}
                  </IconButton>
                ) : (
                  row[column.id]
                )}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </Card>
  );

  return (
    <Box>
      {isMobile ? rows.map((row) => renderCardView(row)) : renderListView()}
    </Box>
  );
};

export default CustomItemList;
